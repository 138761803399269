<div class="row changed-background">
  <div class="row first-element">
    <div class="col-2">
      <label>ID:</label>
    </div>
    <div class="col-6">
      <p>{{ item.id }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-2">
      <label>Attribute:</label>
    </div>
    <div class="col-6">
      <p>{{ item.attribute }}</p>
    </div>
  </div>
  <div class="row old-value">
    <div class="col-2">
      <label>Old value:</label>
    </div>
    <div class="col-6">
      <p>{{ item.old }}</p>
    </div>
  </div>
  <div class="row new-value">
    <div class="col-2">
      <label>New value:</label>
    </div>
    <div class="col-6">
      <p>{{ item.new }}</p>
    </div>
  </div>
</div>
