import { Component, OnInit } from '@angular/core';
import { ViewCampaignComponent } from '../../campaigns/campaign-table/view-campaign/view-campaign.component';
import { Select, Store } from '@ngxs/store';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { RouterState } from 'src/app/shared/redux';
import { Observable } from 'rxjs';
import { ViewContentComponent } from '../../content/view-content/view-content.component';
import { DrbState, SaveSourceFilesState, SaveSourceImagesState, SaveSourceLinksState, SaveSourceVideosState, SaveXliffState, loadDrbAssociatedItems } from '../redux';
import { DrbService } from 'src/app/core/services/drb.service';
import { NgxConfirmModalComponent } from 'src/app/shared/ngx-confirm-modal/ngx-confirm-modal.component';
import { SpinnerState } from 'src/app/shared/spinner/redux/spinner.state';

@Component({
  selector: 'app-delete-drb',
  templateUrl: './delete-drb.component.html',
  styleUrls: ['./delete-drb.component.css']
})
export class DeleteDrbComponent implements OnInit {
  @Select(RouterState.username) public data_username$: Observable<any>;
  @Select(DrbState.associatedItems) public associatedItems$: Observable<any>;
  @Select(DrbState.xliff) public xliffData$: Observable<any[]>;
  @Select(DrbState.sourceImages) public imageData$: Observable<any[]>;
  @Select(DrbState.sourceFiles) public fileData$: Observable<any[]>;
  @Select(DrbState.sourceLinks) public linkData$: Observable<any[]>;
  @Select(DrbState.sourceVideos) public videoData$: Observable<any[]>;
  @Select(SpinnerState) public loading: Observable<boolean>;

  public spin;
  public title;
  public item;
  public type;
  public affectedCampaignsProdIDs = [];
  public affectedContentProdIDs = [];
  public sourceItemState: any[];
  public bundle_name: string;

  public affectedCampaignsStage = [];
  public affectedContentStage = [];
  public username = '';
  constructor(
    protected ref: NbDialogRef<DeleteDrbComponent>,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
    private service: DrbService,
    private store: Store) { }

  public ngOnInit(): void {
    this.store.dispatch(new loadDrbAssociatedItems(this.item.id));

    this.associatedItems$.subscribe((data) => {
      if(data) {
        [this.affectedCampaignsStage, this.affectedContentStage] = this.service.setupAffectedItems(data, 'stage');
        [this.affectedCampaignsProdIDs, this.affectedContentProdIDs] = this.service.setupAffectedItems(data, 'prod');
      }

    });


    this.loading.subscribe((data) => (this.spin = data));
    switch(this.type) {
      case 'string':
        this.xliffData$.subscribe((data) => (this.sourceItemState = data));
        break;
      case 'file':
        this.fileData$.subscribe((data) => (this.sourceItemState = data));
        break;
      case 'link':
        this.linkData$.subscribe((data) => (this.sourceItemState = data));
        break;
      case 'video':
        this.videoData$.subscribe((data) => (this.sourceItemState = data));
        break;
      case 'image-source':
        this.imageData$.subscribe((data) => (this.sourceItemState = data));
        break;
    }
  }

  public clear(): void {
    this.affectedCampaignsProdIDs = [];
    this.affectedContentProdIDs = [];
    this.sourceItemState = [];
    this.affectedCampaignsStage = [];
    this.affectedContentStage = [];
    this.username = '';
  }

  public close(): void {
    this.clear();
    this.ref.close();
  }

  public deletionEnabled() {
    if(this.affectedCampaignsProdIDs.length > 0 ||
      this.affectedContentProdIDs.length > 0 ||
      this.affectedCampaignsStage.length > 0 ||
      this.affectedContentStage.length > 0) {

        return true;
       }

    return false;
  }

  public delete(): void {

    const localSourceItem = JSON.parse(JSON.stringify(this.sourceItemState));
    const index = localSourceItem.findIndex((i) => i.id === this.item.id);
    if(index > -1) {
      delete localSourceItem[index];
      localSourceItem.splice(index,1);
    }

    switch(this.type) {
      case 'string':
        this.store.dispatch(new SaveXliffState(localSourceItem, this.bundle_name));
        break;
      case 'file':
        this.store.dispatch(new SaveSourceFilesState(localSourceItem, this.bundle_name));
        break;
      case 'image-source':
        this.store.dispatch(new SaveSourceImagesState(localSourceItem, this.bundle_name));
        break;
      case 'link':
        this.store.dispatch(new SaveSourceLinksState(localSourceItem, this.bundle_name));
        break;
      case 'video':
        this.store.dispatch(new SaveSourceVideosState(localSourceItem, this.bundle_name));
        break;
  }

  this.close();
  }


  public confirmDeletion() {
    this.dialogService
      .open(NgxConfirmModalComponent, {
        context: {
          title: 'Confirm DELETE',
          yesButton: 'DELETE',
          noButton: 'Cancel',
          message:
            'Take notice that deleting a drb is an irreversible operation.' +
            '\n\n' +
            'To save, type CONFIRM below and click DELETE'
        }
      })
      .onClose.subscribe((data) => {
        if (data) {
          this.delete();
        }
      });
  }

  public viewCampaign(event): void {
    const found = this.affectedCampaignsStage.find((element) => element.id === event);
    if (found) {
      this.dialogService
        .open(ViewCampaignComponent, {
          closeOnEsc: false,
          closeOnBackdropClick: false,
          context: {
            campaign: found
          }
        });
    } else {
      this.toastrService.show(status, 'Campaign with this ID does not exist', {
        status: 'danger'
      });
    }
  }

  public viewContent(event): void {
    const found = this.affectedContentStage.find((element) => element.id === event);
    if (found) {
      this.dialogService
        .open(ViewContentComponent, {
          closeOnEsc: false,
          closeOnBackdropClick: false,
          context: {
            content: found
          }
        });
    } else {
      this.toastrService.show(status, 'Content with this Content ID does not exist', {
        status: 'danger'
      });
    }
  }

}
