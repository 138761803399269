import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { ToggleHide, ToggleShow } from './spinner.actions';

@State<boolean>({
    name: 'spinner',
    defaults: true
})

@Injectable()
export class SpinnerState {
  @Action(ToggleShow)
    public toggleShow(store: StateContext<boolean>) {
        store.setState(true);
    }

  @Action(ToggleHide)
  public toggleHide(store: StateContext<boolean>) {
      store.setState(false);
  }
}
