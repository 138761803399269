import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';

import { Store } from '@ngxs/store';
import { API } from '@aws-amplify/api';
import {
  DeleteContentSuccess,
  LoadContentSuccess,
  PostSaveComplete,
  PostSaveFail,
  UpdateContentSuccess
} from 'src/app/components/content/redux/content.actions';
import { CogwheelService } from 'src/app/core/services/cogwheel.service';
import { environment } from 'src/environments/environment';
import { Content } from '../../models/content';
import { AmplifyServiceBuilder } from '../services/amplify.service.builder';
import { TimestampDatePipe } from '../../shared/custom-pipe/timestamp-date.pipe';

@Injectable({ providedIn: 'root' })
export class ContentService {
  public allKeys: string[] = [];
  public content_api = 'content_api';
  public single_content_api = 'single_content_api';
  public type = 'content';

  constructor(
    private store: Store,
    private toastrService: NbToastrService,
    private formatDatePipe: TimestampDatePipe,
    private cogWheelService: CogwheelService
  ) {}

  public buildKeysForTable(content: Content[]) {
    this.cogWheelService.buildKeysForTable(content, this.allKeys, this.store, this.type, false);
  }

  public loadContentAmplify(reload?) {
    API.get(this.content_api, `/${environment.stage}/content`, '')
      .then((response) => {
        if (reload) {
          this.toastrService.show(status, 'Fetching Content Complete', {
            status: 'success'
          });
        }
        this.buildKeysForTable(response);
        response.records.forEach((element) => {
          element.local_created_dttm = this.formatDatePipe.transform(element?.created_dttm, 'timestampToDate');
          element.local_last_updated_dttm = this.formatDatePipe.transform(
            element?.last_updated_dttm,
            'timestampToDate'
          );
        });
        this.store.dispatch(new LoadContentSuccess(response));
      })
      .catch((error) => {
        console.error(error);
        this.toastrService.show(status, 'Fetching Content Failed', {
          status: 'danger'
        });
      });
  }

  public postContentAmplify(newContent: Content) {
    const requestData = AmplifyServiceBuilder(this.content_api, false, newContent);
    API.post(this.content_api, `/${environment.stage}/content`, requestData)
      .then((response) => {
        this.store.dispatch(new PostSaveComplete(response, newContent));
      })
      .catch((error) => {
        this.store.dispatch(new PostSaveFail(error));
      });
  }

  public updateContentAmplify(newContent: Content) {
    const requestData = AmplifyServiceBuilder(this.content_api, false, newContent);
    API.put(this.content_api, `/${environment.stage}/content`, requestData)
      .then((response) => {
        this.store.dispatch(new UpdateContentSuccess(response));
      })
      .catch((error) => {
        console.error(error);
        const message =
          error.response && error.response.data
            ? error.response.data.message
              ? error.response.data.message
              : error.response.data
            : 'Error!';
        this.toastrService.show(status, 'Updating Content Failed: ' + message, {
          status: 'danger'
        });
      });
  }

  public deleteContentAmplify(content: Content) {
    const requestData = AmplifyServiceBuilder(this.single_content_api, false, content);
    API.del(this.single_content_api, `/${environment.stage}/content/${content.content_key}`, requestData)
      .then((response) => {
        this.toastrService.show(status, 'Deleted Content Record', {
          status: 'success'
        });
        this.store.dispatch(new DeleteContentSuccess(content));
      })
      .catch((error) => {
        console.error(error);
        const message =
          error.response && error.response.data
            ? error.response.data.message
              ? error.response.data.message
              : error.response.data
            : 'Error!';
        this.toastrService.show(status, 'Deleting Content Failed - ' + message, {
          status: 'danger'
        });
      });
  }
}
