/* eslint-disable @angular-eslint/component-selector */
import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
      <p class="text-muted">{{ title }}: Version</p>
    </span>
  `
})
export class FooterComponent {
  public title = environment.appName;
}
