import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { CampaignService } from 'src/app/core/services/campaign.service';
import { WebSocketService } from 'src/app/core/services/web-socket.service';

@Component({
  selector: 'app-campaign-terminate-modal',
  templateUrl: './campaign-terminate-modal.component.html',
  styleUrls: ['./campaign-terminate-modal.component.css']
})
export class CampaignTerminateModalComponent implements OnInit {
  public title: string;
  public message: string;
  public yesButton = 'Yes';
  public noButton = 'No';
  public reasonText = '';
  public job_id: string;
  public campaign_name: string;

  constructor(
    protected ref: NbDialogRef<CampaignTerminateModalComponent>,
    private service: CampaignService,
    private socketService: WebSocketService,
    private toastrService: NbToastrService
  ) {}

  public ngOnInit() {}

  public clickedYes() {
    if (this.job_id) {
      this.service.terminateCampaignJob(null, this.reasonText).subscribe((data) => {
        if (data.message === 'successful') {
          this.socketService.closeWindow(data.job_id);
          this.ref.close(true);
        } else {
          this.toastrService.show(
            status,
            `Terminating Campaign: ${this.campaign_name}'s Job:` + this.job_id + ' Failed',
            {
              status: 'danger'
            }
          );
        }
      });
    } else {
      this.toastrService.show(status, 'Could not find Job ID, try refreshing the Campaigns Table.', {
        status: 'danger'
      });
    }
  }

  public clickedNo() {
    this.ref.close(false);
  }
}
