import { AmplifyAPI } from 'src/app/models/amplifyApi';
import { config } from '../../../environments/environment';
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function AmplifyServiceBuilder(api_name: string, headers?: boolean, body?: any, queryStringParameters?: any) {
  const api: AmplifyAPI = config.API.endpoints.find((obj) => obj.name === api_name);
  const requestData = {
    headers: null,
    body: null,
    queryStringParameters: null
  };
  if (headers) {
    requestData.headers = {'x-api-key': api.apiKey};
  }
  if (body) {
    requestData.body = body;
  }
  if (queryStringParameters) {
    requestData.queryStringParameters = queryStringParameters;
  }

  return requestData;
}
