export class LoadCampaign {
  public static readonly type = '[Campaign] Load Campaign';
  constructor() { }
}

export class LoadCampaignSuccess {
  public static readonly type = '[Campaign] Load Campaign SUCCESS';
  constructor(public payload: any) { }
}

export class LoadCampaignFail {
  public static readonly type = '[Campaign] Load Campaign FAIL';
  constructor() { }
}

export class LoadAffectedCampaigns {
  public static readonly type = '[Campaign] Load Affected Campaigns';
  constructor(public payload: any) { }
}

export class LoadAffectedCampaignsSuccess {
  public static readonly type = '[Campaign] Load Affected Campaigns SUCCESS';
  constructor(public payload: any) { }
}

export class LoadAffectedCampaignsByInsight {
  public static readonly type = '[Campaign] Load Affected Campaigns By Insight';
  constructor(public payload: any) { }
}

export class ReloadCampaign {
  public static readonly type = '[Campaign] Reload Campaign';
  constructor() { }
}

export class ReloadCampaignSuccess {
  public static readonly type = '[Campaign] Reload Campaign SUCCESS';
  constructor(public payload: any) { }
}

export class ReloadCampaignFail {
  public static readonly type = '[Campaign] Reload Campaign FAIL';
  constructor() { }
}


export class PostCampaign {
  public static readonly type = '[Campaign] Post Campaign';
  constructor(public payload: any, public edit?: boolean) { }
}

export class DeepDeleteCampaign {
  public static readonly type = '[Campaign] Deep Delete';
  constructor(public payload: any) { }
}

export class DeepDeleteCampaignSuccess {
  public static readonly type = '[Campaign] Deep Delete SUCCESS';
  constructor(public payload: any) { }
}

export class PostSaveComplete {
  public static readonly type = '[Campaign] Post Save Complete';
  constructor(public data: any, public campaign: any) { }
}

export class PostSaveFail {
  public static readonly type = '[Campaign] Post Save Fail';
  constructor(public payload: any) { }
}

export class SaveCampaignDeliveries {
  public static readonly type = '[Campaign Deliveries] Save Campaign Deliveries ';
  constructor(public payload: any) { }
}
export class PopulateCampaignEventTriggers {
  public static readonly type = '[Campaign] Event Triggers Populate';
  constructor(public payload: any) { }
}

export class AppendCampaignEventTriggers {
  public static readonly type = '[Campaign] Event Triggers Append';
  constructor(public payload: any) { }
}

export class AddCampaignJob {
  public static readonly type = '[Campaign] Campaign Job Added';
  constructor(public payload: any) { }
}

export class RemoveCampaignJob {
  public static readonly type = '[Campaign] Campaign Job Removed';
  constructor(public payload: any) { }
}

export class ManageCampaign {
  public static readonly type = '[Campaign] Manage Campaign';
  constructor(public payload: any) { }
}

export class PromotionSuccess {
  public static readonly type = '[Campaign] Promote Campaign';
  constructor(public payload: any) { }
}

