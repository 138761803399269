import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { CleanScoreState, LoadSmartCleanRecords, RemoveCleanScore } from '../redux';
import { Observable } from 'rxjs';
import { SmartCleanService } from 'src/app/core/services/smart-clean.service';
import { SpinnerState } from 'src/app/shared/spinner/redux/spinner.state';

@Component({
  selector: 'app-view-clean-score',
  templateUrl: './view-clean-score.component.html',
  styleUrls: ['./view-clean-score.component.scss']
})
export class ViewCleanScoreComponent implements OnInit, AfterViewInit {
  @Select(CleanScoreState.smartCleanRecords) public data$: Observable<any[]>;
  @Select(SpinnerState) public loading: Observable<boolean>;


  public Object = Object;
  public spin;
  public cleanScoreRecords = [];
  public selectedPmaps = [];
  public selectedRobots: string[] = [];
  public loadedRobots: string[] = [];

  constructor(private store: Store,
              private service: SmartCleanService) { }

  public ngAfterViewInit(): void {
    this.robotIdClear();
  }

  public ngOnInit(): void {
    this.data$.subscribe((data) => {
      if(data.length > 0) {
        this.service.populateFields(data, this.cleanScoreRecords);
        this.loadedRobots = this.selectedRobots;
      }
    });
    this.loading.subscribe((data) => (this.spin = data));
  }


  public robotIdAdd(event) {
    this.store.dispatch(new LoadSmartCleanRecords(event.label));
  }

  public robotIdRemove(event) {
    delete this.cleanScoreRecords[event.label];
    delete this.selectedPmaps[event.label];
    this.loadedRobots = this.selectedRobots;
}

  public robotIdClear() {
    this.store.dispatch(new RemoveCleanScore());
    this.cleanScoreRecords = [];
    this.selectedPmaps = [];
    this.loadedRobots = [];
    this.selectedRobots = [];
  }

}
