/*******************************************
 * ROBOT list actions
 ******************************************/
import {MapRegion, RobotMaps, RobotMapVersion} from '../../../models/qa-models/robot-maps';

export class LoadAllRobots {
  public static readonly type = '[QA] Load All Robots';
  constructor() {}
}

export class LoadOwnerRobots {
  public static readonly type = '[QA] Load Owner Robots';
  constructor() {}
}

export class LoadOwnerRobotsSuccess {
  public static readonly type = '[QA] Load Owner Robots SUCCESS';
  constructor(public payload: any) {}
}

export class LoadRobotsSuccess {
  public static readonly type = '[QA] Load Robots SUCCESS';
  constructor(public payload: any) {}
}

export class LoadRobotsFail {
  public static readonly type = '[QA] Load Robots FAIL';
  constructor() {}
}

export class ReloadAllRobots {
  public static readonly type = '[QA] Reload All Robots';
  constructor() {}
}

export class ReloadOwnerRobots {
  public static readonly type = '[QA] Reload Owner Robots';
  constructor() {}
}

export class ReloadRobotsSuccess {
  public static readonly type = '[QA] Reload Robots SUCCESS';
  constructor(public payload: any) {}
}

export class ReloadRobotsFail {
  public static readonly type = '[QA] Reload Robots FAIL';
  constructor() {}
}

export class PostRobots {
  public static readonly type = '[QA] Post Robots';
  constructor(public payload: any, public edit?: boolean) {}
}

export class DeleteRobots {
  public static readonly type = '[QA] Delete Robots';
  constructor(public payload: any) {}
}

export class DeleteRobotsSuccess {
  public static readonly type = '[QA] Delete Robots SUCCESS';
  constructor(public payload: any) {}
}

export class SaveRobotComplete {
  public static readonly type = '[QA] Robot Save Complete';
  constructor(public data: any, public robots: any) {}
}

export class SaveRobotFail {
  public static readonly type = '[QA] Robot Save Fail';
  constructor(public payload: any) {}
}

/*******************************************
 * KOZR list actions
 ******************************************/

export class LoadKOZRs {
  public static readonly type = '[QA] Load KOZRs';
  constructor() {}
}

export class LoadKOZRsSuccess {
  public static readonly type = '[QA] Load KOZRs SUCCESS';
  constructor(public payload: any) {}
}

export class LoadKOZRsFail {
  public static readonly type = '[QA] Load KOZRs FAIL';
  constructor() {}
}

export class ReloadKOZRs {
  public static readonly type = '[QA] Reload KOZRs';
  constructor() {}
}

export class ReloadKOZRsSuccess {
  public static readonly type = '[QA] Reload KOZRs SUCCESS';
  constructor(public payload: any) {}
}

export class ReloadKOZRsFail {
  public static readonly type = '[QA] Reload KOZRs FAIL';
  constructor() {}
}

export class PostKOZRs {
  public static readonly type = '[QA] Post KOZRs';
  constructor(public payload: any, public edit?: boolean) {}
}

export class DeleteKOZRs {
  public static readonly type = '[QA] Delete KOZRs';
  constructor(public payload: any) {}
}

export class SaveKOZRComplete {
  public static readonly type = '[QA] KOZR Save Complete';
  constructor(public data: any, public cannedKozr: any, public edit?: boolean) {}
}

export class SaveKOZRFail {
  public static readonly type = '[QA] KOZR Save Fail';
  constructor(public payload: any) {}
}

/*******************************************
 *          QA Tool Notification Test
 ******************************************/
export class QAPostNotificationSuccess {
  public static readonly type = '[QA] Post Notification SUCESS';
  constructor() {}
}

export class QAPostNotificationFail {
  public static readonly type = '[QA] Post Notification Fail';
  constructor(public payload: any) {}
}


/*******************************************
 *          Favorite Recommendation
 ******************************************/
export class LoadRobotMapsSuccess {
  public static readonly type = '[QA] Load Robot Maps';
  constructor(public robotMaps: RobotMaps) {}
}

export class LoadRobotMapVersionsSuccess {
  public static readonly type = '[QA] Load Robot Map Versions';
  constructor(public rmv: RobotMapVersion) {}
}

export class LoadRobotMapRegionsSuccess {
  public static readonly type = '[QA] Load Robot Map Regions';
  constructor(public regions: MapRegion[]) {}
}

/*******************************************
 *          HTA Recommendation
 ******************************************/
export class HtaRecommendationLoad {
  public static readonly type = '[QA] Load HTA-Recommendation';
  constructor() {}
}

export class HtaRecommendationLoadSucess {
  public static readonly type = '[QA] Load HTA-Recommendation SUCESS';
  constructor(public payload: any) {}
}

export class HtaRecommendationLoadFail {
  public static readonly type = '[QA] Load HTA-Recommendation FAIL';
  constructor() {}
}

export class HtaRecommendationReload {
  public static readonly type = '[QA] Reload HTA-Recommendation';
  constructor() {}
}

export class HtaRecommendationPost {
  public static readonly type = '[QA] Post HTA-Recommendation';
  constructor(public payload: any) {}
}

export class HtaRecommendationDelete {
  public static readonly type = '[QA] Delete HTA-Recommendation';
  constructor(public payload: any) {}
}

export class HtaRecommendationDeleteSuccess {
  public static readonly type = '[QA] Delete HTA-Recommendation inside redux';
  constructor(public payload: any) {}
}

export class HtaRecommendationSaveComplete {
  public static readonly type = '[QA] HTA-Recommendation Save Complete';
  constructor(public data: any, public robots: any) {}
}

export class HtaRecommendationSaveFail {
  public static readonly type = '[QA] HTA-Recommendation Save Fail';
  constructor(public payload: any) {}
}

export class RtpSendSuccess {
  public static readonly type = '[QA] RTP Send Success';
  constructor() {}
}

export class RtpSendFailed {
  public static readonly type = '[QA] RTP Send Failed';
  constructor() {}
}
