import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { CannedKozr } from 'src/app/models/cannedKozr';

@Component({
    selector: 'app-view-canned-kozr',
    templateUrl: './view-canned-kozr.component.html',
    styleUrls: ['./view-canned-kozr.component.css']
})
export class ViewCannedKozrComponent implements OnInit {
  public cannedKozr: CannedKozr;

  constructor(protected ref: NbDialogRef<ViewCannedKozrComponent>) {}

  public ngOnInit() {}

  public close() {
      this.ref.close();
  }
}
