import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { QaServiceService } from 'src/app/core/services/qa-service.service';
import { LoadKOZRs } from '../../redux/qa.actions';
import { QaState } from '../../redux/qa.state';

@Component({
  selector: 'app-timeline-kozr-bots',
  templateUrl: './timeline-kozr-bots.component.html',
  styleUrls: ['./timeline-kozr-bots.component.scss']
})
export class TimelineKozrBotsComponent implements OnInit {
  @Select(QaState.cannedKOZR) public data_kozr$: Observable<any[]>;

  public selectedRobots;
  public kozrRobots;
  public timelineNotification;
  public notificationMessage;
  public show = false;

  constructor(private store: Store, private service: QaServiceService) {
    if (this.store.selectSnapshot(QaState.cannedKOZR).length < 1) {
      store.dispatch(new LoadKOZRs());
    }
  }

  public ngOnInit() {
    this.data_kozr$.subscribe((data) => (this.kozrRobots = data));
  }

  public onRobotSelect() {
    this.timelineNotification = this.service.loadTimelineNotificationsAmplify(this.selectedRobots);

    if (this.isEmpty(this.timelineNotification)) {
      this.show = true;
      this.notificationMessage = `There are no Timeline notifications for * ${this.selectedRobots.robotid} * Robot Id.`;
    } else {
      this.show = false;
    }
  }

  public isEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }

    return true;
  }
}
