/* eslint-disable @angular-eslint/component-selector */
import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { OktaRestriction } from 'src/app/shared/okta-restrictions/okta-restrictions';
import { MENU_ITEMS, MENU_ITEMS_PROD } from './pages-menu';

import {Store } from '@ngxs/store';
import { DrbState } from 'src/app/components/drbs/redux';
import { TitleCasePipe } from '@angular/common';


@Component({
  selector: 'ngx-pages',
  styleUrls: ['pages.component.scss'],
  template: ` <nb-menu [items]="menu" autoCollapse="true"></nb-menu> `
})
export class PagesComponent {

  public menu;

  constructor(private restrictionService: OktaRestriction, private store: Store) {
    if (environment.stage === 'prod') {
      this.menu = MENU_ITEMS_PROD;
    } else {
      this.menu = MENU_ITEMS;
    }

    this.addDRBMenu();

    if (!this.restrictionService.qa_permission) {
      this.menu = this.menu.filter((item) => item.title !== 'QA Tools');
    } else if (environment.stage !== 'stage') {
      const index = this.menu.map((i) => i.title).indexOf('QA Tools');
      this.menu[index].children = this.menu[index].children.filter(
        (child) => child.title !== 'Clean Score' || child.title !== 'Mess Makers'
      );
    }
    if (!this.restrictionService.manager_permission) {
      this.menu = this.menu.filter((item) => item.title !== 'Scripted Navigation');
    }
  }

public addDRBMenu() {
  const drbIndex = this.menu.map((i) => i.title).indexOf('DRBs');
  environment.bundle_names.forEach((bundle_name => {
    const pipe = new TitleCasePipe();
    const title = pipe.transform(bundle_name.replace('_', ' '));
    const child = {
      title,
      link: '/drb/' + bundle_name,
      pathMatch: 'full',
      data: {name: bundle_name},
      children: [
        {
          title: 'View',
          link: '/drb/' + bundle_name + '/view',
          pathMatch: 'full'
        }
      ]
    };
    if (this.restrictionService.drb_user_permission && this.restrictionService.drb_manager_permission) {

      if (environment.stage !== 'prod') {
          child.children.push({
            title: 'Create/Edit',
            link: '/drb/' + bundle_name + '/create',
            pathMatch: 'full'
          });
      }

      child.children.push({
        title: 'Deploy',
        link: '/drb/' + bundle_name + '/deploy',
        pathMatch: 'full'
      });
    }
    this.menu[drbIndex].children.push(child);
  }));
}

}
