import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CampaignState, DeepDeleteCampaign, LoadAffectedCampaignsByInsight } from '../../campaigns/redux';
import { ViewCampaignComponent } from '../../campaigns/campaign-table/view-campaign/view-campaign.component';
import { RouterState } from 'src/app/shared/redux';
import { DeleteInsights } from '../redux';
import { Insight } from 'src/app/models/insight';

@Component({
  selector: 'app-delete-insight',
  templateUrl: './delete-insight.component.html',
  styleUrls: ['./delete-insight.component.css']
})
export class DeleteInsightComponent implements OnInit {
  @Select(CampaignState.affectedCampaigns) public dataAffectedCampaigns$: Observable<any>;
  @Select(RouterState.username) public data_username$: Observable<any>;
  @Select(CampaignState.spin) public dataSpin$: Observable<any>;

  public spin;
  public title;
  public insight;
  public confirmation = '';
  public affectedCampaigns = [];
  public activeCampaigns = [];
  public selectedCampaigns = [];
  public checked = [];
  public username = '';
  constructor(
    protected ref: NbDialogRef<DeleteInsightComponent>,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
    private store: Store
  ) {}

  public ngOnInit(): void {
    this.dataAffectedCampaigns$.subscribe((data) => {
      this.activeCampaigns = data.campaigns.filter(
        (campaign) => campaign.status === 'ACTIVE' || campaign.status === 'RUNNING'
      );

      return (this.affectedCampaigns = data.campaigns);
    });
    this.data_username$.subscribe((data) => (this.username = data));

    this.store.dispatch(new LoadAffectedCampaignsByInsight(this.insight));
    this.dataSpin$.subscribe((data) => (this.spin = data));
  }

  public close(): void {
    this.ref.close();
  }
  public deepDelete(): void {
    for (const campaign of this.selectedCampaigns) {
      const payload = {
        campaign_id: campaign.id,
        deleted_by: this.username
      };
      this.store.dispatch(new DeepDeleteCampaign(payload));
    }

    const insightToDelete = Insight.copy(this.insight);
    insightToDelete.deleted = true;
    this.store.dispatch(new DeleteInsights(insightToDelete));

    this.ref.close();
  }

  public checkedChange(item, event): void {
    if (event) {
      this.selectedCampaigns.push(item);
    } else {
      this.selectedCampaigns = this.selectedCampaigns.filter((campaign) => campaign.id !== item.id);
    }
  }

  public viewCampaign(event): void {
    const found = this.affectedCampaigns.find((element) => element.id === event);
    if (found) {
      this.dialogService
        .open(ViewCampaignComponent, {
          closeOnEsc: false,
          closeOnBackdropClick: false,
          context: {
            campaign: found
          }
        })
        .onClose.subscribe((d) => {
          this.store.dispatch(new LoadAffectedCampaignsByInsight(this.insight));
        });
    } else {
      this.toastrService.show(status, 'Campaign with this ID does not exist', {
        status: 'danger'
      });
    }
  }
}
