import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { TimestampDatePipe } from '../../../shared/custom-pipe/timestamp-date.pipe';

@Component({
  selector: 'app-view-content',
  templateUrl: './view-content.component.html',
  styleUrls: ['./view-content.component.css']
})
export class ViewContentComponent implements OnInit {
  public content;

  public localeDisplays: string[] = [];

  public formatted_created_time = '';
  public formatted_updated_date = '';

  constructor(protected ref: NbDialogRef<ViewContentComponent>, private formatDatePipe: TimestampDatePipe) {}

  public ngOnInit() {
    this.handleDate();
  }

  public formatDate = (time) => {
    const formatted_time = this.formatDatePipe.transform(time, 'timestampToDate');
    if (formatted_time) {
      return formatted_time;
    } else {
      return '';
    }
  };

  public handleDate() {
    const created_date = this.content.created_dttm;
    this.formatted_created_time = this.formatDate(created_date);

    const updated_date = this.content.last_updated_dttm;
    this.formatted_updated_date = this.formatDate(updated_date);
  }

  public close() {
    this.ref.close();
  }
}
