export interface Frequency {
  value: number;
  label: string;
}

// TODO add `custom` option so the user can input their own freq
export const FREQUENCIES: Frequency[] = [
    {value: 30*60, label: 'Every 30 Minutes'},
    {value: 60*60, label: 'Every Hour'},
    {value: 6*60*60, label: 'Every 6 Hours'},
    {value: 12*60*60, label: 'Every 12 Hours'},
    {value: 24*60*60, label: 'Every Day'},
    {value: 7*24*60*60, label: 'Every Week'},
    {value: 2*7*24*60*60, label: 'Every 2 Weeks'}
];
