<app-spinner *ngIf="spin"></app-spinner>
<nb-card accent="success">
  <nb-card-header>
    <div class="row">
      <div style="white-space: pre-line" class="col-auto mr-auto">
        {{ getTitleAndSubtitle() }}
      </div>
      <div class="col-auto">
        <button
          nbButton
          outline
          status="info"
          shape="round"
          size="small"
          (click)="loadTableData()"
          nbTooltip="Refresh table"
        >
          <nb-icon icon="refresh-outline"></nb-icon>
        </button>
      </div>
    </div>
    <div class="row">
      <div *ngIf="deploying" class="col-auto mr-auto">
        <p>
          <nb-icon icon="info-outline"></nb-icon>
          <i>Please click Refresh button to update the deployment status.</i>
        </p>
      </div>
    </div>

    <div class="row" *ngIf="diff">
      <div *ngIf="environment !== 'dev' && !noDrb">
        <div class="row">
          <div class="col-4">
            <label>{{ getLatestSuccessfulVersionLabel() }}</label>
            <p>{{ diff.latest_successful_version }}</p>
          </div>
          <div class="col-4">
            <label>{{ getDraftOrCandidateVersionLabel() }}</label>
            <p>{{ getDraftOrCandidateVersion() }}</p>
          </div>
          <div class="col-3" [ngClass]="getStatusClass()">
            <label>Deployment Status: </label>
            <p>{{ getDraftOrCandidateStatus() }}</p>
          </div>
        </div>
        <br />
        <div class="row" *ngIf="isDeployFailed()">
          <label
            >DRB version {{ getDraftOrCandidateVersion() }} failed to deploy. For more information contact Insights
            team.</label
          >
        </div>
      </div>
    </div>
  </nb-card-header>

  <nb-card-body>
    <div class="row" *ngIf="noDrb">
      <div class="col-auto mr-auto">There is no DRB draft version for comparison.</div>
    </div>
    <div *ngIf="!diff.diff && !noDrb">
      <div class="col-auto mr-auto">
        {{ getNoDiffMessage() }}
      </div>
    </div>
    <div *ngIf="diff.diff && !noDrb">
      <div>
        <strong class="text-uppercase">Strings</strong>
        <hr />
        <div *ngIf="diffXliff.added.length > 0">
          <p><b>Added</b></p>
          <div *ngFor="let item of diffXliff.added">
            <app-xliff-added [item]="item"> </app-xliff-added>
            <br />
          </div>
        </div>
        <div *ngIf="diffXliff.updated.length > 0">
          <p><b>Updated</b></p>
          <div *ngFor="let item of diffXliff.updated">
            <app-changed [item]="item"></app-changed>
            <br />
          </div>
        </div>
        <div *ngIf="diffXliff.removed.length > 0">
          <p><b>Removed</b></p>
          <div *ngFor="let item of diffXliff.removed">
            <app-xliff-removed [item]="item"></app-xliff-removed>
            <br />
          </div>
        </div>
      </div>
      <br />

      <div *ngFor="let source of diffSources">
        <strong class="text-uppercase">{{ source.sourceName }}</strong>
        <hr />
        <div *ngIf="source.diffItems.added.length > 0">
          <p><b>Added</b></p>
          <div *ngFor="let item of source.diffItems.added">
            <app-source-added [item]="item"></app-source-added>
            <br />
          </div>
        </div>
        <div *ngIf="source.diffItems.updated.length > 0">
          <p><b>Updated</b></p>
          <div *ngFor="let item of source.diffItems.updated">
            <app-changed [item]="item"></app-changed>
            <br />
          </div>
        </div>
        <div *ngIf="source.diffItems.removed.length > 0">
          <p><b>Removed</b></p>
          <div *ngFor="let item of source.diffItems.removed">
            <app-source-removed [item]="item"></app-source-removed>
            <br />
          </div>
        </div>
        <br />
      </div>
      <hr />
      <div class="row">
        <div class="col-auto mr-auto">
          <div *ngIf="!translating && !deploying && allowDeploy">
            <button nbButton status="success" size="small" shape="round" (click)="deployNewDrb()">Deploy</button>
          </div>
          <div *ngIf="translating">
            <b>Deployment is disabled while DRB is in translation process.</b>
          </div>
        </div>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <p>
      <i>All times displayed are in local timezone. </i>
    </p>
  </nb-card-footer>
</nb-card>
