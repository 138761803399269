<nb-card>
  <nb-card-header>
    <label
      ><strong>{{ campaign.name }}</strong></label
    >
  </nb-card-header>

  <nb-card-body>
    <!-- Campaign version 1 -->
    <div *ngIf="campaignVersion === 'v1'">
      <div>
        <label>Schedule:</label>
      </div>

      <div [formGroup]="sRecommendationForm">
        <div
          style="padding-right: 15px"
          formArrayName="regions"
          *ngFor="let region of schedules.controls; let i = index"
        >
          <ng-container [formGroupName]="i">
            <div class="form-group" formGroupName="schedule">
              <div class="row" style="padding-left: 15px">
                <label class="col">Day</label>
                <label class="col">Hour</label>
                <label class="col">Minute</label>
                <label class="col">Score</label>
              </div>
              <div class="row" style="padding-left: 15px; padding-top: 15px">
                <button class="btn btn-warning" (click)="deleteSchedule(i)" style="padding-right: 15px">-</button>
                <div class="col" style="padding-left: 15px">
                  <select required (change)="onScheduleChange()" class="form-control" formControlName="day">
                    <option value="Mon">Monday</option>
                    <option value="Tue">Tuesday</option>
                    <option value="Wed">Wednesday</option>
                    <option value="Thur">Thursday</option>
                    <option value="Fri">Friday</option>
                    <option value="Sat">Saturday</option>
                    <option value="Sun">Sunday</option>
                  </select>
                </div>
                <div class="col" style="padding-left: 65px">
                  <select required (change)="onScheduleChange()" class="form-control row" formControlName="hour">
                    <option *ngFor="let h of hourCounter(23); let i = index">{{ i }}</option>
                  </select>
                </div>
                <!-- <div  class="form-group mr-3 col"> -->
                <div class="col" style="padding-left: 25px">
                  <select (change)="onScheduleChange()" required class="form-control" formControlName="minute">
                    <option>0</option>
                    <option>15</option>
                    <option>30</option>
                    <option>45</option>
                  </select>
                </div>
                <div class="col" style="padding-left: 45px">
                  <input
                    (change)="onScheduleChange()"
                    required
                    [(ngModel)]="scoreList_v1[i]"
                    style="width: 120px"
                    formControlName="score"
                    type="number"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <hr />
      <button class="btn btn-primary col-2" color="warning" (click)="addSchedules()">Add day</button>
    </div>

    <!-- Campaign version 2 -->

    <div *ngIf="campaignVersion === 'v2'">
      <div class="row">
        <label class="col">Pmap ID:</label>
        <label class="col">Pmap Version ID:</label>
      </div>
      <div class="row">
        <div class="col">
          <input
            type="text"
            class="form-control"
            id="pmap_id"
            [(ngModel)]="pmap_id"
            required
            (change)="onRegionChanged()"
          />
        </div>
        <div class="col">
          <input
            type="text"
            class="form-control col"
            id="pmapv_id"
            [(ngModel)]="pmapv_id"
            style="padding-right: 10px"
            required
            (change)="onRegionChanged()"
          />
        </div>
      </div>
      <hr />
      <div [formGroup]="rRecommendationForm">
        <div formArrayName="regions" style="padding-right: 15px" *ngFor="let region of regions.controls; let i = index">
          <ng-container [formGroupName]="i">
            <button class="btn btn-warning" (click)="deleteRegions(i)" style="padding-right: 10px">-</button>
            <br />
            <div>
              <div class="row">
                <label class="col-6">Region ID:</label>
              </div>
              <div class="row" style="padding-left: 15px; padding-right: 15px">
                <input
                  type="text"
                  class="form-control col-6"
                  id="region_id"
                  formControlName="region_id"
                  placeholder="If left untouched, __all__ is selected."
                />
                <!-- <label class="col-6">If left untouched, DEFAULT_REGION is selected.</label> -->
              </div>

              <!-- 2nd schedule row -->
              <div class="form-group" formGroupName="schedule">
                <div class="row">
                  <label class="col">Day of week:</label>
                  <label class="col">Hour:</label>
                  <label class="col">Minute:</label>
                  <label class="col">Score:</label>
                </div>
                <div class="row" style="padding-left: 15px">
                  <select required (change)="onRegionChanged()" class="form-control col" formControlName="day">
                    <option value="Mon">Monday</option>
                    <option value="Tue">Tuesday</option>
                    <option value="Wed">Wednesday</option>
                    <option value="Thur">Thursday</option>
                    <option value="Fri">Friday</option>
                    <option value="Sat">Saturday</option>
                    <option value="Sun">Sunday</option>
                  </select>
                  <select
                    required
                    (change)="onRegionChanged()"
                    class="form-control col mr-3 ml-3"
                    formControlName="hour"
                  >
                    <option *ngFor="let h of hourCounter(23); let i = index">{{ i }}</option>
                  </select>
                  <select (change)="onRegionChanged()" required class="form-control col mr-3" formControlName="minute">
                    <option>0</option>
                    <option>15</option>
                    <option>30</option>
                    <option>45</option>
                  </select>
                  <input
                    (change)="onRegionChanged()"
                    required
                    [(ngModel)]="scoreList_v2[i]"
                    formControlName="score"
                    type="number"
                    class="form-control col"
                  />
                </div>
              </div>
            </div>
            <hr />
          </ng-container>
        </div>
      </div>
      <div>
        <button type="button" class="btn btn-primary" (click)="addRegion()" style="padding-top: 5px">
          Add region schedule
        </button>
      </div>
    </div>
    <br />
    <div *ngIf="displayMaxSizeError">
      <p class="bg-gradient-warning text-danger">Maximum amount of region schedules achieved.</p>
    </div>
  </nb-card-body>
</nb-card>
