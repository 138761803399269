<nb-card accent="success" style="width: 105%; margin: auto">
  <nb-card-header>
    <div class="row">
      <div class="col-auto mr-auto">
        <nb-icon icon="play-circle-outline" *ngIf="title === 'Activate'"></nb-icon>
        <nb-icon icon="stop-circle-outline" *ngIf="title !== 'Activate'"></nb-icon>
      </div>
      <div class="col-auto mr-auto">
        <div class="row">Are you sure you want to "{{ title }}"?</div>
        <div class="row">
          <div class="col-auto">
            {{ campaign.name }}
          </div>
        </div>
      </div>
      <div class="col-auto">
        <button nbButton (click)="clickedCancel()">X</button>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class="row" *ngIf="!disableDatepicker">
      <div class="col"><label>Start Time:</label></div>
      <div class="col-9">
        <mat-form-field appearance="outline">
          <mat-label>Choose a date</mat-label>
          <input
            matInput
            [min]="minStartDate"
            required
            (dateChange)="changeEvent()"
            [matDatepicker]="picker"
            [(ngModel)]="startDateForDatepicker"
          />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker type="datetime">
            <mat-datepicker-actions>
              <div class="d-flex flex-row-reverse">
                <div class="p-2">
                  <button nbButton status="success" matDatepickerApply>Apply</button>
                </div>
                <div class="p-2">
                  <button nbButton matDatepickerCancel>Cancel</button>
                </div>
              </div>
            </mat-datepicker-actions>
          </mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <br />
    <div class="row" *ngIf="!disableDatepicker">
      <div class="col"><label>End Time:</label></div>
      <div class="col-9">
        <mat-form-field appearance="outline">
          <mat-label>Choose a date</mat-label>
          <input matInput [min]="minEndDate" required [matDatepicker]="picker2" [(ngModel)]="endDateForDatepicker" />
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2 type="datetime">
            <mat-datepicker-actions>
              <div class="d-flex flex-row-reverse">
                <div class="p-2">
                  <button nbButton status="success" matDatepickerApply>Apply</button>
                </div>
                <div class="p-2">
                  <button nbButton matDatepickerCancel>Cancel</button>
                </div>
              </div>
            </mat-datepicker-actions>
          </mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <br />

    <div class="row" *ngIf="!disableDatepicker">
      <div class="col"><label>Schedule:</label></div>
      <div class="col-9">
        <nb-select
          id="schedule_seconds"
          name="schedule_seconds"
          class="custom small"
          placeholder="Select Schedule"
          [(ngModel)]="campaign.schedule_seconds"
          style="width: 100%"
          [disabled]="disableDatepicker"
        >
          <nb-option *ngFor="let frequency of FREQUENCIES" [value]="frequency.value">{{ frequency.label }} </nb-option>
        </nb-select>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div class="d-flex flex-row-reverse">
      <div class="p-2">
        <button
          nbButton
          status="success"
          (click)="clickedStart()"
          *ngIf="title === 'Activate'"
          nbTooltip="Clicking Yes will start the Campaign as per the schedule."
        >
          Yes
        </button>

        <button
          nbButton
          status="success"
          (click)="clickedStop()"
          *ngIf="title !== 'Activate'"
          nbTooltip="Clicking Yes will deactivate the Campaign and Insight immediately."
        >
          Yes
        </button>
      </div>
      <div class="p-2">
        <button nbButton status="danger" (click)="clickedCancel()">No</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
