import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-preview-html',
    templateUrl: './preview-html.component.html',
    styleUrls: ['./preview-html.component.css']
})
export class PreviewHtmlComponent implements OnInit {

  public html;
  constructor() {}

  public ngOnInit(): void {}
}
