import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {
  NbButtonModule,
  NbCardModule,
  NbLayoutModule,
  NbSelectModule,
  NbSidebarModule,
  NbThemeModule
} from '@nebular/theme';
import { ThemeModule } from '../theme.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, NbThemeModule.forRoot({ name: 'default' }), ThemeModule.forRoot()],
  exports: [NbButtonModule, NbCardModule, NbLayoutModule, NbSidebarModule, NbSelectModule, NbThemeModule, ThemeModule]
})
export class NebularModule {}
