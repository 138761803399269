import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { iif, insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { InsightService } from 'src/app/core/services/insight.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { TableSpinerReloadHide } from 'src/app/shared/ngx-table/ngx-table/redux/table-spinner.actions';
import { InsightsStateModel } from './insights-state.model';
import {
  AddInsightJob,
  DeleteInsights,
  InsightSpinHide,
  IsightSpinShow,
  LoadInsights,
  LoadInsightsInactive,
  LoadInsightsInactiveSuccess,
  LoadInsightsSuccess,
  LoadListOfFailedJobs,
  ManageInsight,
  PostInsights,
  ReloadInsights,
  ReloadInsightsSuccess,
  RemoveInsightJob,
  SaveInsightComplete,
  SaveInsightFail,
  StoreListOfFailedJobs
} from './insights.actions';

@State<InsightsStateModel>({
  name: 'insights',
  defaults: {
    allInsights:[],
    insightsActive: [],
    insightsInactive: [],
    error: null,
    insightJobs: [],
    insightSpin: null,
    failedJobs: []
  }
})
@Injectable()
export class InsightsState {
  constructor(
    private service: InsightService,
    private toastrService: NbToastrService,
    private utilityService: UtilityService
  ) {}

  @Selector()
  public static failedJobs(state: InsightsStateModel) {
    return state.failedJobs;
  }

  @Selector()
  public static insightsActive(state: InsightsStateModel) {
    return state.insightsActive;
  }

  @Selector()
  public static insightsInactive(state: InsightsStateModel) {
    return state.insightsInactive;
  }

  @Selector()
  public static allInsights(state: InsightsStateModel) {
    return state.insightsActive.concat(state.insightsInactive);
  }

  @Selector()
  public static insightsJobs(state: InsightsStateModel) {
    return state.insightJobs;
  }

  @Selector()
  public static insightSpinner(state: InsightsStateModel) {
    return state.insightSpin;
  }

  @Action(LoadInsights)
  public async loadInsights(ctx: StateContext<InsightsStateModel>) {
    ctx.dispatch(new IsightSpinShow(true));
    this.service.loadInsightsAmplify();
  }

  @Action(LoadInsightsSuccess)
  public loadInsightsSuccess(ctx: StateContext<InsightsStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      insightsActive: payload
    });
    ctx.dispatch(new InsightSpinHide(false));
  }

  @Action(ReloadInsights)
  public async reloadInsights(ctx: StateContext<InsightsStateModel>) {
    this.toastrService.show(status, 'Fetch Initiated', { status: 'info' });
    this.service.loadInsightsAmplify(true, true);
  }

  @Action(ReloadInsightsSuccess)
  public reloadInsightsSuccess(ctx: StateContext<InsightsStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      insightsActive: payload
    });
    ctx.dispatch(TableSpinerReloadHide);
  }

  @Action(PostInsights)
  public async postInsights(ctx: StateContext<InsightsStateModel>, { payload }: any) {
    Object.keys(payload).forEach((data) => {
      if (data.startsWith('local_')) {
        delete payload[data];
      }
    });
    this.service.postInsightsAmplify(payload);
  }

  @Action(DeleteInsights)
  public deleteInsights(ctx: StateContext<InsightsStateModel>, { payload }: any) {
    this.service.deleteInsightsAmplify(payload);
  }

  @Action(SaveInsightComplete)
  public insightsComplete(ctx: StateContext<InsightsStateModel>, { data, insights }: any) {
    // Data can contain "true" when its updating so we update item with "insights" which is local copy
    // In case you make new "insights" with post it will return new Object with "data" so in second case
    // We append it to last place in array with data
    const state = ctx.getState();
    if (data === true) {
      if (insights.deleted === true) {
        ctx.setState(
          patch({
            // @ts-ignore
            insightsActive: removeItem((item) => item.id === insights.id)
          })
        );
      } else {
        ctx.setState(
          patch({
            insightsActive: updateItem((item) => item.id === insights.id, insights)
          })
        );
      }
    } else {
      ctx.setState(
        patch({
          insightsActive: [...state.insightsActive, data]
        })
      );
    }
    this.toastrService.show(status, 'Save: Complete', { status: 'success' });
  }

  @Action(SaveInsightFail)
  public insightsFail(ctx: StateContext<InsightsStateModel>, { payload }: any) {
    const state = ctx.getState();
    const error = this.utilityService.convertErrorToMessage(payload);
    ctx.setState({
      ...state,
      error
    });
    this.toastrService.show(status, 'Save: Fail - ' + error, {
      status: 'danger',
      duration: 6000
    });
  }

  @Action(ManageInsight)
  public activateInsight(ctx: StateContext<InsightsStateModel>, { payload }: any) {
    ctx.setState(
      patch<InsightsStateModel>({
        insightsActive: iif<any[]>(
          (insights) => insights.some((f) => f.id === payload.id),
          updateItem<any>((f) => f.id === payload.id, payload),
          insertItem(payload)
        )
      })
    );
  }

  @Action(AddInsightJob)
  public insightJobInsert(ctx: StateContext<InsightsStateModel>, { payload }: any) {
    ctx.setState(
      patch<InsightsStateModel>({
        insightJobs: iif<any[]>(
          (insightJobs) => insightJobs.some((f) => f.insightId === payload.insightId),
          updateItem<any>((f) => f.insightId === payload.insightId, payload),
          insertItem(payload)
        )
      })
    );
  }

  @Action(RemoveInsightJob)
  public insightJobRemove(ctx: StateContext<InsightsStateModel>, { payload }: any) {
    ctx.setState(
      patch({
        // @ts-ignore
        insightJobs: removeItem((item) => item.job_id === payload)
      })
    );
  }

  @Action(IsightSpinShow)
  public insightsSpinShow(ctx: StateContext<InsightsStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      insightSpin: payload
    });
  }

  @Action(InsightSpinHide)
  public insightsSpinFail(ctx: StateContext<InsightsStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      insightSpin: payload
    });
  }

  @Action(LoadListOfFailedJobs)
  public listFailedJobs(ctx: StateContext<InsightsStateModel>) {
    this.service.loadFailedJobsAmplify();
  }

  @Action(StoreListOfFailedJobs)
  public storeListOfFailedJobs(ctx: StateContext<InsightsStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      failedJobs: payload
    });
  }

  @Action(LoadInsightsInactive)
  public async loadInsightsInactive(ctx: StateContext<InsightsStateModel>) {
    this.service.loadInsightsAmplify();
  }

  @Action(LoadInsightsInactiveSuccess)
  public loadInsightsInactiveSuccess(ctx: StateContext<InsightsStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      insightsInactive: payload
    });
    ctx.dispatch(new InsightSpinHide(false));
  }
}
