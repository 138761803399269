import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NbDateService, NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { Select, Store, Actions, ofActionCompleted } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { FREQUENCIES } from 'src/app/models/frequencies';
import { Insight } from 'src/app/models/insight';
import { NgxGenericModalComponent } from 'src/app/shared/ngx-generic-modal/ngx-generic-modal.component';
import { RouterState } from '../../../shared/redux/router-state';
import { PostInsights } from '../redux/insights.actions';
import { SaveInsightComplete, ReloadInsights, LoadInsightsInactive } from '../redux';

@Component({
  selector: 'app-edit-insight',
  templateUrl: './edit-insight.component.html',
  styleUrls: ['./edit-insight.component.scss', '../../../shared/common_syles.css']
})
export class EditInsightComponent implements OnInit, AfterViewInit {
  @Select(RouterState.theme) public data$: Observable<any>;
  @Select(RouterState.username) public data_username$: Observable<any>;
  @ViewChild('codeeditor') private codeEditor;

  public FREQUENCIES = FREQUENCIES;
  public theme = '';

  public insight: Insight;
  public title: string;
  public showDatepicker = false;
  public dateForDatepickerStart: Date;
  public dateForDatepickerEnd: Date;

  //Using to disable future dates in datepicker
  public minStartDate: Date;
  public minEndDate: Date;

  public username = '';

  public categories = [
    'KOZ_recommendation',
    'SCM',
    'coach',
    'coaching',
    'ecommerce',
    'education',
    'first-time',
    'iRobotFor message',
    'low_usage_assist',
    'maintenance',
    'maintenance_reminder',
    'marketing',
    'nps',
    'onboarding',
    'schedule_recommendation',
    'seasonal_recommendation',
    'smart-home',
    'smart-maps',
    'start_refuse_assist',
    'support',
    'testing_microfleet_racer',
    'warnings',
    'welcome_optimize_reminder'
  ];

  constructor(
    private store: Store,
    protected ref: NbDialogRef<EditInsightComponent>,
    private actions$: Actions,
    private dialogService: NbDialogService,
    protected dateService: NbDateService<Date>,
    private toastrService: NbToastrService
  ) {
    this.data$.subscribe((data) => (this.theme = data));
    this.minStartDate = this.dateService.addMonth(this.dateService.today(), 0);
    this.minEndDate = this.dateService.addDay(this.dateService.today(), 1);
  }

  public ngOnInit() {
    const targetInsight = new Insight();
    Object.assign(targetInsight, this.insight);
    this.insight = targetInsight;

    this.data_username$.subscribe((data) => (this.username = data));
    this.actions$.pipe(ofActionCompleted(SaveInsightComplete)).subscribe((data) => {
      if (data) {
        this.ref.close();
      }
    });
    this.showDatepicker = this.insight.enabled;

    this.convertDateForPicker();
  }

  public ngAfterViewInit() {
    const default_themes = ['default', 'corporate'];

    const theme = default_themes.includes(this.theme) ? 'default' : 'material';
    setTimeout(() => {
      const editor = this.codeEditor.codeMirror;
      // editor.setOption("extraKeys", {
      //   LeftTripleClick: function (cm) {
      //     cm.setOption("fullScreen", !cm.getOption("fullScreen"));
      //   },
      // });
      editor.setOption('theme', theme);
    }, 10);
  }
  public checkedActivated() {
    this.showDatepicker = !this.insight.enabled;
  }

  public save() {
    if (this.insight.enabled) {
      if (this.dateForDatepickerStart && this.dateForDatepickerEnd) {
        this.insight.start_date = Math.round(this.dateForDatepickerStart.getTime() / 1000);
        this.insight.end_date = Math.round(this.dateForDatepickerEnd.getTime() / 1000);
      } else {
        this.toastrService.show(status, 'Start and End Time is required for an Activated Insight.', {
          status: 'danger'
        });

        // We don't want to execute the save API call
        return;
      }
    } else {
      this.insight.start_date = null;
      this.insight.end_date = null;
    }
    if(this.title === 'Clone'){
      this.insight.created_by = this.username;
      this.insight.last_updated_by = this.username;
    }
    if ('id' in this.insight) {
      this.insight.last_updated_by = this.username;
    } else {
      this.insight.created_by = this.username;
      this.insight.last_updated_by = this.username;
    }

    this.store.dispatch(new PostInsights(this.insight));
  }

  public close() {
    this.dialogService
      .open(NgxGenericModalComponent, {
        context: {
          title: 'Confirm Close',
          message: 'Are you sure that you want to close the window? Any changes that you have made will be lost.'
        }
      })
      .onClose.subscribe((data) => {
        if (data) {
          this.ref.close();
        }
      });
  }
  public changeEvent() {
    this.compareStartEndDate();
  }

  public convertDateForPicker() {
    if (this.insight.start_date) {
      this.dateForDatepickerStart = new Date(this.insight.start_date * 1000);
    }
    if (this.insight.end_date) {
      this.dateForDatepickerEnd = new Date(this.insight.end_date * 1000);
    }

    if (this.insight.start_date < this.insight.end_date) {
      this.minEndDate = this.dateService.addDay(new Date(this.insight.start_date * 1000), 1);
    }
  }

  public compareStartEndDate() {
    if (this.dateForDatepickerEnd) {
      if (this.dateForDatepickerStart.getTime() > this.dateForDatepickerEnd.getTime()) {
        this.dateForDatepickerEnd = this.dateService.addDay(new Date(this.dateForDatepickerStart.getTime()), 1);
        this.minEndDate = this.dateService.addDay(new Date(this.dateForDatepickerStart.getTime()), 1);
      } else {
        this.minEndDate = this.dateService.addDay(new Date(this.dateForDatepickerStart.getTime()), 1);
      }
    } else {
      this.minEndDate = this.dateService.addDay(new Date(this.dateForDatepickerStart.getTime()), 1);
    }
  }
}
