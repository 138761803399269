import { Asset } from './asset';

export class Insight extends Asset {
  public id?: number;
  public category: string;
  public name: string;
  public status?: string = 'INACTIVE';
  public batch_job_id?: string;

  // Make insights easier to find
  public tags?: string[]; // FIXME should be a Set<string>
  public description?: string;

  // If this is an Acela-based trigger, this is the query that will be executed
  public query?: string;

  // Metadata
  public created_dttm?: number;
  public created_by?: string;
  public last_updated_dttm?: number;
  public last_updated_by?: string;

  // Lifecycle values
  public enabled?: boolean;
  public schedule?: string; // How often the insight is run
  public schedule_seconds?: number; // How oftehn the insight is run in seconds
  public rule?: string; // Cloudwatch Event rule
  public next_run_time?: number;
  public last_run_time?: number;

  // Simple metrics
  public executions = 0;
  public total_duration = 0;
  public total_results = 0;

  //Dates
  public start_date?: number;
  public end_date?: number;

  //Helper property used only by front-end
  public local_created_dttm?: string = 'Time not provided.';
  public local_last_updated_dttm?: string = 'Time not provided.';
  public local_start_date?: string = 'Time not provided.';
  public local_end_date?: string = 'Time not provided.';
  public local_next_run_time?: string = 'Time not provided.';
  public local_last_run_time?: string = 'Time not provided.';

  constructor() {
    super();
  }

  public get label() {
    return ('' + this.id).padStart(3, '0') + ' - ' + this.name;
  }

  public static copy(sourceInsight: Insight) {
    const targetInsight = new Insight();
    Object.assign(targetInsight, sourceInsight);

    return targetInsight;
  }

  public static clone(insight: Insight) {
    const clonedInsight: Insight = new Insight();
    Object.assign(clonedInsight, insight);
    clonedInsight.name = 'Clone - ' + insight.name;

    //resetting the metrics
    clonedInsight.executions = 0;
    clonedInsight.total_results = 0;
    clonedInsight.total_duration = 0;

    clonedInsight.enabled = false;
    clonedInsight.last_run_time = null;
    clonedInsight.next_run_time = null;
    clonedInsight.status = 'INACTIVE';

    delete clonedInsight.id;

    return clonedInsight;
  }
}
