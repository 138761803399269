import { Component, Input, OnInit } from '@angular/core';
import { Metadata } from 'src/app/models/robot-family-metadata';

@Component({
  selector: 'app-metadata-table-cell',
  templateUrl: './metadata-table-cell.component.html',
  styleUrls: ['./metadata-table-cell.component.css']
})
export class metadataTableCellComponent implements OnInit {
  @Input() public value: Metadata[];
  @Input() public rowData: any;
  public renderValue: Metadata[];
  public extent_type_sort? = null;
  public confidence_sort? = null;
  public priority_sort? = null;

  constructor() {}


  public ngOnInit() {
    if (this.value) {
      this.renderValue = this.value;
      this.sortDefault();
    }
  }

  public sortDefault() {
    const values = this.value.slice();
    if(values.filter((m)=> m.priority !== null).length !== 0){
      this.renderValue = values.sort((a,b)=> this.sortColumns(a.priority, b.priority, this.priority_sort));
    } else {
      this.renderValue = values.sort((a,b)=> this.sortColumns(a.confidence, b.confidence, this.confidence_sort));
    }
  }

  private sortColumns(a, b, sort_order) {
    if(sort_order) {

      return a > b ? 1 : a < b ? -1 : 0;
    } else {

      return a < b ? 1 : a > b ? -1 : 0;
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public sort(header) {
    const values = this.value.slice(); //since value is read-only we cannot call sort on it, hence we use slice to make a copy of value so that we can call sort on the copy

    switch(header) {
      case 'extent_type':
        this.renderValue = values.sort((a,b)=> this.sortColumns(a.extent_type, b.extent_type, this.extent_type_sort));
        this.extent_type_sort = !this.extent_type_sort;
        this.confidence_sort = null;
        this.priority_sort = null;
        break;
      case 'confidence':
        this.renderValue = values.sort((a,b)=> this.sortColumns(a.confidence, b.confidence, this.confidence_sort));
        this.confidence_sort = !this.confidence_sort;
        this.priority_sort = null;
        this.extent_type_sort = null;

        break;
      case 'priority':
        this.renderValue = values.sort((a,b)=> this.sortColumns(a.priority, b.priority, this.priority_sort));
        this.priority_sort = !this.priority_sort;
        this.confidence_sort = null;
        this.extent_type_sort = null;
        break;
    }
  }
}
