import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SharedModule } from 'src/app/shared/shared.module';

import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import {
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDialogModule,
  NbIconModule,
  NbInputModule,
  NbListModule,
  NbSelectModule
} from '@nebular/theme';
import { CoreModule } from 'src/app/core/core.module';
import { ValidatorDirective } from 'src/app/shared/validation/validation_insight_name_regex';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatDatepickerModule } from '@matheo/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { routedComponents } from './insights-routing.module';
import { EditInsightComponent } from './edit-insight/edit-insight.component';
import { FailedJobInsightListComponent } from './failed-job-insight-list/failed-job-insight-list.component';
import { FailedJobInsightComponent } from './failed-job-insight/failed-job-insight.component';
import { InsightWebsocketWindowComponent } from './insight-websocket-window/insight-websocket-window.component';
import { InsightsComponent } from './insights.component';
import { StatsInsightComponent } from './stats-insight/stats-insight.component';
import { ViewInsightComponent } from './view-insight/view-insight.component';
import { InsightTerminateModalComponent } from './insight-terminate-modal/insight-terminate-modal.component';
import { DeleteInsightComponent } from './delete-insight/delete-insight.component';

const COMPONENTS = [
  InsightsComponent,
  EditInsightComponent,
  StatsInsightComponent,
  FailedJobInsightComponent,
  ViewInsightComponent,
  FailedJobInsightListComponent,
  InsightWebsocketWindowComponent,
  InsightTerminateModalComponent
];

@NgModule({
  imports: [
    NgSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    NgSelectModule,
    SharedModule,
    CommonModule,
    CoreModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NbDialogModule,
    NbCardModule,
    NbEvaIconsModule,
    NbIconModule,
    NbButtonModule,
    NbCheckboxModule,
    NbInputModule,
    NbSelectModule,
    NbListModule,
    CodemirrorModule
  ],
  providers: [],
  declarations: [...COMPONENTS, ...routedComponents, ValidatorDirective, DeleteInsightComponent]
})
export class InsightsModule {}
