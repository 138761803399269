import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Robot } from 'src/app/models/robot';

@Component({
    selector: 'app-view-my-robots',
    templateUrl: './view-my-robots.component.html',
    styleUrls: ['./view-my-robots.component.css']
})
export class ViewMyRobotsComponent implements OnInit {
  @Input() public robot: Robot;

  constructor(
    protected ref: NbDialogRef<ViewMyRobotsComponent>
  ) { }

  public ngOnInit(): void {
  }

  public close() {
      this.ref.close();
  }
}
