import { DecimalPipe } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { KozrService } from 'src/app/core/services/kozr.service';
import { CannedKozr } from 'src/app/models/cannedKozr';
import { NgxGenericModalComponent } from 'src/app/shared/ngx-generic-modal/ngx-generic-modal.component';
import { SpinnerState } from 'src/app/shared/spinner/redux/spinner.state';
import { DeleteKOZRs, LoadKOZRs, ReloadKOZRs } from '../redux/qa.actions';
import { QaState } from '../redux/qa.state';
import { EditCannedKozrComponent } from './edit-canned-kozr/edit-canned-kozr.component';
import { ViewCannedKozrComponent } from './view-canned-kozr/view-canned-kozr.component';
import { ViewStatsComponent } from './view-stats/view-stats.component';

@Component({
  selector: 'app-canned-kozrs',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './canned-kozrs.component.html',
  styleUrls: ['./canned-kozrs.component.css'],
  providers: [DecimalPipe]
})
export class CannedKozrsComponent implements OnInit, AfterViewInit {
  @Select(QaState.cannedKOZR) public data$: Observable<any[]>;
  @Select(SpinnerState) public loading: Observable<boolean>;
  public tableData;
  public spin;

  constructor(private store: Store, private service: KozrService, private dialogService: NbDialogService) {
    if (this.store.selectSnapshot(QaState.cannedKOZR).length < 1) {
      this.store.dispatch(LoadKOZRs);
    }
  }

  public ngAfterViewInit() {
    if (this.store.selectSnapshot(QaState.cannedKOZR).length > 1) {
      this.service.buildKeysForTable(this.store.selectSnapshot(QaState.cannedKOZR));
  }
  }

  public ngOnInit() {
    this.data$.subscribe((data) => {
      this.tableData = data;
      this.service.buildKeysForTable(data);
    });
    this.loading.subscribe((data) => (this.spin = data));
  }

  public clickedButtonTable($event) {
    if ($event.action === 'view') {
      this.viewCannedKozr($event.data);
    }
    if ($event.action === 'edit') {
      this.editCannedKozr($event.data);
    }
    if ($event.action === 'stats') {
      this.viewStats($event.data);
    }
    if ($event.action === 'clone') {
      this.cloneCannedKozr($event.data);
    }
    if ($event.action === 'delete') {
      this.deleteCannedKozr($event.data);
    }
  }

  public newCreation() {
    this.newCannedKozr();
  }

  public newCannedKozr() {
    this.dialogService.open(EditCannedKozrComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'New',
        cannedKozr: new CannedKozr()
      }
    });
  }

  public refreshTableData() {
    this.store.dispatch(ReloadKOZRs);
  }

  public viewCannedKozr(cannedKozr: CannedKozr) {
    this.dialogService.open(ViewCannedKozrComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        cannedKozr
      }
    });
  }

  public editCannedKozr(cannedKozr: CannedKozr) {
    const clonedContent = CannedKozr.clone(cannedKozr);
    this.dialogService.open(EditCannedKozrComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'Edit',
        cannedKozr: clonedContent
      }
    });
  }

  public cloneCannedKozr(cannedKozr: CannedKozr) {
    const clonedContent = CannedKozr.clone(cannedKozr);
    clonedContent.koz_id = null;
    this.dialogService.open(EditCannedKozrComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'Clone',
        cannedKozr: clonedContent
      }
    });
  }

  public viewStats(cannedKozr: CannedKozr) {
    this.dialogService.open(ViewStatsComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        cannedKozr
      }
    });
  }

  public deleteCannedKozr(cannedKozr: CannedKozr) {
    this.dialogService
      .open(NgxGenericModalComponent, {
        closeOnEsc: false,
        closeOnBackdropClick: false,
        context: {
          title: 'Are you sure you want to delete Canned-Kozr',
          message: cannedKozr.koz_id + ' - ' + cannedKozr.active_pmapv_id
        }
      })
      .onClose.subscribe((data) => {
        if (data === true) {
          const insightToDelete = CannedKozr.copy(cannedKozr);
          insightToDelete.deleted = true;
          this.store.dispatch(new DeleteKOZRs(insightToDelete));
        }
      });
  }
}
