import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'splitWordUppercase'
})
export class SplitWordUppercasePipe implements PipeTransform {

    public transform(value: any, args?: any): any {

        const result = value.match(/[A-Z][a-z]+|[0-9]+/g).join(' ');

        return result;
    }

}
