import { Asset } from './asset';

/****************************************
 * CleaningEstimate model
 *
 * Made with DynamoDB object model
 * in mind
 ***************************************/

export class CleaningEstimate extends Asset {
  public robot_id: string;
  public pmap_id: string;
  //public version: string;
  public area_type: string; // zone or region
  public area_id: string;
  public value: number;
  public data_model_version: string;
  //public sk: string;

  constructor() {
      super();
  }

}
