import { Injectable } from '@angular/core';
import { NbToastrService, NbWindowService } from '@nebular/theme';
import { Store } from '@ngxs/store';
import { Auth } from '@aws-amplify/auth';
import { InsightWebsocketWindowComponent } from 'src/app/components/insights/insight-websocket-window/insight-websocket-window.component';
import { AmplifyAPI } from 'src/app/models/amplifyApi';
import { config } from './../../../environments/environment';
import { AddInsightJob, RemoveInsightJob } from './../../components/insights/redux/insights.actions';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  public ws = null;
  public refArray = [];

  constructor(private toastrService: NbToastrService, private windowService: NbWindowService, private store: Store) {}

  public async setUp() {
    Auth.currentSession().then((res) => {
      const accessToken = res.getAccessToken();
      const token = accessToken.getJwtToken();
      const api: AmplifyAPI = config.API.endpoints.find((obj) => obj.name === 'web_socket');
      this.webSocket(token, api.endpoint);
    });
  }

  public webSocket(token, url) {
    this.ws = new WebSocket(`${url}?token=${token}`);

    this.ws.onerror = (error) => {
      this.toastrService.show(status, 'WebSocket Error - Contact support if persistent', { status: 'danger' });
    };

    this.ws.onclose = (e) => {};

    this.ws.onmessage = (event) => {
      const results = JSON.parse(event.data);
      let index;
      if (results.status) {
        if (results.status === 'SUCCEEDED' || results.status === 'FAILED') {
          index = this.refArray.findIndex((element) => element.job_id === results.jobId);
          this.removeFromRedux(results.jobId);
        } else {
          index = this.refArray.findIndex((element) => element.job_id === results.job_id);
        }
        if (index !== -1) {
          if (this.refArray[index].windowRef) {
            this.refArray[index].windowRef.close();
            this.refArray[index].windowRef = this.windowService.open(InsightWebsocketWindowComponent, {
              title: this.refArray[index].insightId + ' - ' + results.status,
              context: { results }
            });
            this.refArray[index].windowRef.minimize();
          } else {
            this.refArray[index].windowRef = this.windowService.open(InsightWebsocketWindowComponent, {
              title: this.refArray[index].insightId + ' - ' + results.status,
              context: { results }
            });
          }
        }
      }
    };
  }

  public sendMessage = (message, insight?) => {
    const obj = { insightId: insight, job_id: message, status: '', windowRef: null, insightJob: null };
    this.refArray.push(obj);
    this.saveToRedux(obj);
    this.ws.send(JSON.stringify({ action: 'getstatus', jobId: message, insight_id: insight }));
  };

  //This is here beacuse if you dispatch original object it will go in immutable mode and then it throws errror above in onMessage method
  public saveToRedux(obj) {
    const job = Object.assign({}, obj);
    this.store.dispatch(new AddInsightJob(job));
  }

  public removeFromRedux(jobId) {
    this.store.dispatch(new RemoveInsightJob(jobId));
  }

  //This is called when user stops insight from running. We handle window removal
  public closeWindow(jobId) {
    const index = this.refArray.findIndex((element) => element.job_id === jobId);
    if (index !== -1) {
      if (this.refArray[index].windowRef) {
        this.refArray[index].windowRef.close();
      }
    }
    this.refArray = this.refArray.filter((element) => element.job_id !== jobId);
    this.removeFromRedux(jobId);
    this.toastrService.show(status, 'Successfully stopped ' + jobId, { status: 'info' });
  }
}
