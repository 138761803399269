<nb-card accent="success" style="width: 105%; margin: auto">
  <nb-card-header>
    <div class="row">
      <div *ngIf="this.service.promotionError" class="col-auto">
        An error occurred while trying to promote the campaign:
        {{ campaign.name }}
      </div>
      <div *ngIf="!this.service.promotionResponse && this.service.promotionSpinner" class="col-auto">
        Promotion has started.
      </div>
      <div
        *ngIf="!this.service.promotionResponse && !this.service.promotionSpinner && !this.service.promotionError"
        class="col-auto mr-auto"
      >
        <div class="row">
          <p>
            Are you sure you want to "<b>{{ title }}</b
            >":
          </p>
        </div>
        <div class="row">
          <div class="col-auto">Campaign:</div>
          <div class="col-auto">
            {{ campaign.name }}
          </div>
        </div>
      </div>
      <div class="col-auto">
        <button nbButton (click)="clickedCancel()">X</button>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <!-- This section is visible when user is prompted to promote the campaign -->
    <div *ngIf="!this.service.promotionResponse && !this.service.promotionSpinner && !this.service.promotionError">
      <div class="row justify-content-center">This Campaign promotion will also promote:</div>
      <br />
      <br />
      <div class="row">
        <div class="col-auto">Content:</div>
        <div class="col-auto">
          <a [routerLink]="[]" (click)="viewContent(content.content_id)">{{ content.content_id }}</a>
        </div>
      </div>
      <div class="row">
        <div class="col-auto">Insight:</div>
        <div class="col-auto">
          <a [routerLink]="[]" (click)="viewInsight(campaign_trigger.id)">{{ campaign_trigger.name }}</a>
        </div>
      </div>
    </div>
    <!-- This section is visible when the response exists from the promotion process request-->
    <!-- <div *ngIf="this.service.promotionResponse">
      <div class="row justify-content-center">
        Campaign has been promoted to the {{ target_environment }}.
      </div>
      <br />
      <br />
      <div class="row">
        <div class="col-4">Campaign ID:</div>
        <div class="col-8">
          {{ this.service.promotionResponse.campaign_id }}
        </div>
      </div>
      <div class="row">
        <div class="col-4">Insight ID:</div>
        <div class="col-8">{{ this.service.promotionResponse.insight_id }}</div>
      </div>
      <div class="row">
        <div class="col-4">Content ID:</div>
        <div class="col-8">{{ this.service.promotionResponse.content_id }}</div>
      </div>
    </div> -->
    <!-- This section is visible while the request is pending. -->
    <div *ngIf="this.service.promotionSpinner">Campaign is getting promoted to {{ target_environment }}.</div>
    <!-- This section is visible when there is an error in the request. -->
    <div *ngIf="this.service.promotionError">
      <div class="row" style="color: red">Error:</div>
      <br />
      <div class="row">
        {{ this.service.promotionError }}
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer *ngIf="!this.service.promotionResponse && !this.service.promotionError">
    <div *ngIf="this.service.promotionSpinner" class="row justify-content-center">
      <div class="row">
        <div class="col-auto">
          <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!this.service.promotionSpinner" class="d-flex flex-row-reverse">
      <div class="p-2">
        <button
          nbButton
          status="success"
          (click)="clickedPromote()"
          nbTooltip="Clicking Yes will promote the campaign to the target environment."
        >
          Yes
        </button>
      </div>
      <div class="p-2">
        <button nbButton status="danger" (click)="clickedCancel()">No</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
