export class LoadMessMakers {
  public static readonly type = '[QA] Load Mess Makers Records';
  constructor(public payload: any) {}
}

export class LoadMessMakersSuccess {
  public static readonly type = '[QA] Load Mess Makers Records SUCCESS';
  constructor(public payload: any) {}
}

export class PatchMessMakers {
  public static readonly type = '[QA] Patch Mess Makers';
  constructor(public payload: any) {}
}

export class PatchMessMakersSuccess {
  public static readonly type = '[QA] Patch Mess Makers SUCCESS';
  constructor(public payload: any) {}
}

export class PatchMessMakersFail {
  public static readonly type = '[QA] Patch Mess Makers FAILED';
  constructor(public payload: any) {}
}
export class LoadMessMakersFail {
  public static readonly type = '[QA] Load Mess Makers FAILED';
  constructor(public payload: any) {}
}
