import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { iif, insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { CampaignService } from 'src/app/core/services/campaign.service';
import { TableSpinerReloadHide } from 'src/app/shared/ngx-table/ngx-table/redux/table-spinner.actions';
import { ToggleHide } from 'src/app/shared/spinner/redux/spinner.actions';
import { ReloadContent } from '../../content/redux';
import { LoadInsightsInactive, ReloadInsights } from '../../insights/redux';
import { ReloadSurveys } from '../../surveys/redux';
import { CampaignStateModel } from './campaign-state.model';
import {
  AddCampaignJob,
  AppendCampaignEventTriggers,
  LoadCampaign,
  LoadCampaignSuccess,
  LoadAffectedCampaigns,
  LoadAffectedCampaignsSuccess,
  ManageCampaign,
  PopulateCampaignEventTriggers,
  PostCampaign,
  PostSaveComplete,
  PostSaveFail,
  ReloadCampaign,
  ReloadCampaignSuccess,
  RemoveCampaignJob,
  SaveCampaignDeliveries,
  DeepDeleteCampaign,
  DeepDeleteCampaignSuccess,
  LoadAffectedCampaignsByInsight
} from './campaign.actions';

@State<CampaignStateModel>({
  name: 'campaign',
  defaults: {
    campaign: [],
    campaignDeliveries: [],
    campaignEventTriggers: [],
    error: null,
    campaignJobs: [],
    promotion: null,
    affectedCampaigns: [],
    spin: true
  }
})
@Injectable()
export class CampaignState {
  constructor(private toastrService: NbToastrService, private service: CampaignService) {}

  @Selector()
  public static campaign(state: CampaignStateModel) {
    return state.campaign;
  }

  @Selector()
  public static deliveries(state: CampaignStateModel) {
    return state.campaignDeliveries;
  }

  @Selector()
  public static eventTriggers(state: CampaignStateModel) {
    return state.campaignEventTriggers;
  }

  @Selector()
  public static campaignJobs(state: CampaignStateModel) {
    return state.campaignJobs;
  }

  @Selector()
  public static PromotionSuccess(state: CampaignStateModel) {
    return state.promotion;
  }

  @Selector()
  public static affectedCampaigns(state: CampaignStateModel) {
    return state.affectedCampaigns;
  }

  @Selector()
  public static spin(state: CampaignStateModel) {
    return state.spin;
  }

  @Action(LoadCampaign)
  public async loadCampaign(ctx: StateContext<CampaignStateModel>) {
    this.service.loadCampaignsAmplify();
  }

  @Action(LoadCampaign)
  public async PromotionSuccess(ctx: StateContext<CampaignStateModel>) {}

  @Action(LoadCampaignSuccess)
  public loadCampaignSuccess(ctx: StateContext<CampaignStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      campaign: payload.campaigns
    });
    ctx.dispatch(ToggleHide);
    ctx.dispatch(new PopulateCampaignEventTriggers(payload.campaigns));
  }

  @Action(LoadAffectedCampaigns)
  public loadAffectedCampaigns(ctx: StateContext<CampaignStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      spin: true
    });
    this.service.loadAffectedCampaignsAmplify(payload);
  }

  @Action(LoadAffectedCampaignsSuccess)
  public loadAffectedCampaignsSuccess(ctx: StateContext<CampaignStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      affectedCampaigns: payload,
      spin: false
    });
  }

  @Action(LoadAffectedCampaignsByInsight)
  public LoadAffectedCampaignsByInsight(ctx: StateContext<CampaignStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      spin: true
    });
    this.service.loadAffectedCampaignsByInsightAmplify(payload);
  }

  @Action(ReloadCampaign)
  public async reloadCampaign(ctx: StateContext<CampaignStateModel>) {
    this.toastrService.show(status, 'Fetch Initiated', { status: 'info' });
    this.service.loadCampaignsAmplify(true);
  }

  @Action(ReloadCampaignSuccess)
  public reloadCampaignSuccess(ctx: StateContext<CampaignStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      campaign: payload
    });
    ctx.dispatch(TableSpinerReloadHide);
    ctx.dispatch(new PopulateCampaignEventTriggers(payload.campaigns));
  }

  @Action(PostCampaign)
  public async postCampaign(ctx: StateContext<CampaignStateModel>, { payload, edit }: any) {
    delete payload.insight_name;
    Object.keys(payload).forEach((data) => {
      if (data.startsWith('local_')) {
        delete payload[data];
      }
    });
    this.service.postCampaignsAmplify(payload, edit);
  }

  @Action(DeepDeleteCampaign)
  public async deepDeleteCampaign(ctx: StateContext<CampaignStateModel>, { payload }: any) {
    this.service.deepDeleteCampaignAmplify(payload);
  }

  @Action(DeepDeleteCampaignSuccess)
  public deepDeleteCampaignSuccess(ctx: StateContext<CampaignStateModel>, { payload }: any) {
    ctx.setState(
      patch({
        campaign: removeItem((item: any) => item.id === payload.campaign_id)
      })
    );
    ctx.dispatch(ReloadInsights);
    ctx.dispatch(LoadInsightsInactive);
    ctx.dispatch(ReloadContent);
    ctx.dispatch(ReloadSurveys);
  }

  @Action(PostSaveComplete)
  public campaignComplete(ctx: StateContext<CampaignStateModel>, { data, campaign }: any) {
    // Data can contain "true" when its updating so we update item with "campaign" which is local copy
    // In case you make new "campaign" with post it will return new Object with "data" so in second case
    // We append it to last place in array with data
    const state = ctx.getState();
    if (data === true) {
      ctx.setState(
        patch({
          campaign: updateItem((item) => item.id === campaign.id, campaign)
        })
      );
      this.toastrService.show(status, 'Edit: Complete', { status: 'success' });
    }
    if (data === undefined) {
      ctx.setState(
        patch({
          campaign: [...state.campaign, campaign]
        })
      );
      this.toastrService.show(status, 'Save: Complete', { status: 'success' });
    }
  }

  @Action(PostSaveFail)
  public campaignFail(ctx: StateContext<CampaignStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      error: payload.message
    });
    this.toastrService.show(status, 'Save: Fail - ' + payload.message, { status: 'danger' });
  }

  @Action(SaveCampaignDeliveries)
  public campaignDeliveries(ctx: StateContext<CampaignStateModel>, { payload }: any) {
    ctx.setState(
      patch<CampaignStateModel>({
        campaignDeliveries: iif<any[]>(
          (campaignDeliveries) => campaignDeliveries.some((f) => f.campaign_id === payload.campaign_id),
          updateItem<any>((f) => f.campaign_id === payload.campaign_id, payload),
          insertItem(payload)
        )
      })
    );
  }

  @Action(PopulateCampaignEventTriggers)
  public populateCampaignEventTriggers(ctx: StateContext<CampaignStateModel>, { payload }: any) {
    const arr = [];
    payload.forEach((element) => {
      if (element.event_triggers) {
        if (element.event_triggers instanceof Array) {
          // eslint-disable-next-line @typescript-eslint/no-shadow
          element.event_triggers.forEach((element) => {
            arr.push(element);
          });
        } else {
          arr.push(element.event_triggers);
        }
      }
    });

    const unique = [...new Set(arr.map((item) => item))];

    const state = ctx.getState();
    ctx.setState(
      patch({
        campaignEventTriggers: unique
      })
    );
  }

  @Action(AppendCampaignEventTriggers)
  public appendCampaignEventTriggers(ctx: StateContext<CampaignStateModel>, { payload }: any) {
    const state = ctx.getState();
    let arr = state.campaignEventTriggers;
    arr = Object.assign([], arr);
    if (payload instanceof Array) {
      payload.forEach((element) => {
        arr.push(element);
      });
    } else {
      arr.push(payload);
    }

    const unique = [...new Set(arr.map((item) => item))];
    ctx.setState(
      patch({
        campaignEventTriggers: unique
      })
    );
  }

  @Action(AddCampaignJob)
  public campaignJobInsert(ctx: StateContext<CampaignStateModel>, { payload }: any) {
    ctx.setState(
      patch<CampaignStateModel>({
        campaignJobs: iif<any[]>(
          (campaignJobs) => campaignJobs.some((f) => f.campaignId === payload.campaignId),
          updateItem<any>((f) => f.campaignId === payload.campaignId, payload),
          insertItem(payload)
        )
      })
    );
  }

  @Action(RemoveCampaignJob)
  public campaignJobRemove(ctx: StateContext<CampaignStateModel>, { payload }: any) {
    ctx.setState(
      patch({
        // @ts-ignore
        campaignJobs: removeItem((item) => item.job_id === payload)
      })
    );
  }

  @Action(ManageCampaign)
  public activateCampaign(ctx: StateContext<CampaignStateModel>, { payload }: any) {
    ctx.setState(
      patch<CampaignStateModel>({
        campaign: iif<any[]>(
          (campaign) => campaign.some((f) => f.id === payload.id),
          updateItem<any>((f) => f.id === payload.id, payload),
          insertItem(payload)
        )
      })
    );
  }
}
