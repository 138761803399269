import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { InsightsState } from 'src/app/components/insights/redux';
import { Annotation } from 'src/app/models/annotation';
import { KozRecommendation } from 'src/app/models/kozRecommendation';

import { NbToastrService } from '@nebular/theme';
import { API } from '@aws-amplify/api';
import { LoadKOZRsSuccess, ReloadKOZRs } from 'src/app/components/qa-testing/redux/qa.actions';
import { Insight } from 'src/app/models/insight';
import { CogwheelService } from 'src/app/core/services/cogwheel.service';
import { RouterState } from 'src/app/shared/redux/router-state';
import { environment } from 'src/environments/environment';
import { CannedKozr } from '../../models/cannedKozr';
import { AmplifyServiceBuilder } from '../services/amplify.service.builder';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class KozrService {
  @Select(RouterState.username) public data_username$: Observable<any>;
  @Select(InsightsState.insightsActive) public data_insights$: Observable<any>;

  public username;
  public insights: Insight[];
  public cannedKozrRobots;
  public err;
  public recommendationToPublish;
  public isPublished;
  public signedRequests;
  public user;

  public cannedKozrss: any;
  public kozr_api = 'kozr_api';
  public notifications_api = 'notifications_api';

  /*******************************************
   * Canned KOZRs
   ******************************************/
  public signedRequests_cannedKozr: any;
  public owner: string;
  public allKeys: string[] = [];
  public selectedKeys: string[] = [];
  public cannedKozrs: any;
  public type = 'canned-kozrs';

  constructor(private utilityService: UtilityService,
              private store: Store,
              private toastrService: NbToastrService,
              private cogWheelService: CogwheelService) {
    this.data_username$.subscribe((data) => (this.username = data));
    this.data_insights$.subscribe((data) => (this.insights = data));

  }

  public buildKeysForTable(kozr: any[]) {
    this.cogWheelService.buildKeysForTable(kozr, this.allKeys, this.store, this.type, true);
    }

  public loadKozrAmplify(reload?) {
    const requestData = AmplifyServiceBuilder(this.kozr_api, true);
    API.get(this.kozr_api, `/${environment.stage}/insights/qa/canned-kozrs`, requestData)
      .then((response) => {
        if (reload) {
          this.toastrService.show(status, 'Fetch Complete', { status: 'success' });
        }
        this.buildKeysForTable(response);
        this.store.dispatch(new LoadKOZRsSuccess(response));
      })
      .catch((error) => {
        console.error(error);
        this.toastrService.show(status, 'Fetching KOZR Failed ' + this.utilityService.convertErrorToMessage(error), {
          status: 'danger'
        });
      });
  }

  public postKozrAmplify(kozr, edit) {
    const body = kozr;
    const requestData = AmplifyServiceBuilder(this.kozr_api, true, body);

    API.post(this.kozr_api, `/${environment.stage}/insights/qa/canned-kozrs`, requestData)
      .then((response) => {
        this.toastrService.show(status, 'Saving Canned KOZR Succeeded', { status: 'success' });
        this.store.dispatch(new ReloadKOZRs());
      })
      .catch((error) => {
        console.error(error);
        this.toastrService.show(status, 'Saving KOZR Failed ' + this.utilityService.convertErrorToMessage(error), {
          status: 'danger'
        });
      });
  }

  public deleteKozrAmplify(kozr) {
    const body = kozr;
    const requestData = AmplifyServiceBuilder(this.kozr_api, true, body);

    API.del(this.kozr_api, `/${environment.stage}/insights/qa/canned-kozrs`, requestData)
      .then((response) => {
        this.toastrService.show(status, 'Deleting Canned KOZR Succeeded', { status: 'success' });
        this.store.dispatch(new ReloadKOZRs());
      })
      .catch((error) => {
        this.toastrService.show(status, 'Deleting KOZR Failed ' + this.utilityService.convertErrorToMessage(error), {
          status: 'danger'
        });
      });
  }

  /*******************************************
   * Return an observable CannedKOZR list
   * object
   ******************************************/

  /***********************************************************************/

  public submitKozRecommendation(selectedCampaign, selectedRobot) {
    const insightObject: Insight = Object.assign(
      {},
      this.insights.find(({ id }) => id === selectedCampaign.trigger_id)
    );
    const selectedKozRecommendation = selectedRobot;

    const recommendation: KozRecommendation = {} as KozRecommendation;
    const annotation: Annotation = {} as Annotation;

    recommendation.robot_id = selectedKozRecommendation.robotid;
    recommendation.campaign_id = insightObject.category + ':' + insightObject.name;
    annotation.geometry_type = 'polygon2d';
    annotation.geometry = selectedKozRecommendation.koz_geom[0].coordinates;
    annotation.pmap_id = selectedKozRecommendation.pmap_id;
    annotation.max_ts = Math.round(Date.now() / 1000);
    annotation.active_pmap_id = selectedKozRecommendation.active_pmapv_id;
    annotation.map_insight_id = selectedKozRecommendation.koz_id;
    annotation.recommendation_type = 'koz';
    annotation.behavior = 'keepout';
    if (selectedKozRecommendation.cumul_orientation_rad) {
      annotation.cumul_orientation_rad = selectedKozRecommendation.cumul_orientation_rad;
    }
    annotation.max_score = selectedKozRecommendation.score;

    recommendation.map_annotation = annotation;

    this.recommendationToPublish = recommendation;
    const body = recommendation;
    const requestData = AmplifyServiceBuilder(this.kozr_api, true, body);

    API.post('notifications_api', `/${environment.stage}/campaigns/qa/notifications`, requestData)
      .then((response) => {
        this.isPublished = response;
      })
      .catch((error) => {
        this.toastrService.show(status, 'Save: Fail - ' + this.utilityService.convertErrorToMessage(error), {
          status: 'danger'
        });
      });
  }

  /*******************************************
   * Canned KOZRs
   ******************************************/
  public addCannedKozr(newCannedKozr: CannedKozr) {
    newCannedKozr.create_timestamp = new Date().getTime();
    newCannedKozr.koz_id = Math.round(Math.random() * 10);
  }
}
