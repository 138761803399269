import { Component, ComponentFactoryResolver, ComponentRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { QaServiceService } from 'src/app/core/services/qa-service.service';
import { Robot } from 'src/app/models/robot';
import { SpinnerState } from 'src/app/shared/spinner/redux/spinner.state';
import { LoadAllRobots, LoadOwnerRobots } from '../redux';
import { QaState } from '../redux/qa.state';
import { CleanTimeEstimatesSubComponent } from './clean-time-estimates-sub/clean-time-estimates-sub.component';

@Component({
  selector: 'app-clean-time-estimates',
  templateUrl: './clean-time-estimates.component.html',
  styleUrls: ['./clean-time-estimates.component.scss']
})
export class CleanTimeEstimatesComponent implements OnInit {
  @Select(QaState.robots) public data_allRobots$: Observable<any[]>;
  @Select(QaState.ownerRobots) public data_ownerRobots$: Observable<any[]>;
  @Select(SpinnerState) public loading: Observable<boolean>;

  public myRobots: Robot[] = [];
  public commonRobots: Robot[] = [];
  public robotSelected = false;
  public selectedRobot: Robot;
  public submissionSent = false;
  public estimateJson = '';

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @ViewChild('viewContainerRef', { read: ViewContainerRef })
  public VCR: ViewContainerRef;
  public child_unique_key = 0;
  public componentsReferences = Array<ComponentRef<CleanTimeEstimatesSubComponent>>();
  public spin;

  constructor(private store: Store, private CFR: ComponentFactoryResolver, public service: QaServiceService) {
    if (this.store.selectSnapshot(QaState.robots).length < 1) {
      this.store.dispatch(new LoadAllRobots());
    }
    if (this.store.selectSnapshot(QaState.ownerRobots).length < 1) {
      this.store.dispatch(new LoadOwnerRobots());
    }
  }

  public ngOnInit(): void {
    this.data_allRobots$.subscribe((data) => (this.commonRobots = data));
    this.data_ownerRobots$.subscribe((data) => (this.myRobots = data));
    this.loading.subscribe((data) => (this.spin = data));
  }

  public groupByFn() {
    return (item) => {
      if ('pmap_id' in item) {
        return 'KOZR Robots';
      }
      if (this.myRobots.find((s) => s.id === item.id)) {
        return 'My Robots';
      }

      return 'Common Robots';
    };
  }

  public clear() {
    this.robotSelected = false;
    this.child_unique_key = 0;
    this.componentsReferences = [];
  }

  /******************************************************
   * Robot selection in HTML
   *****************************************************/

  public onRobotSelect(selectBot) {
    this.selectedRobot = selectBot;
    if (selectBot !== null) {
      this.robotSelected = true;
    } else {
      this.robotSelected = false;
    }
  }

  public showSubmit(): boolean {
    return true;
  }

  public onSubmit() {
    const cleaningEstimate = [];
    this.componentsReferences.forEach((data) => {
      const estimate = data.instance.estimate;
      estimate.robot_id = this.selectedRobot.id;
      cleaningEstimate.push(estimate);
    });

    this.service.submitCleaningEstimateAmplify(cleaningEstimate);

    if (this.service.sentEstimates !== null) {
      this.submissionSent = true;
      this.estimateJson = JSON.stringify(this.service.sentEstimates);
    } else {
      this.submissionSent = false;
    }
  }

  public createComponent(data?, index?) {
    const componentFactory = this.CFR.resolveComponentFactory(CleanTimeEstimatesSubComponent);

    const childComponentRef = this.VCR.createComponent(componentFactory);

    const childComponent = childComponentRef.instance;
    childComponent.unique_key = ++this.child_unique_key;
    childComponent.parentRef = this;

    // add reference for newly created component
    this.componentsReferences.push(childComponentRef);
  }

  //Method to delete specific component from container view
  public remove(key: number) {
    if (this.VCR.length < 1) {
      return;
    }

    const componentRef = this.componentsReferences.filter((x) => x.instance.unique_key === key)[0];

    const vcrIndex: number = this.VCR.indexOf(componentRef.hostView);

    // removing component from container
    this.VCR.remove(vcrIndex);

    // removing component from the list
    this.componentsReferences = this.componentsReferences.filter((x) => x.instance.unique_key !== key);
  }
}
