<div style="height: 550px;">
  <div class="row">
    <div class="col">
      <ng-select fullWidth [(ngModel)]="selectedRobots" (change)="onRobotSelect()" placeholder="Select ID">
        <ng-option *ngFor="let kozr of kozrRobots" [value]="kozr">
          {{ kozr.robotid }}
        </ng-option>
      </ng-select>
    </div>
  </div>
  <br>
  <div class="row">
    <div class="col">
      <div *ngIf="timelineNotification">
        <nb-accordion *ngFor="let notification of timelineNotification.events">
          <nb-accordion-item>
            <nb-accordion-item-header>
              <div class="col-auto mr-auto"><label> Notification: </label></div>
            </nb-accordion-item-header>
            <nb-accordion-item-body>
              {{ notification | json }}
            </nb-accordion-item-body>
          </nb-accordion-item>
        </nb-accordion>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div *ngIf="show">
        <nb-card accent="warning">
          <nb-card-body>
            <span [innerHTML]="notificationMessage | bold"></span>
          </nb-card-body>
        </nb-card>
      </div>
    </div>
  </div>
</div>