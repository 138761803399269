<app-spinner *ngIf="spin"></app-spinner>
<app-ngx-table
  type={{service.type}}
  description="Surveys table gives insight into mobile surveys presented to the user and allows creation of new surveys."
  [buttonEnabled]="displayNewButton"
  [tableData]="tableData"
  *ngIf="!spin"
  (clickedButton)="clickedButtonTable($event)"
  (createNew)="newCreation()"
  (refreshTable)="refreshTableData()"
></app-ngx-table>
