import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-qa-cl-form',
  templateUrl: './qa-cl-form.component.html',
  styleUrls: ['./qa-cl-form.component.css']
})
export class QaClFormComponent implements OnInit {
  @Input() public campaign;
  @Input() public parsedCampaignValues;

  @Output() public outputCampaignValues = new EventEmitter();

  public campName: string;
  public localParamArray = [];
  public localValueArray = [];

  // Currently used
  public valid_object_types = ['couch', 'counter', 'table', 'christmas_tree'];

  // Preparation for further integration, beyond 5.0 system
  public valid_extent_types = [
    'around_bed',
    'around_sofa',
    'around_coffee_table',
    'around_dining_table',
    'around_toilet',
    'around_kitchen_island',
    'rug',
    'front_of_kitchen_counter',
    'front_of_refrigerator',
    'around_christmas_tree',
    'unspecified',
    'around_pet_bowl',
    'around_litter_box',
    'front_of_oven',
    'front_of_dishwasher'
  ];

  public formGroup: FormGroup;
  public verify = true;
  public btnVerifyDisable = true;

  constructor() {}

  public ngOnInit(): void {
    this.fillParameters();
  }

  public fillParameters() {
    let index = 0;
    for (const parameter of this.parsedCampaignValues) {
      this.localParamArray[index] = parameter;
      if (parameter === 'primary_object_type') {
        this.localValueArray[5] = 'around_bed'; // initial value
      }
      index++;
    }
    this.localValueArray.length = this.localParamArray.length;
  }

  public parameterValueChanged(arrayPosition, newChange) {
    if (typeof newChange === 'object') {
      this.localValueArray[arrayPosition] = newChange.target.value;
    } else {
      this.localValueArray[arrayPosition] = newChange;
    }
    this.outputCampaignValues.emit(this.localValueArray);
  }

  // Unused atm
  public onClickVerify() {
    for (const values of this.localValueArray) {
      if (values === '') {
        this.verify = false;
        break;
      } else {
        this.verify = true;
      }
      if (!this.verify) {
        break;
      }
    }
    if (this.localValueArray.includes(undefined)) {
      this.verify = false;
    }
  }
}
