
<div style="min-height: 550px">
    <nb-card>
        <nb-card-header>
            <h3 style="position: relative;">QA Tool</h3>
        </nb-card-header>
        <nb-card-body>
            <div>
                <nb-tabset fullWidth>
                    <nb-tab tabTitle="Common Robots">
                        <app-common-bots></app-common-bots>
                    </nb-tab>
                    <nb-tab tabTitle="KOZR Robots">
                        <app-kozr-bots></app-kozr-bots>
                    </nb-tab>
                </nb-tabset>
            </div>
        </nb-card-body>
    </nb-card>
</div>