import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SharedModule } from 'src/app/shared/shared.module';
import { ValidatorDirective } from 'src/app/shared/validation/validation_robot-family-metadata_regex';

import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import {
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDialogModule,
  NbIconModule,
  NbInputModule,
  NbAccordionModule,
  NbListModule,
  NbSelectModule
} from '@nebular/theme';
import { CoreModule } from 'src/app/core/core.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatDatepickerModule } from '@matheo/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { EditRobotFamilyMetadataComponent } from './edit-robot-family-metadata/edit-robot-family-metadata.component';
import { RobotFamilyMetadataComponent } from './robot-family-metadata.component';
import { ViewRobotFamilyMetadataComponent } from './view-robot-family-metadata/view-robot-family-metadata.component';
import { routedComponents } from 'src/app/shared/shared-routing.module';

const COMPONENTS = [
  RobotFamilyMetadataComponent,
  EditRobotFamilyMetadataComponent,
  ViewRobotFamilyMetadataComponent
];

@NgModule({
  imports: [
    NgSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    NgSelectModule,
    SharedModule,
    CommonModule,
    CoreModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NbDialogModule,
    NbCardModule,
    NbEvaIconsModule,
    NbIconModule,
    NbButtonModule,
    NbCheckboxModule,
    NbInputModule,
    NbAccordionModule,
    NbSelectModule,
    NbListModule,
    CodemirrorModule
  ],
  providers: [],
  declarations: [...COMPONENTS, ...routedComponents, ValidatorDirective]
})
export class RobotFamilyMetadataModule { }
