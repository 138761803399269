<nb-card accent="success" style="width: 1100px; margin: auto">
  <nb-card-header>
    <div class="row">
      <div class="col-auto mr-auto">
        <nb-icon icon="search-outline"></nb-icon>
      </div>
      <div class="col-auto mr-auto">{{ title }} High Traffic Area</div>
      <div class="col-auto"><button nbButton (click)="close()">X</button></div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <form style="height: 700px; padding: 1.143em; margin: -1.143em">
      <strong class="text-uppercase">High Traffic Area Details</strong>
      <hr />
      <div class="form-group row">
        <label for="robotid" class="col-sm-3 col-form-label col-form-label-sm">Robot ID:</label>
        <div class="input-group col-sm-9">
          <input
            nbInput
            id="robotid"
            [(ngModel)]="htaRecommendation.robotid"
            class="form-control form-control-sm"
            type="text"
            placeholder="Robot ID"
            name="robotid"
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="pmap_id" class="col-sm-3 col-form-label col-form-label-sm">Pmap ID:</label>
        <div class="input-group col-sm-9">
          <input
            nbInput
            id="pmap_id"
            [(ngModel)]="htaRecommendation.pmap_id"
            class="form-control form-control-sm"
            type="text"
            placeholder="Pmap ID"
            name="pmap_id"
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="active_pmapv_id" class="col-sm-3 col-form-label col-form-label-sm">Active Pmap:</label>
        <div class="input-group col-sm-9 align-self-center">
          <input
            nbInput
            id="active_pmapv_id"
            [(ngModel)]="htaRecommendation.active_pmapv_id"
            class="form-control form-control-sm"
            type="text"
            placeholder="Active Pmap ID"
            name="active_pmapv_id"
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="hta_geom" class="col-sm-3 col-form-label col-form-label-sm">HTA Geometry:</label>
        <div class="input-group col-sm-9 align-self-center">
          <textarea
            nbInput
            fullWidth
            style="width: 100%"
            (input)="updateModel('hta_geom', $event)"
            id="hta_geom"
            class="form-control form-control-sm"
            name="hta_geom"
            rows="10"
            >{{ htaRecommendation.hta_geom | json }}</textarea
          >
        </div>
      </div>
      <div class="form-group row">
        <label for="score" class="col-sm-3 col-form-label col-form-label-sm">Comments:</label>
        <div class="input-group col-sm-9 align-self-center">
          <div *ngFor="let c of comments; let i = index; trackBy: trackByIndex" class="mt-2 mr-2">
            <input
              nbInput
              id="{{ 'comment' + i }}"
              [(ngModel)]="comments[i]"
              class="form-control form-control-sm"
              type="text"
              placeholder="Comment {{ i }}"
              name="{{ 'comment' + i }}"
            />
          </div>
          <button nbButton (click)="addComment()" class="btn btn-sm btn-warning" size="small" status="warning">
            <nb-icon icon="plus-circle-outline"></nb-icon>
          </button>
        </div>
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer>
    <div class="d-flex flex-row-reverse">
      <div class="p-2">
        <button nbButton status="success" (click)="save()">Save</button>
      </div>
      <div class="p-2">
        <button nbButton status="info" (click)="close()">Close</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
