<div class="row removed-background">
  <div class="row first-element">
    <div class="col-2">
      <label>ID:</label>
    </div>
    <div class="col-6">
      <p>{{ item.id }}</p>
    </div>
  </div>
  <div class="row" *ngIf="item.file">
    <div class="col-2">
      <label>File:</label>
    </div>
    <div class="col-6">
      <p>{{ item.file }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-2">
      <label>Location:</label>
    </div>
    <div class="col-6">
      <p>{{ item.location }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-2">
      <label>Created by:</label>
    </div>
    <div class="col-6">
      <p>{{ item.created_by }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-2">
      <label>Created on:</label>
    </div>
    <div class="col-6">
      <p>{{ utility.convertUnixTime_global(item.created_ts) }}</p>
    </div>
  </div>
</div>
