import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { QaServiceService } from 'src/app/core/services/qa-service.service';
import { SpinnerState } from 'src/app/shared/spinner/redux/spinner.state';

@Component({
  selector: 'app-timeline-notifications',
  templateUrl: './timeline-notifications.component.html',
  styleUrls: ['./timeline-notifications.component.css']
})
export class TimelineNotificationsComponent implements OnInit {
  @Select(SpinnerState) public loading: Observable<boolean>;
  public selectedRobot: any = 0;

  constructor(private service: QaServiceService) {}
  public ngOnInit() {}

  public onRobotSelect() {
    this.service.loadTimelineNotificationsAmplify(this.selectedRobot);
  }
}
