<nb-card
  accent="success"
  style="width: 120%; height: 100%; min-width: 700px; max-height: 800px; max-width: 1800px; margin: auto"
>
  <nb-card-header>
    <div class="row">
      <div class="col-auto mr-auto">
        <nb-icon icon="search-outline"></nb-icon>
      </div>
      <div class="col-auto mr-auto">{{ title }} Scripted Navigation</div>
      <div class="col-auto"><button nbButton (click)="close()">X</button></div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div style="height: 700px; padding: 1.143em; margin: -1.143em">
      <form [formGroup]="scriptedNavForm">
        <div formArrayName="scripts">
          <div *ngFor="let script of scripts.controls; let scriptIndex = index" [formGroupName]="scriptIndex">
            <!-- Script Section -->
            <div class="row">
              <nb-accordion style="width: 100%">
                <nb-accordion-item [expanded]="true" style="width: 100%">
                  <nb-accordion-item-header>
                    <div class="col-auto mr-auto">
                      <h3>Script {{ scriptIndex + 1 }}</h3>
                    </div>
                    <div class="col-auto" style="padding-right: 40px">
                      <button
                        nbTooltip="Delete Script"
                        nbButton
                        size="small"
                        status="danger"
                        (click)="removeScript(scriptIndex)"
                      >
                        <nb-icon icon="trash-2-outline"></nb-icon>
                      </button>
                    </div>
                  </nb-accordion-item-header>
                  <nb-accordion-item-body>
                    <div class="row">
                      <div class="col">
                        <label>Setup Root Screen Key<span style="color: red">*</span>:</label>
                      </div>
                      <div class="col-9">
                        <input nbInput formControlName="setup_root_screen_key" />
                      </div>
                    </div>
                    <br />
                    <div class="row">
                      <div class="col-12">
                        <div class="row">
                          <div class="col">
                            <label>Screen Name<span style="color: red">*</span>:</label>
                          </div>
                          <div class="col-9">
                            <input nbInput formControlName="screen_name" />
                          </div>
                        </div>
                        <br />
                        <div class="row">
                          <div class="col"><label>Total Screen Number:</label></div>
                          <div class="col-9">
                            <input nbInput formControlName="total_screen_number" />
                          </div>
                        </div>
                        <br />
                      </div>
                    </div>
                    <br />
                    <!-- Screen  Section-->
                    <div formGroupName="screen">
                      <h4>Screen: {{ script.get('screen_name').value }}</h4>
                      <br />
                      <div class="row">
                        <div class="col-6">
                          <div class="row">
                            <div class="col">
                              <label>Class Key<span style="color: red">*</span>:</label>
                            </div>
                            <div class="col-9">
                              <input nbInput formControlName="class_key" />
                            </div>
                          </div>
                          <br />
                          <div class="row">
                            <div class="col"><label>Background Color:</label></div>
                            <div class="col-9">
                              <input nbInput formControlName="background_color" />
                            </div>
                          </div>
                          <br />
                          <div class="row">
                            <div class="col"><label>Title Text:</label></div>
                            <div class="col-9">
                              <input nbInput formControlName="title_text" />
                            </div>
                          </div>
                          <br />
                          <div class="row">
                            <div class="col"><label>Hides Back Button:</label></div>
                            <div class="col-9">
                              <nb-checkbox formControlName="hides_back_button"></nb-checkbox>
                            </div>
                          </div>
                          <br />
                        </div>
                        <div class="col-6">
                          <div class="row">
                            <div class="col">
                              <label>Header Text<span style="color: red">*</span>:</label>
                            </div>
                            <div class="col-9">
                              <input nbInput formControlName="header_text" />
                            </div>
                          </div>
                          <br />
                          <div class="row">
                            <div class="col">
                              <label>Info Text<span style="color: red">*</span>:</label>
                            </div>
                            <div class="col-9">
                              <input nbInput formControlName="info_text" />
                            </div>
                          </div>
                          <br />
                          <div class="row">
                            <div class="col"><label>Hyperlink:</label></div>
                            <div class="col-9">
                              <input nbInput formControlName="hyperlink" />
                            </div>
                          </div>
                          <br />
                          <div class="row">
                            <div class="col"><label>URL:</label></div>
                            <div class="col-9">
                              <input nbInput formControlName="url" />
                            </div>
                          </div>
                          <br />
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-6">
                          <nb-card>
                            <nb-card-header>Close Button</nb-card-header>

                            <nb-card-body>
                              <div formGroupName="close_button">
                                <div class="row">
                                  <div class="col"><label>Title:</label></div>
                                  <div class="col-9">
                                    <input nbInput formControlName="title" />
                                  </div>
                                </div>
                                <br />
                                <div class="row">
                                  <div class="col"><label>Image:</label></div>
                                  <div class="col-9">
                                    <input nbInput formControlName="image" />
                                  </div>
                                </div>
                                <br />
                                <div class="row">
                                  <div class="col"><label>Target:</label></div>
                                  <div class="col-9">
                                    <input nbInput formControlName="target" />
                                  </div>
                                </div>
                                <br />
                              </div>
                            </nb-card-body>
                          </nb-card>
                        </div>
                        <div class="col-6">
                          <nb-card>
                            <nb-card-header>Help Button</nb-card-header>

                            <nb-card-body>
                              <div formGroupName="help_button">
                                <div class="row">
                                  <div class="col"><label>Title:</label></div>
                                  <div class="col-9">
                                    <input nbInput formControlName="title" />
                                  </div>
                                </div>
                                <br />
                                <div class="row">
                                  <div class="col"><label>Image:</label></div>
                                  <div class="col-9">
                                    <input nbInput formControlName="image" />
                                  </div>
                                </div>
                                <br />
                                <div class="row">
                                  <div class="col"><label>Target:</label></div>
                                  <div class="col-9">
                                    <input nbInput formControlName="target" />
                                  </div>
                                </div>
                                <br />
                              </div>
                            </nb-card-body>
                          </nb-card>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-6">
                          <nb-card>
                            <nb-card-header>Image</nb-card-header>

                            <nb-card-body>
                              <div formGroupName="image">
                                <div class="row">
                                  <div class="col">
                                    <label>Name<span style="color: red">*</span>:</label>
                                  </div>
                                  <div class="col-9">
                                    <input nbInput formControlName="name" />
                                  </div>
                                </div>
                                <br />
                                <div class="row">
                                  <div class="col"><label>Bundle:</label></div>
                                  <div class="col-9">
                                    <input nbInput formControlName="bundle" />
                                  </div>
                                </div>
                                <br />
                                <div class="row">
                                  <div class="col-3"><label>Content Description:</label></div>
                                  <div class="col-9">
                                    <input nbInput formControlName="content_description" />
                                  </div>
                                </div>
                                <br />
                              </div>
                            </nb-card-body>
                          </nb-card>
                        </div>
                        <div class="col-6">
                          <nb-card>
                            <nb-card-header>Video</nb-card-header>

                            <nb-card-body>
                              <div formGroupName="video">
                                <div class="row">
                                  <div class="col">
                                    <label>Name<span style="color: red">*</span>:</label>
                                  </div>
                                  <div class="col-9">
                                    <input nbInput formControlName="name" />
                                  </div>
                                </div>
                                <br />
                                <div class="row">
                                  <div class="col"><label>Type:</label></div>
                                  <div class="col-9">
                                    <input nbInput formControlName="type" />
                                  </div>
                                </div>
                                <br />
                                <div class="row">
                                  <div class="col"><label>Bundle:</label></div>
                                  <div class="col-9">
                                    <input nbInput formControlName="bundle" />
                                  </div>
                                </div>
                                <br />
                                <div class="row">
                                  <div class="col-3"><label>Loop Playback:</label></div>
                                  <div class="col-9">
                                    <nb-checkbox formControlName="loop_playback"></nb-checkbox>
                                  </div>
                                </div>
                                <br />
                              </div>
                            </nb-card-body>
                          </nb-card>
                        </div>
                      </div>

                      <div class="row">
                        <nb-card>
                          <nb-card-header>Pagination</nb-card-header>

                          <nb-card-body>
                            <div formGroupName="pagination">
                              <div class="row">
                                <div class="col"><label>Title:</label></div>
                                <div class="col-9">
                                  <input nbInput formControlName="title" />
                                </div>
                              </div>
                              <br />

                              <div class="row">
                                <div class="col"><label>Screen Number:</label></div>
                                <div class="col-9">
                                  <input nbInput formControlName="screen_number" type="number" />
                                </div>
                              </div>
                              <br />

                              <div class="row">
                                <div class="col"><label>Page Control:</label></div>
                                <div class="col-9">
                                  <nb-checkbox formControlName="page_control"></nb-checkbox>
                                </div>
                              </div>
                              <br />

                              <br />
                              <div class="row">
                                <div class="col-6">
                                  <nb-card>
                                    <nb-card-header>Back</nb-card-header>

                                    <nb-card-body>
                                      <div formGroupName="back">
                                        <div class="row">
                                          <div class="col"><label>Title:</label></div>
                                          <div class="col-9">
                                            <input nbInput formControlName="title" />
                                          </div>
                                        </div>
                                        <br />
                                        <div class="row">
                                          <div class="col"><label>Image:</label></div>
                                          <div class="col-9">
                                            <input nbInput formControlName="image" />
                                          </div>
                                        </div>
                                        <br />
                                        <div class="row">
                                          <div class="col"><label>Target:</label></div>
                                          <div class="col-9">
                                            <input nbInput formControlName="target" />
                                          </div>
                                        </div>
                                        <br /></div></nb-card-body
                                  ></nb-card>
                                </div>
                                <div class="col-6">
                                  <nb-card>
                                    <nb-card-header>Next</nb-card-header>

                                    <nb-card-body>
                                      <div formGroupName="next">
                                        <div class="row">
                                          <div class="col"><label>Title:</label></div>
                                          <div class="col-9">
                                            <input nbInput formControlName="title" />
                                          </div>
                                        </div>
                                        <br />
                                        <div class="row">
                                          <div class="col"><label>Image:</label></div>
                                          <div class="col-9">
                                            <input nbInput formControlName="image" />
                                          </div>
                                        </div>
                                        <br />
                                        <div class="row">
                                          <div class="col"><label>Target:</label></div>
                                          <div class="col-9">
                                            <input nbInput formControlName="target" />
                                          </div>
                                        </div>
                                        <br /></div></nb-card-body
                                  ></nb-card>
                                </div>
                              </div>
                            </div> </nb-card-body
                        ></nb-card>
                      </div>

                      <div formArrayName="navigation">
                        <div
                          *ngFor="
                            let nav of script.get('screen').get('navigation').controls;
                            let navigationIndex = index
                          "
                          [formGroupName]="navigationIndex"
                        >
                          <nb-accordion style="width: 100%">
                            <nb-accordion-item [expanded]="true" style="width: 100%">
                              <nb-accordion-item-header>
                                <div class="col-auto mr-auto">
                                  <h5>Navigation {{ navigationIndex + 1 }}</h5>
                                </div>
                                <div class="col-auto" style="padding-right: 40px">
                                  <button
                                    nbTooltip="Delete Navigation"
                                    nbButton
                                    size="small"
                                    status="danger"
                                    (click)="removeNavigation(scriptIndex)"
                                  >
                                    <nb-icon icon="trash-2-outline"></nb-icon>
                                  </button>
                                </div>
                              </nb-accordion-item-header>
                              <nb-accordion-item-body>
                                <div class="row">
                                  <div class="col"><label>Title:</label></div>
                                  <div class="col-9">
                                    <input nbInput formControlName="title" />
                                  </div>
                                </div>
                                <br />
                                <div class="row">
                                  <div class="col"><label>Hidden:</label></div>
                                  <div class="col-9">
                                    <nb-checkbox formControlName="hidden"></nb-checkbox>
                                  </div>
                                </div>
                                <br />
                                <div *ngIf="nav.get('hidden').value">
                                  <div class="row">
                                    <div class="col">
                                      <label>Identifier<span style="color: red">*</span>:</label>
                                    </div>
                                    <div class="col-9">
                                      <input nbInput formControlName="identifier" />
                                    </div>
                                  </div>
                                  <br />
                                </div>
                                <div class="row">
                                  <div class="col">Style:</div>
                                  <div class="col-9">
                                    <div class="col-9">
                                      <nb-select placeholder="Style" formControlName="style">
                                        <nb-option value="primary">Primary</nb-option>
                                        <nb-option value="secondary">Secondary</nb-option>
                                        <nb-option value="tertiary">Tertiary</nb-option>
                                      </nb-select>
                                    </div>
                                  </div>
                                </div>
                                <br />

                                <div class="row">
                                  <div class="col">Action Type:</div>
                                  <div class="col-9">
                                    <div class="col-9">
                                      <nb-select
                                        placeholder="Action Type"
                                        (selectedChange)="onActionTypeChange(scriptIndex, navigationIndex, $event)"
                                      >
                                        <nb-option value="push">Push</nb-option>
                                        <nb-option value="pop">Pop</nb-option>
                                        <nb-option value="custom">Custom</nb-option>
                                        <nb-option value="alert">Alert</nb-option>
                                        <nb-option value="current_vc">current_vc</nb-option>
                                      </nb-select>
                                    </div>
                                  </div>
                                </div>
                                <br />
                                <div *ngIf="nav.get('type').value !== ''" class="row">
                                  <nb-card>
                                    <nb-card-header>Action: {{ nav.get('type').value | titlecase }}</nb-card-header>

                                    <nb-card-body>
                                      <!-- Push Object -->
                                      <div *ngIf="nav.get('type').value === 'push'" formGroupName="push">
                                        <div class="row">
                                          <div class="col">
                                            <label>Push Screen Key<span style="color: red">*</span>:</label>
                                          </div>
                                          <div class="col-9">
                                            <input nbInput formControlName="screen_key" />
                                          </div>
                                        </div>
                                        <br />
                                      </div>

                                      <!-- Pop Object -->
                                      <div *ngIf="nav.get('type').value === 'pop'" formGroupName="pop">
                                        <div class="row">
                                          <div class="col">
                                            <label>Pop Screen Key<span style="color: red">*</span>:</label>
                                          </div>
                                          <div class="col-9">
                                            <input nbInput formControlName="screen_key" />
                                          </div>
                                        </div>
                                        <br />
                                      </div>

                                      <!-- Custom Object -->
                                      <div *ngIf="nav.get('type').value === 'custom'" formGroupName="custom">
                                        <div class="row">
                                          <div class="col">
                                            <label>Handler Key<span style="color: red">*</span>:</label>
                                          </div>
                                          <div class="col-9">
                                            <input nbInput formControlName="handler_key" />
                                          </div>
                                        </div>
                                        <br />

                                        <div formGroupName="navigation_action">
                                          <div class="row">
                                            <div class="col">Navigation Action<span style="color: red">*</span>:</div>
                                            <div class="col-9">
                                              <div class="col-9">
                                                <nb-select
                                                  placeholder="Action Type"
                                                  (selectedChange)="
                                                    onNavigationActionTypeChange(
                                                      scriptIndex,
                                                      navigationIndex,
                                                      nav.get('type').value,
                                                      $event
                                                    )
                                                  "
                                                >
                                                  <nb-option value="push">Push</nb-option>
                                                  <nb-option value="pop">Pop</nb-option>
                                                  <nb-option value="alert">Alert</nb-option>
                                                </nb-select>
                                              </div>
                                            </div>
                                          </div>
                                          <br />
                                          <div
                                            *ngIf="
                                              nav.get('custom').get('navigation_action').get('type').value === 'push'
                                            "
                                            formGroupName="push"
                                          >
                                            <div class="row">
                                              <div class="col">
                                                <label>Push Screen Key<span style="color: red">*</span>:</label>
                                              </div>
                                              <div class="col-9">
                                                <input nbInput formControlName="screen_key" />
                                              </div>
                                            </div>
                                            <br />
                                          </div>
                                          <div
                                            *ngIf="
                                              nav.get('custom').get('navigation_action').get('type').value === 'pop'
                                            "
                                            formGroupName="pop"
                                          >
                                            <div class="row">
                                              <div class="col">
                                                <label>Pop Screen Key<span style="color: red">*</span>:</label>
                                              </div>
                                              <div class="col-9">
                                                <input nbInput formControlName="screen_key" />
                                              </div>
                                            </div>
                                            <br />
                                          </div>

                                          <div
                                            *ngIf="
                                              nav.get('custom').get('navigation_action').get('type').value === 'alert'
                                            "
                                            formGroupName="alert"
                                          >
                                            <div class="row">
                                              <div class="col">
                                                <label>Title<span style="color: red">*</span>:</label>
                                              </div>
                                              <div class="col-9">
                                                <input nbInput formControlName="title" />
                                              </div>
                                            </div>
                                            <br />
                                            <div class="row">
                                              <div class="col">
                                                <label>Message<span style="color: red">*</span>:</label>
                                              </div>
                                              <div class="col-9">
                                                <input nbInput formControlName="message" />
                                              </div>
                                            </div>
                                            <br />
                                            <div class="row">
                                              <div class="col">Style:</div>

                                              <div class="col-9">
                                                <nb-select placeholder="Style" formControlName="style">
                                                  <nb-option value="alert">Alert</nb-option>
                                                  <nb-option value="action-sheet">Action Sheet</nb-option>
                                                </nb-select>
                                              </div>
                                            </div>
                                            <br />
                                          </div>
                                        </div>

                                        <div class="row">
                                          <nb-card>
                                            <nb-card-header>Custom Attribute</nb-card-header>
                                            <nb-card-body>
                                              <div class="row">
                                                <div class="col-6">
                                                  <div class="row">
                                                    <div class="col"><label>Name</label></div>
                                                    <div class="col-9">
                                                      <input nbInput formControlName="key_name" />
                                                    </div>
                                                  </div>
                                                  <br />
                                                </div>
                                                <div class="col-6">
                                                  <div class="row">
                                                    <div class="col"><label>Value</label></div>
                                                    <div class="col-9">
                                                      <input nbInput formControlName="key_value" />
                                                    </div>
                                                  </div>
                                                  <br />
                                                </div>
                                              </div> </nb-card-body
                                          ></nb-card>
                                        </div>
                                      </div>

                                      <!-- Current VC Object -->
                                      <div *ngIf="nav.get('type').value === 'current_vc'" formGroupName="current_vc">
                                        <div formGroupName="navigation_action">
                                          <div class="row">
                                            <div class="col">Navigation Action<span style="color: red">*</span>:</div>
                                            <div class="col-9">
                                              <div class="col-9">
                                                <nb-select
                                                  placeholder="Action Type"
                                                  (selectedChange)="
                                                    onNavigationActionTypeChange(
                                                      scriptIndex,
                                                      navigationIndex,
                                                      nav.get('type').value,
                                                      $event
                                                    )
                                                  "
                                                >
                                                  <nb-option value="push">Push</nb-option>
                                                  <nb-option value="pop">Pop</nb-option>
                                                  <nb-option value="alert">Alert</nb-option>
                                                </nb-select>
                                              </div>
                                            </div>
                                          </div>
                                          <br />
                                          <div
                                            *ngIf="
                                              nav.get('current_vc').get('navigation_action').get('type').value ===
                                              'push'
                                            "
                                            formGroupName="push"
                                          >
                                            <div class="row">
                                              <div class="col">
                                                <label>Push Screen Key<span style="color: red">*</span>:</label>
                                              </div>
                                              <div class="col-9">
                                                <input nbInput formControlName="screen_key" />
                                              </div>
                                            </div>
                                            <br />
                                          </div>
                                          <div
                                            *ngIf="
                                              nav.get('current_vc').get('navigation_action').get('type').value === 'pop'
                                            "
                                            formGroupName="pop"
                                          >
                                            <div class="row">
                                              <div class="col">
                                                <label>Pop Screen Key<span style="color: red">*</span>:</label>
                                              </div>
                                              <div class="col-9">
                                                <input nbInput formControlName="screen_key" />
                                              </div>
                                            </div>
                                            <br />
                                          </div>

                                          <div
                                            *ngIf="
                                              nav.get('current_vc').get('navigation_action').get('type').value ===
                                              'alert'
                                            "
                                            formGroupName="alert"
                                          >
                                            <div class="row">
                                              <div class="col">
                                                <label>Title<span style="color: red">*</span>:</label>
                                              </div>
                                              <div class="col-9">
                                                <input nbInput formControlName="title" />
                                              </div>
                                            </div>
                                            <br />
                                            <div class="row">
                                              <div class="col">
                                                <label>Message<span style="color: red">*</span>:</label>
                                              </div>
                                              <div class="col-9">
                                                <input nbInput formControlName="message" />
                                              </div>
                                            </div>
                                            <br />
                                            <div class="row">
                                              <div class="col">Style:</div>

                                              <div class="col-9">
                                                <nb-select placeholder="Style" formControlName="style">
                                                  <nb-option value="alert">Alert</nb-option>
                                                  <nb-option value="action-sheet">Action Sheet</nb-option>
                                                </nb-select>
                                              </div>
                                            </div>
                                            <br />
                                          </div>
                                        </div>
                                      </div>

                                      <!-- Alert Object -->
                                      <div *ngIf="nav.get('type').value === 'alert'" formGroupName="alert">
                                        <div class="row">
                                          <div class="col">
                                            <label>Title<span style="color: red">*</span>:</label>
                                          </div>
                                          <div class="col-9">
                                            <input nbInput formControlName="title" />
                                          </div>
                                        </div>
                                        <br />
                                        <div class="row">
                                          <div class="col">
                                            <label>Message<span style="color: red">*</span>:</label>
                                          </div>
                                          <div class="col-9">
                                            <input nbInput formControlName="message" />
                                          </div>
                                        </div>
                                        <br />
                                        <div class="row">
                                          <div class="col">Style:</div>

                                          <div class="col-9">
                                            <nb-select placeholder="Style" formControlName="style">
                                              <nb-option value="alert">Alert</nb-option>
                                              <nb-option value="action-sheet">Action Sheet</nb-option>
                                            </nb-select>
                                          </div>
                                        </div>
                                        <br />
                                      </div> </nb-card-body
                                  ></nb-card>
                                </div> </nb-accordion-item-body></nb-accordion-item
                          ></nb-accordion>
                        </div>
                        <br />
                        <button nbButton status="info" (click)="addNavigation(scriptIndex)">Add Navigation</button>
                      </div>
                    </div>
                  </nb-accordion-item-body></nb-accordion-item
                ></nb-accordion
              >
            </div>
          </div>
        </div>
        <br />
        <button nbButton status="info" (click)="addScript()">Add Script</button>
      </form>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div class="d-flex flex-row-reverse">
      <div class="p-2">
        <button nbButton status="success" (click)="copyToClipboard()">Copy To Clipboard</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
