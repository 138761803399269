<app-spinner *ngIf="spin"></app-spinner>
<nb-card style="height: 700px" *ngIf="!spin">
  <nb-card-header>
    <h3 style="position: relative">Cleaning Time Estimate</h3>
  </nb-card-header>
  <nb-card-body>
    <div style="height: 700px; overflow: auto; padding: 1.143em; margin: -1.143em">
      <div class="container" style="max-width: 800px">
        <div style="margin: 10px">
          <ng-container>
            <div class="formgroupmodal">
              <div>
                <h4>Select a robot:</h4>
                <br />
                <div class="row">
                  <div class="col-auto" style="width: 530px">
                    <ng-select
                      [(ngModel)]="selectedRobot"
                      (change)="onRobotSelect($event)"
                      [groupBy]="groupByFn()"
                      (clear)="clear()"
                    >
                      <ng-container>
                        <ng-option *ngFor="let p of commonRobots" [value]="p"> {{ p.name }} - {{ p.id }} </ng-option>
                      </ng-container>
                    </ng-select>
                  </div>
                </div>
              </div>
              <br />
              <div *ngIf="robotSelected !== false" class="col-mr-auto">
                <button nbButton nbTooltip="Add new estimate" status="success" size="small" (click)="createComponent()">
                  <nb-icon icon="plus-square-outline"></nb-icon>Add New Cleaning Estimate
                </button>
              </div>
              <div *ngIf="robotSelected !== false">
                <ng-template #viewContainerRef></ng-template>
              </div>
            </div>
            <div style="margin-top: 10px">
              <button
                *ngIf="robotSelected !== false"
                class="btn btn-primary"
                (click)="onSubmit()"
                [disabled]="!showSubmit()"
              >
                Submit
              </button>
            </div>
            <div class="alert alert-success mt-4" *ngIf="submissionSent">
              <h4>Notification submitted!</h4>
              <hr />
              <pre>{{ this.service.sentEstimates | json }}</pre>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>
