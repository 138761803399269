import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { CannedKozr } from 'src/app/models/cannedKozr';

@Component({
    selector: 'app-view-stats',
    templateUrl: './view-stats.component.html',
    styleUrls: ['./view-stats.component.css']
})
export class ViewStatsComponent implements OnInit {
  public cannedKozr: CannedKozr;

  constructor(
    protected ref: NbDialogRef<ViewStatsComponent>
  ) {}

  public ngOnInit() {
  }

  public close() {
      this.ref.close();
  }
}
