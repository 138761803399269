<hr />
<label
  ><strong>{{ campaign.name }}</strong></label
>
<form class="form-group">
  <div *ngFor="let p of localParamArray; let i = index" class="row" style="margin: 10px">
    <label for="p" class="col-6" style="margin-top: 10px">{{ p }}:</label>
    <div class="col-6" fullWidth>
      <input
        type="text"
        required
        [(ngModel)]="localValueArray[i]"
        name="p"
        class="form-control"
        nbInput
        id="p"
        (change)="parameterValueChanged(i, $event)"
      />
      <div *ngIf="p.touched && !p.dirty" class="alert alert-danger">
        <div *ngIf="p.errors.required">{{ p }} is a required field.</div>
      </div>
    </div>
  </div>
</form>
