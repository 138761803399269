import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { NgxGenericModalComponent } from 'src/app/shared/ngx-generic-modal/ngx-generic-modal.component';
import { RouterState } from 'src/app/shared/redux/router-state';
import { DrbState, SaveDrbState } from '../../redux';

@Component({
  selector: 'app-manage-contributors',
  templateUrl: './manage-contributors.component.html',
  styleUrls: ['./manage-contributors.component.css']
})
export class ManageContributorsComponent implements OnInit {
  @Select(RouterState.username) public data_username$: Observable<any>;
  @Select(DrbState.drb) public data$: Observable<any>;

  public drb;
  public contributors;
  public username;
  public errorMessage;
  public max_contributors = 2;
  public bundle_name;

  constructor(
    private store: Store,
    protected ref: NbDialogRef<ManageContributorsComponent>,
    private dialogService: NbDialogService
  ) {}

  public ngOnInit() {
    this.data$.subscribe((data) => (this.drb = data));
    this.data_username$.subscribe((data) => (this.username = data));
    if (this.contributors.length === 0) {
      this.contributors = Array(this.max_contributors).fill('');
    }
    if (this.contributors.length > 0 && this.contributors.length < this.max_contributors) {
      const numEmptyValues = this.max_contributors - this.contributors.length;
      const emptyValues = new Array(numEmptyValues).fill('');
      this.contributors = this.contributors.concat(emptyValues);
    }
  }

  public save() {
    const localDrb = JSON.parse(JSON.stringify(this.drb));

    if (this.contributors.every(this.verifyEmail)) {
      if (this.checkIfNoDuplicates()) {
        if (this.checkContributorOwner()) {
          localDrb.contributors = this.contributors.filter((x) => x !== '');
          localDrb.last_updated_by = this.username;
          localDrb.last_updated_ts = Math.floor(Date.now() / 1000);
          localDrb.bundle_name = this.bundle_name;

          this.store.dispatch(new SaveDrbState(localDrb));
          this.ref.close();
        } else {
          this.errorMessage = 'Action not allowed: User is already the Owner.';
        }
      } else {
        this.errorMessage = 'Action not allowed: User is already a contributor.';
      }
    } else {
      this.errorMessage = 'Please enter valid contributor email address. name@irobot.com';
    }
  }

  public trackByIndex(index: number, obj: any): any {
    return index;
  }

  public verifyEmail(email) {
    return email.length === 0 || Boolean(email.match('^[\\w-\\.]+@irobot.com$'));
  }

  public close() {
    this.dialogService
      .open(NgxGenericModalComponent, {
        context: {
          title: 'Confirm Close',
          message: 'Are you sure that you want to close the window? Any changes that you have made will be lost.'
        }
      })
      .onClose.subscribe((data) => {
        if (data) {
          this.ref.close();
        }
      });
  }

  public checkIfNoDuplicates() {
    const nonEmptyArray = this.contributors.filter((str) => str !== '');
    const set = new Set(nonEmptyArray);

    return set.size === nonEmptyArray.length;
  }

  public checkContributorOwner() {
    return !this.contributors.includes(this.drb.owner);
  }
}
