import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Xliff } from 'src/app/models/drb-models/xliff';
import { NgxGenericModalComponent } from 'src/app/shared/ngx-generic-modal/ngx-generic-modal.component';
import { RouterState } from 'src/app/shared/redux/router-state';
import { DrbState, SaveXliffState } from '../../redux';
import { translationLanguages } from '../../../../shared/constants/translation-languages';
import { NgxConfirmModalComponent } from 'src/app/shared/ngx-confirm-modal/ngx-confirm-modal.component';

@Component({
  selector: 'app-edit-xliff',
  templateUrl: './edit-xliff.component.html',
  styleUrls: ['./edit-xliff.component.css', '../../../../shared/common_syles.css']
})
export class EditXliffComponent implements OnInit {
  @Select(DrbState.xliff) public data$: Observable<any[]>;
  @Select(DrbState.ids) public ids$: Observable<any[]>;
  @Select(RouterState.username) public data_username$: Observable<any>;

  public title;
  public xliff: Xliff;
  public xliffState: {};
  public username = '';
  public ids;
  public patternId = '^[a-z0-9_]+$';
  public patternFirstChar = '^\\S.*';
  public translationLanguages = translationLanguages;
  public initialSource;
  public bundle_name: string;
  public editable = false;

  constructor(
    private store: Store,
    protected ref: NbDialogRef<EditXliffComponent>,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService
  ) {}

  public ngOnInit() {
    this.data_username$.subscribe((data) => (this.username = data));
    this.data$.subscribe((data) => (this.xliffState = data));
    this.ids$.subscribe((data) => (this.ids = data));
    this.initialSource = this.xliff.source;

    if (this.title === 'New') {
      this.xliff.note = 'Do not translate';
    }
  }

  public save() {
    const localSourceXliff = JSON.parse(JSON.stringify(this.xliffState));

    if (this.title === 'New') {
      const xliffId = this.prefix() + this.xliff.id + '_dmc';
      if (this.ids.some((item) => item === xliffId)) {
        this.toastrService.show('', xliffId + ' already exists!', { status: 'danger' });
      } else {
        this.xliff.id = xliffId;
        this.xliff.version = 1;
        this.xliff.created_by = this.username;
        this.xliff.created_ts = Math.floor(Date.now() / 1000);
        this.xliff.last_updated_by = this.username;
        this.xliff.last_updated_ts = Math.floor(Date.now() / 1000);

        localSourceXliff.push(this.xliff);
        this.store.dispatch(new SaveXliffState(localSourceXliff, this.bundle_name));
        this.ref.close();
      }
    } else if (this.title === 'Edit') {
      const index = localSourceXliff.findIndex((item) => item.id === this.xliff.id);
      localSourceXliff[index].version = this.xliff.version + 1;
      localSourceXliff[index].source = this.xliff.source;
      localSourceXliff[index].note = this.editable ? 'Do not translate' : this.xliff.note;
      localSourceXliff[index].last_updated_by = this.username;
      localSourceXliff[index].last_updated_ts = Math.floor(Date.now() / 1000);

      if (this.initialSource !== this.xliff.source) {
        // eslint-disable-next-line guard-for-in
        for (const lang in translationLanguages) {
          localSourceXliff[index][lang] = '';
        }
        this.confirmEditSave(localSourceXliff);
      } else if (this.initialSource === this.xliff.source) {
        // eslint-disable-next-line guard-for-in
        for (const lang in translationLanguages) {
          localSourceXliff[index][lang] = this.xliff[lang];
        }
        this.store.dispatch(new SaveXliffState(localSourceXliff, this.bundle_name));
        this.ref.close();
      }
    }
  }

  public close() {
    this.dialogService
      .open(NgxGenericModalComponent, {
        context: {
          title: 'Confirm Close',
          message: 'Are you sure that you want to close the window? Any changes that you have made will be lost.'
        }
      })
      .onClose.subscribe((data) => {
        if (data) {
          this.ref.close();
        }
      });
  }

  public confirmEditSave(localSourceXliff: Xliff) {
    this.dialogService
      .open(NgxConfirmModalComponent, {
        context: {
          title: 'Confirm Save',
          yesButton: 'Save',
          noButton: 'Cancel',
          message:
            'Before saving please be aware that editing the source field will clear all the current translations. Are you sure that you want to save your changes?' +
            '\n\n' +
            'To save, type CONFIRM below and click SAVE'
        }
      })
      .onClose.subscribe((data) => {
        if (data) {
          this.store.dispatch(new SaveXliffState(localSourceXliff, this.bundle_name));
          this.ref.close();
        }
      });
  }

  public descOrder = (a, b) => {
    if (a.key < b.key) {
      return b.key;
    }
  };

  public prefix() {
    if(this.bundle_name === 'marketing') {

      return 'mkt_';
    } else if(this.bundle_name === 'marketing_translations') {

      return 'mkt_t_';
    }
  }
}
