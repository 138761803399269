export class SetURL {
  public static readonly type = '[URL] SetURL';
  constructor(public payload: any) {}
}

export class SetIsLogedIn {
  public static readonly type = '[URL] SetIsLogedIn';
  constructor(public payload: any) {}
}

export class SetUsername {
  public static readonly type = '[URL] SetUsername';
  constructor(public payload: any) {}
}

export class SetUserGroups {
  public static readonly type = '[URL] SetUserGroups';
  constructor(public payload: any) {}
}

export class SetTheme {
  public static readonly type = '[URL] SetTheme';
  constructor(public payload: any) {}
}

