import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { Clipboard } from '@angular/cdk/clipboard';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { NgxGenericModalComponent } from 'src/app/shared/ngx-generic-modal/ngx-generic-modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-scripted-nav',
  templateUrl: './create-scripted-nav.component.html',
  styleUrls: ['./create-scripted-nav.component.css']
})
export class CreateScriptedNavComponent implements OnInit {
  public scriptedNavForm: FormGroup;
  public title: string;

  constructor(
    protected ref: NbDialogRef<CreateScriptedNavComponent>,
    private clipboard: Clipboard,
    private fb: FormBuilder,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
    private router: Router
  ) {
    this.scriptedNavForm = this.fb.group({
      scripts: this.fb.array([])
    });
  }
  public get scripts(): FormArray {
    return this.scriptedNavForm.get('scripts') as FormArray;
  }
  public ngOnInit(): void {
    this.addScript();
  }

  public initScript(): FormGroup {
    return this.fb.group({
      total_screen_number: [''],
      screen_name: [''],
      setup_root_screen_key: ['', Validators.required],
      screen: this.initScreen()
    });
  }

  public initScreen(): FormGroup {
    return this.fb.group({
      class_key: ['', Validators.required],
      close_button: this.initButtonObject(),
      help_button: this.initButtonObject(),
      background_color: [''],
      title_text: [''],
      hides_back_button: [''],
      image: this.initImageObject(),
      video: this.initVideoObject(),
      header_text: ['', Validators.required],
      info_text: ['', Validators.required],
      hyperlink: [''],
      pagination: this.initPaginationObject(),
      navigation: this.fb.array([]),
      url: ['']
    });
  }

  public initButtonObject(): FormGroup {
    return this.fb.group({
      title: [''],
      image: [''],
      target: ['']
    });
  }

  public initImageObject(): FormGroup {
    return this.fb.group({
      name: ['', Validators.required],
      bundle: [''],
      content_description: ['']
    });
  }

  public initVideoObject(): FormGroup {
    return this.fb.group({
      name: ['', Validators.required],
      type: ['', Validators.required],
      bundle: [''],
      loop_playback: ['']
    });
  }

  public initPaginationObject(): FormGroup {
    return this.fb.group({
      title: [''],
      screen_number: [''],
      page_control: [''],
      back: this.initNavigationAction(),
      next: this.initNavigationAction()
    });
  }

  public initNavigationAction(): FormGroup {
    return this.fb.group({
      title: [''],
      image: [''],
      target: ['', Validators.required]
    });
  }

  public initNavigationObject(): FormGroup {
    return this.fb.group({
      title: [''],
      hidden: [''],
      identifier: [''],
      style: ['primary'],
      type: [''],
      push: this.initPushObject(),
      pop: this.initPopObject(),
      custom: this.initCustomObject(),
      current_vc: this.initCurrentVcObject(),
      alert: this.initAlertObject()
    });
  }

  public initActionObject(type: string): FormGroup {
    const group = this.fb.group({
      type: [type, Validators.required]
    });

    switch (type) {
      case 'push':
        group.addControl('push', this.initPushObject());
        break;
      case 'pop':
        group.addControl('pop', this.initPopObject());
        break;
      case 'custom':
        group.addControl('custom', this.initCustomObject());
        break;
      case 'current_vc':
        group.addControl('current_vc', this.initCurrentVcObject());
        break;
      case 'alert':
        group.addControl('alert', this.initAlertObject());
        break;
      default:
        break;
    }

    return group;
  }

  public initPushObject(): FormGroup {
    return this.fb.group({
      screen_key: ['', Validators.required]
    });
  }

  public initPopObject(): FormGroup {
    return this.fb.group({
      screen_key: ['', Validators.required]
    });
  }

  public initCustomObject(): FormGroup {
    return this.fb.group({
      handler_key: ['', Validators.required],
      navigation_action: this.initActionObject(''),
      key_name: [''],
      key_value: ['']
    });
  }

  public initCurrentVcObject(): FormGroup {
    return this.fb.group({
      navigation_action: this.initActionObject('')
    });
  }

  public initAlertObject(): FormGroup {
    return this.fb.group({
      title: ['', Validators.required],
      message: ['', Validators.required],
      style: ['alert']
    });
  }

  public addScript(): void {
    this.scripts.push(this.initScript());
  }

  public removeScript(index: number): void {
    this.scripts.removeAt(index);
  }

  public addNavigation(scriptIndex: number): void {
    const control = this.scripts.at(scriptIndex).get('screen.navigation') as FormArray;
    control.push(this.initNavigationObject());
  }

  public removeNavigation(scriptIndex: number, navigationIndex: number): void {
    const control = this.scripts.at(scriptIndex).get('screen.navigation') as FormArray;
    control.removeAt(navigationIndex);
  }

  public onActionTypeChange(scriptIndex: number, navigationIndex: number, event: any): void {
    console.log(event, scriptIndex);
    const actionType = event;
    const control = (this.scripts.at(scriptIndex).get('screen.navigation') as FormArray).at(
      navigationIndex
    ) as FormGroup;
    control.get('type').setValue(actionType);
    // Reset the controls based on the selected action type
    ['push', 'pop', 'custom', 'current_vc', 'alert'].forEach((type) => {
      if (type !== actionType) {
        control.removeControl(type);
      }
    });

    // Add the selected action control
    if (!control.get(actionType)) {
      switch (actionType) {
        case 'push':
          control.addControl('push', this.initPushObject());
          break;
        case 'pop':
          control.addControl('pop', this.initPopObject());
          break;
        case 'custom':
          control.addControl('custom', this.initCustomObject());
          break;
        case 'current_vc':
          control.addControl('current_vc', this.initCurrentVcObject());
          break;
        case 'alert':
          control.addControl('alert', this.initAlertObject());
          break;
      }
    }
  }

  public onNavigationActionTypeChange(
    scriptIndex: number,
    navigationIndex: number,
    actionType: string,
    event: any
  ): void {
    const selectedActionType = event;
    const control = (this.scripts.at(scriptIndex).get('screen.navigation') as FormArray)
      .at(navigationIndex)
      .get(actionType)
      .get('navigation_action') as FormGroup;

    control.get('type').setValue(selectedActionType);

    // Reset the controls based on the selected action type
    ['push', 'pop', 'alert'].forEach((type) => {
      if (type !== selectedActionType) {
        control.removeControl(type);
      }
    });

    // Add the selected action control
    if (!control.get(selectedActionType)) {
      switch (selectedActionType) {
        case 'push':
          control.addControl('push', this.initPushObject());
          break;
        case 'pop':
          control.addControl('pop', this.initPopObject());
          break;
        case 'alert':
          control.addControl('alert', this.initAlertObject());
          break;
      }
    }
  }
  public transformObject(obj: any, parentKey: string = ''): any {
    // recursive function
    // creates custom attribute when finds key_name and key_value
    // removes control type from navigation and navigation_action objects
    // sets screen name as key for screen object
    if (Array.isArray(obj)) {
      return obj.map((item) => this.transformObject(item, parentKey));
    } else if (typeof obj === 'object' && obj !== null) {
      const newObj: any = {};
      for (const key of Object.keys(obj)) {
        if (key === 'key_name' && obj[key] && obj.key_value) {
          newObj[obj[key]] = obj.key_value;
        } else if (key === 'screen_name' && obj[key] && obj.screen) {
          newObj[obj[key]] = this.transformObject(obj.screen);
        } else if ((parentKey === 'navigation' || parentKey === 'navigation_action') && key === 'type') {
          // Skip the 'type' key for navigation objects
        } else if (key !== 'key_value' && key !== 'screen') {
          newObj[key] = this.transformObject(obj[key], key);
        }
      }

      return newObj;
    }

    return obj;
  }
  public removeEmptyValues(obj: any): any {
    // recursive
    // removes undefined, empty fields, empty objects and arrays
    if (Array.isArray(obj)) {
      const filteredArray = obj
        .map((item) => this.removeEmptyValues(item))
        .filter(
          (item) => item !== null && item !== undefined && (typeof item !== 'object' || Object.keys(item).length > 0)
        );

      return filteredArray.length > 0 ? filteredArray : undefined;
    } else if (typeof obj === 'object' && obj !== null) {
      const newObj: any = {};
      for (const key of Object.keys(obj)) {
        const value = obj[key];
        const cleanedValue = this.removeEmptyValues(value);
        if (
          cleanedValue !== null &&
          cleanedValue !== undefined &&
          (typeof cleanedValue !== 'object' || Object.keys(cleanedValue).length > 0)
        ) {
          newObj[key] = cleanedValue;
        }
      }

      return Object.keys(newObj).length > 0 ? newObj : undefined;
    }

    return obj === '' ? undefined : obj;
  }
  public copyToClipboard(): void {
    // cleanup empty data
    const cleanedData = this.removeEmptyValues(this.scriptedNavForm.value.scripts);
    // handle key manipulation
    const transformedData = this.transformObject(cleanedData);
    const json = JSON.stringify(transformedData, null, 2);
    this.clipboard.copy(json);
    this.toastrService.show(status, 'Copied to clipboard!', { status: 'success' });
  }

  public close() {
    this.dialogService
      .open(NgxGenericModalComponent, {
        context: {
          title: 'Confirm Close',
          message: 'Are you sure that you want to close the window? Any changes that you have made will be lost.'
        }
      })
      .onClose.subscribe((data) => {
        if (data) {
          this.ref.close();
          this.router.navigate(['/content/table']);
        }
      });
  }
}
