<br>
<nb-accordion>
  <nb-accordion-item>
    <nb-accordion-item-header (click)="$event.stopPropagation()">
      <div class="col-auto mr-auto"><label> Cleaning Estimate: {{unique_key}}</label></div>
      <div class="col-auto" style="padding-right: 40px"><button nbTooltip="Delete" nbButton size="small" status="danger"
          (click)="remove_me()">
          <nb-icon icon="trash-2-outline"></nb-icon>
        </button></div>
    </nb-accordion-item-header>
    <nb-accordion-item-body>
      <div class="row">
        <div class="col">
          <label>Area Type:</label>
        </div>
        <div class="col">
          <nb-select [(ngModel)]="estimate.area_type">
            <nb-option *ngFor="let type of areaTypeValues" [value]="type">{{type}}
            </nb-option>
          </nb-select>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col">
          <label>Area ID:</label>
        </div>
        <div class="col">
          <input nbInput type="number" class="form-control" [(ngModel)]="estimate.area_id">
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col">
          <label>Pmap ID:</label>
        </div>
        <div class="col">
          <input nbInput type="text" class="form-control" [(ngModel)]="estimate.pmap_id">
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col">
          <label>Cleaning Estimate (in seconds):</label>
        </div>
        <div class="col">
          <input nbInput type="number" class="form-control" [(ngModel)]="estimate.value">
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col">
          <label>Data Model Version:</label>
        </div>
        <div class="col">
          <input nbInput type="text" class="form-control" [(ngModel)]="estimate.data_model_version">
        </div>
      </div>
    </nb-accordion-item-body>
  </nb-accordion-item>
</nb-accordion>
