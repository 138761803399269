/* eslint-disable import/no-deprecated */
import { Amplify } from '@aws-amplify/core';
import { Component } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { config } from '../environments/environment';
import { RouterState } from './shared/redux/router-state';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  @Select(RouterState.isLogedIn) public data$: Observable<boolean>;
  public isLogin = false;

  constructor(private router: Router) {
    Amplify.configure(config);
    this.data$.subscribe((data) => (this.isLogin = data));
    if (window.location.href.includes('callback')) {
      this.router.navigateByUrl('/redirect');
    } else if (window.location.href.includes('signout')) {
      this.router.navigateByUrl('/signout');
    } else if (window.location.href.includes('campaigns/table/') && this.isLogin) {
      this.router.navigate(['/campaigns/table']);
    } else {
      this.router.navigateByUrl('/login');
    }
  }
}
