import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs/index';
import { UtilityService } from 'src/app/core/services/utility.service';
import { TimestampDatePipe } from '../../../shared/custom-pipe/timestamp-date.pipe';
import { RouterState } from '../../../shared/redux/router-state';
import { Insight } from '../../../models/insight';

@Component({
  selector: 'app-view-insight',
  templateUrl: './view-insight.component.html',
  styleUrls: ['./view-insight.component.css', '../../../shared/common_syles.css']
})
export class ViewInsightComponent implements OnInit, AfterViewInit {
  @Select(RouterState.theme) public data$: Observable<any>;

  @ViewChild('codeeditor') private codeEditor;

  public insight: Insight;
  public scheduleLabel: string;
  public formatted_next_run_time = '';
  public formatted_start_date = '';
  public formatted_end_date = '';
  public formatted_created_date = '';
  public formatted_updated_date = '';
  public insight_name_validator = false;
  public theme = '';
  constructor(
    private utilityService: UtilityService,
    protected ref: NbDialogRef<ViewInsightComponent>,
    private formatDatePipe: TimestampDatePipe
  ) {
    this.data$.subscribe((data) => (this.theme = data));
  }

  public regexValidator(nameRe: RegExp, value: string) {
    const validation = nameRe.test(value);

    return validation ? false : true;
  }
  public ngOnInit() {
    const regex = String.raw`^[a-zA-Z0-9][a-z\d\-_:\s]*$`;
    this.insight_name_validator = this.regexValidator(new RegExp(regex, 'i'), this.insight.name);
    this.scheduleLabel = this.utilityService.getMatchedFrequencyLabel(this.insight.schedule_seconds);
    const next_run_time = this.insight.next_run_time;
    this.formatted_next_run_time = this.formatDate(next_run_time);

    const created_date = this.insight.created_dttm;
    this.formatted_created_date = this.formatDate(created_date);

    const updated_date = this.insight.last_updated_dttm;
    this.formatted_updated_date = this.formatDate(updated_date);

    this.formatted_start_date = this.formatDate(this.insight.start_date);
    this.formatted_end_date = this.formatDate(this.insight.end_date);
  }

  public ngAfterViewInit() {
    const default_themes = ['default', 'corporate'];

    const theme = default_themes.includes(this.theme) ? 'default' : 'material';
    setTimeout(() => {
      if (this.codeEditor) {
        const editor = this.codeEditor.codeMirror;
        // editor.setOption("extraKeys", {
        //   LeftTripleClick: function (cm) {
        //     cm.setOption("fullScreen", !cm.getOption("fullScreen"));
        //   },
        // });
        editor.setOption('theme', theme);
      }
    }, 10);
  }

  public close() {
    this.ref.close();
  }

  public toDateTime = (epoch_time) => {
    const t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(epoch_time);

    return t;
  };

  public formatDate = (time) => {
    const formatted_time = this.formatDatePipe.transform(time, 'timestampToDate');
    if (formatted_time) {
      return formatted_time;
    } else {
      return '';
    }
  };
}
