import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { RouterState } from 'src/app/shared/redux/router-state';
import { FREQUENCIES, Frequency } from '../../models/frequencies';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  @Select(RouterState.username) public data_username$: Observable<any>;
  public signedApiEndpoints: any = {};
  public username;

  private FREQUENCIES_MAP: Map<number, Frequency>;

  constructor() {
    this.data_username$.subscribe((data) => (this.username = data));
  }

  //Used in view campaign and insights
  public getFrequencies(): Map<number, Frequency> {
    if (this.FREQUENCIES_MAP == null) {
      this.FREQUENCIES_MAP = new Map<number, Frequency>();
      for (const frequency of FREQUENCIES) {
        this.FREQUENCIES_MAP.set(frequency.value, frequency);
      }
    }

    return this.FREQUENCIES_MAP;
  }

  //Used in view campaign and insights
  public getMatchedFrequencyLabels(frequencyValues: number[]): string[] {
    const frequencyLabels: string[] = [];
    if (frequencyValues != null) {
      const frequencies: Map<number, Frequency> = this.getFrequencies();
      for (const frequencyValue of frequencyValues) {
        if (frequencies.has(frequencyValue)) {
          frequencyLabels.push(frequencies.get(frequencyValue).label);
        }
      }
    }

    return frequencyLabels;
  }

  //Used in view campaign and insights
  public getMatchedFrequencyLabel(frequencyValue: number): string {
    const frequencyLabels: string[] = this.getMatchedFrequencyLabels([frequencyValue]);

    return frequencyLabels.length > 0 ? frequencyLabels[0] : null;
  }

  /****************************************************************
   * Unix time convert method
   *
   * A method done explicitly for epoch conversion. Can be used
   * throught the whole code wherever time conversion is present.
   *
   ***************************************************************/

  //Used on many places
  public convertUnixTime_global(epoch: number): string | boolean {
    if (epoch !== 0 && epoch !== null && epoch !== undefined) {
      const epochDate: Date = new Date(epoch * 1000);
      const min: string = String(epochDate.getMinutes()).padStart(2, '0');
      const hour: number = ((epochDate.getHours() + 11) % 12) + 1;
      let strDate = '';
      const meridiem = epochDate.getHours() > 12 ? 'PM' : 'AM';

      enum months {
        Jan,
        Feb,
        Mar,
        Apr,
        May,
        Jun,
        Jul,
        Aug,
        Sep,
        Oct,
        Nov,
        Dec
      }

      strDate = `${epochDate.getFullYear()}/${
        months[epochDate.getMonth()]
      }/${epochDate.getDate()}   ${hour}:${min} ${meridiem}`;

      return strDate;
    } else {
      return null;
    }
  }

  public convertErrorToMessage(error) {
    const message =
      error.response && error.response.data
        ? error.response.data.message
          ? error.response.data.message
          : error.response.data
        : 'Error!';

    return message;
  }

  public createRandomMockID() {
    return 'mock-' + (Math.random() + 1).toString(36).substring(2); // creates a random number/string value
  }

  public formatDictToObject(data) {
    const tableData = new Array();
    const files = Object.entries(data).map(([key, values]) => ({ id: key, ...(values as {}) }));
    files.forEach((element) => {
      tableData.push(element);
    });

    return tableData;
  }

  public verifyEmail(email) {
    return email.length === 0 || email.match('^[\\w-\\.]+@irobot.com$');
  }
}
