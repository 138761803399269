import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {append, insertItem, patch, removeItem, updateItem} from '@ngxs/store/operators';
import { KozrService } from 'src/app/core/services/kozr.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { RobotsService } from 'src/app/core/services/robots.service';
import {
  TableSpinerReloadActive,
  TableSpinerReloadHide
} from 'src/app/shared/ngx-table/ngx-table/redux/table-spinner.actions';
import { ToggleHide, ToggleShow } from 'src/app/shared/spinner/redux/spinner.actions';
import {MapsService} from '../../../core/services/maps.service';
import { HtaRecommendationService } from './../../../core/services/hta-recommendation.service';
import { QaStateModel } from './qa-state.model';
import {
  DeleteKOZRs,
  DeleteRobots,
  DeleteRobotsSuccess,
  HtaRecommendationDelete,
  HtaRecommendationDeleteSuccess,
  HtaRecommendationLoad,
  HtaRecommendationLoadSucess,
  HtaRecommendationPost,
  HtaRecommendationReload,
  HtaRecommendationSaveComplete,
  LoadAllRobots,
  LoadKOZRs,
  LoadKOZRsSuccess,
  LoadOwnerRobots,
  LoadOwnerRobotsSuccess, LoadRobotMapRegionsSuccess, LoadRobotMapsSuccess, LoadRobotMapVersionsSuccess,
  LoadRobotsSuccess,
  PostKOZRs,
  PostRobots,
  QAPostNotificationFail,
  QAPostNotificationSuccess,
  ReloadAllRobots,
  ReloadKOZRs,
  ReloadKOZRsSuccess,
  ReloadOwnerRobots,
  ReloadRobotsSuccess,
  SaveKOZRComplete,
  SaveKOZRFail,
  SaveRobotComplete,
  SaveRobotFail
} from './qa.actions';

@State<QaStateModel>({
  name: 'qa',
  defaults: {
    Robots: [],
    ownerRobots: [],
    CannedKOZRbots: [],
    error: null,
    htaRecommendation: [],
    robotMaps: [],
    robotMapVersions: [],
    robotMapRegions: []
  }
})
@Injectable()
export class QaState {
  constructor(
    private robotService: RobotsService,
    private kozrService: KozrService,
    private toastrService: NbToastrService,
    private htaService: HtaRecommendationService,
    private utilityService: UtilityService,
    private mapsService: MapsService
  ) {}

  /*******************************************
   *                 ROBOTS
   ******************************************/

  @Selector()
  public static robots(state: QaStateModel) {
    return state.Robots;
  }

  @Selector()
  public static htaRecommendation(state: QaStateModel) {
    return state.htaRecommendation;
  }

  @Selector()
  public static ownerRobots(state: QaStateModel) {
    return state.ownerRobots;
  }

  @Selector()
  public static cannedKOZR(state: QaStateModel) {
    return state.CannedKOZRbots;
  }

  @Selector()
  public static robotMaps(state: QaStateModel) {
    return state.robotMaps;
  }

  @Selector()
  public static robotMapVersions(state: QaStateModel) {
    return state.robotMapVersions;
  }

  @Selector()
  public static robotMapRegions(state: QaStateModel) {
    return state.robotMapRegions;
  }

  /******************************************/

  @Action(LoadAllRobots)
  public async loadAllRobots(ctx: StateContext<QaStateModel>) {
    ctx.dispatch(ToggleShow);
    this.robotService.loadOwnerRobotsAmplify();
    this.robotService.loadAllRobotsAmplify();
  }

  @Action(LoadOwnerRobots)
  public async loadOwnerRobots(ctx: StateContext<QaStateModel>) {
    ctx.dispatch(ToggleShow);
    this.robotService.loadOwnerRobotsAmplify();
  }

  @Action(LoadOwnerRobotsSuccess)
  public async loadOwnerRobotsSuccess(ctx: StateContext<QaStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      ownerRobots: payload
    });
    ctx.dispatch(ToggleHide);
  }

  @Action(LoadRobotsSuccess)
  public loadRobotsSuccess(ctx: StateContext<QaStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      Robots: payload
    });
    ctx.dispatch(ToggleHide);
  }

  @Action(ReloadAllRobots)
  public async reloadAllRobots(ctx: StateContext<QaStateModel>) {
    this.toastrService.show(status, 'Fetch Initiated', { status: 'info' });
    this.robotService.loadAllRobotsAmplify(true);
  }

  @Action(ReloadOwnerRobots)
  public async reloadOwnerRobots(ctx: StateContext<QaStateModel>) {
    ctx.dispatch(TableSpinerReloadActive);

    this.robotService.loadOwnerRobotsAmplify();
  }

  @Action(ReloadRobotsSuccess)
  public reloadRobotsSuccess(ctx: StateContext<QaStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      Robots: payload
    });
    ctx.dispatch(TableSpinerReloadHide);
  }

  @Action(PostRobots)
  public async postRobots(ctx: StateContext<QaStateModel>, { payload, edit }: any) {
    this.robotService.postRobotAmplify(payload, edit);
  }

  @Action(DeleteRobots)
  public deleteRobots(ctx: StateContext<QaStateModel>, { payload }: any) {
    this.robotService.deleteRobotAmplify(payload);
  }

  @Action(DeleteRobotsSuccess)
  public deleteRobotsSucess(ctx: StateContext<QaStateModel>, { payload }: any) {
    if (payload.deleted === true) {
      ctx.setState(
        patch({
          // @ts-ignore
          ownerRobots: removeItem((item) => item.id === payload.id),
          // @ts-ignore
          Robots: removeItem((item) => item.id === payload.id)
        })
      );
      this.toastrService.show(status, 'Delete: Complete', { status: 'success' });
    }
  }

  @Action(SaveRobotComplete)
  public robotsComplete(ctx: StateContext<QaStateModel>, { data, robots }: any) {
    const state = ctx.getState();
    if (data === true) {
      ctx.setState(
        patch({
          ownerRobots: updateItem((item) => item.id === robots.id, robots)
        })
      );
      this.toastrService.show(status, 'Edit: Complete', { status: 'success' });
    } else {
      ctx.setState(
        patch({
          // @ts-ignore
          ownerRobots: [...state.ownerRobots, robots],
          Robots: [...state.Robots, robots]
        })
      );

      this.toastrService.show(status, 'Save: Complete', { status: 'success' });
    }
  }

  @Action(SaveRobotFail)
  public robotsFail(ctx: StateContext<QaStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      error: payload
    });
    this.toastrService.show(status, 'Save: Fail - ' + payload.statusText, { status: 'danger' });
  }

  /*******************************************
   *                  KOZRS
   ******************************************/

  @Selector()
  public static kozrs(state: QaStateModel) {
    return state.CannedKOZRbots;
  }

  /******************************************/

  @Action(LoadKOZRs)
  public async loadKOZRs(ctx: StateContext<QaStateModel>) {
    ctx.dispatch(ToggleShow);
    this.kozrService.loadKozrAmplify();
  }

  @Action(LoadKOZRsSuccess)
  public loadKOZRsSuccess(ctx: StateContext<QaStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      CannedKOZRbots: payload
    });
    ctx.dispatch(ToggleHide);
  }

  @Action(ReloadKOZRs)
  public async reloadKOZRs(ctx: StateContext<QaStateModel>) {
    this.kozrService.loadKozrAmplify(true);
  }

  @Action(ReloadKOZRsSuccess)
  public reloadKOZRsSuccess(ctx: StateContext<QaStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      CannedKOZRbots: payload
    });
    ctx.dispatch(TableSpinerReloadHide);
  }

  @Action(PostKOZRs)
  public async postKOZRs(ctx: StateContext<QaStateModel>, { payload, edit }: any) {
    this.kozrService.postKozrAmplify(payload, edit);
  }

  @Action(DeleteKOZRs)
  public deleteInsights(ctx: StateContext<QaStateModel>, { payload }: any) {
    this.kozrService.deleteKozrAmplify(payload);
  }

  @Action(SaveKOZRComplete)
  public KOZRComplete(ctx: StateContext<QaStateModel>, { data, cannedKozr, edit }: any) {
    // Data can contain "true" when its updating so we update item with "insights" which is local copy
    // In case you make new "insights" with post it will return new Object with "data" so in second case
    // We append it to last place in array with data
    const state = ctx.getState();
    if (edit === true) {
      if (cannedKozr.deleted === true) {
        ctx.setState(
          patch({
            // @ts-ignore
            CannedKOZRbots: removeItem((item) => item.koz_id === cannedKozr.koz_id)
          })
        );
      } else {
        ctx.setState(
          patch({
            CannedKOZRbots: updateItem((item) => item.koz_id === cannedKozr.koz_id, cannedKozr)
          })
        );
      }
    } else {
      ctx.setState(
        patch({
          CannedKOZRbots: [...state.CannedKOZRbots, data]
        })
      );
    }
    this.toastrService.show(status, 'Save: Complete', { status: 'success' });
  }

  @Action(SaveKOZRFail)
  public KOZRsFail(ctx: StateContext<QaStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      error: payload
    });
    this.toastrService.show(status, 'Save: Fail - ' + payload.statusText, { status: 'danger' });
  }

  /*******************************************
   *          QA Tool Notification Test
   ******************************************/
  @Action(QAPostNotificationSuccess)
  public async postNotificationSuccess(ctx: StateContext<QaStateModel>) {
    this.toastrService.show(status, 'Submit: Complete', { status: 'success' });
  }

  @Action(QAPostNotificationFail)
  public async postNotificitaionFail(ctx: StateContext<QaStateModel>, { payload }: any) {
    this.toastrService.show(status, 'Submit: Fail - ' + this.utilityService.convertErrorToMessage(payload), {
      status: 'danger',
      duration: 6000
    });
  }

  /*******************************************
   *          Favorite Recommendation
   ******************************************/
  @Action(LoadRobotMapsSuccess)
  public async robotMapLoadSuccess(ctx: StateContext<QaStateModel>, { robotMaps }: any) {
    // const state = ctx.getState();
    ctx.setState(
        patch({
          robotMaps: insertItem(robotMaps)
            }
        )
    );
  }

  @Action(LoadRobotMapVersionsSuccess)
  public async robotMapVersionsLoadSuccess(ctx: StateContext<QaStateModel>, { rmv }: any) {
    const state = ctx.getState();
    ctx.setState(
        patch({
          robotMapVersions: insertItem(rmv)
        })
    );
  }

  @Action(LoadRobotMapRegionsSuccess)
  public async robotMapRegionsLoadSuccess(ctx: StateContext<QaStateModel>, { regions }: any) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      robotMapRegions: regions
    });
  }

  /*******************************************
   *          HTA Recommendation
   ******************************************/
  @Action(HtaRecommendationLoad)
  public async htaRecommendationLoad(ctx: StateContext<QaStateModel>) {
    ctx.dispatch(ToggleShow);
    this.htaService.loadHtaRecommendationAmplify();
  }

  @Action(HtaRecommendationLoadSucess)
  public htaRecommendationLoadSuccess(ctx: StateContext<QaStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      htaRecommendation: payload
    });
    ctx.dispatch(ToggleHide);
  }

  @Action(HtaRecommendationReload)
  public async htaRecommendationReload(ctx: StateContext<QaStateModel>) {
    this.htaService.loadHtaRecommendationAmplify(true);
  }

  @Action(HtaRecommendationPost)
  public async htaRecommendationPost(ctx: StateContext<QaStateModel>, { payload, edit }: any) {
    this.htaService.submitHtaRecommendationAmplify(payload);
  }

  @Action(HtaRecommendationDelete)
  public htaRecommendationDelete(ctx: StateContext<QaStateModel>, { payload }: any) {
    this.htaService.deleteHtaRecommendationAmplify(payload);
  }

  @Action(HtaRecommendationSaveComplete)
  public htaRecommendationComplete(ctx: StateContext<QaStateModel>, { data, cannedKozr, edit }: any) {
    // Data can contain "true" when its updating so we update item with "insights" which is local copy
    // In case you make new "insights" with post it will return new Object with "data" so in second case
    // We append it to last place in array with data
    const state = ctx.getState();
    if (edit === true) {
      if (cannedKozr.deleted === true) {
        ctx.setState(
          patch({
            // @ts-ignore
            htaRecommendation: removeItem((item) => item.koz_id === robot.koz_id)
          })
        );
      } else {
        ctx.setState(
          patch({
            htaRecommendation: updateItem((item) => item.koz_id === cannedKozr.koz_id, cannedKozr)
          })
        );
      }
    } else {
      ctx.setState(
        patch({
          htaRecommendation: [...state.htaRecommendation, data]
        })
      );
    }
    this.toastrService.show(status, 'Save: Complete', { status: 'success' });
  }

  @Action(HtaRecommendationDeleteSuccess)
  public htaRecommendationDeleteSuccess(ctx: StateContext<QaStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState(
      patch({
        // @ts-ignore
        htaRecommendation: removeItem((item) => item.hta_id === payload.hta_id)
      })
    );
    this.toastrService.show(status, 'Delete: Complete', { status: 'success' });
  }
}
