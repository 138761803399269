import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  LoadSmartCleanRecordsFail,
  LoadSmartCleanRecordsSuccess,
  PatchCleanScoreFail,
  PatchCleanScoreSuccess
} from 'src/app/components/qa-testing/clean-score/redux/clean-score.actions';
import { environment } from 'src/environments/environment';
import { API } from '@aws-amplify/api';
import { AmplifyServiceBuilder } from './amplify.service.builder';
import { CleanScore, QACleanScoreItem, QARegionItem } from 'src/app/models/qa-models/smart-clean';

@Injectable({ providedIn: 'root' })
export class SmartCleanService {
  public smart_clean_api = 'smart_clean_api';

  constructor(private store: Store) {}

  public populateFields(payload, cleanScoreRecords): void {
    const response = payload[payload.length - 1];

    const robot_id = response.cmd.robot_id;
    if (cleanScoreRecords?.[robot_id] === undefined) {
      cleanScoreRecords[robot_id] = {};
    } else {
      return;
    }

    response.regions.forEach((region) => {
      let item = new QACleanScoreItem();
      const pmap_id = region.pmap_id;
      const region_id = region.pk.split('#').pop();

      if (cleanScoreRecords?.[robot_id]?.[pmap_id] !== undefined) {
        item = cleanScoreRecords[robot_id][pmap_id];
      }

      item.pmap_id = pmap_id;

      const r = new QARegionItem();
      r.clean_score = region.region_score;
      r.region_id = region_id;
      item.regions.push(r);

      cleanScoreRecords[robot_id][pmap_id] = item;
    });

    this.sortRegions(cleanScoreRecords);
  }

  public loadSmartCleanRecordsAmplify(robot_id) {
    API.get(this.smart_clean_api, `/${environment.apiAccountStage}/qa/list?robot_id=${robot_id}`, '')
      .then((response) => {
        if ('regions' in response) {
          this.store.dispatch(new LoadSmartCleanRecordsSuccess(response));
        } else {
          this.store.dispatch(new LoadSmartCleanRecordsFail(`No Maps found for ${robot_id}`));
        }
      })
      .catch((error) => {
        this.store.dispatch(new LoadSmartCleanRecordsFail(error));
        console.error(error);
      });
  }

  public patchCleanScoreAmplify(newRecords: CleanScore) {
    const requestData = AmplifyServiceBuilder(this.smart_clean_api, false, newRecords);
    API.patch(this.smart_clean_api, `/${environment.apiAccountStage}/qa/modify`, requestData)
      .then((response) => {
        this.store.dispatch(new PatchCleanScoreSuccess(response));
      })
      .catch((error) => {
        console.error(error);
        const message =
          error.response && error.response.data
            ? error.response.data.message
              ? error.response.data.message
              : error.response.data
            : 'Error!';
        this.store.dispatch(new PatchCleanScoreFail(message));
      });
  }

  private sortRegions(records): void {
    const recordKeys = Object.keys(records);
    recordKeys.forEach((recordKey) => {
      const scoreKeys = Object.keys(records[recordKey]);
      scoreKeys.forEach((scoreKey) => {
        const regions = records[recordKey][scoreKey].regions;
          regions.sort((a, b) => {
            if (Number(a.region_id) < Number(b.region_id)) {
              return -1;
            }

            return 1;
          });
      });
    });
  }
}
