export class LoadSmartCleanRecords {
  public static readonly type = '[QA] Load Smart Clean Records';
  constructor(public payload: any) {}
}

export class LoadSmartCleanRecordsSuccess {
  public static readonly type = '[QA] Load Smart Clean Records SUCCESS';
  constructor(public payload: any) {}
}

export class PatchCleanScore {
  public static readonly type = '[QA] Patch Clean Score';
  constructor(public payload: any) {}
}

export class PatchCleanScoreSuccess {
  public static readonly type = '[QA] Patch Clean Score SUCCESS';
  constructor(public payload: any) {}
}

export class PatchCleanScoreFail {
  public static readonly type = '[QA] Patch Clean Score FAILED';
  constructor(public payload: any) {}
}
export class LoadSmartCleanRecordsFail {
  public static readonly type = '[QA] Load Clean Score FAILED';
  constructor(public payload: any) {}
}


export class RemoveCleanScore {
  public static readonly type = '[QA] Removed Clean Score';
  constructor() {}
}
