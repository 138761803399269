import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';

import { Store } from '@ngxs/store';
import { API } from '@aws-amplify/api';
import {
  DeleteMetadataSuccess,
  LoadMetadataSuccess,
  SaveMetadataComplete,
  SaveMetadataFail,
  UpdateMetadataSuccess
} from 'src/app/components/robot-family-metadata/redux/robot-family-metadata.actions';
import { CogwheelService } from 'src/app/core/services/cogwheel.service';
import { environment } from 'src/environments/environment';
import { RobotFamilyMetadata } from '../../models/robot-family-metadata';
import { AmplifyServiceBuilder } from '../services/amplify.service.builder';
import { TimestampDatePipe } from '../../shared/custom-pipe/timestamp-date.pipe';

@Injectable({providedIn: 'root'})
export class RobotFamilyMetadataService {
  public robot_family_metadata_api = 'robot_family_metadata_api';
  public single_metadata_api = 'single_robot_family_metadata_api';
  public allKeys: string[] = [];
  public type = 'robot-family-metadata';

  constructor(
    private store: Store,
    private toastrService: NbToastrService,
    private formatDatePipe: TimestampDatePipe,
    private cogWheelService: CogwheelService) { }

    public buildKeysForTable(metadata: RobotFamilyMetadata[]) {
      this.cogWheelService.buildKeysForTable(metadata, this.allKeys, this.store, this.type, true);
        }

  public loadMetadataAmplify(reload?) {
    API.get(this.robot_family_metadata_api, `/${environment.stage}/`, '')
      .then((response) => {
        if (reload) {
          this.toastrService.show(status, 'Fetching Metadata Complete', {
            status: 'success'
          });
        }
        this.buildKeysForTable(response);
        response.records.forEach((element) => {
          element.local_created_dttm = this.formatDatePipe.transform(element?.created_dttm, 'timestampToDate');
          element.local_last_updated_dttm = this.formatDatePipe.transform(
            element?.last_updated_dttm,
            'timestampToDate'
          );
        });
        this.store.dispatch(new LoadMetadataSuccess(response));
      })
      .catch((error) => {
        console.error(error);
        this.toastrService.show(status, 'Fetching Metadata Failed', {
          status: 'danger'
        });
      });
  }

    public postMetadataAmplify(newMetadata: RobotFamilyMetadata) {
      const requestData = AmplifyServiceBuilder(this.robot_family_metadata_api, false, newMetadata);
      API.post(this.robot_family_metadata_api, `/${environment.stage}/`, requestData)
        .then((response) => {
          const record = JSON.parse(response.body);
          record.local_created_dttm = this.formatDatePipe.transform(record?.created_dttm, 'timestampToDate');
          record.local_last_updated_dttm = this.formatDatePipe.transform(record?.last_updated_dttm, 'timestampToDate');
          response.body = JSON.stringify(record);
          this.store.dispatch(new SaveMetadataComplete(response, newMetadata));
        })
        .catch((error) => {
          this.store.dispatch(new SaveMetadataFail(error));
        });
    }

  public updateMetadataAmplify(newMetadata: RobotFamilyMetadata) {
    const requestData = AmplifyServiceBuilder(this.robot_family_metadata_api, false, newMetadata);
    API.put(this.robot_family_metadata_api, `/${environment.stage}/`, requestData)
      .then((response) => {

        const record = JSON.parse(response.body);
        record.local_created_dttm = this.formatDatePipe.transform(record?.created_dttm, 'timestampToDate');
        record.local_last_updated_dttm = this.formatDatePipe.transform(record?.last_updated_dttm, 'timestampToDate');
        response.body = JSON.stringify(record);

        this.store.dispatch(new UpdateMetadataSuccess(response));
      })
      .catch((error) => {
        console.error(error);
        const message =
          error.response && error.response.data
            ? error.response.data.message
              ? error.response.data.message
              : error.response.data
            : 'Error!';
        this.toastrService.show(status, 'Updating Metadata Failed: ' + message, {
          status: 'danger'
        });
      });
  }

  public deleteMetadataAmplify(metadata: RobotFamilyMetadata) {
    const requestData = AmplifyServiceBuilder(this.single_metadata_api, false, metadata);
    API.del(this.single_metadata_api, `/${environment.stage}/${metadata.robot_family}`, requestData)
      .then((response) => {
        this.toastrService.show(status, 'Deleted Metadata Record', {
          status: 'success'
        });
        this.store.dispatch(new DeleteMetadataSuccess(metadata));
      })
      .catch((error) => {
        console.error(error);
        const message =
          error.response && error.response.data
            ? error.response.data.message
              ? error.response.data.message
              : error.response.data
            : 'Error!';
        this.toastrService.show(status, 'Deleting Metadata Failed - ' + message, {
          status: 'danger'
        });
      });
  }
}
