import { Asset } from './asset';

export class SurveyHint {
  public survey_id: string;
  public response_type = 'Survey';
}

export class ActionButton {
  public text?: string;
  public text_id: string;
  public destination_uri?: string;
  public destination_uri_id?: string;
  public destination_type: any;
}

// TODO: We should refactor this to use ENUM
export const DestinationType = [
  { value: 'internal_screen', label: 'INTERNAL_SCREEN' },
  { value: 'internal_browser', label: 'INTERNAL_BROWSER' },
  { value: 'external_browser', label: 'EXTERNAL_BROWSER' }
];

export const ActionDisplay = [
  { value: 'half_modal_view', label: 'HalfModalView' },
  { value: 'full_modal_view', label: 'FullModalView' },
  { value: 'template_view', label: 'TemplateView' },
  { value: 'personalized_template_view', label: 'Personalized Template View' }
];

// This is used for type checking on edit-content component. Numbers in PayloadRecord become string in case of removing so we need to check and
// Convert it to null before saving. It holds all number properties from PayloadRecord. Update array in case of extending PayloadRecord.
export const Fields = [
  'context_text_display_order',
  'video_resource_display_order',
  'video_id',
  'video_url_display_order',
  'content_html_display_order',
  'image_display_order',
  'webview_url_display_order'
];

export class PayloadRecord {
  //This is for internal use only
  public payload_record_type?: string = null;

  public content_text?: string = null;
  public content_text_id?: string = null;
  public context_text_display_order?: number = null;

  public video_id?: number = null;
  public video_url_display_order?: number = null;

  public content_html?: string = null;
  public content_html_id?: string = null;
  public content_html_display_order?: number = null;

  public image_id?: string = null;
  public image_url?: string = null;
  public image_display_order?: number = null;

  public webview_url?: string = null;
  public webview_url_id?: string = null;
  public webview_url_display_order?: number = null;

  public script_id?: string = null;
  public script_id_display_order?: number = null;
  //This is used for banner carousel
  public banner_id?: string = null;
  public banner_text?: string = null;
  public disclaimers_text?: string = null;
  public banner_image_id?: string = null;
  public product_url?: string = null;
  public source_code?: string = null;

  public action_display?: any = null;
}

export class Content extends Asset {
  public payload_records?: PayloadRecord[];
  public active_parent_uri_id?: number;

  public content_key?: string;
  public content_version: number;
  public content_id: number;
  public category_icon_id: string;
  public category_title_text?: string;
  public category_title_id?: string;
  public headline_text?: string;
  public headline_text_id?: string;
  public display_type: string;
  public action_type: string;
  public delivery_channel?: string;
  public content_html?: string;
  public content_html_id?: string;
  public content_html_display_order?: number;
  public responses?: SurveyHint[];
  public image_id?: string;
  public image_url?: string;
  public image_display_order?: number;
  public language?: string = 'en';
  public locale?: string = 'en-US';
  public min_app_version?: string = '4.3.0';
  //Dynamic MCA push notification
  public push_title_string_id: string;
  public push_string_id: string;

  public action_button?: ActionButton[];

  // Metadata
  public created_by?: string;
  public created_dttm?: number;
  public last_updated_dttm?: number;
  public last_updated_by?: string;

  //Helper property used only by front-end
  public local_created_dttm?: string = 'Time not provided.';
  public local_last_updated_dttm?: string = 'Time not provided.';

  constructor() {
    super();
  }
  public get label() {
    return ('' + this.content_id).padStart(3, '0') + ' - ' + this.content_version;
  }
  public static clone(content: Content) {
    let clonedContent: Content = new Content();

    clonedContent = JSON.parse(JSON.stringify(content));

    return clonedContent;
  }

  public ensureSingleElementInActionButtonArray(arr: ActionButton[]) {
    arr.sort();

    return (arr[0] = arr[arr.length - 1]);
  }
}
