<app-spinner *ngIf="spin"></app-spinner>
<div style="min-height: 550px">
  <nb-card>
    <nb-card-header>
      <div *ngIf="!deployInProgres && metaData">
        <h3 *ngIf="mode === 'create'" style="position: relative">Create/Edit DRB</h3>
        <h3 *ngIf="mode === 'view'" style="position: relative">View DRB</h3>
        <p style="position: relative">{{ bundle_name }}</p>
        <br />
        <div class="row" *ngIf="mode === 'view'">
          <div class="col-2">
            <label>DRB version:</label>
          </div>
          <div class="col-3">
            <ng-select
              id="version"
              name="version"
              placeholder="Select DRB Version"
              (change)="onChange($event)"
              [(ngModel)]="draftVersion"
              [clearable]="false"
              required
            >
              <ng-option *ngFor="let version of versions" [value]="version"> {{ version }} </ng-option>
            </ng-select>
          </div>
        </div>
        <hr />
        <div class="row" *ngIf="mode === 'create' || versionSelected">
          <div class="row">
            <div class="col-4">
              <label>Version: </label>
              <p>{{ metaData.version }}</p>
            </div>
            <div class="col-4">
              <label>Created by: </label>
              <p>{{ metaData.created_by }}</p>
            </div>
            <div class="col-4">
              <label>Created on: </label>
              <p>{{ utility.convertUnixTime_global(metaData.created_ts) }}</p>
            </div>
            <div class="col-4">
              <label>Status: </label>
              <p>{{ metaData.status }}</p>
            </div>
            <div class="col-4" *ngIf="mode === 'view'">
              <label>Draft: </label>
              <p style="text-transform: uppercase">{{ metaData.draft }}</p>
            </div>
            <div class="col-4">
              <label>Last updated by: </label>
              <p>{{ metaData.last_updated_by }}</p>
            </div>
            <div class="col-4">
              <label>Last updated on: </label>
              <p>{{ utility.convertUnixTime_global(metaData.last_updated_ts) }}</p>
            </div>
            <div class="col-4">
              <label>Owner: </label>
              <p>{{ metaData.owner }}</p>
            </div>
            <div class="col-4">
              <label style="margin-right: 0.5rem">Contributors: </label>
              <button
                *ngIf="mode === 'create' && metaData.owner === username"
                nbButton
                size="small"
                shape="round"
                (click)="manageContributors()"
              >
                <nb-icon icon="edit-2-outline"></nb-icon>
              </button>
              <p>{{ displayContributors() }}</p>
            </div>
            <div class="col-4" *ngIf="environment === 'stage' && mode === 'view'">
              <label>Deployed to stage: </label>
              <p style="text-transform: uppercase">{{ metaData.deployed_to_stage }}</p>
            </div>
            <div class="col-4" *ngIf="environment === 'stage' && mode === 'view' && metaData.deployed_to_stage">
              <label>Deployed to stage on: </label>
              <p>{{ utility.convertUnixTime_global(metaData.deployed_to_stage_ts) }}</p>
            </div>
            <div class="col-4" *ngIf="environment === 'prod' && mode === 'view'">
              <label>Deployed to prod: </label>
              <p style="text-transform: uppercase">{{ metaData.deployed_to_prod }}</p>
            </div>
            <div class="col-4" *ngIf="environment === 'prod' && mode === 'view' && metaData.deployed_to_prod">
              <label>Deployed to prod on: </label>
              <p>{{ utility.convertUnixTime_global(metaData.deployed_to_prod_ts) }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="deployInProgres">
        <div class="row">
          <label>DRB draft version is currently deploying.</label>
        </div>
        <div class="row">
          <label>If you want to check DRB information please go to View page.</label>
        </div>
        <div class="row">
          <label>If you want to create new draft version, please wait until deployment finishes.</label>
        </div>
        <div class="row">
          <div class="col-1">
            <button nbButton size="small" shape="round" (click)="reload()">Reload</button>
          </div>
        </div>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div *ngIf="(mode === 'create' && !deployInProgres && metaData) || versionSelected">
        <nb-route-tabset [tabs]="sourceTabs" fullWidth></nb-route-tabset>
      </div>
    </nb-card-body>
  </nb-card>
</div>
