import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TitleCasePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CogwheelState } from './redux';

@Component({
  selector: 'app-ngx-cogwheel',
  templateUrl: './ngx-cogwheel.component.html',
  styleUrls: ['./ngx-cogwheel.component.scss']
})
export class NgxCogwheelComponent implements OnInit {
  @Select(CogwheelState.componentKeys) public allKeys$: Observable<any>;
  @Select(CogwheelState.componentCurrentKeys) public currentKeys$: Observable<[]>;
  @Select(CogwheelState.type) public type$: Observable<string>;

  public allKeys;
  public currentKeys;
  public type;

  //Holds true and false for selection so we can render checkbox correctly
  public checked = [];

  //Just a helper array
  public currentKeysHelperArray = [];

  public cogWheelSelection = [];

  //This one is used for drag and drop representation
  public fullSelectedArray = [];

  constructor(private titlecasePipe: TitleCasePipe, protected ref: NbDialogRef<NgxCogwheelComponent>) {}

  public ngOnInit() {
    this.allKeys$.subscribe((data) => (this.allKeys = data));
    this.currentKeys$.subscribe((data) => (this.currentKeys = data));
    this.type$.subscribe((data) => (this.type = data));

    // This maps current selection in new array with true / false so HTML checkbox can read and set value accordingly
    this.allKeys.forEach((element) => {
      if (this.currentKeys.hasOwnProperty(element)) {
        this.currentKeysHelperArray.push(element);
        this.checked.push(true);
      } else {
        this.checked.push(false);
      }
    });
    this.fullSelectedArray = this.cogWheelSelection.concat(this.currentKeysHelperArray);
  }

  public checkedChange(selectedKey, value) {
    if (value) {
      this.cogWheelSelection.push(selectedKey);
    } else {
      this.currentKeysHelperArray = this.currentKeysHelperArray.filter((item) => item !== selectedKey);
      this.cogWheelSelection = this.cogWheelSelection.filter((item) => item !== selectedKey);
    }

    this.fullSelectedArray = this.cogWheelSelection.concat(this.currentKeysHelperArray);
    this.fullSelectedArray.reverse();
  }

  public drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.fullSelectedArray, event.previousIndex, event.currentIndex);
  }

  public saveCurrentSelection() {
    /*const objectNewSelections = Object.create(null);
    this.cogWheelSelection.forEach(element => {
      //Here we escape _ to make it look better for the name in colum header
      var result = element.replace(/_/g, ' ');
      //Here we make first letter of word in Uppercase for more pleasing experience
      result = this.titlecasePipe.transform(result);
      objectNewSelections[element] = { title: result};
    });

    const objectOldSelections = Object.create(null);
    this.currentKeysHelperArray.forEach(element => {
      //Here we escape _ to make it look better for the name in colum header
      var result = element.replace(/_/g, ' ');
      //Here we make first letter of word in Uppercase for more pleasing experience
      result = this.titlecasePipe.transform(result);
      objectOldSelections[element] = { title: result};
    }); */

    const objectOldSelections = Object.create(null);
    this.fullSelectedArray.forEach((element) => {
      //Here we escape _ to make it look better for the name in colum header
      let result = element.replace(/_/g, ' ');
      //Here we make first letter of word in Uppercase for more pleasing experience
      result = this.titlecasePipe.transform(result);
      objectOldSelections[element] = { title: result };
    });

    const finalObject = Object.assign(objectOldSelections);
    localStorage.setItem(this.type, JSON.stringify(finalObject));
    this.ref.close(true);
  }

  public close() {
    this.ref.close();
  }
}
