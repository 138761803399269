import { Asset } from './asset';

export class Metadata extends Asset{
  public extent_type: string;
  public confidence: number;
  public priority?: number;
  constructor() {
    super();
  }
}

export class RobotFamilyMetadata extends Asset {
  // Metadata
  public robot_family: string;
  public metadata_type: string;
  public metadata: Metadata[];
  public created_dttm?: number;
  public created_by?: string;
  public last_updated_dttm?: number;
  public last_updated_by?: string;

  //Helper property used only by front-end
  public local_created_dttm?: string = 'Time not provided.';
  public local_last_updated_dttm?: string = 'Time not provided.';

  constructor() {
    super();
  }
  public get label() {
    return ('' + this.robot_family).padStart(3, '0') + ' - ' + this.metadata_type;
  }

  public static copy(sourceMetadata: RobotFamilyMetadata) {
    const targetMetadata = new RobotFamilyMetadata();
    Object.assign(targetMetadata, sourceMetadata);

    return targetMetadata;
  }

  public static clone(sourceMetadata: RobotFamilyMetadata) {
    let clonedMetadata: RobotFamilyMetadata = new RobotFamilyMetadata();
    clonedMetadata = JSON.parse(JSON.stringify(sourceMetadata));

    return clonedMetadata;
  }
}
