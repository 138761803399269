<nb-card
  accent="success"
  style="width: 100%; height: 100%; min-width: 700px; max-height: 800px; max-width: 1600px; margin: auto"
>
  <nb-card-header>
    <div class="row">
      <div class="col-auto mr-auto">
        <nb-icon icon="nb-trash"></nb-icon>
      </div>
      <div class="col-auto mr-auto">{{ title }}</div>
      <div class="col-auto"><button nbButton (click)="close()">X</button></div>
    </div>
    <div class="row">
      <div class="col-auto mr-auto" style="margin-top: 1rem">{{ campaign.name + ' - ' + campaign.id }}</div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div style="height: 100%; padding: 1.143em; margin: -1.143em">
        <div *ngIf="campaign.enabled">
          <h5 style="padding: 1rem 0">You cannot delete an active Campaign, please deactivate it first!</h5>
        </div>
        <div *ngIf="!campaign.enabled">
          <div class="deep-delete-spinner" *ngIf="spin">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <form #campaignForm="ngForm" *ngIf="!spin">
            <strong class="text-uppercase">Select associated objects for deletion</strong>
            <hr />

            <div class="row">
              <div class="col">
                <div style="display: flex; align-items: center">
                  <nb-checkbox
                    id="campaign.trigger_id"
                    name="deleteInsight"
                    [(ngModel)]="delete.insight"
                    style="padding: 3px 10px 0px 0px"
                    dis
                    [disabled]="insight.name === 'Insight not found' || anyCampaignsActive(campaign.trigger_id)"
                  ></nb-checkbox>
                  <label style="padding-top: 6px">Insight: {{ insight.name }}</label>
                  <button
                    *ngIf="campaign.trigger_id && insight.name !== 'Insight not found'"
                    nbButton
                    size="medium"
                    ghost
                    status="info"
                    (click)="viewInsight(campaign.trigger_id)"
                  >
                    <nb-icon icon="external-link-outline" nbTooltip="Open Selected Insight"></nb-icon>
                  </button>
                </div>
                <div *ngIf="insight.name !== 'Insight not found'">
                  <p style="color: green" *ngIf="affectedCampaigns.insight.length - 1 === 0">
                    Other campaigns won't be affected by deletion of this insight
                  </p>
                  <p style="color: red" *ngIf="anyCampaignsActive(campaign.trigger_id) === 1">
                    There is <strong>one ACTIVE</strong> campaign using this insight. Deactivate it before deleting this insight!
                  </p>
                  <p style="color: red" *ngIf="anyCampaignsActive(campaign.trigger_id) > 1">
                    There are <strong>{{ anyCampaignsActive(campaign.trigger_id) }} ACTIVE</strong> campaigns using this insight. Deactivate them before deleting this insight!
                  </p>
                  <p style="color: orange" *ngIf="affectedCampaigns.insight.length - 1 > 0">
                    Following campaigns are going to be affected by deletion of this insight:
                  </p>
                  <ul *ngFor="let c of affectedCampaigns.insight">
                    <li *ngIf="c.id !== campaign.id">{{ c.name }} - <strong>{{ c.status }}</strong></li>
                  </ul>
                </div>
              </div>
            </div>
            <br />

            <div class="row">
              <div class="col">
                <div style="display: flex; align-items: center">
                  <nb-checkbox
                    id="campaign.content_id"
                    name="deleteContent"
                    [(ngModel)]="delete.content"
                    style="padding: 3px 10px 0px 0px"
                    [disabled]="!contentsIncludes(campaign.content_id) || anyCampaignsActive(false, campaign.content_id)"
                  ></nb-checkbox>
                  <label style="padding-top: 6px" *ngIf="contentsIncludes(campaign.content_id)">
                    Content ID: {{ campaign.content_id }}
                  </label>
                  <label style="padding-top: 6px" *ngIf="!contentsIncludes(campaign.content_id)">
                    Content ID: Content not found
                  </label>
                  <button
                    *ngIf="campaign.content_id && contentsIncludes(campaign.content_id)"
                    nbButton
                    size="medium"
                    ghost
                    status="info"
                    (click)="viewContent(campaign.content_id)"
                  >
                    <nb-icon icon="external-link-outline" nbTooltip="Open Selected Content"></nb-icon>
                  </button>
                </div>
                <div *ngIf="contentsIncludes(campaign.content_id)">
                  <p style="color: green" *ngIf="affectedCampaigns.content.length - 1 === 0">
                    Other campaigns won't be affected by deletion of this content
                  </p>
                  <p style="color: red" *ngIf="anyCampaignsActive(false, campaign.content_id) === 1">
                    There is <strong>one ACTIVE</strong> campaign using this content. Deactivate it before deleting this content!
                  </p>
                  <p style="color: red" *ngIf="anyCampaignsActive(false, campaign.content_id) > 1">
                    There are <strong>{{ anyCampaignsActive(false, campaign.content_id) }} ACTIVE</strong> campaigns using this content. Deactivate them before deleting this content!
                  </p>
                  <p style="color: orange" *ngIf="affectedCampaigns.content.length - 1 > 0">
                    Following campaigns are going to be affected by deletion of this content:
                  </p>
                  <ul *ngFor="let c of affectedCampaigns.content">
                    <li *ngIf="c.id !== campaign.id">{{ c.name }} - <strong>{{ c.status }}</strong></li>
                  </ul>
                </div>
              </div>
            </div>
            <br />

            <div *ngFor="let response of campaign.responses">
              <div class="row">
                <div class="col">
                  <div style="display: flex; align-items: center">
                    <nb-checkbox
                      id="response.id"
                      name="deleteSurvey"
                      [(ngModel)]="delete.surveys[response.id]"
                      style="padding: 3px 10px 0px 0px"
                      [disabled]="!surveysIncludes(response.id) || anyCampaignsActive(false, false, response.id)"
                    ></nb-checkbox>
                    <label style="padding-top: 6px" *ngIf="surveysIncludes(response.id)">
                      Survey: {{ response.name }}
                    </label>
                    <label style="padding-top: 6px" *ngIf="!surveysIncludes(response.id)">
                      Survey: Survey not found
                    </label>
                    <button
                      *ngIf="surveysIncludes(response.id)"
                      nbButton
                      size="medium"
                      ghost
                      status="info"
                      (click)="viewSurvey(response.id)"
                    >
                      <nb-icon icon="external-link-outline" nbTooltip="Open Selected Survey"></nb-icon>
                    </button>
                  </div>
                  <div *ngIf="surveysIncludes(response.id)">
                    <p style="color: green" *ngIf="affectedCampaigns.surveys[response.id].length - 1 === 0">
                      Other campaigns won't be affected by deletion of this survey
                    </p>
                    <p style="color: red" *ngIf="anyCampaignsActive(false, false, response.id) === 1">
                      There is <strong>one ACTIVE</strong> campaign using this survey. Deactivate it before deleting this survey!
                    </p>
                    <p style="color: red" *ngIf="anyCampaignsActive(false, false, response.id) > 1">
                      There are <strong>{{ anyCampaignsActive(false, false, response.id) }} ACTIVE</strong> campaigns using this survey. Deactivate them before deleting this survey!
                    </p>
                    <p style="color: orange" *ngIf="affectedCampaigns.surveys[response.id].length - 1 > 0">
                      Following campaigns are going to be affected by deletion of this survey:
                    </p>
                    <ul *ngFor="let c of affectedCampaigns.surveys[response.id]">
                      <li *ngIf="c.id !== campaign.id">{{ c.name }} - <strong>{{ c.status }}</strong></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </form>
        </div>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div *ngIf="!campaign.enabled">
      <div class="row">
        <div class="col">
          <p>Deleting a campaign and its associated objects is <strong>irreversible</strong> and could possibly affect other campaigns.</p>
          <label>Type "DELETE" to confirm deletion</label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <input
            id="confirmation"
            name="confirmation"
            placeholder="DELETE"
            type="string"
            class="form-control form-control-sm"
            [(ngModel)]="confirmation"
            required
            nbInput
            style="width: 100%"
          />
        </div>
      </div>
    </div>
    <div class="d-flex flex-row-reverse">
      <div class="p-2" *ngIf="!campaign.enabled">
        <button
          nbButton
          status="success"
          [disabled]="confirmation.toLowerCase() !== 'delete'"
          (click)="deepDelete()">Delete</button>
      </div>
      <div class="p-2">
        <button nbButton (click)="close()">Close</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
