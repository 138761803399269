/* eslint-disable space-before-function-paren */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { Component, DoCheck, OnDestroy, OnInit } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { UtilityService } from 'src/app/core/services/utility.service';
import { Campaign } from 'src/app/models/campaign';
import { SpinnerState } from 'src/app/shared/spinner/redux/spinner.state';
import { LoadCampaign } from '../../redux/campaign.actions';
import { CampaignState } from '../../redux/campaign.state';

@Component({
  selector: 'app-campaign-dashboard',
  templateUrl: './campaign-dashboard.component.html',
  styleUrls: ['./campaign-dashboard.component.css']
})
export class CampaignDashboardComponent implements OnInit, OnDestroy, DoCheck {
  @Select(CampaignState.campaign) public data$: Observable<any[]>;
  @Select(SpinnerState) public loading: Observable<boolean>;

  public spin;

  public campaignsInput;
  public campaigns: Campaign[] = [];

  public data_campaignsByType: any;
  public data_notificationsByCampaigns: any;
  public data_responsesByCampaigns: any;
  public campNames = [];
  public campTypes = [];
  public campExecutions = [];
  public campNotifications = [];
  public campNotifications_byCategory = [];
  public campResponses = [];
  public colorScheme: any;
  public themeSubscription: any;

  public currentTime: number = new Date().getTime();
  public weeksTimeLimit: number;
  public weeksTime_string;
  public preloaded = false;
  public totalExecutions = 0;
  public totalNotifications = 0;
  public totalResponses = 0;

  constructor(public utilityService: UtilityService, private theme: NbThemeService, private store: Store) {
    if (this.store.selectSnapshot(CampaignState.campaign).length < 1) {
      this.store.dispatch(LoadCampaign);
    }
  }

  public ngOnInit(): void {
    this.data$.subscribe((data) => {
      this.campaignsInput = data;
      this.campaigns = this.campaignsInput;
      this.fillCampaignTypes();
      this.createPieCharts();
    });
    this.loading.subscribe((data) => (this.spin = data));

    this.weeksTimeLimit = this.currentTime / 1000 - 604800;

    this.weeksTime_string = this.utilityService.convertUnixTime_global(this.weeksTimeLimit);
  }

  /**************************************
   * Checks for every change regarding
   * this modal
   *************************************/
  public ngDoCheck() {
    this.fillCampaigns();
  }

  public ngOnDestroy(): void {
    if (this.themeSubscription) {
      this.themeSubscription.unsubscribe();
    }
  }

  /******************************************************
   * Create pie charts based on campaigns fetched
   *****************************************************/

  public createPieCharts(): void {
    this.chart_campaignByType();
    this.chart_notificationsByCampaign();
    this.chart_responsesByCampaign();
  }

  /******************************************************
   * Chart generation methods
   *****************************************************/

  public chart_campaignByType() {
    // Auto-generate colors
    const bgColors = [];

    for (let i = 0; i < this.campTypes.length; i++) {
      bgColors[i] = this.generateColor();
    }

    // Sort array by execution number
    const list = [];
    for (let j = 0; j < this.campTypes.length; j++) {
      list.push({
        type: this.campTypes[j],
        executions: this.campExecutions[j]
      });
    }
    list.sort(function (a, b) {
      return a.executions < b.executions ? -1 : a.executions === b.executions ? 0 : 1;
    });
    for (let k = 0; k < list.length; k++) {
      this.campTypes[k] = list[k].type;
      this.campExecutions[k] = list[k].executions;
    }

    // Subscribe to the selected theme
    this.themeSubscription = this.theme.getJsTheme().subscribe((config) => {
      const chartjs: any = config.variables.chartjs;

      this.data_campaignsByType = {
        labels: this.campTypes,
        datasets: [
          {
            data: this.campExecutions,
            backgroundColor: bgColors
          }
        ],
        options: {
          maintainAspectRatio: false,
          responsive: true,
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
          },
          legend: {
            display: true,
            position: 'bottom'
          },
          series: [
            {
              labelLine: {
                normal: {
                  lineStyle: {
                    color: chartjs.axisLineColor
                  }
                }
              }
            }
          ],
          scales: {
            xAxes: [
              {
                display: false
              }
            ],
            yAxes: [
              {
                display: false
              }
            ]
          }
        }
      };
    });
  }

  /************************************************/

  public chart_notificationsByCampaign() {
    // Auto-generate colors
    const bgColors = [];

    for (let i = 0; i < this.campNames.length; i++) {
      bgColors[i] = this.generateColor();
    }

    // Sort array by notification number
    const list = [];
    for (let j = 0; j < this.campNames.length; j++) {
      list.push({
        name: this.campNames[j],
        notifications: this.campNotifications[j]
      });
    }
    list.sort(function (a, b) {
      return a.notifications < b.notifications ? -1 : a.notifications === b.notifications ? 0 : 1;
    });
    for (let k = 0; k < list.length; k++) {
      this.campNames[k] = list[k].name;
      this.campNotifications[k] = list[k].notifications;
    }

    // Subscribe to the selected theme
    this.themeSubscription = this.theme.getJsTheme().subscribe((config) => {
      const chartjs: any = config.variables.chartjs;

      this.data_notificationsByCampaigns = {
        labels: this.campNames,
        datasets: [
          {
            data: this.campNotifications,
            backgroundColor: bgColors
          }
        ],
        options: {
          legend: {
            display: false,
            position: 'right'
          },
          series: [
            {
              labelLine: {
                normal: {
                  lineStyle: {
                    color: chartjs.axisLineColor
                  }
                }
              }
            }
          ]
        }
      };
    });
  }

  /************************************************/

  public chart_responsesByCampaign() {
    // Auto-generate colors
    const bgColors = [];

    for (let i = 0; i < this.campNames.length; i++) {
      bgColors[i] = this.generateColor();
    }

    // Sort array by response amount
    const list = [];
    for (let j = 0; j < this.campNames.length; j++) {
      list.push({
        name: this.campNames[j],
        responses: this.campResponses[j]
      });
    }
    list.sort(function (a, b) {
      return a.responses < b.responses ? -1 : a.responses === b.responses ? 0 : 1;
    });
    for (let k = 0; k < list.length; k++) {
      this.campNames[k] = list[k].name;
      this.campResponses[k] = list[k].responses;
    }

    // Subscribe to the selected theme
    this.themeSubscription = this.theme.getJsTheme().subscribe((config) => {
      const chartjs: any = config.variables.chartjs;

      this.data_responsesByCampaigns = {
        labels: this.campNames,
        datasets: [
          {
            data: this.campResponses,
            backgroundColor: bgColors
          }
        ],
        options: {
          legend: {
            display: false,
            position: 'right'
          },
          series: [
            {
              labelLine: {
                normal: {
                  lineStyle: {
                    color: chartjs.axisLineColor
                  }
                }
              }
            }
          ]
        }
      };
    });
  }

  private fillCampaigns() {
    if (this.preloaded) {
      // Refresh everything
      this.campaigns = [];
      this.campaigns = this.campaignsInput;
    }
    if (this.campaignsInput.length !== 0 && !this.preloaded) {
      this.campaigns = this.campaignsInput;
      this.fillCampaignTypes();
      this.createPieCharts();
      this.preloaded = true;
    }
  }

  private fillCampaignTypes() {
    this.campaigns.forEach((campaign) => {
      if (campaign.last_run_time > this.weeksTimeLimit) {
        // Count campaign types
        let different = true;

        for (let i = 0; i <= this.campTypes.length; i++) {
          if (this.campTypes[i] === campaign.campaign_type) {
            this.campExecutions[i] += campaign.executions;
            this.campNotifications_byCategory[i] += campaign.total_notifications;
            different = false;
          }
        }

        if (different) {
          this.campTypes.push(campaign.campaign_type);
          this.campExecutions.push(campaign.executions);
          different = false;
        }

        // Total execution counter
        this.totalExecutions += campaign.executions;
        // Total notifications counter
        this.totalNotifications += campaign.total_notifications;
        // Total campaign response counter
        if (campaign.responses) {
          this.totalResponses += campaign.responses.length;
        }

        /**************************************
         * Data for notifications by campaign
         *************************************/
        this.campNames.push(campaign.name);
        this.campNotifications.push(campaign.total_notifications);
        if (campaign.responses) {
          this.campResponses.push(campaign.responses.length);
        }
      }
    });
  }

  /******************************************************
   * Random color generator
   *****************************************************/

  private generateColor() {
    const letters = '456789DEF'.split('');
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 9)]; // number depends on character size in letters
    }

    return color;
  }
}
