import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch, removeItem, updateItem } from '@ngxs/store/operators';
import { RobotFamilyMetadataService } from 'src/app/core/services/robot-family-metadata.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import {
  TableSpinerReloadActive,
  TableSpinerReloadHide
} from 'src/app/shared/ngx-table/ngx-table/redux/table-spinner.actions';
import { ToggleHide, ToggleShow } from 'src/app/shared/spinner/redux/spinner.actions';
import { RobotFamilyMetadataStateModel } from './robot-family-metadata-state.model';
import {
  DeleteMetadata,
  DeleteMetadataSuccess,
  LoadMetadata,
  LoadMetadataSuccess,
  PostMetadata,
  SaveMetadataComplete,
  SaveMetadataFail,
  ReloadMetadata,
  ReloadMetadataSuccess,
  UpdateMetadata,
  UpdateMetadataSuccess
} from './robot-family-metadata.actions';

@State<RobotFamilyMetadataStateModel>({
  name: 'metadata',
  defaults: {
    robotFamilyMetadata: [],
    error: null
  }
})
@Injectable()
export class RobotFamilyMetadataState {
  constructor(
    private service: RobotFamilyMetadataService,
    private toastrService: NbToastrService,
    private utilityService: UtilityService
  ) {}

  @Selector()
  public static robotFamilyMetadata(state: RobotFamilyMetadataStateModel) {
    return state.robotFamilyMetadata;
  }

  @Action(LoadMetadata)
  public async loadMetadata(ctx: StateContext<RobotFamilyMetadataStateModel>) {
    ctx.dispatch(ToggleShow);
    this.service.loadMetadataAmplify();
  }

  @Action(LoadMetadataSuccess)
  public loadMetadataSuccess(ctx: StateContext<RobotFamilyMetadataStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      robotFamilyMetadata: payload.records
    });
    ctx.dispatch(ToggleHide);
  }

  @Action(ReloadMetadata)
  public async reloadMetadata(ctx: StateContext<RobotFamilyMetadataStateModel>) {
    this.toastrService.show(status, 'Fetch Initiated', { status: 'info' });
    this.service.loadMetadataAmplify(true);
  }

  @Action(ReloadMetadataSuccess)
  public reloadMetadataSuccess(ctx: StateContext<RobotFamilyMetadataStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      robotFamilyMetadata: payload
    });
    ctx.dispatch(TableSpinerReloadHide);
  }

  @Action(PostMetadata)
  public async postMetadata(ctx: StateContext<RobotFamilyMetadataStateModel>, { payload }: any) {
    Object.keys(payload).forEach((data) => {
      if (data.startsWith('local_')) {
        delete payload[data];
      }
    });
    this.service.postMetadataAmplify(payload);
  }

  @Action(UpdateMetadata)
  public async updateMetadata(ctx: StateContext<RobotFamilyMetadataStateModel>, { payload }: any) {
    this.service.updateMetadataAmplify(payload);
  }


  @Action(UpdateMetadataSuccess)
  public async updateMetadataComplete(ctx: StateContext<RobotFamilyMetadataStateModel>, { payload }: any) {
    const state = ctx.getState();

    ctx.setState(
      patch({
        robotFamilyMetadata: updateItem((item) => item.robot_family === JSON.parse(payload.body).robot_family, JSON.parse(payload.body))
      })
    );
    this.toastrService.show(status, 'Update: Complete', { status: 'success' });
  }

  @Action(DeleteMetadata)
  public deleteMetadata(ctx: StateContext<RobotFamilyMetadataStateModel>, { payload }: any) {
    this.service.deleteMetadataAmplify(payload);
  }

  @Action(DeleteMetadataSuccess)
  public async DeleteMetadata(ctx: StateContext<RobotFamilyMetadataStateModel>, { payload }: any) {
    ctx.setState(
      patch({
        // @ts-ignore
        robotFamilyMetadata: removeItem((item) => item.robot_family === payload.robot_family)
      })
    );
  }

  @Action(SaveMetadataComplete)
  public metadataComplete(ctx: StateContext<RobotFamilyMetadataStateModel>, { data, robotFamilyMetadata }: any) {
    const state = ctx.getState();
    ctx.setState(
      patch({
        robotFamilyMetadata: [...state.robotFamilyMetadata, JSON.parse(data.body)]
      })
    );

    this.toastrService.show(status, 'Save: Complete', { status: 'success' });
  }

  @Action(SaveMetadataFail)
  public metadataFail(ctx: StateContext<RobotFamilyMetadataStateModel>, { payload }: any) {
    const state = ctx.getState();
    const error = this.utilityService.convertErrorToMessage(payload);
    ctx.setState({
      ...state,
      error
    });
    this.toastrService.show(status, 'Save: Fail - ' + error, { status: 'danger' });
  }

}

