import { Asset } from './asset';
import { MapGeometry } from './mapGeometry';
export class CannedKozr extends Asset {
  public koz_id: number;
  public robotid: number;
  public pmap_id: string;
  public active_pmapv_id: string;
  public pmapv_ids: Array<string>;
  public koz_geom: Array<MapGeometry>;
  public related_events_geojson: Array<MapGeometry>;
  public event_types: Array<string>;
  public score: number;
  public comments: Array<string>;
  public create_timestamp: number;
  public cumul_orientation_rad: number;

  constructor() {
    super();
  }

  public get label() {
    return ('' + this.koz_id).padStart(3, '0') + ' - ' + this.active_pmapv_id;
  }

  public static clone(cannedKozr: CannedKozr) {
    const clonedcannedKozr: CannedKozr = new CannedKozr();
    Object.assign(clonedcannedKozr, cannedKozr);
    clonedcannedKozr.active_pmapv_id = 'Clone - ' + cannedKozr.active_pmapv_id;

    return clonedcannedKozr;
  }
}
