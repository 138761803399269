<nb-card
  accent="success"
  style="
    width: 90%;
    height: 90%;
    min-width: 700px;
    min-height: 430px;
    max-height: 800px;
    max-width: 1000px;
    margin: auto;
  "
>
  <nb-card-header>
    <div class="row">
      <div class="col-auto mr-auto">
        <nb-icon icon="edit-2-outline"></nb-icon>
      </div>
      <div class="col-auto mr-auto">Manage Contributors</div>
      <div class="col-auto"><button nbButton (click)="close()">X</button></div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div style="padding: 1.143em; margin: -1.143em">
      <strong class="text-uppercase">Contributors</strong>
      <p>Enter the email of the people you want to allow editing of this DRB</p>
      <hr />
      <form #xliffForm="ngForm">
        <div *ngFor="let contributor of contributors; let i = index; trackBy: trackByIndex">
          <div class="row">
            <div class="col"><label>Contributor:</label></div>
            <div class="col-9">
              <input
                id="{{ 'contributor' + i }}"
                name="{{ 'contributor' + i }}"
                nbInput
                fullWidth
                placeholder="Contributor {{ i + 1 }}"
                style="width: 100%"
                [(ngModel)]="contributors[i]"
              />
            </div>
          </div>
          <br />
        </div>
      </form>
      <p style="color: red; font-size: 12px">{{ errorMessage }}</p>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div class="d-flex flex-row-reverse">
      <div class="p-2">
        <button nbButton status="success" (click)="save()" [disabled]="!xliffForm.form.valid">Save</button>
      </div>

      <div class="p-2">
        <button nbButton (click)="close()">Close</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
