<nb-card accent="success" style="width: 900px" class="own-scroll">
  <nb-card-header>
    <div class="row">
      <div class="col-auto mr-auto">
        <nb-icon icon="search-outline"></nb-icon>
      </div>
      <div class="col-auto mr-auto">Survey {{ survey.survey_id }}</div>
      <div class="col-auto"><button nbButton (click)="close()">X</button></div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div style="height: 700px; padding: 1.143em; margin: -1.143em">
      <h6 class="text-uppercase">Survey Details</h6>

      <div class="row">
        <div class="col"><label>Survey ID:</label></div>
        <div class="col-9">
          <label>{{ survey.survey_id }}</label>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col"><label>Survey Name:</label></div>
        <div class="col-9">
          <label>{{ survey.survey_name }}</label>
        </div>
      </div>
      <br />

      <div class="row">
        <div class="col"><label>Survey Type:</label></div>
        <div class="col-9">
          <label>{{ survey.survey_type }}</label>
        </div>
      </div>
      <br />

      <div *ngFor="let questions of survey.questions">
        <div class="row">
          <nb-accordion style="width: 100%">
            <nb-accordion-item style="width: 100%">
              <nb-accordion-item-header>
                <div class="col-auto mr-auto">
                  <label> Question: {{ questions.question_id }}</label>
                </div>
              </nb-accordion-item-header>
              <nb-accordion-item-body>
                <div class="row">
                  <div class="col"><label>Question ID:</label></div>
                  <div class="col-9">
                    <label>{{ questions.question_id }}</label>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col"><label>Question Type:</label></div>
                  <div class="col-9">
                    <label>{{ questions.question_type }}</label>
                  </div>
                </div>
                <br />

                <div class="row">
                  <div class="col"><label>Question Name:</label></div>
                  <div class="col-9">
                    <label>{{ questions.question_name }}</label>
                  </div>
                </div>
                <br />

                <div class="row">
                  <div class="col"><label>Visible</label></div>
                  <div class="col-9">
                    <nb-checkbox disabled name="visible" [(ngModel)]="questions.visible"></nb-checkbox>
                  </div>
                </div>
                <br />

                <div class="row">
                  <div class="col"><label>Question Value:</label></div>
                  <div class="col-9">
                    <label>{{ questions.value }}</label>
                  </div>
                </div>
                <br />

                <div class="row">
                  <div class="col"><label>Question Image ID:</label></div>
                  <div class="col-9">
                    <label>{{ questions.image_id }}</label>
                  </div>
                </div>
                <br />

                <div class="row">
                  <div class="col"><label>Question Title ID:</label></div>
                  <div class="col-9">
                    <label>{{ questions.title_id }}</label>
                  </div>
                </div>
                <br />

                <div class="row">
                  <div class="col"><label>Question Subtitle ID:</label></div>
                  <div class="col-9">
                    <label>{{ questions.subtitle_id }}</label>
                  </div>
                </div>
                <br />

                <div class="row">
                  <div class="col"><label>Question Display Order:</label></div>
                  <div class="col-9">
                    <label>{{ questions.display_order }}</label>
                  </div>
                </div>
                <br />

                <div *ngFor="let answers of questions.answers">
                  <nb-accordion>
                    <nb-accordion-item>
                      <nb-accordion-item-header>Answer: {{ answers.answer_id }}</nb-accordion-item-header>
                      <nb-accordion-item-body>
                        <div class="row">
                          <div class="col"><label>Answer ID:</label></div>
                          <div class="col-9">
                            <label>{{ answers.answer_id }}</label>
                          </div>
                        </div>
                        <br />
                        <div class="row">
                          <div class="col"><label>Answer Type:</label></div>
                          <div class="col-9">
                            <label>{{ answers.answer_type }}</label>
                          </div>
                        </div>
                        <br />

                        <div class="row">
                          <div class="col"><label>Value:</label></div>
                          <div class="col-9">
                            <label>{{ answers.value }}</label>
                          </div>
                        </div>
                        <br />

                        <div class="row">
                          <div class="col"><label>Value ID:</label></div>
                          <div class="col-9">
                            <label>{{ answers.value_id }}</label>
                          </div>
                        </div>
                        <br />

                        <div class="row">
                          <div class="col"><label>Minimum Value:</label></div>
                          <div class="col-9">
                            <label>{{ answers.min_value }}</label>
                          </div>
                        </div>
                        <br />

                        <div class="row">
                          <div class="col">
                            <label>Minimum Value Description:</label>
                          </div>
                          <div class="col-9">
                            <label>{{ answers.min_value_text }}</label>
                          </div>
                        </div>
                        <br />

                        <div class="row">
                          <div class="col"><label>Maximum Value:</label></div>
                          <div class="col-9">
                            <label>{{ answers.max_value }}</label>
                          </div>
                        </div>
                        <br />

                        <div class="row">
                          <div class="col">
                            <label>Maximum Value Description:</label>
                          </div>
                          <div class="col-9">
                            <label>{{ answers.max_value_text }}</label>
                          </div>
                        </div>
                        <br />

                        <div class="row">
                          <div class="col">
                            <label>Answer Image ID:</label>
                          </div>
                          <div class="col-9">
                            <label>{{ answers.image_id }}</label>
                          </div>
                        </div>
                        <br />

                        <div *ngFor="let actions of answers.actions">
                          <nb-accordion>
                            <nb-accordion-item>
                              <nb-accordion-item-header>Action: {{ actions.label }}</nb-accordion-item-header>
                              <nb-accordion-item-body>
                                <div class="row">
                                  <div class="col">
                                    <label>Action Type:</label>
                                  </div>
                                  <div class="col-9">
                                    <label>{{ actions.action_type }}</label>
                                  </div>
                                </div>
                                <br />

                                <div class="row">
                                  <div class="col">
                                    <label>Action Value:</label>
                                  </div>
                                  <div class="col-9">
                                    <label>{{ actions.action_value }}</label>
                                  </div>
                                </div>
                                <br />

                                <div class="row">
                                  <div class="col"><label>Deleted</label></div>
                                  <div class="col-9">
                                    <nb-checkbox disabled name="visible" [(ngModel)]="actions.deleted"></nb-checkbox>
                                  </div>
                                </div>
                                <br />

                                <div *ngIf="actions.action_payload">
                                  <h6 class="text-uppercase">Action Payload</h6>
                                  <div class="row">
                                    <div class="col">
                                      <label>Deleted</label>
                                    </div>
                                    <div class="col-9">
                                      <nb-checkbox
                                        disabled
                                        name="visible"
                                        [(ngModel)]="actions.action_payload.deleted"
                                      ></nb-checkbox>
                                    </div>
                                  </div>
                                  <br />

                                  <div class="row">
                                    <div class="col"><label>URL:</label></div>
                                    <div class="col-9">
                                      <label>{{ actions.action_payload.url }}</label>
                                    </div>
                                  </div>
                                  <br />
                                </div>
                              </nb-accordion-item-body>
                            </nb-accordion-item>
                          </nb-accordion>
                        </div>
                      </nb-accordion-item-body>
                    </nb-accordion-item>
                  </nb-accordion>
                </div>
              </nb-accordion-item-body>
            </nb-accordion-item>
          </nb-accordion>
        </div>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div class="d-flex flex-row-reverse">
      <div class="p-2">
        <button nbButton status="info" (click)="close()">Close</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
