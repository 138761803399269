import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { Store, Actions, ofActionCompleted } from '@ngxs/store';
import { Survey } from 'src/app/models/survey';
import { NgxGenericModalComponent } from 'src/app/shared/ngx-generic-modal/ngx-generic-modal.component';
import { PostSurveys, SaveSurveysComplete } from '../redux/surveys.actions';
import { SurveysState } from '../redux/surveys.state';

@Component({
  selector: 'app-edit-survey',
  templateUrl: './edit-survey.component.html',
  styleUrls: ['./edit-survey.component.css', '../../../shared/common_syles.css']
})
export class EditSurveyComponent implements OnInit, AfterViewInit {
  public title;
  public survey: Survey;
  public surveyForm: FormGroup;
  public actionTypes = ['show_question', 'open_url_web_view', 'open_url_browser'];
  public answerType = ['text', 'rating'];
  public questionType = ['radio', 'thumbs_up_down', 'rating_nps', 'button', 'checkbox', 'transition'];
  public surveyType = ['survey', 'profile'];

  constructor(
    private fb: FormBuilder,
    protected ref: NbDialogRef<EditSurveyComponent>,
    private store: Store,
    private actions$: Actions,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService
  ) {
    /** Survey */
    this.surveyForm = this.fb.group({
      survey_id: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9\:_-]*$/i)]],
      survey_name: ['', Validators.required],
      survey_type: '',
      language: 'en',
      locale: 'en-US',
      questions: this.fb.array([])
    });
  }

  public ngOnInit(): void {
    if (this.title === 'Edit') {
      this.surveyForm.controls.survey_id.disable();
    }
    if (this.title === 'Clone') {
      this.survey.survey_id = null;
    }

    this.actions$.pipe(ofActionCompleted(SaveSurveysComplete)).subscribe((data) => {
      if (data) {
        this.ref.close();
      }
    });
  }

  public ngAfterViewInit(): void {
    if (this.title === 'Edit' || this.title === 'Clone') {
      // flush questions
      this.clearFormArray();
      // populate the form with empty fields so they can be patched with a value later
      this.survey.questions.forEach((q) => {
        const question: FormGroup = this.newQuestion();
        this.questions().push(question);

        q.answers.forEach((a) => {
          const answer = this.newAnswer();

          (question.get('answers') as FormArray).push(answer);

          a.actions.forEach((act) => {
            (answer.get('actions') as FormArray).push(this.newAction());
          });
        });
      });
      // patch values of the form with survey data
      this.surveyForm.patchValue(this.survey);
    }
  }

  public clearFormArray() {
    this.questions().clear();
  }

  /** Questions */
  public questions(): FormArray {
    return this.surveyForm.get('questions') as FormArray;
  }

  public newQuestion(): FormGroup {
    return this.fb.group({
      question_id: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9\:_-]*$/i)]],
      question_type: ['', [Validators.required]],
      question_name: '',
      language: 'en',
      locale: 'en-US',
      visible: true,
      value_id: ' ',
      value: '',
      image_id: '',
      title_id: '',
      subtitle_id: '',
      display_order: null,
      answers: this.fb.array([])
    });
  }

  public addQuestion() {
    this.questions().push(this.newQuestion());
  }

  public removeQuestion(qi: number) {
    this.questions().removeAt(qi);
  }

  /** Answers */

  public answers(qi: number): FormArray {
    return this.questions().at(qi).get('answers') as FormArray;
  }

  public newAnswer(): FormGroup {
    return this.fb.group({
      answer_id: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9\:_-]*$/i)]],
      answer_type: '',
      value: '',
      value_id: '',
      min_value: null,
      min_value_text: '',
      min_value_text_id: '',
      max_value: null,
      max_value_text: '',
      max_value_text_id: '',
      language: 'en',
      locale: 'en-US',
      image_id: '',
      actions: this.fb.array([])
    });
  }

  public addAnswer(qi: number) {
    this.answers(qi).push(this.newAnswer());
  }

  public removeAnswer(qi: number, ai: number) {
    this.answers(qi).removeAt(ai);
  }

  /** Actions */

  public actions(qi: number, ai: number): FormArray {
    return this.answers(qi).at(ai).get('actions') as FormArray;
  }

  public newAction(): FormGroup {
    const ap = this.fb.group({ deleted: false, url: '' }) as FormGroup;

    return this.fb.group({
      action_type: ['', [Validators.required]],
      action_value: '',
      action_payload: ap
    });
  }

  public addAction(qi: number, ai: number) {
    this.actions(qi, ai).push(this.newAction());
  }

  public removeAction(qi: number, ai: number, aci: number) {
    this.actions(qi, ai).removeAt(aci);
  }

  public save() {
    let edit;
    if (this.title === 'Edit') {
      edit = true;
    }

    this.store.dispatch(new PostSurveys(this.surveyForm.getRawValue(), edit));
  }

  public onSubmit() {
    if (this.title === 'New' || this.title === 'Clone') {
      if (this.uniqueSurveyName() && this.uniqueSurveyId()) {
        this.save();
      } else if (!this.uniqueSurveyName()) {
        this.toastrService.show(status, 'Survey name has to be unique!', { status: 'danger' });
      } else if (!this.uniqueSurveyId()) {
        this.toastrService.show(status, 'Survey id has to be unique!', { status: 'danger' });
      }
    }
    if (this.title === 'Edit') {
      if (this.uniqueSurveyName()) {
        this.save();
      } else {
        this.toastrService.show(status, 'Survey name has to be unique!', { status: 'danger' });
      }
    }
  }

  public uniqueSurveyName() {
    const surveys = this.store.selectSnapshot(SurveysState.surveys);
    const surveyName = this.surveyForm.getRawValue().survey_name;
    const surveyId = this.surveyForm.getRawValue().survey_id;

    if (this.title === 'New' || this.title === 'Clone') {
      return !(surveys.some((survey) => surveyName === survey.survey_name));
    } else if (this.title === 'Edit') {
      return !(surveys.some((survey) => surveyName === survey.survey_name && surveyId !== survey.survey_id));
    } else {
      return false;
    }
  }

  public uniqueSurveyId() {
    const surveys = this.store.selectSnapshot(SurveysState.surveys);
    const surveyId = this.surveyForm.getRawValue().survey_id;

    return !(surveys.some((survey) => survey.survey_id === surveyId));
  }

  public close() {
    this.dialogService
      .open(NgxGenericModalComponent, {
        context: {
          title: 'Confirm Close',
          message: 'Are you sure that you want to close the window? Any changes that you have made will be lost.'
        }
      })
      .onClose.subscribe((data) => {
        if (data) {
          this.ref.close();
        }
      });
  }
}
