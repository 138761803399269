import { MapAnnotation } from './qa-models/map-annotation';
import { ChangedSpaceEvent } from './qa-models/changed-space-event';
import { ObjectZoneRecommendation } from './qa-models/object-zone-recommendation';
import { SmartScheduleRecommendation } from './qa-models/schedule-recommendation';
import { FavoriteRecommendation } from './qa-models/favorite-recommendation';
import { RobotHealthNotification } from './qa-models/robot-health-notification';
import { ImageReview } from './qa-models/image-review';

export class QAInsightEvent {
  public robot_id: string;
  public campaign_id: string;
  public robot_name?: string;
  public map_annotation?: MapAnnotation = null;
  public changed_space_event?: ChangedSpaceEvent = null;
  public object_zone_recommendation?: ObjectZoneRecommendation = null;
  public schedule_recommendation?: SmartScheduleRecommendation = null;
  public favorite_recommendation?: FavoriteRecommendation = null;
  public robot_health_notification?: RobotHealthNotification = null;
  public image_review?: ImageReview = null;
}
