import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { UtilityService } from 'src/app/core/services/utility.service';
import { Xliff } from 'src/app/models/drb-models/xliff';
import { translationLanguages } from '../../../../shared/constants/translation-languages';

@Component({
  selector: 'app-view-xliff',
  templateUrl: './view-xliff.component.html',
  styleUrls: ['./view-xliff.component.css']
})
export class ViewXliffComponent implements OnInit {
  public xliff: Xliff;
  public showSpinner = false;
  public translationLanguages = translationLanguages;

  constructor(
    protected ref: NbDialogRef<ViewXliffComponent>,
    private dialogService: NbDialogService,
    public utility: UtilityService
  ) {}

  public ngOnInit() {}

  public close() {
    this.ref.close();
  }

  public descOrder = (a, b) => {
    if (a.key < b.key) {
      return b.key;
    }
  };
}
