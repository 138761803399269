import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { FailedJobInsightComponent } from '../failed-job-insight/failed-job-insight.component';

@Component({
  selector: 'app-failed-job-insight-list',
  templateUrl: './failed-job-insight-list.component.html',
  styleUrls: ['./failed-job-insight-list.component.css']
})
export class FailedJobInsightListComponent implements OnInit {
  public failedJobsList: any;

  constructor(protected ref: NbDialogRef<FailedJobInsightListComponent>, private dialogService: NbDialogService) {}

  public ngOnInit(): void {}

  public close() {
    this.ref.close();
  }

  public viewFailedJob(event) {
    this.dialogService.open(FailedJobInsightComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        failedJob: event
      }
    });
  }
}
