import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch, removeItem, updateItem } from '@ngxs/store/operators';
import { ContentService } from 'src/app/core/services/content.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import {
  TableSpinerReloadActive,
  TableSpinerReloadHide
} from 'src/app/shared/ngx-table/ngx-table/redux/table-spinner.actions';
import { ToggleHide, ToggleShow } from 'src/app/shared/spinner/redux/spinner.actions';
import { ContentStateModel } from './content-state.model';
import {
  DeleteContent,
  DeleteContentSuccess,
  LoadContent,
  LoadContentSuccess,
  PostContent,
  PostSaveComplete,
  PostSaveFail,
  ReloadContent,
  ReloadContentSuccess,
  UpdateContent,
  UpdateContentSuccess
} from './content.actions';

@State<ContentStateModel>({
  name: 'content',
  defaults: {
    content: [],
    error: null,
    deliveryChannelError: null
  }
})
@Injectable()
export class ContentState {
  constructor(
    private service: ContentService,
    private toastrService: NbToastrService,
    private utilityService: UtilityService
  ) {}

  @Selector()
  public static content(state: ContentStateModel) {
    return state.content;
  }

  @Action(LoadContent)
  public async loadContent(ctx: StateContext<ContentStateModel>) {
    ctx.dispatch(ToggleShow);
    this.service.loadContentAmplify();
  }

  @Action(LoadContentSuccess)
  public loadContentSuccess(ctx: StateContext<ContentStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      content: payload.records
    });
    ctx.dispatch(ToggleHide);
  }

  @Action(ReloadContent)
  public async reloadContent(ctx: StateContext<ContentStateModel>) {
    this.toastrService.show(status, 'Fetch Initiated', { status: 'info' });
    this.service.loadContentAmplify(true);
  }

  @Action(ReloadContentSuccess)
  public reloadContentSuccess(ctx: StateContext<ContentStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      content: payload
    });
    ctx.dispatch(TableSpinerReloadHide);
  }

  @Action(PostContent)
  public async postContent(ctx: StateContext<ContentStateModel>, { payload }: any) {
    Object.keys(payload).forEach((data) => {
      if (data.startsWith('local_')) {
        delete payload[data];
      }
    });
    this.service.postContentAmplify(payload);
  }

  @Action(UpdateContent)
  public async updateContent(ctx: StateContext<ContentStateModel>, { payload }: any) {
    this.service.updateContentAmplify(payload);
  }

  @Action(UpdateContentSuccess)
  public async updateContentComplete(ctx: StateContext<ContentStateModel>, { payload }: any) {
    const state = ctx.getState();

    ctx.setState(
      patch({
        content: updateItem((item) => item.content_id === payload.content_id, payload)
      })
    );
    this.toastrService.show(status, 'Update: Complete', { status: 'success' });
  }

  @Action(DeleteContent)
  public deleteContent(ctx: StateContext<ContentStateModel>, { payload }: any) {
    this.service.deleteContentAmplify(payload);
  }

  @Action(DeleteContentSuccess)
  public async DeleteContent(ctx: StateContext<ContentStateModel>, { payload }: any) {
    ctx.setState(
      patch({
        // @ts-ignore
        content: removeItem((item) => item.content_id === payload.content_id)
      })
    );
  }

  @Action(PostSaveComplete)
  public contentComplete(ctx: StateContext<ContentStateModel>, { data, content }: any) {
    // Data can contain "true" when its updating so we update item with "content" which is local copy
    // In case you make new "content" with post it will return new Object with "data" so in second case
    // We append it to last place in array with data
    const state = ctx.getState();
    ctx.setState(
      patch({
        content: [...state.content, data]
      })
    );

    this.toastrService.show(status, 'Save: Complete', { status: 'success' });
  }

  @Action(PostSaveFail)
  public contentFail(ctx: StateContext<ContentStateModel>, { payload }: any) {
    const state = ctx.getState();
    const error = this.utilityService.convertErrorToMessage(payload);
    ctx.setState({
      ...state,
      error
    });
    this.toastrService.show(status, 'Save: Fail - ' + error, { status: 'danger' });
  }
}
