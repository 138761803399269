<nb-card accent="success">
  <nb-card-header>
    <div class="row">
      <div class="col-auto mr-auto"><nb-icon icon="settings-outline"></nb-icon></div>
      <div class="col-auto mr-auto">Select columns</div>
      <div class="col-auto">{{ type | titlecase }}</div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class="row">
      <div class="col-lg-3"></div>
      <div class="col-lg-9">
        <div class="row">
          <nb-checkbox
            status="success"
            class="col-lg-3"
            *ngFor="let item of allKeys; index as i"
            [checked]="checked[i]"
            (checkedChange)="checkedChange(item, $event)"
          >
            {{ item | replaceunderscore }}
          </nb-checkbox>
        </div>
      </div>
    </div>

    <br />
    <hr />
    <div class="row justify-content-center">Column order</div>
    <br />
    <div class="row justify-content-center">
      <div cdkDropList cdkDropListOrientation="horizontal" class="example-list" (cdkDropListDropped)="drop($event)">
        <div cdkDrag class="example-box" *ngFor="let ch of fullSelectedArray">
          <button nbButton size="tiny">{{ ch | replaceunderscore }}</button>
        </div>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div class="d-flex flex-row-reverse">
      <div class="p-2">
        <button nbButton status="success" (click)="saveCurrentSelection()">Save</button>
      </div>
      <div class="p-2">
        <button nbButton status="danger" (click)="close()">Cancel</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
