import { Asset } from './asset';

export class Payload extends Asset {
  public url = '';

  constructor() {
    super();
  }

  public static copy(sourcePayload: Payload) {
    return Object.assign(new Payload(), sourcePayload);
  }
}

export class Action extends Asset {
  public action_type = '';
  public action_value?: string = null;
  public action_payload?: Payload = null;

  constructor() {
    super();
  }

  public static copy(sourceAction: Action) {
    const targetAction = new Action();
    Object.assign(targetAction, sourceAction);
    if (sourceAction.action_payload) {
      targetAction.action_payload = Payload.copy(sourceAction.action_payload);
    }

    return targetAction;
  }
}

export class Answer extends Asset {
  public answer_id: string;
  public answer_type = '';
  public value = '';
  public value_id?: string = null;
  public min_value?: number = null;
  public min_value_text?: string = null;
  public min_value_text_id?: string = null;
  public max_value?: number = null;
  public max_value_text?: string = null;
  public max_value_text_id?: string = null;
  public actions: Action[] = [];
  public language = 'en';
  public locale = 'en-US';
  public image_id?: string = null;

  constructor() {
    super();
  }

  public static copy(sourceAnswer: Answer) {
    const targetAnswer = new Answer();
    Object.assign(targetAnswer, sourceAnswer);
    targetAnswer.actions = sourceAnswer.actions.map((a: Action) => Action.copy(a));

    return targetAnswer;
  }
}

export class Question extends Asset {
  public question_id: string;
  public question_type = '';
  public question_name?: string = null;
  public language = 'en';
  public locale = 'en-US';
  public visible = true;
  public answers: Answer[] = [];
  public value_id?: string = null;
  public value?: string = null;
  public image_id?: string = null;
  public title_id?: string = null;
  public subtitle_id?: string = null;
  public display_order?: number = null;

  constructor() {
    super();
  }

  public static copy(sourceQuestion: Question) {
    const targetQuestion = new Question();
    Object.assign(targetQuestion, sourceQuestion);
    targetQuestion.answers = sourceQuestion.answers.map((a: Answer) => Answer.copy(a));

    return targetQuestion;
  }
}

export class Survey extends Asset {
  public survey_id: string;
  public survey_name?: string = null;
  public survey_type = '';
  public language?: string = 'en';
  public locale?: string = 'en-US';
  public questions: Question[] = [];

  constructor() {
    super();
  }

  public static copy(sourceSurvey: Survey) {
    const targetSurvey = new Survey();
    Object.assign(targetSurvey, sourceSurvey);
    targetSurvey.questions = sourceSurvey.questions.map((q: Question) => Question.copy(q));

    return targetSurvey;
  }
}
