import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {API} from '@aws-amplify/api';
import {NbToastrService} from '@nebular/theme';
import {Store} from '@ngxs/store';
import {
    LoadRobotMapRegionsSuccess,
    LoadRobotMapsSuccess,
    LoadRobotMapVersionsSuccess
} from '../../components/qa-testing/redux';
import {MapRegion, RobotMaps, RobotMapVersion} from '../../models/qa-models/robot-maps';
import {AmplifyServiceBuilder} from './amplify.service.builder';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MapsService {
  /*
    This MapsService relies on a service role
   */

  public maps_api = 'maps_api';

  constructor(protected store: Store,
              protected toastrService: NbToastrService,
              private router: Router) { }

  public loadMapsForRobotAmplify(robotId): void {
    const requestData = AmplifyServiceBuilder(this.maps_api, false);

    if(requestData) {
      // Returns a flattened list of pmap IDs
      API.get(this.maps_api, `/${environment.stage}/maps/${robotId}/pmaps`, requestData)
        .then((response) => {
          this.toastrService.show(status, 'Fetching Robot Maps Complete', {
              status: 'success'
          });

          const robotMaps = new RobotMaps(robotId, response);
          this.store.dispatch(new LoadRobotMapsSuccess(robotMaps));
        })
        .catch((error) => {
          this.toastrService.show(status, 'Fetching Robot Maps FAILED', {
            status: 'danger'
          });
          console.error(error);
        });
    } else {
      this.router.navigate(['/login'], {replaceUrl: true});
    }
  }

  public loadMapVersionsForRobotAmplify(robotId, pmapId): void {
      const requestData = AmplifyServiceBuilder(this.maps_api, false);

      if(requestData) {
        // Returns a flattened list of pmap versions
        API.get(this.maps_api, `/${environment.stage}/maps/${robotId}/pmaps/${pmapId}/versions`, requestData)
          .then((response) => {
            this.toastrService.show(status, 'Fetching Robot Map Versions Complete', {
                status: 'success'
            });

            const rmv = new RobotMapVersion(robotId, pmapId, response);
            this.store.dispatch(new LoadRobotMapVersionsSuccess(rmv));
          })
          .catch((error) => {
            this.toastrService.show(status, 'Fetching Robot Map Versions FAILED', {
              status: 'danger'
            });
            console.error(error);
          });
      } else {
        this.router.navigate(['/login'], {replaceUrl: true});
      }
  }

    public async loadMapRegionsAmplify(robotId, pmapId, pmapVersionId) {
      const requestData = AmplifyServiceBuilder(this.maps_api, false);

      let regions = [];
      if(requestData) {
        // Returns a flattened list of pmap regions
        await API.get(this.maps_api, `/${environment.stage}/maps/${robotId}/pmaps/${pmapId}/versions/${pmapVersionId}/regions`, requestData)
          .then((response) => {
            this.toastrService.show(status, 'Fetching Robot Map Regions Complete', {
                status: 'success'
            });

            if(Array.isArray(response)) {
              regions = response.map(r => new MapRegion(r.id, r.region_type));
              this.store.dispatch(new LoadRobotMapRegionsSuccess(regions));
            }

          })
          .catch((error) => {
            this.toastrService.show(status, 'Fetching Robot Map Regions FAILED', {
              status: 'danger'
            });
            console.error(error);
          });
      } else {
        this.router.navigate(['/login'], {replaceUrl: true});
      }

      return regions;
  }
}
