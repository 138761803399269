export class Xliff {
  public id?: string = '';
  public version: number = null;
  public source = '';
  public note = '';
  public created_by = '';
  public last_updated_by = '';
  public created_ts: number = null;
  public last_updated_ts: number = null;
  public ar_SA = '';
  public cs = '';
  public da = '';
  public de = '';
  public en_GB = '';
  public es_419 = '';
  public es = '';
  public fi = '';
  public fr_CA = '';
  public fr = '';
  public he = '';
  public it = '';
  public ja = '';
  public ko = '';
  public nb = '';
  public nl = '';
  public pl = '';
  public pt_BR = '';
  public pt_PT = '';
  public ru = '';
  public sv = '';
  public tr_TR = '';
  public zh_Hans = '';
  public zh_Hant = '';
}
