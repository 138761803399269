import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import {
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbIconModule,
  NbInputModule,
  NbPopoverModule,
  NbSpinnerModule,
  NbTooltipModule
} from '@nebular/theme';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NgxGenericModalComponent } from './ngx-generic-modal/ngx-generic-modal.component';
import { SplitWordUppercasePipe } from './custom-pipe/split-word-uppercase.pipe';
import { BoldPipe } from './custom-pipe/bold.pipe';
import { ReplaceUnderscorePipe } from './custom-pipe/replace-underscore.pipe';
import { ReplaceDashesPipe } from './custom-pipe/replace-dashes.pipe';
import { NgxTableComponent } from './ngx-table/ngx-table/ngx-table.component';
import { NgxCogwheelComponent } from './ngx-cogwheel/ngx-cogwheel.component';
import { OktaLoginComponent } from './okta-login/okta-login.component';
import { OktaRedirectComponent } from './okta-redirect/okta-redirect.component';
import { routedComponents } from './shared-routing.module';
import { SignoutComponent } from './signout/signout.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { StatusLightComponent } from './status-light/status-light.component';
import { metadataTableCellComponent } from './metadata-table-cell/metadata-table-cell.component';
import { FormatPipe } from './custom-pipe/format.pipe';
import { NgxConfirmModalComponent } from './ngx-confirm-modal/ngx-confirm-modal.component';

const DECLARED = [FormatPipe, ReplaceUnderscorePipe, ReplaceDashesPipe, BoldPipe, SplitWordUppercasePipe];

const COMPONENTS = [
  NgxTableComponent,
  SpinnerComponent,
  NgxCogwheelComponent,
  NgxGenericModalComponent,
  StatusLightComponent,
  metadataTableCellComponent
];

@NgModule({
  imports: [
    ReactiveFormsModule,
    NbCardModule,
    Ng2SmartTableModule,
    NbEvaIconsModule,
    NbIconModule,
    NbButtonModule,
    NbCardModule,
    CommonModule,
    NbCheckboxModule,
    NbSpinnerModule,
    FormsModule,
    NbInputModule,
    DragDropModule,
    NbInputModule,
    NbTooltipModule,
    NbPopoverModule
  ],
  exports: [
    FormatPipe,
    NgxTableComponent,
    SpinnerComponent,
    NgxCogwheelComponent,
    ReplaceUnderscorePipe,
    ReplaceDashesPipe,
    BoldPipe,
    SplitWordUppercasePipe
  ],
  declarations: [
    ...DECLARED,
    ...COMPONENTS,
    ...routedComponents,
    OktaLoginComponent,
    OktaRedirectComponent,
    SignoutComponent,
    NgxConfirmModalComponent
  ]
})
export class SharedModule {}
