/* eslint-disable @typescript-eslint/dot-notation */
import { Component, OnInit } from '@angular/core';
import { RouterState } from 'src/app/shared/redux/router-state';
import { Actions, ofActionCompleted, Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DrbService } from 'src/app/core/services/drb.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { SpinnerState } from 'src/app/shared/spinner/redux/spinner.state';
import { DrbState, LoadDrbByVersion, LoadDrbByVersionSuccess, LoadDrbVersions } from '../redux';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-view-drb',
  templateUrl: '../drb-sources/drb-sources.component.html',
  styleUrls: ['../drb-sources/drb-sources.component.css']
})
export class ViewDrbComponent implements OnInit {
  @Select(DrbState.view_drb) public view_data$: Observable<any[]>;
  @Select(DrbState.versions) public versions$: Observable<any[]>;
  @Select(RouterState.username) public data_username$: Observable<any>;
  @Select(SpinnerState) public loading: Observable<boolean>;

  public spin;
  public displayVersionButton;
  public mode;
  public metaData;
  public username;
  public versions: any[];
  public versionSelected;
  public deployInProgres = false;
  public environment: string = environment.stage;
  public bundle_name: string;
  public draftVersion;

  public sourceTabs: any[] = [
    {
      title: 'Strings',
      route: './strings'
    },
    {
      title: 'Files',
      route: './files'
    },
    {
      title: 'Images',
      route: './images'
    },
    {
      title: 'Links',
      route: './links'
    },
    {
      title: 'Videos',
      route: './videos'
    }
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: DrbService,
    private store: Store,
    private actions$: Actions,
    public utility: UtilityService
  ) {}

  public ngOnInit() {
    this.route.url.subscribe((event) =>
      {
        this.bundle_name = event[1].path;
        this.store.dispatch(new LoadDrbVersions(this.bundle_name));
    });
    this.mode = 'view';
    this.displayVersionButton = true;
    this.loadDrbData();
    this.data_username$.subscribe((data) => (this.username = data));
    this.versions$.subscribe((data) => {
      if (data.length > 0) {
        this.versions = [...data].reverse();
        this.draftVersion = this.versions[0];
      }
    });
    this.loading.subscribe((data) => (this.spin = data));

    this.view_data$.subscribe((data) => {
      if (data) {
        this.metaData = {
          version: data['version'],
          created_by: data['created_by'],
          created_ts: data['created_ts'],
          status: data['status'],
          last_updated_by: data['last_updated_by'],
          last_updated_ts: data['last_updated_ts'],
          owner: data['owner'],
          contributors: data['contributors'],
          draft: data['draft'],
          deployed_to_stage: data['deployed_to_stage'],
          deployed_to_stage_ts: data['deployed_to_stage_ts'],
          deployed_to_prod: data['deployed_to_prod'],
          deployed_to_prod_ts: data['deployed_to_prod_ts']
        };
        this.versionSelected = true;
        if (this.router.url.includes('drb/' + this.bundle_name  + '/view')) {
          this.router.navigate(['/drb/' + this.bundle_name  + '/view/strings']);
        }
      }
    });
  }
  public manageContributors() {}

  public onChange(version) {
    if (version) {
      this.store.dispatch(new LoadDrbByVersion(version, this.bundle_name));
    }
  }

  public loadDrbData() {
    this.view_data$.subscribe((data) => {
      this.metaData = {
        version: data['version'],
        created_by: data['created_by'],
        created_ts: data['created_ts'],
        status: data['status'],
        last_updated_by: data['last_updated_by'],
        last_updated_ts: data['last_updated_ts'],
        owner: data['owner'],
        contributors: data['contributors'],
        draft: data['draft'],
        deployed_to_stage: data['deployed_to_stage'],
        deployed_to_stage_ts: data['deployed_to_stage_ts'],
        deployed_to_prod: data['deployed_to_prod'],
        deployed_to_prod_ts: data['deployed_to_prod_ts']
      };
    });
  }
  public reload() {}

  public displayContributors() {
    if (this.metaData['contributors']) {
      const contributors = JSON.parse(JSON.stringify(this.metaData['contributors'])).filter(
        (element) => element.length > 0
      );

      return contributors.join(', ');
    }
  }
}
