import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { UtilityService } from 'src/app/core/services/utility.service';
import { Insight } from 'src/app/models/insight';
import { StatisticsModel } from 'src/app/models/statistics';

@Component({
  selector: 'app-stats-insight',
  templateUrl: './stats-insight.component.html',
  styleUrls: ['./stats-insight.component.css']
})
export class StatsInsightComponent extends StatisticsModel implements OnInit {
  public insight: Insight;

  constructor(public utilityService: UtilityService, protected ref: NbDialogRef<StatsInsightComponent>) {
    super();
  }

  public ngOnInit() {}

  /**************************************************************/

  public getAverageExecDuration(): string | boolean {
    return super.getAverageExecDuration(this.insight.total_duration, this.insight.executions);
  }

  /**************************************************************/

  public getAveragePublishes(): number {
    return super.getAverageValue(this.insight.total_results, this.insight.executions);
  }

  /**************************************************************
   * Used in HTML
   *************************************************************/
  public convertUnixTime(epoch: number): string | boolean {
    return this.utilityService.convertUnixTime_global(epoch);
  }

  public close() {
    this.ref.close();
  }
}
