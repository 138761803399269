import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { RouterStateModel } from './router-state.model';
import { SetIsLogedIn, SetTheme, SetURL, SetUserGroups, SetUsername } from './router.action';

@State<RouterStateModel>({
    name: 'router',
    defaults: {
        URL: null,
        isLoggedIn: false,
        username: '',
        user_groups: [],
        theme: ''
    }
})
@Injectable()
export class RouterState {
    constructor() {}

    @Selector()
    public static url(state: RouterStateModel) {
        return state.URL;
    }

    @Selector()
    public static theme(state: RouterStateModel) {
        return state.theme;
    }

    @Selector()
    public static isLogedIn(state: RouterStateModel) {
        return state.isLoggedIn;
    }

    @Selector()
    public static username(state: RouterStateModel) {
        return state.username;
    }

    @Selector()
    public static user_groups(state: RouterStateModel) {
        return state.user_groups;
    }

    @Action(SetURL)
    public setUrl(ctx: StateContext<RouterStateModel>, { payload }: any) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            URL: payload
        });
    }

    @Action(SetTheme)
    public setTheme(ctx: StateContext<RouterStateModel>, { payload }: any) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            theme: payload
        });
    }

    @Action(SetIsLogedIn)
    public setIsLogedIn(ctx: StateContext<RouterStateModel>, { payload }: any) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isLoggedIn: payload
        });
    }

    @Action(SetUsername)
    public setUsername(ctx: StateContext<RouterStateModel>, { payload }: any) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            username: payload
        });
    }

    @Action(SetUserGroups)
    public SetUserGroups(ctx: StateContext<RouterStateModel>, { payload }: any) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            user_groups: payload
        });
    }
}
