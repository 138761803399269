import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { SetComponentTableKeys, SetCurrentType} from 'src/app/shared/ngx-cogwheel/redux/cogwheel.actions';


@Injectable({ providedIn: 'root' })
export class CogwheelService {

public buildKeysForTable<T>(t: T[], allKeys: string[], store: Store, type: string, arrayValuesAllowed: boolean) {

    if (allKeys.length < 1) {
      if (t.length > 0) {
        for (const [key, value] of Object.entries(t[0])) {
          if (!arrayValuesAllowed && Array.isArray(value)) {
                      continue;
          } else {
            allKeys.push(key);
          }
        }
        store.dispatch(new SetCurrentType(type));
        store.dispatch(new SetComponentTableKeys(allKeys, type));
      }
    } else {
      store.dispatch(new SetComponentTableKeys(allKeys, type));
    }
  }
}
