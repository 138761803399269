<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <h4 class="bg-logo" routerLink="/insights">iRobot</h4>
  </div>
  <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select>
  <a class="logo" href="#" (click)="navigateHome()"><span>{{title}}</span></a>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action>
      <nb-user [nbContextMenu]="userMenu" name={{user}} nbContextMenuTrigger="hover" shape="semi-round">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
