<nb-card accent="success" style="width: 70%; margin: auto">
  <nb-card-header>
    <div class="row">
      <div class="col-auto mr-auto">
        <nb-icon icon="search-outline"></nb-icon>
      </div>
      <div class="col-auto mr-auto">
        Canned-Kozr {{ cannedKozr.koz_id }} Stats
      </div>
      <div class="col-auto"><button nbButton (click)="close()">X</button></div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <h6 class="text-uppercase">Canned-Kozr Details</h6>
    <dl class="row small">
      <dt class="col-2">Robot ID</dt>
      <dd class="col-10">{{ cannedKozr.robotid }}</dd>
      <dt class="col-2">Pmap ID</dt>
      <dd class="col-10">{{ cannedKozr.pmap_id }}</dd>
      <dt class="col-2">Active Pmap</dt>
      <dd class="col-10">{{ cannedKozr.active_pmapv_id }}</dd>
      <dt class="col-2">Created</dt>
      <dd class="col-10">
        {{ cannedKozr.create_timestamp * 1000 | date: "short" }}
      </dd>
    </dl>
  </nb-card-body>
  <nb-card-footer>
    <div class="d-flex flex-row-reverse">
      <div class="p-2">
        <button nbButton status="info" (click)="close()">Close</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
