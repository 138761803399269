export class LoadMetadata {
  public static readonly type = '[RobotFamilyMetadata] Load Metadata';
  constructor() {}
}

export class LoadMetadataSuccess {
  public static readonly type = '[RobotFamilyMetadata] Load Metadata SUCCESS';
  constructor(public payload: any) {}
}

export class LoadMetadataFail {
  public static readonly type = '[RobotFamilyMetadatata] Load Metadata FAIL';
  constructor() {}
}

export class ReloadMetadata {
  public static readonly type = '[RobotFamilyMetadata] Reload Metadata';
  constructor() {}
}

export class ReloadMetadataSuccess {
  public static readonly type = '[RobotFamilyMetadata] Reload Metadata SUCCESS';
  constructor(public payload: any) {}
}

export class ReloadMetadataFail {
  public static readonly type = '[RobotFamilyMetadata] Reload Metadata FAIL';
  constructor() {}
}

export class PostMetadata {
  public static readonly type = '[RobotFamilyMetadata] Post Metadata';
  constructor(public payload: any) {}
}

export class UpdateMetadata {
  public static readonly type = '[RobotFamilyMetadata] Update Metadata';
  constructor(public payload: any) {}
}

export class UpdateMetadataSuccess {
  public static readonly type = '[RobotFamilyMetadata] Update Metadata SUCCESS';
  constructor(public payload: any) {}
}

export class DeleteMetadata {
  public static readonly type = '[RobotFamilyMetadata] Delete Metadata';
  constructor(public payload: any) {}
}

export class DeleteMetadataSuccess {
  public static readonly type = '[RobotFamilyMetadata] Delete Metadata SUCCESS';
  constructor(public payload: any) {}
}

export class SaveMetadataComplete {
  public static readonly type = '[RobotFamilyMetadata] Metadata Save Complete';
  constructor(public data: any, public metadata: any) {}
}

export class SaveMetadataFail {
  public static readonly type = '[RobotFamilyMetadata] Metadata Save Fail';
  constructor(public payload: any) {}
}
