import { Component, OnInit } from '@angular/core';
import { NbDateService, NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';

import { Actions, Select, ofActionCompleted } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ViewContentComponent } from 'src/app/components/content/view-content/view-content.component';
import { InsightsState } from 'src/app/components/insights/redux/insights.state';
import { CampaignService } from 'src/app/core/services/campaign.service';
import { NgxGenericModalComponent } from 'src/app/shared/ngx-generic-modal/ngx-generic-modal.component';
import { environment } from 'src/environments/environment';
import { Insight } from '../../../../../models/insight';
import { ViewInsightComponent } from '../../../../insights/view-insight/view-insight.component';
import { PromotionSuccess } from '../../../redux';
/*
This component acts as a handler for promoting campagins
*/

@Component({
  selector: 'app-campaign-promotion-component',
  templateUrl: './campaign-promotion-component.component.html',
  styleUrls: ['./campaign-promotion-component.component.scss']
})
export class CampaignPromotionComponent implements OnInit {
  @Select(InsightsState.insightsActive) public data$: Observable<any[]>;

  public title;
  public campaign;
  public insight: Insight[];
  public content;
  public username;
  public campaign_trigger;
  public spinner = false;
  public results: any = false;
  public errors: any = false;

  public target_environment = environment.stage === 'stage' ? 'Production Environment' : 'Stage Environment';

  constructor(
    protected ref: NbDialogRef<CampaignPromotionComponent>,
    public service: CampaignService,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
    protected dateService: NbDateService<Date>,
    private actions$: Actions
  ) {}

  public ngOnInit() {
    this.campaign_trigger = this.insight.find((element) => element.id === Number(this.campaign.trigger_id));
    this.actions$.pipe(ofActionCompleted(PromotionSuccess)).subscribe((data) => {
      if (data) {
        this.clickedCancel();
      }
    });
  }

  public clickedPromote() {
    this.dialogService
      .open(NgxGenericModalComponent, {
        context: {
          title: 'Before promotion you need to confirm you want to Promote: ',
          message: this.campaign.name
        }
      })
      .onClose.subscribe((data) => {
        if (data) {
          this.spinner = true;
          this.postCampaignAndInsight();
        }
      });
  }

  public viewInsight(event) {
    const found = this.insight.find((element) => element.id === event);
    if (found) {
      this.dialogService.open(ViewInsightComponent, {
        closeOnEsc: false,
        closeOnBackdropClick: false,
        context: {
          insight: found
        }
      });
    } else {
      this.toastrService.show(status, 'Insight with this ID does not exist', {
        status: 'danger'
      });
    }
  }

  public viewContent(event) {
    const found = this.content;
    if (found) {
      this.dialogService.open(ViewContentComponent, {
        closeOnEsc: false,
        closeOnBackdropClick: false,
        context: {
          content: found
        }
      });
    } else {
      this.toastrService.show(status, 'Content with this ID does not exist', {
        status: 'danger'
      });
    }
  }

  public clickedCancel() {
    this.service.promotionError = false;
    this.service.promotionResponse = false;
    this.service.promotionSpinner = false;
    this.ref.close();
  }

  public postCampaignAndInsight() {
    this.service.postPromoteCampaign(this.campaign.id, this.username);
  }
}
