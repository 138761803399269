<nb-card accent="success" style="width: 90%; height: 90%; min-width: 700px; max-height: 800px; max-width: 1600px; margin: auto">
  <nb-card-header>
    <div class="row">
      <div class="col-auto mr-auto">
        <nb-icon icon="search-outline"></nb-icon>
      </div>
      <div class="col-auto mr-auto">View {{ metadata.robot_family }}</div>
      <div class="col-auto"><button nbButton (click)="close()">X</button></div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div style="height: 700px; padding: 1.143em; margin: -1.143em">
      <h5 class="text-uppercase">Robot Family Metadata Details</h5>
      <dl class="row small">
        <dt class="col-3">Robot Family</dt>
        <dd *ngIf="metadata.robot_family !== null; else noUser" class="col-9">
          {{ metadata.robot_family }}
        </dd>
      </dl>
      <dl class="row small">
        <dt class="col-3">Metadata Type</dt>
        <dd *ngIf="metadata.metadata_type !== null; else noUser" class="col-9">
          {{ metadata.metadata_type }}
        </dd>
      </dl>
      <dl class="row small">
        <dt class="col-3">Created By</dt>
        <dd *ngIf="metadata.created_by !== null; else noUser" class="col-9">
          {{ metadata.created_by }}
        </dd>
      </dl>
      <dl class="row small">
        <dt class="col-3">Created On</dt>
        <dd class="col-9">{{ formatted_created_time }}</dd>
      </dl>
      <dl class="row small">
        <dt class="col-3">Last Updated By</dt>
        <dd *ngIf="metadata.last_updated_by !== null; else noUser" class="col-9">
          {{ metadata.last_updated_by }}
        </dd>
      </dl>
      <dl class="row small">
        <dt class="col-3">Last Updated On</dt>
        <dd class="col-9">{{ formatted_updated_date }}</dd>
      </dl>
      <ng-template #noUser>
        <dd class="col-9">Unknown</dd>
      </ng-template>

      <hr />
      <h6 class="text-uppercase">Metadata</h6>
      <ng-container *ngFor="let item of metadata | keyvalue">
        <ng-container *ngIf="item.key === 'metadata' && item.value?.length > 0">
          <table style="width: 100%;">
            <tr>
              <th>Extent Type</th>
              <th>Minimum Confidence</th>
              <th>Priority</th>
            </tr>
            <tr *ngFor="let data of item.value; let i = index">
              <td *ngIf="data !== null && data !== ''">
                {{ data.extent_type }}
              </td>
              <td *ngIf="data !== null && data !== ''">
                {{ data.confidence }}
              </td>
              <td *ngIf="data !== null && data !== ''">
                {{ data.priority }}
              </td>
            </tr>
          </table>
        </ng-container>
      </ng-container>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div class="d-flex flex-row-reverse">
      <div class="p-2">
        <button nbButton status="info" (click)="close()">Close</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
