import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { Actions, Select, Store, ofActionCompleted } from '@ngxs/store';
import { Observable } from 'rxjs';
import { KozrService } from 'src/app/core/services/kozr.service';
import { QaServiceService } from 'src/app/core/services/qa-service.service';
import { UserService } from 'src/app/core/services/user.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { EventDetail, RealTimePersonalizationEvent } from 'src/app/models/rtp-models';
import { SpinnerState } from 'src/app/shared/spinner/redux/spinner.state';
import { environment } from 'src/environments/environment';
import { LoadAllRobots, LoadOwnerRobots } from '../redux';
import { QaState } from '../redux/qa.state';
import { RtpSendFailed, RtpSendSuccess } from './../redux/qa.actions';

@Component({
  selector: 'app-rtp-testing',
  templateUrl: './rtp-testing.component.html',
  styleUrls: ['./rtp-testing.component.scss', '../../../shared/common_syles.css']
})
export class RtpTestingComponent implements OnInit, DoCheck {
  @Input() public authorizedRobots;

  @Select(QaState.robots) public data_allRobots$: Observable<any[]>;
  @Select(QaState.ownerRobots) public data_ownerRobots$: Observable<any[]>;
  @Select(SpinnerState) public loading: Observable<boolean>;

  public spin;

  public event_types = ['MissionEndEvent', 'NewUserRobotProvisioned', 'UserAppUpgraded'];
  public endpoint_types = ['account', 'composite', 'contact', 'email', 'robot', 'user'];
  // Mission End event list
  public mission_end_events_success = ['busy', 'ok', 'full', 'stuck', 'bat', 'schErr', 'batcncl', 'inc'];
  public mission_end_events_cancellation = ['cncl', 'usrSlp', 'plcDoc', 'usrEnd', 'usrSpt'];
  public selectedEndpointType = '';
  public selectedEventType = '';
  public selectBot: any; // for ngModel
  public selectedRobot: any = {};
  public robotSelected = false;
  public optionSelect: string;
  public authorizedRobotsArray = [];
  public myRobots = [];
  public commonRobots = [];
  public kozrRobots = [];
  public robotNames = [];
  public robotAttributes = [];
  public payroll = {};

  public app_id = '';
  public old_version = '';
  public new_version = '';
  public endpoint_id = '';
  public numberOfMissions = 0;
  public numberOfSuccessfulMissions = 1;
  public numberOfCancelledMissions = 1;
  public missionsDone = '';

  public submissionSent = null;

  constructor(
    public utilityService: UtilityService, // used in HTML
    private store: Store,
    private userService: UserService,
    private kozrService: KozrService,
    private qaService: QaServiceService,
    private actions$: Actions
  ) {
    if (this.store.selectSnapshot(QaState.robots).length < 1) {
      this.store.dispatch(new LoadAllRobots());
    }
    if (this.store.selectSnapshot(QaState.ownerRobots).length < 1) {
      this.store.dispatch(new LoadOwnerRobots());
    }
  }

  public ngOnInit(): void {
    this.data_allRobots$.subscribe((data) => (this.commonRobots = data));
    this.data_ownerRobots$.subscribe((data) => (this.myRobots = data));
    this.loading.subscribe((data) => (this.spin = data));

    this.actions$.pipe(ofActionCompleted(RtpSendSuccess)).subscribe((data) => {
      this.submissionSent = true;
    });

    this.actions$.pipe(ofActionCompleted(RtpSendFailed)).subscribe((data) => {
      this.submissionSent = false;
    });
  }

  public onEventTypeSelect(optionSelected) {
    this.selectedEventType = optionSelected;
  }

  public onRobotSelect(selectBot) {
    this.selectedRobot = selectBot;
    if (selectBot !== null) {
      this.robotSelected = true;
    } else {
      this.robotSelected = false;
    }
  }

  public groupByFn(myRobots: any[]) {
    return (item) => {
      if ('pmap_id' in item) {
        return 'KOZR Robots';
      }
      if (myRobots.includes(item)) {
        return 'My Robots';
      }

      return 'Common Robots';
    };
  }

  /******************************************************
   * Submission method
   *****************************************************/

  public onSubmit() {
    const ts = Math.round(Date.now() / 1000);
    this.payroll = {};

    const details: EventDetail = {
      timestamp: ts,
      event_type: this.selectedEventType,
      endpoint_id: this.endpoint_id,
      endpoint_type: this.selectedEndpointType,
      attributes: this.formRobotEvent(this.selectedEventType)
    };

    const submission: RealTimePersonalizationEvent = {
      // was RealTimePersonalizationEvent
      //Time: ts,
      Source: 'com.irobot.data', // standard
      Resources: [],
      DetailType: 'PersonalizationEvent',
      Detail: `${JSON.stringify(details)}`,
      EventBusName: `personalization-${environment.stage}`
    };

    if (this.selectedEventType === 'MissionEndEvent') {
      submission.Source = 'com.irobot.iot';
      submission.DetailType = 'MissionEndEvent';
      this.qaService.submitRTPNotification(submission);
    } else {
      this.userService.sendToEventBridge(submission);
    }
    this.payroll = submission;
  }

  /******************************************************
   * Form the 'Detail' tab in the RTP event scheme
   *****************************************************/

  public formRobotEvent(eventType) {
    let attributes = [];

    switch (eventType) {
      case 'NewUserRobotProvisioned':
        attributes = [
          { name: 'robot_id', value: this.selectedRobot.id },
          { name: 'robot_name', value: this.selectedRobot.name },
          {
            name: 'robot_regdate',
            value: this.selectedRobot.registration_date
          },
          { name: 'robot_timezone', value: this.selectedRobot.timezone },
          { name: 'robot_sku', value: this.selectedRobot.sku },
          { name: 'is_test', value: true }
        ];
        break;
      case 'UserAppUpgraded':
        attributes = [
          { name: 'robot_id', value: this.selectedRobot.id },
          { name: 'robot_name', value: this.selectedRobot.name },
          { name: 'robot_timezone', value: this.selectedRobot.timezone },
          { name: 'robot_sku', value: this.selectedRobot.sku },
          { name: 'app_id', value: this.app_id },
          { name: 'old_version', value: this.old_version },
          { name: 'new_version', value: this.new_version },
          { name: 'is_test', value: true }
        ];
        break;
      case 'MissionEndEvent':
        attributes = [
          { name: 'robot_id', value: this.selectedRobot.id },
          { name: 'robot_name', value: this.selectedRobot.name },
          { name: 'robot_timezone', value: this.selectedRobot.timezone },
          { name: 'robot_sku', value: this.selectedRobot.sku },
          { name: 'mr_nMssn', value: this.numberOfMissions },
          { name: 'mr_done', value: this.missionsDone },
          { name: 'mission_cancel_count', value: this.numberOfCancelledMissions},
          { name: 'mission_success_count', value: this.numberOfSuccessfulMissions},
          { name: 'is_test', value: true }
        ];
        break;
      default:
        break;
    }

    return attributes;
  }

  public enableSubmit(): boolean {
    if (this.selectedEventType !== '' && this.endpoint_id !== '' && this.selectedRobot !== '') {
      return true;
    } else {
      return false;
    }
  }

  public ngDoCheck(): void {
    this.kozrRobots = this.kozrService.cannedKozrRobots;
  }
}
