import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-status-light',
  templateUrl: './status-light.component.html',
  styleUrls: ['./status-light.component.css']
})
export class StatusLightComponent implements OnInit {
  @Input() public value: string;
  @Input() public rowData: any;
  public renderValue: string;

  constructor() {}

  public ngOnInit() {
    if (this.value) {
      this.renderValue = this.value;
    }
  }
}
