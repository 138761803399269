export class LoadContent {
  public static readonly type = '[Content] Load Content';
  constructor() {}
}

export class LoadContentSuccess {
  public static readonly type = '[Content] Load Content SUCCESS';
  constructor(public payload: any) {}
}

export class LoadContentFail {
  public static readonly type = '[Content] Load Content FAIL';
  constructor() {}
}

export class ReloadContent {
  public static readonly type = '[Content] Reload Content';
  constructor() {}
}

export class ReloadContentSuccess {
  public static readonly type = '[Content] Reload Content SUCCESS';
  constructor(public payload: any) {}
}

export class ReloadContentFail {
  public static readonly type = '[Content] Reload Content FAIL';
  constructor() {}
}

export class PostContent {
  public static readonly type = '[Content] Post Content';
  constructor(public payload: any) {}
}

export class PostSaveComplete {
  public static readonly type = '[Content] Post Save Complete';
  constructor(public data: any, public content: any) {}
}

export class PostSaveFail {
  public static readonly type = '[Content] Post Save Fail';
  constructor(public payload: any) {}
}

export class UpdateContent {
  public static readonly type = '[Content] Update Content';
  constructor(public payload: any) {}
}

export class UpdateContentSuccess {
  public static readonly type = '[Content] Update Content SUCCESS';
  constructor(public payload: any) {}
}

export class DeleteContent {
  public static readonly type = '[Content] Delete Content';
  constructor(public payload: any) {}
}

export class DeleteContentSuccess {
  public static readonly type = '[Content] Delete Content SUCCESS';
  constructor(public payload: any) {}
}
