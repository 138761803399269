<div>
<h6 class="text-uppercase">Job Details</h6>
<dl class="row small">
  <dt class="col-2">Job name:</dt>
  <dd class="col-10">
    <div *ngIf ="results.jobName">{{results.jobName}}</div>
    <div *ngIf ="!results.jobName"class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
    </div>
  </dd>

  <dt class="col-2">ID:</dt>
  <dd class="col-10">
    <div>{{results.jobId}} {{results.job_id}}</div>
  </dd>

  <dt class="col-2">Status:</dt>
  <dd class="col-10">
    <div *ngIf ="results.status">{{results.status}}</div>
    <div *ngIf ="!results.status"class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
    </div>
  </dd>

  <dt class="col-2">Created:</dt>
  <dd class="col-10">
    <div *ngIf ="results.createdAt">{{results.createdAt | date:'medium'}}</div>
    <div *ngIf ="!results.createdAt"class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
    </div>
  </dd>

  <dt class="col-2">Started:</dt>
  <dd class="col-10">
    <div *ngIf ="results.startedAt">{{results.startedAt | date:'medium'}}</div>
    <div *ngIf ="!results.startedAt"class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
    </div>
  </dd>

  <dt class="col-2">Stopped:</dt>
  <dd class="col-10">
    <div *ngIf ="results.stoppedAt">{{results.stoppedAt | date:'medium'}}</div>
    <div *ngIf ="!results.stoppedAt"class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
    </div>
  </dd>
</dl>
</div>