import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Store } from '@ngxs/store';
import { NgxGenericModalComponent } from 'src/app/shared/ngx-generic-modal/ngx-generic-modal.component';
import { HtaRecommendationPost } from '../../redux/qa.actions';

@Component({
  selector: 'app-edit-hta-recommendation',
  templateUrl: './edit-hta-recommendation.component.html',
  styleUrls: ['./edit-hta-recommendation.component.css']
})
export class EditHtaRecommendationComponent implements OnInit {
  public htaRecommendation;
  public title;
  public comments;

  constructor(
    protected ref: NbDialogRef<EditHtaRecommendationComponent>,
    private store: Store,
    private dialogService: NbDialogService
  ) {}

  public ngOnInit() {
    this.comments = this.htaRecommendation.comments ? [...this.htaRecommendation.comments] : [];
  }

  public close() {
    this.dialogService
      .open(NgxGenericModalComponent, {
        context: {
          title: 'Confirm Close',
          message: 'Are you sure that you want to close the window? Any changes that you have made will be lost.'
        }
      })
      .onClose.subscribe((data) => {
        if (data) {
          this.ref.close();
        }
      });
  }

  public save() {
    let edit = false;
    if (this.title === 'Edit') {
      edit = true;
    }
    this.htaRecommendation.comments = this.comments;
    this.store.dispatch(new HtaRecommendationPost(this.htaRecommendation));
    this.ref.close();
  }

  public deleteContentItem(index) {
    this.htaRecommendation.deleteContent(index);
  }

  public trackByIndex(index: number, obj: any): any {
    return index;
  }

  public updateModel(key, event) {
    this.htaRecommendation[key] = JSON.parse(event.target.value);
  }
  public addComment() {
    this.comments.push('');
  }
  public addNewItem(field) {
    if (this.htaRecommendation.hasOwnProperty(field)) {
      if (Array.isArray(this.htaRecommendation[field])) {
        if (this.htaRecommendation[field].length > 0) {
          const helperArray = [];
          const string = '';
          helperArray.push(string);
          this.htaRecommendation[field] = [...this.htaRecommendation[field], ...helperArray];
        }
      } else {
        this.htaRecommendation[field] = [];
        this.htaRecommendation[field].push('');
      }
    } else {
      this.htaRecommendation[field] = [];
      this.htaRecommendation[field].push('');
    }
  }
}
