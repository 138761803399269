import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'timestamp-date'
})
export class TimestampDatePipe implements PipeTransform {
    public transform(value: any, args?: any): any {
        if (args === 'timestampToDate') {
            if (value) {
                const epochDate: Date = new Date(value * 1000);
                const min: string = String(epochDate.getMinutes()).padStart(2, '0');
                const hour: number = ((epochDate.getHours() + 11) % 12) + 1;
                let strDate = '';
                const meridiem = epochDate.getHours() > 12 ? 'PM' : 'AM';

        enum months {
          Jan,
          Feb,
          Mar,
          Apr,
          May,
          Jun,
          Jul,
          Aug,
          Sep,
          Oct,
          Nov,
          Dec,
        }

        strDate = `${epochDate.getFullYear()}/${
            months[epochDate.getMonth()]
        }/${epochDate.getDate()}   ${hour}:${min} ${meridiem}`;

        return strDate;
            }
        }

        return value;
    }
}
