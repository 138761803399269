/* eslint-disable @typescript-eslint/no-shadow */
import { DecimalPipe } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CampaignService } from 'src/app/core/services/campaign.service';
import { InsightService } from 'src/app/core/services/insight.service';
import { WebSocketService } from 'src/app/core/services/web-socket.service';
import { Campaign } from 'src/app/models/campaign';
import { SpinnerState } from 'src/app/shared/spinner/redux/spinner.state';
import { OktaRestriction } from 'src/app/shared/okta-restrictions/okta-restrictions';
import { LoadContent } from '../../content/redux/content.actions';
import { ContentState } from '../../content/redux/content.state';
import { InsightsState, LoadInsights, LoadInsightsInactive } from '../../insights/redux';
import { SurveysState } from '../../surveys/redux';
import { CampaignState, LoadCampaign, ReloadCampaign } from '../redux';
import { NgxGenericModalComponent } from './../../../shared/ngx-generic-modal/ngx-generic-modal.component';
import { LoadSurveys } from './../../surveys/redux/surveys.actions';
import { EditCampaignComponent } from './edit-campaign/edit-campaign.component';
import { StatsCampaignComponent } from './stats-campaign/stats-campaign.component';
import { CampaignTerminateModalComponent } from './campaign-terminate-modal/campaign-terminate-modal.component';
import { DeleteCampaignComponent } from './delete-campaign/delete-campaign.component';
import {ToggleShow } from 'src/app/shared/spinner/redux/spinner.actions';

@Component({
  selector: 'app-campaigns',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.css'],
  providers: [DecimalPipe]
})
export class CampaignsComponent implements OnInit, AfterViewInit {
  @Select(CampaignState.campaign) public data$: Observable<any[]>;
  @Select(CampaignState.campaignJobs) public campaignJobsData$: Observable<any[]>;
  @Select(SpinnerState) public loading: Observable<boolean>;

  public spin;
  public tableData;
  public total$: Observable<number>;
  public campaignJobs;
  public displayNewButton = false;

  constructor(
    private service: CampaignService,
    private store: Store,
    private dialogService: NbDialogService,
    private router: Router,
    private socketService: WebSocketService,
    private toastrService: NbToastrService,
    private insightService: InsightService, //TODO: do we still need this here?
    private restrictionService: OktaRestriction
  ) {
    this.store.dispatch(ToggleShow);
    if (
      this.store.selectSnapshot(InsightsState.insightsActive).length < 1 ||
      this.store.selectSnapshot(InsightsState.insightsInactive).length < 1
    ) {
      this.store.dispatch(LoadInsights);
      this.store.dispatch(LoadInsightsInactive).subscribe(() => {
        this.store.dispatch(LoadCampaign);
      });
    } else {
      this.store.dispatch(LoadCampaign);
    }
    if (this.store.selectSnapshot(ContentState.content).length < 1) {
      this.store.dispatch(LoadContent);
    }
    if (this.store.selectSnapshot(SurveysState.surveys).length < 1) {
      this.store.dispatch(LoadSurveys);
    }
  }

  public ngAfterViewInit() {
    if (this.store.selectSnapshot(CampaignState.campaign).length > 1) {
      this.service.buildKeysForTable(this.store.selectSnapshot(CampaignState.campaign));
    }
  }

  public ngOnInit() {
    this.data$.subscribe((data) => {
      this.tableData = data;

      this.service.buildKeysForTable(data);
    });

    this.loading.subscribe((data) => (this.spin = data));
    this.campaignJobsData$.subscribe((data) => (this.campaignJobs = data));
    this.displayNewButton = this.restrictionService.manager_permission;
  }


  public clickedButtonTable($event) {
    if ($event.action === 'view') {
      this.viewCampaign($event.data);
    }
    if ($event.action === 'edit') {
      if ($event.data.status === 'RUNNING') {
        this.toastrService.show(status, 'Cannot Edit a running Campaign', { status: 'danger' });
      } else {
        this.editCampaign($event.data);
      }
    }
    if ($event.action === 'clone') {
      this.cloneCampaign($event.data);
    }
    if ($event.action === 'activate') {
      if ($event.data.status === 'RUNNING') {
        this.toastrService.show(status, 'Cannot Start a running Campaign', { status: 'danger' });
      } else {
        this.runCampaign($event.data);
      }
    }
    if ($event.action === 'deactivate') {
      if ($event.data.status !== 'RUNNING') {
        this.toastrService.show(status, 'Campaign is not running', { status: 'danger' });
      } else {
        this.terminateCampaign($event.data);
      }
    }
    if ($event.action === 'delete') {
      this.deleteCampaign($event.data);
    }
    if ($event.action === 'stats') {
      this.viewStats($event.data);
    }
  }

  public newCreation() {
    this.newCampaign();
  }

  public newCampaign(): void {
    this.dialogService.open(EditCampaignComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'New',
        campaign: new Campaign()
      }
    });
  }

  public refreshTableData() {
    this.store.dispatch(ReloadCampaign);
  }

  public viewCampaign(campaign: Campaign): void {
    this.router.navigate(['/campaigns/table/' + campaign.id]);
  }

  public editCampaign(campaign: Campaign): void {
    this.dialogService.open(EditCampaignComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'Edit',
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        campaign: <Campaign>Campaign.copy(campaign)
      }
    });
  }

  public cloneCampaign(campaign: Campaign): void {
    const clonedCampaign = Campaign.clone(campaign);
    clonedCampaign.id = null;
    this.dialogService.open(EditCampaignComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'Clone',
        campaign: clonedCampaign
      }
    });
  }

  public deleteCampaign(campaign: Campaign): void {
    this.dialogService
      .open(DeleteCampaignComponent, {
        closeOnEsc: false,
        closeOnBackdropClick: false,
        context: {
          title: 'Are you sure you want to delete Campaign',
          campaign: Campaign.copy(campaign) as Campaign
        }
      });
  }

  public runCampaign(campaign: Campaign): void {
    this.dialogService
      .open(NgxGenericModalComponent, {
        closeOnEsc: false,
        closeOnBackdropClick: false,
        context: {
          title: 'Are you sure you want to "Run": ',
          message: campaign.id
        }
      })
      .onClose.subscribe((data) => {
        if (data) {
          this.socketService.setUp();
          this.service.runCampaignJob(campaign).subscribe((data) => {
            setTimeout(() => {
              this.socketService.sendMessage(data.job_id, campaign.id);
            }, 2000);
          });
        }
      });
  }

  public terminateCampaign(campaign: Campaign): void {
    this.dialogService.open(CampaignTerminateModalComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'Are you sure you want to "Terminate": ',
        message: campaign.id,
        job_id: campaign.batch_job_id,
        campaign_name: campaign.name
      }
    });
  }

  public viewStats(campaign: Campaign): void {
    this.dialogService.open(StatsCampaignComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        campaign
      }
    });
  }
}
