import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { NgxGenericModalComponent } from 'src/app/shared/ngx-generic-modal/ngx-generic-modal.component';
import { RouterState } from 'src/app/shared/redux/router-state';
import { DrbState, SaveSourceFilesState, SaveSourceLinksState, SaveSourceVideosState } from '../../redux';
import { SourceItem } from 'src/app/models/drb-models/source-item';

@Component({
  selector: 'app-edit-source-item',
  templateUrl: './edit-source-item.component.html',
  styleUrls: ['./edit-source-item.component.css', '../../../../shared/common_syles.css']
})
export class EditSourceItemComponent implements OnInit {
  @Select(DrbState.sourceFiles) public fileData$: Observable<any[]>;
  @Select(DrbState.sourceLinks) public linkData$: Observable<any[]>;
  @Select(DrbState.sourceVideos) public videoData$: Observable<any[]>;
  @Select(DrbState.ids) public ids$: Observable<any[]>;
  @Select(RouterState.username) public data_username$: Observable<any>;

  public title;
  public sourceItem: SourceItem;
  public sourceItemState: any[];
  public username = '';
  public ids;
  public patternId = '^[a-z0-9_]+$';
  public patternLocation = '^[^\'"]*$';
  public type;
  public bundle_name;

  constructor(
    private store: Store,
    protected ref: NbDialogRef<EditSourceItemComponent>,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService
  ) {}

  public ngOnInit() {
    this.data_username$.subscribe((data) => (this.username = data));
    if (this.type === 'file') {
      this.fileData$.subscribe((data) => (this.sourceItemState = data));
    }
    if (this.type === 'link') {
      this.linkData$.subscribe((data) => (this.sourceItemState = data));
    }
    if (this.type === 'video') {
      this.videoData$.subscribe((data) => (this.sourceItemState = data));
    }
    this.ids$.subscribe((data) => (this.ids = data));
  }

  public save() {
    const localSourceItem = JSON.parse(JSON.stringify(this.sourceItemState));

    if (this.title === 'New') {
      const sourceItemId = this.prefix() + this.sourceItem.id + '_dmc';
      if (this.ids.some((item) => item === sourceItemId)) {
        this.toastrService.show('', sourceItemId + ' already exists!', { status: 'danger' });
      } else {
        this.sourceItem.id = sourceItemId;
        this.sourceItem.location = this.sourceItem.location;
        this.sourceItem.created_by = this.username;
        this.sourceItem.created_ts = Math.floor(Date.now() / 1000);
        this.sourceItem.last_updated_by = this.username;
        this.sourceItem.last_updated_ts = Math.floor(Date.now() / 1000);

        localSourceItem.push(this.sourceItem);
        this.dispatch(localSourceItem);
        this.ref.close();
      }
    } else if (this.title === 'Edit') {
      const index = localSourceItem.findIndex((item) => item.id === this.sourceItem.id);
      localSourceItem[index].location = this.sourceItem.location;
      localSourceItem[index].last_updated_by = this.username;
      localSourceItem[index].last_updated_ts = Math.floor(Date.now() / 1000);

      this.dispatch(localSourceItem);
      this.ref.close();
    }
  }

  public titleCaseWord(word: string) {
    if (!word) {
      return word;
    }

    return word[0].toUpperCase() + word.substring(1).toLowerCase();
  }

  public dispatch(localSourceItem) {
    if (this.type === 'file') {
      this.store.dispatch(new SaveSourceFilesState(localSourceItem, this.bundle_name));
    }
    if (this.type === 'link') {
      this.store.dispatch(new SaveSourceLinksState(localSourceItem, this.bundle_name));
    }
    if (this.type === 'video') {
      this.store.dispatch(new SaveSourceVideosState(localSourceItem, this.bundle_name));
    }
  }

  public close() {
    this.dialogService
      .open(NgxGenericModalComponent, {
        context: {
          title: 'Confirm Close',
          message: 'Are you sure that you want to close the window? Any changes that you have made will be lost.'
        }
      })
      .onClose.subscribe((data) => {
        if (data) {
          this.ref.close();
        }
      });
  }

  public prefix() {
    if(this.bundle_name === 'marketing') {

      return 'mkt_';
    } else if(this.bundle_name === 'marketing_translations') {

      return 'mkt_t_';
    }
  }
}
