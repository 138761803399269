<app-spinner *ngIf="spin"></app-spinner>
<div *ngIf="(metaData && !translating) || mode === 'view'">
  <br />
  <div *ngIf="mode !== 'view' && !metaData.translations_received">
    <nb-toggle
      style="padding: 0.5rem 1.5rem"
      labelPosition="start"
      status="success"
      [checked]="metaData.translate"
      (click)="optForTranslation()"
      nbTooltip="Opting strings for translation will affect the total translation cost!"
      nbTooltipPlacement="right"
      ><strong class="text-uppercase">Translate</strong></nb-toggle
    >
    <b *ngIf="metaData.translate" class="translateScheduleMsg"
      >Next translation process is scheduled for {{ getNextMonday() }} at 4:00 PM ET.</b
    >
  </div>
  <br />
  <nb-accordion>
    <nb-accordion-item>
      <nb-accordion-item-header> Translations metadata </nb-accordion-item-header>
      <nb-accordion-item-body>
        <div class="row">
          <div class="col-4">
            <label>Translate: </label>
            <p>{{ metaData.translate }}</p>
          </div>
          <div class="col-4">
            <label>Status: </label>
            <p>{{ metaData.translation_status }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-4" *ngIf="metaData.translations_submitted_ts">
            <label>Translations submitted on: </label>
            <p>{{ utility.convertUnixTime_global(metaData.translations_submitted_ts) }}</p>
          </div>
          <div class="col-4" *ngIf="metaData.translations_received_ts">
            <label>Translations received on: </label>
            <p>{{ utility.convertUnixTime_global(metaData.translations_received_ts) }}</p>
          </div>
          <div class="col-4" *ngIf="metaData.translations_submitted_ts && !metaData.translations_received_ts">
            <label>Translations expected by: </label>
            <p>{{ getTwoWeeksFromNow(metaData.translations_submitted_ts) }} <span style="color: red"> (can be subject to change) </span></p>
          </div>
        </div>
      </nb-accordion-item-body>
    </nb-accordion-item>
  </nb-accordion>
  <br />
  <app-ngx-table
    type="{{ type }}"
    [mode]="mode"
    [description]="description"
    [buttonEnabled]="displayNewButton"
    [tableData]="tableData"
    *ngIf="!spin"
    (clickedButton)="clickedButtonTable($event)"
    (createNew)="newCreation()"
    (refreshTable)="refreshTableData()"
  ></app-ngx-table>
  <router-outlet></router-outlet>
</div>
<div *ngIf="translating && mode !== 'view'">
  <nb-card accent="info">
    <nb-card-header>Strings are currently in translation process</nb-card-header>
    <nb-card-body>
      <div class="row">
        <label>Submitted on {{ utility.convertUnixTime_global(metaData.translations_submitted_ts) }}</label>
      </div>
      <p>If you want to inspect the strings, you can do so in the DRBs view window.</p>
    </nb-card-body>
  </nb-card>
</div>
