<nb-card
  accent="success"
  style="width: 100%; height: 100%; min-width: 700px; max-height: 800px; max-width: 1600px; margin: auto"
>
  <nb-card-header>
    <div class="row">
      <div class="col-auto mr-auto">
        <nb-icon icon="nb-trash"></nb-icon>
      </div>
      <div class="col-auto mr-auto">{{ title }}</div>
      <div class="col-auto"><button nbButton (click)="close()">X</button></div>
    </div>
    <div class="row">
      <div class="col-auto mr-auto" style="margin-top: 1rem">{{ insight.name + ' - ' + insight.id }}</div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div style="height: 100%; padding: 1.143em; margin: -1.143em">
          <div class="deep-delete-spinner" *ngIf="spin">
            <div></div>
          </div>
          <form #campaignForm="ngForm" *ngIf="!spin">
            <strong class="text-uppercase">Select associated objects for deletion</strong>
            <hr />

            <div class="row">
              <div class="col">
                <p style="color: green" *ngIf="affectedCampaigns.length === 0">
                  Other campaigns won't be affected by deletion of this insight
                </p>
                <div *ngIf="affectedCampaigns.length > 0">
                  <p style="color: red" *ngIf="activeCampaigns.length === 1">
                    There is <strong>one ACTIVE</strong> campaign using this insight. Deactivate it before deleting this insight!
                  </p>
                  <p style="color: red" *ngIf="activeCampaigns.length > 1">
                    There are <strong>{{ activeCampaigns.length }} ACTIVE</strong> campaigns using this insight. Deactivate them before deleting this insight!
                  </p>
                  <p style="color: orange">
                    Following campaigns are going to be affected by deletion of this insight:
                  </p>
                  <div *ngFor="let campaign of affectedCampaigns; index as i">
                    <nb-checkbox
                      id="campaign.id"
                      name="deleteCampaign"
                      dis
                      style="padding: 3px 10px 0px 0px;"
                      [disabled]="campaign.status === 'ACTIVE' || campaign.status === 'RUNNING'"
                      (checkedChange)="checkedChange(campaign, $event)"
                    >
                    </nb-checkbox>
                    <label style="padding-top: 6px">{{ campaign.name }} - <strong>{{ campaign.status }}</strong></label>
                    <button
                      nbButton
                      size="medium"
                      ghost
                      status="info"
                      (click)="viewCampaign(campaign.id)" >
                    <nb-icon icon="external-link-outline" nbTooltip="Open Selected Content"></nb-icon>
                    </button>
                  </div>
                  </div>
                </div>
              </div>
            <br />
          </form>
    </div>
  </nb-card-body>
  <nb-card-footer>
      <div class="row">
        <div class="col">
          <p>Deleting an Insight is <strong>irreversible</strong> and could possibly affect other campaigns.</p>
          <label>Type "DELETE" to confirm deletion</label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <input
            id="confirmation"
            name="confirmation"
            placeholder="DELETE"
            type="string"
            class="form-control form-control-sm"
            [(ngModel)]="confirmation"
            required
            nbInput
            style="width: 100%"
          />
        </div>
      </div>
    <div class="d-flex flex-row-reverse">
        <button
          nbButton
          status="success"
          [disabled]="confirmation.toLowerCase() !== 'delete'"
          (click)="deepDelete()">Delete</button>
      <div class="p-2">
        <button nbButton (click)="close()">Close</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
