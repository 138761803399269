/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { QaServiceService } from 'src/app/core/services/qa-service.service';
import { SpinnerState } from 'src/app/shared/spinner/redux/spinner.state';
import { CampaignState } from '../../campaigns/redux';
import { LoadAllRobots, LoadOwnerRobots } from '../redux';
import { QaState } from '../redux/qa.state';
import { RobotParts } from './robot-parts-config';

@Component({
  selector: 'app-robot-health-dashboard',
  templateUrl: './robot-health-dashboard.component.html',
  styleUrls: ['./robot-health-dashboard.component.css']
})
export class RobotHealthDashboardComponent implements OnInit {
  @Select(QaState.robots) public data_allRobots$: Observable<any[]>;
  @Select(QaState.ownerRobots) public data_ownerRobots$: Observable<any[]>;
  @Select(SpinnerState) public loading: Observable<boolean>;
  @Select(CampaignState.campaign) public data_campaigns$: Observable<any[]>;

  public myRobots = [];
  public commonRobots = [];
  public robotSelected = false;
  public campaignSelected = false;
  public selectedRobot;
  public selectedCampaign;
  public selectableCampaigns = [];
  public robotParts_clone = [];
  public selectedParts: any[] = [];
  public counters = [];
  public submitButton = false;

  public spin;
  public robotParts;
  public showUnsupportedWarning = false;

  constructor(private store: Store, private service: QaServiceService) {
    if (this.store.selectSnapshot(QaState.robots).length < 1) {
      this.store.dispatch(new LoadAllRobots());
    }
    if (this.store.selectSnapshot(QaState.ownerRobots).length < 1) {
      this.store.dispatch(new LoadOwnerRobots());
    }
  }

  public ngOnInit(): void {
    this.data_allRobots$.subscribe((data) => (this.commonRobots = data));
    this.data_ownerRobots$.subscribe((data) => (this.myRobots = data));
    this.loading.subscribe((data) => (this.spin = data));
    this.data_campaigns$.subscribe((data) => {
      this.selectableCampaigns = data.filter((a) => a.campaign_type === 'RobotHealthNotification');
    });
  }

  public groupByFn(myRobots: any[]) {
    return (item) => {
      if ('pmap_id' in item) {
        return 'KOZR Robots';
      }
      if (myRobots.includes(item)) {
        return 'My Robots';
      }

      return 'Common Robots';
    };
  }

  public resetSelectedData() {
    // restart the state
    this.counters = [];
    this.selectedParts = [];
    this.robotParts_clone.map((e) => {
      e.counter = 0;
      e.minutes_remaining = 0;
      e.minutes_used = 0;

      return e;
    });
  }

  public onRobotSelect(selectBot) {
    //TO-DO. Once we get access to Content API we should fetch parts from API instead from helper config file
    if (selectBot) {
      const sku = selectBot.sku.toLowerCase();
      if (sku.includes('s9') || sku.includes('i7') || sku.includes('j7') || sku.includes('i3') || sku.includes('i1')) {
        this.robotParts = RobotParts[RobotParts.findIndex((item) => item.series === sku.substring(0, 2))].parts;
        this.showUnsupportedWarning = false;
      } else {
        this.robotParts = [];
        this.selectedParts = [];
        this.counters = [];
        this.robotParts_clone = [];
        this.showUnsupportedWarning = true;
      }
    }

    this.selectedRobot = selectBot;
    this.resetSelectedData();

    //  display part selection section
    if (selectBot !== null) {
      this.robotSelected = true;
    } else {
      this.robotSelected = false;
    }

    // clone part data for display purposes
    this.robotParts_clone = JSON.parse(JSON.stringify(this.robotParts));
    this.robotParts_clone.map((e) => {
      e.counter = 0;
      e.minutes_remaining = 0;
      e.minutes_used = 0;

      return e;
    });
  }

  public onCampaignSelect(campaign) {
    this.selectedCampaign = campaign;
    if (campaign) {
      this.campaignSelected = true;
    } else {
      this.campaignSelected = false;
    }
    this.resetSelectedData();
  }

  public onPartSelect(selectedParts) {
    // display part cards in view
    selectedParts.map((item) => {
      item.counter ? (item.counter = item.counter) : (item.counter = 0);
      item.minutes_remaining ? (item.minutes_remaining = item.minutes_remaining) : (item.minutes_remaining = 0);
      item.minutes_used ? (item.minutes_used = item.minutes_used) : (item.minutes_used = 0);
    });
    this.selectedParts = selectedParts;

    //reset the counter
    const results = this.robotParts_clone.filter(
      ({ part_id: id1 }) => !this.selectedParts.some(({ part_id: id2 }) => id2 === id1)
    );
    results.map((e) => {
      e.counter = 0;
      e.minutes_remaining = 0;
      e.minutes_used = 0;

      return e;
    });

    // update the payload
    this.counters = selectedParts.map((e) => ({
      part_name: e.part_name,
      counter: e.counter,
      part_id: e.part_id,
      minutes_remaining: e.minutes_remaining,
      minutes_used: e.minutes_used
    }));

    // only allow notifications when parts are selected
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    selectedParts.length > 0 ? this.showSubmit(true) : this.showSubmit(false);
  }

  public showSubmit(boolean) {
    this.submitButton = boolean;
  }

  public onSubmit() {
    this.onPartSelect(this.selectedParts);
    this.service.submitRobotHealthAmplify(this.selectedRobot.id, this.selectedCampaign.id, this.counters);
  }

  public clear() {
    this.robotParts = [];
    this.selectedParts = [];
    this.counters = [];
    this.robotParts_clone = [];
    this.robotSelected = false;
    this.campaignSelected = false;
    this.showUnsupportedWarning = false;
  }
}
