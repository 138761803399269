/* eslint-disable import/no-deprecated */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { API } from '@aws-amplify/api';
import { Amplify } from '@aws-amplify/core';
import { LoadCampaign } from 'src/app/components/campaigns/redux';
import { LoadInsightsSuccess } from 'src/app/components/insights/redux/insights.actions';
import { SetIsLogedIn } from 'src/app/shared/redux/router.action';
import { environment } from 'src/environments/environment';
import { config } from '../../../environments/environment';
import { TimestampDatePipe } from '../../shared/custom-pipe/timestamp-date.pipe';
import { LoadContent } from './../../components/content/redux/content.actions';
import { LoadSurveys } from './../../components/surveys/redux/surveys.actions';
import { LoadDrb } from 'src/app/components/drbs/redux/drb.actions';
import { LoadMetadata } from 'src/app/components/robot-family-metadata/redux';

@Injectable({
  providedIn: 'root'
})
export class AmplifyService {
  public flag = true;
  constructor(private store: Store, private router: Router, private formatDatePipe: TimestampDatePipe) {}

  public async getAPis() {
    API.get('development', `/${environment.stage}/config-s3`, '')
      .then((response) => {
        response.forEach((element) => {
          config.API.endpoints.push(element);
          Amplify.configure(config);
        });
        if (this.flag) {
          this.insightGet();
        }
      })
      .catch((error) => {
        console.error('Error occured ', error);
      });
  }

  public insightGet() {
    this.flag = false;
    const myInit = {
      queryStringParameters: { enabled: true }
    };
    API.get('insights_api', `/${environment.stage}/registrations`, myInit)
      .then((response) => {
        response.forEach((element) => {
          element.local_created_dttm = this.formatDatePipe.transform(element?.created_dttm, 'timestampToDate');
          element.local_last_updated_dttm = this.formatDatePipe.transform(
            element?.last_updated_dttm,
            'timestampToDate'
          );
          element.local_start_date = this.formatDatePipe.transform(element?.start_date, 'timestampToDate');
          element.local_end_date = this.formatDatePipe.transform(element?.end_date, 'timestampToDate');
          element.local_next_run_time = this.formatDatePipe.transform(element?.next_run_time, 'timestampToDate');
          element.local_last_run_time = this.formatDatePipe.transform(element?.last_run_time, 'timestampToDate');
        });
        this.store.dispatch(new LoadInsightsSuccess(response));
        this.store.dispatch(new SetIsLogedIn(true));
        this.router.navigate(['/insights/active'], { replaceUrl: true });
        this.loadInitialData();
      })
      .catch((error) => {
        console.error('Error occured', error);
      });
  }

  public loadInitialData() {
    this.store.dispatch(new LoadCampaign());
    this.store.dispatch(new LoadContent());
    this.store.dispatch(new LoadSurveys());
    this.store.dispatch(new LoadMetadata());
  }
}
