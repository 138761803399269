<app-spinner *ngIf="spin"></app-spinner>
<nb-card style="height: 700px" *ngIf="!spin">
  <nb-card-header>
    <h3 style="position: relative">Robot Health Dashboard</h3>
  </nb-card-header>
  <nb-card-body>
    <div style="height: 700px; padding: 1.143em; margin: -1.143em">
      <div class="container" style="max-width: 900px">
        <div style="margin: 10px">
          <ng-container>
            <div class="formgroupmodal">
              <div class="row">
                <div class="col-auto">
                  <div>
                    <h4>Select a robot:</h4>
                    <h6 *ngIf="robotSelected !== false">
                      Current selection: {{ selectedRobot?.sku | slice: 0:2 }} series
                    </h6>
                    <br />
                    <div class="row">
                      <div class="col-auto" style="width: 530px">
                        <ng-select
                          ngDefaultControl
                          [(ngModel)]="selectedRobot"
                          placeholder="Select the robot"
                          (change)="onRobotSelect($event)"
                          [groupBy]="groupByFn(myRobots)"
                          (clear)="clear()"
                        >
                          <ng-container>
                            <ng-option *ngFor="let p of myRobots" [value]="p"> {{ p.name }} - {{ p.id }} </ng-option>
                            <ng-option *ngFor="let p of commonRobots" [value]="p">
                              {{ p.name }} - {{ p.id }}
                            </ng-option>
                          </ng-container>
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div *ngIf="robotSelected">
                    <div>
                      <div class="row">
                        <div class="col-auto" style="width: 530px">
                          <ng-select
                            [(ngModel)]="selectedCampaign"
                            ngDefaultControl
                            placeholder="Select the Campaign"
                            (change)="onCampaignSelect($event)"
                          >
                            <ng-container>
                              <ng-option *ngFor="let c of selectableCampaigns" [value]="c">
                                {{ c.name | replaceunderscore | titlecase }}
                              </ng-option>
                            </ng-container>
                          </ng-select>
                        </div>
                      </div>
                    </div>
                    <br />
                  </div>
                  <div *ngIf="robotSelected && campaignSelected">
                    <!-- <button
                      size="small"
                      status="info"
                      nbButton
                      (click)="showPartSelect()"
                      outline
                    >
                      Add Part
                    </button> -->
                    <div>
                      <div class="row">
                        <div class="col-auto" style="width: 530px">
                          <ng-select
                            [(ngModel)]="selectedParts"
                            ngDefaultControl
                            [multiple]="true"
                            placeholder="Add Robot Parts"
                            (change)="onPartSelect($event)"
                          >
                            <ng-container>
                              <ng-option *ngFor="let p of robotParts_clone" [value]="p">
                                {{ p.part_name | replaceunderscore | titlecase }}
                              </ng-option>
                            </ng-container>
                          </ng-select>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div *ngFor="let p of selectedParts; let i = index">
                      <nb-card style="max-height: 200px; padding: 5px">
                        <nb-card-header>
                          <h5>
                            {{ p.part_name | replaceunderscore | titlecase }}
                          </h5>
                        </nb-card-header>
                        <nb-card-body>
                          <div class="row">
                            <div class="col-auto">
                              <p>Counter:</p>
                              <input
                                type="number"
                                nbInput
                                step="1"
                                min="0"
                                max="100"
                                onkeydown="return false;"
                                [(ngModel)]="selectedParts[i].counter"
                                ngDefaultControl
                                name="selectedParts[i].counter"
                              />
                            </div>

                            <div class="col-auto">
                              <p>Remaining Part Health Minutes:</p>
                              <input
                                type="number"
                                nbInput
                                step="5"
                                min="0"
                                onkeydown="return false;"
                                [(ngModel)]="selectedParts[i].minutes_remaining"
                                ngDefaultControl
                                name="selectedParts[i].minutes_remaining"
                              />
                            </div>

                            <div class="col-auto">
                              <p>Part Health Minutes Used:</p>
                              <input
                                type="number"
                                nbInput
                                step="5"
                                min="0"
                                onkeydown="return false;"
                                [(ngModel)]="selectedParts[i].minutes_used"
                                ngDefaultControl
                                name="selectedParts[i].minutes_used"
                              />
                            </div>
                          </div>
                        </nb-card-body>
                      </nb-card>
                      <br />
                    </div>
                  </div>
                  <div style="margin-top: 10px">
                    <button
                      *ngIf="robotSelected && campaignSelected"
                      class="btn btn-primary"
                      (click)="onSubmit()"
                      [disabled]="!submitButton"
                    >
                      Submit
                    </button>
                  </div>

                  <div *ngIf="showUnsupportedWarning" class="validation-summary">
                    <p>{{ selectedRobot?.sku | slice: 0:2 }} series is not supported by tool</p>
                    <p>Currently supported (i1, i3, i7, j7, s9)</p>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>
