import { Component, OnInit } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { Actions, ofActionCompleted, ofActionDispatched, Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UtilityService } from 'src/app/core/services/utility.service';
import { OktaRestriction } from 'src/app/shared/okta-restrictions/okta-restrictions';
import { RouterState } from 'src/app/shared/redux';
import {
  BuildDrb,
  BuildDrbFail,
  BuildDrbSuccess,
  DrbState,
  LoadDiff,
  LoadDiffFail,
  LoadDiffSuccess,
  NoDrbDiff
} from '../redux';
import { environment } from 'src/environments/environment';
import { SpinnerState } from 'src/app/shared/spinner/redux/spinner.state';
import { NgxConfirmModalComponent } from 'src/app/shared/ngx-confirm-modal/ngx-confirm-modal.component';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-drb-diff',
  templateUrl: './drb-diff.component.html',
  styleUrls: ['./drb-diff.component.css']
})
export class DrbDiffComponent implements OnInit {
  @Select(RouterState.username) public data_username$: Observable<any>;
  @Select(DrbState.diff) public diff$: Observable<any>;
  @Select(DrbState.deploying) public deploying$: Observable<any>;
  @Select(DrbState.translating) public translating$: Observable<any>;
  @Select(SpinnerState) public loading: Observable<boolean>;

  public spin;
  public diff = {
    diff: null,
    owner: null,
    draft_version: null,
    candidate_version: null,
    last_successful_version: null,
    draft_status: null,
    candidate_status: null
  };
  public diffXliff;
  public diffSources;
  public username;
  public allowDeploy: boolean;
  public deploying;
  public environment: string = environment.stage;
  public noDrb = true;
  public translating;
  public successStatus = ['BUILD_SUCCEEDED', 'DEPLOY_SUCCEEDED_PROD', 'DEPLOY_SUCCEEDED_STAGE'];
  public failStatus = ['BUILD_FAILED', 'DEPLOY_FAILED_PROD', 'DEPLOY_FAILED_STAGE'];
  public startStatus = ['BUILD_STARTED', 'DEPLOY_STARTED_PROD', 'DEPLOY_STARTED_STAGE'];
  public bundle_name = '';

  constructor(
    private store: Store,
    private router: Router,
    private actions$: Actions,
    public utility: UtilityService,
    private dialogService: NbDialogService,
    private restrictionService: OktaRestriction
  ) {}

  public ngOnInit() {
    this.detectRouteChanges();
    this.bundle_name = this.router.url.split('/')[2];
    this.loadTableData();
    this.data_username$.subscribe((data) => (this.username = data));
    this.actions$.pipe(ofActionCompleted(LoadDiffSuccess)).subscribe((data) => {
      this.noDrb = false;
      this.diff = data.action.payload;
      if (data.action.payload.diff) {
        this.diffXliff = this.formatDiff(this.diff.diff.xliff.en);
        this.diffSources = [
          {
            sourceName: 'Files',
            diffItems: this.formatDiff(this.diff.diff.source.files)
          },
          {
            sourceName: 'Images',
            diffItems: this.formatDiff(this.diff.diff.source.images)
          },
          {
            sourceName: 'Links',
            diffItems: this.formatDiff(this.diff.diff.source.links)
          },
          {
            sourceName: 'Videos',
            diffItems: this.formatDiff(this.diff.diff.source.videos)
          }
        ];

        if (
          environment.stage === 'prod' &&
          this.username === this.diff.owner &&
          this.restrictionService.drb_manager_permission
        ) {
          this.allowDeploy = true;
        }

        if (environment.stage === 'stage' && this.username === this.diff.owner) {
          this.allowDeploy = true;
        }
      }
    });

    this.actions$.pipe(ofActionDispatched(BuildDrb)).subscribe((data) => {
      if (data) {
        this.allowDeploy = false;
      }
    });
    this.actions$.pipe(ofActionCompleted(BuildDrbFail, BuildDrbSuccess)).subscribe((data) => {
      if (data) {
        this.allowDeploy = true;
      }
    });

    this.deploying$.subscribe((data) => (this.deploying = data));

    this.loading.subscribe((data) => (this.spin = data));

    this.translating$.subscribe((data) => (this.translating = data));

    this.actions$.pipe(ofActionCompleted(LoadDiffFail, NoDrbDiff)).subscribe((data) => {
      this.diff = {
        diff: null,
        owner: null,
        draft_version: null,
        candidate_version: null,
        last_successful_version: null,
        draft_status: null,
        candidate_status: null
      };
    });
  }

  public deployNewDrb() {
    this.dialogService
      .open(NgxConfirmModalComponent, {
        context: {
          title: 'Deploy DRB' + ' version ' + this.diff.draft_version,
          yesButton: 'Deploy',
          noButton: 'Cancel',
          message:
            'Before deploying please confirm that you have verified all the changes and understand there will be no rollbacks.' +
            '\n\n' +
            'To deploy, type CONFIRM below and click DEPLOY'
        }
      })
      .onClose.subscribe((data) => {
        this.store.dispatch(new BuildDrb(this.bundle_name));
      });
  }

  public loadTableData() {
    this.store.dispatch(new LoadDiff(this.bundle_name));
  }

  public trackByIndex(index: number, obj: any): any {
    return index;
  }

  public formatDiff(diff) {
    return {
      added: this.utility.formatDictToObject(diff.added),
      updated: this.formatUpdatedDiff(diff.updated),
      removed: this.utility.formatDictToObject(diff.removed)
    };
  }

  public formatUpdatedDiff(data) {
    const formatedData = new Array();
    const files = Object.entries(data).map(([key, values]) => ({ id: key, ...(values as {}) }));
    files.forEach((element) => {
      const change = Object.entries(element).map(([key, values]) =>
        key !== 'id'
          ? {
              id: element.id,
              attribute: key,
              ...(values as {})
            }
          : null
      );
      change.forEach((el) => {
        if (el) {
          formatedData.push(el);
        }
      });
    });

    return formatedData;
  }

  public getStatusClass(): string {
    if (
      this.successStatus.includes(this.diff.draft_status) ||
      this.successStatus.includes(this.diff.candidate_status)
    ) {
      return 'added-background';
    } else if (
      this.failStatus.includes(this.diff.draft_status) ||
      this.failStatus.includes(this.diff.candidate_status)
    ) {
      return 'removed-background';
    } else if (
      this.startStatus.includes(this.diff.draft_status) ||
      this.startStatus.includes(this.diff.candidate_status)
    ) {
      return 'changed-background';
    } else {
      return '';
    }
  }

  public isDeployFailed(): boolean {
    return this.failStatus.includes(this.diff.draft_status) || this.failStatus.includes(this.diff.candidate_status);
  }

  public getDraftOrCandidateVersion(): string {
    if (this.environment === 'stage') {
      return this.diff.draft_version;
    } else if (this.environment === 'prod') {
      return this.diff.candidate_version;
    } else {
      return '';
    }
  }

  public getDraftOrCandidateStatus(): string {
    if (this.environment === 'stage') {
      return this.diff.draft_status;
    } else if (this.environment === 'prod') {
      return this.diff.candidate_status;
    } else {
      return '';
    }
  }

  public getLatestSuccessfulVersionLabel(): string {
    if (this.environment === 'stage') {
      return 'Last Successful Stage Version: ';
    } else if (this.environment === 'prod') {
      return 'Last Successful Prod Version: ';
    } else {
      return '';
    }
  }

  public getDraftOrCandidateVersionLabel(): string {
    if (this.environment === 'stage') {
      return 'Draft Version: ';
    } else if (this.environment === 'prod') {
      return 'Last Successful Stage Version: ';
    } else {
      return '';
    }
  }

  public getTitleAndSubtitle(): string {
    if (this.environment === 'stage') {
      return (
        'Validate, build and deploy' +
        '\n' +
        'Verify differences between the last successful DRB version and the draft version'
      );
    } else if (this.environment === 'prod') {
      return 'Deploy' + '\n' + 'Verify differences between last successful Prod and Stage version';
    } else {
      return '';
    }
  }

  public getNoDiffMessage(): string {
    const textSnip = this.environment === 'prod' ? 'Prod and Stage' : 'Stage and Draft';

    return 'There are no differences between last successful ' + textSnip + ' version.';
  }

  private detectRouteChanges(): void {
    this.router.events.subscribe((event) => {
      if(this.router.url.split('/')[1] === 'drb' &&
        this.bundle_name !== this.router.url.split('/')[2] &&
        event instanceof NavigationEnd) {
        this.bundle_name = this.router.url.split('/')[2];
        this.loadTableData();
      }
    });
  }
}
