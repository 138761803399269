<nb-card
  accent="success"
  style="width: 90%; height: 90%; min-width: 700px; max-height: 800px; max-width: 1400px; margin: auto"
>
  <nb-card-header>
    <div class="row">
      <div class="col-auto mr-auto" *ngIf="!insight.id">
        <nb-icon icon="plus-outline"></nb-icon>
      </div>
      <div class="col-auto mr-auto" *ngIf="insight.id">
        <nb-icon icon="edit-2-outline"></nb-icon>
      </div>
      <div class="col-auto mr-auto" *ngIf="!insight.id">Create New Insight</div>
      <div class="col-auto mr-auto" *ngIf="insight.id">Insight: {{ insight.id }}</div>
      <div class="col-auto"><button nbButton (click)="close()">X</button></div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div style="height: 700px; padding: 1.143em; margin: -1.143em">
      <strong class="text-uppercase">Insight Details</strong>
      <hr />
      <form #insightForm="ngForm">
        <div class="row">
          <div class="col">
            <label>Insight Category<span style="color: red">*</span>:</label>
          </div>
          <div class="col-9">
            <ng-select
              id="category"
              name="category"
              placeholder="Select Category"
              [(ngModel)]="insight.category"
              required
              #category="ngModel"
            >
              <ng-option *ngFor="let category of categories" [value]="category">
                {{ category | replaceunderscore | titlecase }}</ng-option
              >
            </ng-select>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col">
            <label>Insight Name<span style="color: red">*</span>:</label>
          </div>
          <div class="col-9">
            <input
              id="name"
              ngbAutofocus
              [(ngModel)]="insight.name"
              class="form-control form-control-sm"
              type="text"
              placeholder="Insight Name"
              name="name"
              #name="ngModel"
              required
              nbInput
              style="width: 100%"
              appValidInsightRegex="^[a-zA-Z0-9][a-z\d\-_:\s]*$"
            />
            <div *ngIf="(name?.touched || name?.dirty) && name?.errors?.required" class="error">
              Name is a required field
            </div>
            <div *ngIf="name.errors?.validInsightRegex" class="error">
              The first character must be alphanumeric, and up to 128 letters (uppercase and lowercase), numbers,
              hyphens, and underscores are allowed.
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col"><label>Description:</label></div>
          <div class="col-9">
            <textarea
              id="description"
              name="description"
              [(ngModel)]="insight.description"
              nbInput
              fullWidth
              placeholder="Description"
              style="width: 100%"
            ></textarea>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col"><label>Trigger Query</label></div>
          <div class="col-9">
            <!-- <p>
              <i
                >Press F11 when cursor is in the editor to toggle full screen
                editing. F11 can also be used to exit full screen editing.</i
              >
            </p>
            <br /> -->
            <ngx-codemirror
              #codeeditor
              id="query"
              name="query"
              [(ngModel)]="insight.query"
              [options]="{
                lineNumbers: true,
                theme: 'default',
                mode: 'sql'
              }"
            ></ngx-codemirror>
          </div>
        </div>
        <br />
        <strong class="text-uppercase">Actions Details</strong>
        <hr />
        <div class="row">
          <div class="col"><label>Activated</label></div>
          <div class="col-9">
            <nb-checkbox
              id="enabled"
              name="enabled"
              (checkedChange)="checkedActivated()"
              [(ngModel)]="insight.enabled"
            ></nb-checkbox>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col"><label>Schedule</label></div>
          <div class="col-9">
            <nb-select
              placeholder="Select Frequency"
              [(ngModel)]="insight.schedule_seconds"
              id="schedule_seconds"
              name="schedule_seconds"
              style="width: 100%"
            >
              <nb-option *ngFor="let frequency of FREQUENCIES" [value]="frequency.value">
                {{ frequency.label }}
              </nb-option>
            </nb-select>
          </div>
        </div>
        <br />
      </form>
      <div *ngIf="showDatepicker" class="row">
        <div class="col"><label>Start Time:</label></div>
        <div class="col-9">
          <mat-form-field appearance="outline">
            <mat-label>Choose a date</mat-label>
            <input
              matInput
              required
              [min]="minStartDate"
              (dateChange)="changeEvent()"
              [matDatepicker]="picker"
              [(ngModel)]="dateForDatepickerStart"
            />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker type="datetime">
              <mat-datepicker-actions>
                <div class="d-flex flex-row-reverse">
                  <div class="p-2">
                    <button nbButton status="success" matDatepickerApply>Apply</button>
                  </div>
                  <div class="p-2">
                    <button nbButton matDatepickerCancel>Cancel</button>
                  </div>
                </div>
              </mat-datepicker-actions>
            </mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <br />

      <div *ngIf="showDatepicker" class="row">
        <div class="col"><label>End Time:</label></div>
        <div class="col-9">
          <mat-form-field appearance="outline">
            <mat-label>Choose a date</mat-label>
            <input matInput [min]="minEndDate" required [matDatepicker]="picker2" [(ngModel)]="dateForDatepickerEnd" />
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2 type="datetime">
              <mat-datepicker-actions>
                <div class="d-flex flex-row-reverse">
                  <div class="p-2">
                    <button nbButton status="success" matDatepickerApply>Apply</button>
                  </div>
                  <div class="p-2">
                    <button nbButton matDatepickerCancel>Cancel</button>
                  </div>
                </div>
              </mat-datepicker-actions>
            </mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <br />
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div class="d-flex flex-row-reverse">
      <div class="p-2">
        <button nbButton status="success" (click)="save()" [disabled]="!insightForm.form.valid">Save</button>
      </div>
      <div class="p-2">
        <button nbButton (click)="close()">Close</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
