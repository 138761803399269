import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Actions, Store } from '@ngxs/store';

@Component({
  selector: 'app-ngx-confirm-modal',
  templateUrl: './ngx-confirm-modal.component.html',
  styleUrls: ['./ngx-confirm-modal.component.css']
})
export class NgxConfirmModalComponent implements OnInit {
  public title: string;
  public message: string;
  public yesButton = 'Save';
  public noButton = 'Cancel';
  public userInput;
  constructor(protected ref: NbDialogRef<NgxConfirmModalComponent>, private store: Store, private actions$: Actions) {}

  public ngOnInit() {}

  public clickedYes() {
    this.ref.close(true);
  }

  public clickedNo() {
    this.ref.close(false);
  }
}
