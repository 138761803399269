<nb-card
  accent="success"
  style="
    width: 90%;
    height: 90%;
    min-height: 340px;
    min-width: 600px;
    max-height: 450px;
    max-width: 900px;
    margin: auto;
  "
>
  <nb-card-header>
    <div class="row">
      <div class="col-auto mr-auto">
        <nb-icon icon="question-mark-circle-outline"></nb-icon>
      </div>
      <div class="col-auto mr-auto">{{ title }}</div>
      <div class="col-auto">
        <button nbButton (click)="clickedNo()">X</button>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div style="white-space: pre-line">{{ message }}</div>
    <br />
    <div class="row">
      <div class="col-3">
        <input
          id="deploy"
          name="deploy"
          nbInput
          fullWidth
          placeholder="CONFIRM"
          style="width: 100%"
          [(ngModel)]="userInput"
        />
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div class="d-flex flex-row-reverse">
      <div class="p-2">
        <button nbButton status="success" (click)="clickedYes()" [disabled]="userInput !== 'CONFIRM'">
          {{ yesButton }}
        </button>
      </div>
      <div class="p-2">
        <button nbButton status="danger" (click)="clickedNo()">
          {{ noButton }}
        </button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
