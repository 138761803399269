import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { InsightsState } from 'src/app/components/insights/redux/insights.state';
import { KozrService } from 'src/app/core/services/kozr.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { Campaign } from 'src/app/models/campaign';
import { Insight } from 'src/app/models/insight';
import { SpinnerState } from 'src/app/shared/spinner/redux/spinner.state';
import { CampaignState } from '../../../campaigns/redux';
import { QaState } from '../../redux';
@Component({
  selector: 'app-kozr-bots',
  templateUrl: './kozr-bots.component.html',
  styleUrls: ['./kozr-bots.component.css']
})
export class KozrBotsComponent implements OnInit {
  @Select(InsightsState.insightsActive) public data_insights$: Observable<any[]>;
  @Select(CampaignState.campaign) public data_campaigns$: Observable<any[]>;
  @Select(QaState.kozrs) public data_kozrs$: Observable<any[]>;
  @Select(SpinnerState) public loading: Observable<boolean>;
  public spin;
  public formRobotData;

  public selectedKozrs: any[] = []; // selected robots
  public kozrBots = []; // kozrbots
  public insights: Insight[];
  public campaigns: Campaign[];
  public selectedCampaign = {};
  public kozrCampaigns = [];
  public kozrs_selected = false;
  public kozr_campaigns_enabled = false;

  public submitBtn_show = false;

  constructor(
    public kozrService: KozrService,
    public utilityService: UtilityService
  ) {
      //Do not delete utilityService, it is used in HTML for time converting. Without it it will brake component
  }

  public sortingByDate(a, b) {
    if (a.created_dttm < b.created_dttm) {
      return -1;
    }
    if (a.created_dttm > b.created_dttm) {
      return 1;
    }

    return 0;
  }

  public ngOnInit() {
      this.data_kozrs$.subscribe((data) => (this.kozrBots = data));
      this.data_insights$.subscribe((data) => (this.insights = data));
      this.data_campaigns$.subscribe((data) => {
          this.campaigns = data.slice().sort(this.sortingByDate);
      });
      this.loading.subscribe((data) => (this.spin = data));
  }

  public testUpdate() {
      //Called every time that the input properties of a component or a directive are checked. Use it to extend change detection by performing a custom check.
      //Add 'implements DoCheck' to the class.

      this.onKozrSelect();
      if (this.kozrs_selected) {
          this.kozr_campaigns_enabled = true;
      } else {
          this.kozr_campaigns_enabled = false;
      }

      if (this.campaigns.length > 0) {
          this.campaigns.map((camp) => {
              const trigger = this.insights.find(
                  (element) => element.id === Number(camp.trigger_id)
              );
              if (!trigger) {return;}
              if (trigger.category === 'KOZ_recommendation') {
                  if (this.kozrCampaigns.length === 0) {
                      this.kozrCampaigns.push(camp);
                  } else {
                      let nameExistant = true;

                      for (const campaign of this.kozrCampaigns) {
                          if (campaign.name !== camp.name) {
                              nameExistant = false;
                          } else {
                              nameExistant = true;
                              break;
                          }
                      }
                      if (!nameExistant) {
                          this.kozrCampaigns.push(camp);
                      }
                  }
              }
          });
      }

      this.submitBtn_show = this.showKozrSubmit();
  }

  /******************************************************
   * Logs the selected KOZR object
   *****************************************************/
  public onKozrSelect() {
    this.kozrService.isPublished = '';
    if (this.selectedKozrs.length > 0) {
      this.kozrs_selected = true;
    } else {
      this.kozrs_selected = false;
    }
  }

  /******************************************************
   * Submit button enabling functions for KOZR robots
   *****************************************************/

  public showKozrSubmit() {
      let show = false;
      if (this.kozrs_selected) {
          this.selectedKozrs.forEach((bot) => {
              if (this.selectedCampaign[bot.robotid] !== undefined) {
                  show = true;
              }
          });
      }

      return show;
  }

  /******************************************************
   * Submission method for Keep Out Zone Recommendations
   *****************************************************/
  public submitKozRecommendations() {
      this.selectedKozrs.forEach((robot) =>
          this.kozrService.submitKozRecommendation(
              this.selectedCampaign[robot.robotid],
              robot
          )
      );
  }
}
