<nb-layout>
  <nb-layout-header fixed *ngIf="isLogin">
    <ngx-header></ngx-header>
  </nb-layout-header>

  <nb-sidebar *ngIf="isLogin">
    <ngx-pages></ngx-pages>
  </nb-sidebar>

  <nb-layout-column>
    <router-outlet></router-outlet>
  </nb-layout-column>

  <nb-layout-footer fixed *ngIf="isLogin">
    <ngx-footer></ngx-footer>
  </nb-layout-footer>
</nb-layout>
