/* eslint-disable @typescript-eslint/no-shadow */
import { Component, OnDestroy, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { Actions, Select, ofActionSuccessful } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoadCampaignSuccess } from '../../../redux/campaign.actions';
import { CampaignState } from '../../../redux/campaign.state';
import { ViewCampaignComponent } from '../view-campaign.component';

@Component({
  selector: 'app-modal-container',
  template: ''
})
export class ModalContainerComponent implements OnDestroy, OnInit {
  // This is only used when you enter URL of specific campaign with ID.

  @Select(CampaignState.campaign) public data$: Observable<any[]>;

  public destroy = new Subject<any>();
  public currentDialog = null;
  public tableData;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: NbDialogService,
    private actions$: Actions
  ) {}

  public ngOnInit() {
    this.data$.subscribe((data) => (this.tableData = data));
    this.route.params.pipe(takeUntil(this.destroy)).subscribe((params) => {
      const found = this.tableData.find((element) => element.id === params.id);
      if (found) {
        this.dialogService
          .open(ViewCampaignComponent, {
            closeOnEsc: false,
            closeOnBackdropClick: false,
            context: {
              campaign: found
            }
          })
          .onClose.subscribe((data) => {
            this.router.navigateByUrl('campaigns/table');
          });
      } else {
        this.actions$.pipe(ofActionSuccessful(LoadCampaignSuccess)).subscribe((data) => {
          const found = this.tableData.find((element) => element.id === params.id);
          this.dialogService
            .open(ViewCampaignComponent, {
              closeOnEsc: false,
              closeOnBackdropClick: false,
              context: {
                campaign: found
              }
            })
            .onClose.subscribe((data) => {
              this.router.navigateByUrl('campaigns/table');
            });
        });
      }
    });
  }

  public ngOnDestroy() {
    this.destroy.next('');
  }
}
