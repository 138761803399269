/* eslint-disable @typescript-eslint/dot-notation */
import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { Actions, ofActionCompleted, Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DrbService } from 'src/app/core/services/drb.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { Xliff } from 'src/app/models/drb-models/xliff';
import { NgxGenericModalComponent } from 'src/app/shared/ngx-generic-modal/ngx-generic-modal.component';
import { RouterState } from 'src/app/shared/redux/router-state';
import { SpinnerState } from 'src/app/shared/spinner/redux/spinner.state';
import { DrbState, LoadDrb, LoadDrbByVersionSuccess, SaveDrbState } from '../redux';
import { EditXliffComponent } from './edit-xliff/edit-xliff.component';
import { ViewXliffComponent } from './view-xliff/view-xliff.component';
import { DeleteDrbComponent } from '../delete-drb/delete-drb.component';

@Component({
  selector: 'app-drb-xliff',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './drb-xliff.component.html',
  styleUrls: ['./drb-xliff.component.css']
})
export class DrbXliffComponent implements OnInit, AfterViewInit {
  @Select(DrbState.drb) public drb$: Observable<any>;
  @Select(DrbState.view_drb) public view_drb$: Observable<any[]>;
  @Select(DrbState.xliff) public data$: Observable<any[]>;
  @Select(DrbState.view_xliff) public view_data$: Observable<any[]>;
  @Select(DrbState.translating) public translating$: Observable<any[]>;
  @Select(SpinnerState) public loading: Observable<boolean>;
  @Select(RouterState.username) public data_username$: Observable<any>;

  public tableData;
  public displayNewButton = true;
  public spin;
  public mode;
  public metaData;
  public username;
  public description;
  public type = 'string';
  public translating;
  public allKeys: string[] = [];
  public bundle_name: string;

  constructor(
    private router: Router,
    private actions$: Actions,
    private service: DrbService,
    private store: Store,
    private dialogService: NbDialogService,
    public utility: UtilityService
  ) {}

  public ngAfterViewInit() {
    if (this.store.selectSnapshot(DrbState.xliff).length > 1) {
      this.service.buildKeysForTable(this.store.selectSnapshot(DrbState.xliff), this.allKeys, this.type);
    } else {
      const defaultKeys = [new Xliff()];
      this.service.buildKeysForTable(defaultKeys, this.allKeys, this.type);
    }
  }

  public ngOnInit() {
    this.bundle_name = this.router.url.split('/')[2];
    this.translating$.subscribe((data) => (this.translating = data));
    if (this.router.url.endsWith('create/strings')) {
      this.description = 'View, add and edit string data';
      this.drb$.subscribe((data) => {
        this.metaData = data;
      });
      this.data$.subscribe((data) => {
        this.tableData = data;
        this.service.buildKeysForTable(this.tableData, this.allKeys, this.type);
      });
      this.data_username$.subscribe((data) => (this.username = data));
      this.checkOwnerOrContributor();
    } else if (this.router.url.endsWith('view/strings')) {
      this.mode = 'view';
      this.displayNewButton = false;
      this.description = 'View string data';
      this.view_drb$.subscribe((data) => {
        this.metaData = data;
      });
      this.view_data$.subscribe((data) => {
        this.tableData = data;
        this.service.buildKeysForTable(this.tableData, this.allKeys, this.type);
      });

      this.actions$.pipe(ofActionCompleted(LoadDrbByVersionSuccess)).subscribe((data) => {
        if (data) {
          this.view_data$.subscribe((loadedData) => {
            this.tableData = loadedData;
            this.service.buildKeysForTable(this.tableData, this.allKeys, this.type);
          });
        }
      });
    }
    this.loading.subscribe((data) => (this.spin = data));
  }

  public newCreation() {
    this.newXliff();
  }

  public refreshTableData() {
    this.dialogService
      .open(NgxGenericModalComponent, {
        context: {
          title: 'Confirm Refresh',
          message: 'Are you sure that you want to refresh draft version?'
        }
      })
      .onClose.subscribe((data) => {
        if (data) {
          this.store.dispatch(new LoadDrb(this.bundle_name));
        }
      });
  }

  public clickedButtonTable($event) {
    if ($event.action === 'view') {
      this.viewXliff($event.data);
    }
    if ($event.action === 'edit') {
      this.editXliff($event.data);
    }
    if ($event.action === 'delete') {
      this.deleteXliff($event.data);
    }
  }

  public viewXliff(xliff: Xliff): void {
    this.dialogService.open(ViewXliffComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        xliff
      }
    });
  }

  public editXliff(xliff: Xliff): void {
    this.dialogService.open(EditXliffComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'Edit',
        xliff: JSON.parse(JSON.stringify(xliff)),
        bundle_name: this.bundle_name
      }
    });
  }
  public deleteXliff(xliff: Xliff): void {
    this.dialogService.open(DeleteDrbComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'Delete',
        item: JSON.parse(JSON.stringify(xliff)),
        type: this.type,
        bundle_name: this.bundle_name
      }
    });
  }

  public newXliff(): void {
    this.dialogService.open(EditXliffComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'New',
        xliff: new Xliff(),
        bundle_name: this.bundle_name
      }
    });
  }

  public checkOwnerOrContributor() {
    if (this.username === this.metaData.owner || this.metaData.contributors.includes(this.username)) {
      this.mode = 'edit';
      this.displayNewButton = true;
    } else {
      if (this.translating) {
        this.mode = 'edit';
        this.displayNewButton = false;
      } else {
        this.mode = 'view';
        this.displayNewButton = false;
      }
    }
  }

  public optForTranslation() {
    const localDrb = JSON.parse(JSON.stringify(this.metaData));

    localDrb.last_updated_by = this.username;
    localDrb.last_updated_ts = Math.floor(Date.now() / 1000);
    localDrb.translate = !this.metaData.translate;
    localDrb.bundle_name = this.bundle_name;

    this.store.dispatch(new SaveDrbState(localDrb));
  }

  public getNextMonday(date = new Date()) {
    const dateCopy = new Date(date.getTime());
    let nextMonday;
    if (dateCopy.getHours() < 16) {
      nextMonday = new Date(dateCopy.setDate(dateCopy.getDate() + ((7 - dateCopy.getDay() + 1) % 7)));
    } else {
      nextMonday = new Date(dateCopy.setDate(dateCopy.getDate() + ((7 - dateCopy.getDay() + 1) % 7 || 7)));
    }

    return nextMonday.getFullYear() + '/' + (nextMonday.getMonth() + 1) + '/' + nextMonday.getDate();
  }

  public getTwoWeeksFromNow(timestamp: number) {
    const date = timestamp + (86400 * 14);

    return this.utility.convertUnixTime_global(date);
  }
}
