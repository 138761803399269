<nb-card accent="success" style="width: 70%; margin: auto">
  <nb-card-header>
    <div class="row">
      <div class="col-auto mr-auto">
        <nb-icon icon="search-outline"></nb-icon>
      </div>
      <h4 class="col-auto mr-auto">Robot: {{ robot.name }}</h4>
      <div class="col-auto"><button nbButton (click)="close()">X</button></div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <h6 class="text-uppercase">Robot Details</h6>
    <dl class="row small">
      <dt class="col-3">ID</dt>
      <dd class="col-9">{{ robot.id }}</dd>
      <dt class="col-3">Name</dt>
      <dd class="col-9">{{ robot.name }}</dd>
      <dt class="col-3">Family</dt>
      <dd class="col-9">{{ robot.family }}</dd>
      <dt class="col-3">SKU</dt>
      <dd class="col-9">{{ robot.sku }}</dd>
      <dt class="col-3">Serial number</dt>
      <dd class="col-9" *ngIf="robot.serial_number">
        {{ robot.serial_number }}
      </dd>
      <dd class="col-9" *ngIf="!robot.serial_number">-</dd>
      <dt class="col-3">Registration Date</dt>
      <dd class="col-9">{{ robot.registration_date | format: "dttm" }}</dd>
      <dt class="col-3">Timezone</dt>
      <dd class="col-9">{{ robot.timezone }}</dd>
    </dl>
  </nb-card-body>
  <nb-card-footer>
    <div class="d-flex flex-row-reverse">
      <div class="p-2">
        <button nbButton status="info" (click)="close()">Close</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
