import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NbDateService, NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { Select, Store, Actions, ofActionCompleted } from '@ngxs/store';
import { Observable } from 'rxjs';
import { RobotFamilyMetadata } from 'src/app/models/robot-family-metadata';
import { NgxGenericModalComponent } from 'src/app/shared/ngx-generic-modal/ngx-generic-modal.component';
import { RouterState } from 'src/app/shared/redux';
import { SaveMetadataComplete, PostMetadata, UpdateMetadata, UpdateMetadataSuccess, DeleteMetadata, DeleteMetadataSuccess } from '../redux';

@Component({
  selector: 'app-edit-robot-family-metadata',
  templateUrl: './edit-robot-family-metadata.component.html',
  styleUrls: ['./edit-robot-family-metadata.component.css', '../../../shared/common_syles.css']
})
export class EditRobotFamilyMetadataComponent implements OnInit, AfterViewInit {
  @Select(RouterState.theme) public data$: Observable<any>;
  @Select(RouterState.username) public data_username$: Observable<any>;

  public theme = '';

  public robotFamilyMetadata: RobotFamilyMetadata;
  public allRFM: [RobotFamilyMetadata];
  public title: string;
  public username = '';
  public metadataForm: FormGroup;
  public metadataTypes = ['cbo'];
  public extentTypes = ['around_bed',
                         'around_sofa',
                         'around_coffee_table',
                         'around_dining_table',
                         'around_toilet',
                         'around_kitchen_island',
                         'rug',
                         'front_of_kitchen_counter',
                         'front_of_refrigerator',
                         'around_christmas_tree',
                         'around_pet_bowl',
                         'around_litter_box',
                         'front_of_oven',
                         'front_of_dishwasher'];

  constructor(
    private fb: FormBuilder,
    private store: Store,
    protected ref: NbDialogRef<EditRobotFamilyMetadataComponent>,
    private actions$: Actions,
    private dialogService: NbDialogService,
    protected dateService: NbDateService<Date>,
    private toastrService: NbToastrService
  ) {
    this.data$.subscribe((data) => (this.theme = data));
    this.metadataForm = this.fb.group({
      robot_family: ['', [Validators.required,Validators.pattern(/^[a-zA-Z0-9\:_-]*$/i)]],
      metadata_type: ['', Validators.required],
      metadata: this.fb.array([])
    }, {validators: [RobotFamilyMetadataValidator.validate_metadata()]});
  }
  public metadata(): FormArray {
    return this.metadataForm.get('metadata') as FormArray;
  }

  public ngOnInit(): void {
    this.data_username$.subscribe((data) => (this.username = data));
    if (this.title === 'Edit') {
      this.actions$.pipe(ofActionCompleted(UpdateMetadataSuccess)).subscribe((data) => {
        if (data) {
          this.ref.close();
        }
      });
      this.actions$.pipe(ofActionCompleted(DeleteMetadataSuccess)).subscribe((data) => {
        if (data) {
          this.ref.close();
        }
      });
    } else {
      this.actions$.pipe(ofActionCompleted(SaveMetadataComplete)).subscribe((data) => {
        if (data) {
          this.ref.close();
        }
      });
    }

    if (this.title === 'Edit' || this.title === 'Clone') {
      this.clearFormArray();
      this.robotFamilyMetadata.metadata.forEach((m) => {
        const metadata: FormGroup = this.newMetadata();
        this.metadata().push(metadata);
      });
      this.metadataForm.patchValue(this.robotFamilyMetadata);
    }
  }

  public ngAfterViewInit(): void {
    this.metadataForm.addValidators(RobotFamilyMetadataValidator.validate_key(this.allRFM, this.robotFamilyMetadata, this.title));
    this.metadataForm.updateValueAndValidity();
    }

  public newMetadata(): FormGroup {
    return this.fb.group({
      extent_type: ['',[Validators.required, Validators.pattern(/^[a-zA-Z0-9\:_-]*$/i)]],
      confidence: [0],
      priority: null
    });
  }

  public clearFormArray() {
    this.metadata().clear();
  }

  public addMetadata() {
    this.metadata().push(this.newMetadata());
  }

  public removeMetadata(qi: number) {
    this.metadata().removeAt(qi);
  }

  public save() {

    this.metadataForm.markAllAsTouched();
    if(this.metadataForm.valid) {
      const robot_family = this.metadataForm.get('robot_family').value;
      const metadata_type = this.metadataForm.get('metadata_type').value;
      const metadata = this.metadataForm.get('metadata').value;
      if (this.title === 'Edit') {

        this.robotFamilyMetadata.metadata = metadata;
        this.robotFamilyMetadata.last_updated_by = this.username;
        if(this.robotFamilyMetadata.robot_family !== robot_family || this.robotFamilyMetadata.metadata_type !== metadata_type) {

            const metadataToDelete = RobotFamilyMetadata.copy(this.robotFamilyMetadata);
            metadataToDelete.deleted = true;
            this.store.dispatch(new DeleteMetadata(metadataToDelete));
            this.robotFamilyMetadata.robot_family = robot_family;
            this.robotFamilyMetadata.metadata_type = metadata_type;
            this.store.dispatch(new PostMetadata(this.robotFamilyMetadata));
          }
          else {

            this.robotFamilyMetadata.metadata = metadata;
            this.store.dispatch(new UpdateMetadata(this.robotFamilyMetadata));
          }

      } else {
        this.robotFamilyMetadata.created_by = this.username;
        this.robotFamilyMetadata.last_updated_by = this.username;
        this.robotFamilyMetadata.robot_family = robot_family;
        this.robotFamilyMetadata.metadata_type = metadata_type;
        this.robotFamilyMetadata.metadata = metadata;
        this.store.dispatch(new PostMetadata(this.robotFamilyMetadata));
      }
    } else {

    }

  }

  public close() {
    this.dialogService
      .open(NgxGenericModalComponent, {
        context: {
          title: 'Confirm Close',
          message: 'Are you sure that you want to close the window? Any changes that you have made will be lost.'
        }
      })
      .onClose.subscribe((data) => {
        if (data) {
          this.ref.close();
        }
      });
  }

}

export class RobotFamilyMetadataValidator {

  public static validate_metadata(): ValidatorFn {
    return (form: FormGroup): ValidationErrors | null => {
      const md = form.get('metadata').value;

      if(md.length <= 0) {
        return {validate_metadata_exist:true};
      }

      const filtered_md =  md.filter(m => m.extent_type !== '');

      if (filtered_md.length > 0) {
        const duplicates = new Set();
        const isDuplicate = filtered_md.some((obj) => duplicates.size === duplicates.add(obj.extent_type).size);

        return isDuplicate ? {validate_metadata:true} : null;
      }

      return null;
    };
  }

  public static validate_key(allRFM: any, loadedMetadata: RobotFamilyMetadata, title: string): ValidatorFn {
    return (form: FormGroup): ValidationErrors | null => {
      const rf = form.get('robot_family').value;
      const mt = form.get('metadata_type').value;
      if(mt !== '' && rf !== '' && allRFM.length > 0) {
        if(title !== 'Edit') {
          const check = allRFM.find(m => m.robot_family === rf && m.metadata_type === mt) === undefined;

          if(!check) {
            return {validate_key:true};
          }
        } else if (loadedMetadata.robot_family !== rf) {
          return allRFM.find(m => m.robot_family === rf && m.metadata_type === mt) === undefined ? null : {validate_key:true};
        }
      }

      return null;
    };
  }

}
