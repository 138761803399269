import { DecimalPipe } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { NbDialogService } from '@nebular/theme';
import { Select, Store } from '@ngxs/store';
import { ContentService } from 'src/app/core/services/content.service';
import { Content } from 'src/app/models/content';
import { SpinnerState } from 'src/app/shared/spinner/redux/spinner.state';
import { OktaRestriction } from 'src/app/shared/okta-restrictions/okta-restrictions';
import { NgxGenericModalComponent } from 'src/app/shared/ngx-generic-modal/ngx-generic-modal.component';
import { EditContentComponent } from './edit-content/edit-content.component';
import { ContentState, DeleteContent, LoadContent, ReloadContent } from './redux';
import { ViewContentComponent } from './view-content/view-content.component';

@Component({
  selector: 'app-content',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css'],
  providers: [DecimalPipe]
})
export class ContentComponent implements OnInit, AfterViewInit {
  @Select(ContentState.content) public data$: Observable<any[]>;
  @Select(SpinnerState) public loading: Observable<boolean>;

  public spin;
  public tableData;
  public displayNewButton = false;

  constructor(
    private service: ContentService,
    private store: Store,
    private dialogService: NbDialogService,
    private restrictionService: OktaRestriction
  ) {
    if (this.store.selectSnapshot(ContentState.content).length < 1) {
      this.store.dispatch(LoadContent);
    }
  }

  public ngAfterViewInit() {
    if (this.store.selectSnapshot(ContentState.content).length > 1) {
      this.service.buildKeysForTable(this.store.selectSnapshot(ContentState.content));
    }
  }

  public ngOnInit() {
    this.data$.subscribe((data) => {
      this.tableData = data;
      this.service.buildKeysForTable(data);
    });
    this.loading.subscribe((data) => (this.spin = data));
    this.displayNewButton = this.restrictionService.manager_permission;
  }

  public clickedButtonTable($event) {
    if ($event.action === 'view') {
      this.viewContent($event.data);
    }
    if ($event.action === 'edit') {
      this.editContent($event.data);
    }
    if ($event.action === 'clone') {
      this.cloneContent($event.data);
    }
    if ($event.action === 'delete') {
      this.deleteContent($event.data);
    }
  }

  public newCreation() {
    this.newContent();
  }

  public refreshTableData() {
    this.store.dispatch(ReloadContent);
  }

  public newContent() {
    this.dialogService.open(EditContentComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'New',
        content: new Content()
      }
    });
  }

  public viewContent(content: Content) {
    this.dialogService.open(ViewContentComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        content
      }
    });
  }

  public editContent(content: Content) {
    this.dialogService.open(EditContentComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'Edit',
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        content: <Content>Content.copy(content)
      }
    });
  }

  public cloneContent(content: Content) {
    const clonedContent = Content.clone(content);
    this.dialogService.open(EditContentComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'Clone',
        content: clonedContent
      }
    });
  }

  public deleteContent(content: Content) {
    this.dialogService
      .open(NgxGenericModalComponent, {
        closeOnEsc: false,
        closeOnBackdropClick: false,
        context: {
          title: 'Are you sure you want to delete Content',
          message: content.content_id + ' - ' + content.headline_text
        }
      })
      .onClose.subscribe((data) => {
        if (data === true) {
          const contentToDelete = Content.copy(content);
          contentToDelete.deleted = true;
          this.store.dispatch(new DeleteContent(contentToDelete));
        }
      });
  }
}
