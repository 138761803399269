<nb-card>
  <nb-card-header>
    <h3 style="position: relative;">Timeline Notification</h3>
  </nb-card-header>
  <nb-card-body>
    <div>
      <nb-tabset fullWidth>
        <nb-tab tabTitle="Common Robots">
          <app-timeline-common-bots></app-timeline-common-bots>
        </nb-tab>
        <nb-tab tabTitle="KOZR Robots">
          <app-timeline-kozr-bots></app-timeline-kozr-bots>
        </nb-tab>
        <nb-tab tabTitle="My Robots">
          <app-timeline-my-bots></app-timeline-my-bots>
        </nb-tab>
      </nb-tabset>
    </div>
  </nb-card-body>
</nb-card>
