import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-failed-job-insight',
  templateUrl: './failed-job-insight.component.html',
  styleUrls: ['./failed-job-insight.component.css']
})
export class FailedJobInsightComponent implements OnInit {
  public failedJob: any;
  public showLogs = false;
  constructor(protected ref: NbDialogRef<FailedJobInsightComponent>) {}

  public ngOnInit(): void {
    this.showLogs = this.failedJob.job_logs.length === 0 ? false : true;
  }

  public close() {
      this.ref.close();
  }
}
