import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { HTARecommendation } from 'src/app/models/hta-recommendation';

@Component({
    selector: 'app-view-stats-hta',
    templateUrl: './view-stats-hta.component.html',
    styleUrls: ['./view-stats-hta.component.css']
})
export class ViewStatsHtaComponent implements OnInit {
  public htaRecommendation: HTARecommendation;

  constructor(protected ref: NbDialogRef<ViewStatsHtaComponent>) { }

  public ngOnInit() {
  }

  public close() {
      this.ref.close();
  }
}
