import { DrbSource } from './drb-source';
import { Xliff } from './xliff';

export class Drb {
  // DRB metadata
  public version: string;
  public created_ts: number;
  public created_by: string;
  public last_updated_ts: number;
  public last_updated_by: string;
  public status: string;
  public owner: string;
  public contributors: any[];
  public bundle_version: string;

  public xliff: {'en': Xliff[]};
  public source: DrbSource;

  public draft: boolean;
  public deployed_to_stage: boolean;
  public deployed_to_stage_ts: number;
  public deployed_to_prod: boolean;
  public deployed_to_prod_ts: number;

  // translations data
  public translate: boolean;
  public translation_status: string;
  public translations_submitted: boolean;
  public translations_submitted_ts: number;
  public translations_received: boolean;
  public translations_received_ts: number;
}
