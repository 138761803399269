<app-spinner *ngIf="spin"></app-spinner>
<div *ngIf="!spin && campaignsInput" class="container">
  <nb-card style="height: auto; padding: 5px">
    <nb-card-header>
      {{ this.utilityService.convertUnixTime_global(weeksTimeLimit, 'date') }} -
      {{ this.utilityService.convertUnixTime_global(currentTime / 1000, 'date') }}</nb-card-header
    >
    <nb-card-body>
      <div class="row" style="padding: 2%">
        <nb-card class="col" style="padding: 5px; border-radius: 2%; margin: 10px">
          <nb-card-header>Campaign executions by insight category:</nb-card-header>
          <nb-card-body>
            <chart type="pie" [data]="data_campaignsByType" [options]="data_campaignsByType.options"></chart>
          </nb-card-body>
        </nb-card>

        <nb-card class="col" style="padding: 5px; border-radius: 2%; margin: 10px">
          <nb-card-header>Notification count by campaign:</nb-card-header>
          <nb-card-body>
            <label class="row" style="font-weight: bold; position: relative; left: 10px"
              >Total campaigns run: {{ campNames.length }}</label
            >
            <hr />
            <label class="row" style="font-weight: bold; position: relative; left: 10px"
              >Total notification sum: {{ totalNotifications }}</label
            >
            <hr />
            <label class="row" style="font-weight: bold; position: relative; left: 10px"
              >Total execution sum: {{ totalExecutions }}</label
            >
            <hr />
            <label class="row" style="font-weight: bold; position: relative; left: 10px"
              >Total campaign responses: {{ totalResponses }}</label
            >
          </nb-card-body>
        </nb-card>
      </div>
      <div class="row" style="padding: 2%">
        <nb-card class="col" style="padding: 5px; border-radius: 2%; margin: 10px">
          <nb-card-header>Notification count by campaign:</nb-card-header>
          <nb-card-body>
            <chart
              type="pie"
              [data]="data_notificationsByCampaigns"
              [options]="data_notificationsByCampaigns.options"
            ></chart>
          </nb-card-body>
        </nb-card>

        <nb-card class="col" style="padding: 5px; border-radius: 2%; margin: 10px">
          <nb-card-header>Total responses by campaign:</nb-card-header>
          <nb-card-body>
            <chart type="pie" [data]="data_responsesByCampaigns" [options]="data_responsesByCampaigns.options"></chart>
          </nb-card-body>
        </nb-card>
      </div>
    </nb-card-body>
  </nb-card>
</div>
