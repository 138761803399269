/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { DrbService } from 'src/app/core/services/drb.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { TableSpinerReloadHide } from 'src/app/shared/ngx-table/ngx-table/redux/table-spinner.actions';
import { ToggleHide, ToggleShow } from 'src/app/shared/spinner/redux/spinner.actions';
import { DrbStateModel } from './drb-state.model';
import {
  BuildDrb,
  BuildDrbSuccess,
  LoadDiff,
  LoadDiffSuccess,
  LoadDrb,
  LoadDrbByVersion,
  LoadDrbByVersionSuccess,
  LoadDrbSuccess,
  LoadDrbVersions,
  LoadDrbVersionsSuccess,
  SaveComplete,
  SaveFail as SaveFail,
  ReloadDrb,
  ReloadDrbSuccess,
  SaveDrbState,
  SaveSourceFilesState as SaveSourceFilesState,
  SaveSourceImagesState,
  SaveSourceLinksState,
  SaveSourceVideosState,
  SaveXliffState,
  UpdateDrbSuccess,
  NoDrbDraft,
  BuildDrbFail,
  LoadDrbByVersionFail,
  LoadDiffFail,
  NoDrbDiff,
  PostDrb,
  loadDrbAssociatedItems,
  loadDrbAssociatedItemsFail,
  loadDrbAssociatedItemsSuccess
} from './drb.actions';

@State<DrbStateModel>({
  name: 'drb',
  defaults: {
    associatedItems: [],
    drb: [],
    xliff: [],
    sourceFiles: [],
    sourceImages: [],
    sourceLinks: [],
    sourceVideos: [],
    view_drb: [],
    view_xliff: [],
    view_sourceFiles: [],
    view_sourceImages: [],
    view_sourceLinks: [],
    view_sourceVideos: [],
    versions: [],
    ids: [],
    diff: [],
    deploying: false,
    translating: false,
    error: null,
    noDrbDraft: null
  }
})
@Injectable()
export class DrbState {
  constructor(
    private service: DrbService,
    private toastrService: NbToastrService,
    private utilityService: UtilityService
  ) {}

  @Selector()
  public static drb(state: DrbStateModel) {
    return state.drb;
  }

  @Selector()
  public static xliff(state: DrbStateModel) {
    return state.xliff;
  }

  @Selector()
  public static sourceFiles(state: DrbStateModel) {
    return state.sourceFiles;
  }

  @Selector()
  public static sourceImages(state: DrbStateModel) {
    return state.sourceImages;
  }

  @Selector()
  public static sourceLinks(state: DrbStateModel) {
    return state.sourceLinks;
  }

  @Selector()
  public static sourceVideos(state: DrbStateModel) {
    return state.sourceVideos;
  }

  @Selector()
  public static view_drb(state: DrbStateModel) {
    return state.view_drb;
  }

  @Selector()
  public static view_xliff(state: DrbStateModel) {
    return state.view_xliff;
  }

  @Selector()
  public static view_sourceFiles(state: DrbStateModel) {
    return state.view_sourceFiles;
  }

  @Selector()
  public static view_sourceImages(state: DrbStateModel) {
    return state.view_sourceImages;
  }

  @Selector()
  public static view_sourceLinks(state: DrbStateModel) {
    return state.view_sourceLinks;
  }

  @Selector()
  public static view_sourceVideos(state: DrbStateModel) {
    return state.view_sourceVideos;
  }

  @Selector()
  public static versions(state: DrbStateModel) {
    return state.versions;
  }

  @Selector()
  public static ids(state: DrbStateModel) {
    return state.ids;
  }

  @Selector()
  public static diff(state: DrbStateModel) {
    return state.diff;
  }

  @Selector()
  public static deploying(state: DrbStateModel) {
    return state.deploying;
  }

  @Selector()
  public static translating(state: DrbStateModel) {
    return state.translating;
  }

  @Selector()
  public static noDrbDraft(state: DrbStateModel) {
    return state.noDrbDraft;
  }

  @Selector()
  public static associatedItems(state: DrbStateModel) {
    return state.associatedItems;
  }

  @Action(LoadDrb)
  public async loadDrb(ctx: StateContext<DrbStateModel>, {bundle_name}: any) {
    ctx.dispatch(ToggleShow);
    this.service.loadDrb(bundle_name);
  }

  @Action(LoadDrbByVersion)
  public async loadDrbByVersion(ctx: StateContext<DrbStateModel>, {version, bundle_name}: any) {
    ctx.dispatch(ToggleShow);
    this.service.loadDrbByVersion(version, bundle_name);
  }

  @Action(LoadDrbVersions)
  public async loadDrbVersions(ctx: StateContext<DrbStateModel>, {bundle_name}: any) {
    ctx.dispatch(ToggleShow);
    this.service.loadDrbVersions(bundle_name);
  }

  @Action(LoadDiff)
  public async loadDiff(ctx: StateContext<DrbStateModel>, {bundle_name}: any) {
    ctx.dispatch(ToggleShow);
    this.service.loadDiff(bundle_name);
  }

  @Action(LoadDrbSuccess)
  public loadDrbSuccess(ctx: StateContext<DrbStateModel>, { payload }: any) {
    const state = ctx.getState();
    const deployInProgress =
      payload['status'] === 'DEPLOY_STARTED_PROD' ||
      payload['status'] === 'DEPLOY_STARTED_STAGE' ||
      payload['status'] === 'BUILD_STARTED' ||
      payload['status'] === 'BUILD_SUCCEEDED'
        ? true
        : false;
    const translationInProgress =
      payload['translations_submitted'] === true && payload['translations_received'] === false;

    ctx.setState({
      ...state,
      drb: payload,
      xliff: payload['xliff']['en'],
      sourceFiles: payload['source']['files'],
      sourceImages: payload['source']['images'],
      sourceLinks: payload['source']['links'],
      sourceVideos: payload['source']['videos'],
      ids: [
        ...payload['xliff']['en'].map((e) => e.id),
        ...payload['source']['files'].map((e) => e.id),
        ...payload['source']['images'].map((e) => e.id),
        ...payload['source']['links'].map((e) => e.id),
        ...payload['source']['videos'].map((e) => e.id)
      ],
      deploying: deployInProgress,
      translating: translationInProgress,
      noDrbDraft: false
    });
    ctx.dispatch(ToggleHide);
  }


  @Action(LoadDrbByVersionSuccess)
  public loadDrbByVersionSuccess(ctx: StateContext<DrbStateModel>, { payload }: any) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      view_drb: payload,
      view_xliff: payload['xliff']['en'],
      view_sourceFiles: payload['source']['files'],
      view_sourceImages: payload['source']['images'],
      view_sourceLinks: payload['source']['links'],
      view_sourceVideos: payload['source']['videos']
    });
    ctx.dispatch(ToggleHide);
  }

  @Action(LoadDrbByVersionFail)
  public loadDrbByVersionFail(ctx: StateContext<DrbStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.dispatch(ToggleHide);
  }

  @Action(LoadDrbVersionsSuccess)
  public loadDrbVersionSuccess(ctx: StateContext<DrbStateModel>, {payload, bundle_name}: any) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      versions: payload
    });
    if (payload.length > 0) {
      const draftVersion = payload[payload.length - 1];
      ctx.dispatch(new LoadDrbByVersion(draftVersion, bundle_name));
    }
    ctx.dispatch(ToggleHide);
  }

  @Action(LoadDiffSuccess)
  public loadDiffSuccess(ctx: StateContext<DrbStateModel>, { payload }: any) {
    const state = ctx.getState();
    const deployInProgress =
      payload['draft_status'] === 'BUILD_STARTED' ||
      payload['draft_status'] === 'DEPLOY_STARTED_PROD' ||
      payload['draft_status'] === 'DEPLOY_STARTED_STAGE' ||
      payload['draft_status'] === 'BUILD_SUCCEEDED'
        ? true
        : false;
    const translationInProgress =
      'translations_submitted' in payload &&
      'translations_received' in payload &&
      payload['translations_submitted'] === true &&
      payload['translations_received'] === false
        ? true
        : false;

    ctx.setState({
      ...state,
      diff: payload,
      deploying: deployInProgress,
      translating: translationInProgress
    });
    ctx.dispatch(ToggleHide);
  }

  @Action(LoadDiffFail)
  public loadDiffFail(ctx: StateContext<DrbStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.dispatch(ToggleHide);
  }

  @Action(loadDrbAssociatedItems)
  public async loadDrbAssociatedItems(ctx: StateContext<DrbStateModel>, drb_id: string) {
    ctx.dispatch(ToggleShow);
    this.service.loadDrbAssociatedItems(drb_id['drb_id']);
  }

  @Action(loadDrbAssociatedItemsSuccess)
  public async loadDrbAssociatedItemsSuccess(ctx: StateContext<DrbStateModel>, payload: any) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      associatedItems: payload
    });
    ctx.dispatch(ToggleHide);
  }

  @Action(loadDrbAssociatedItemsFail)
  public loadDrbAssociatedItemsFail(ctx: StateContext<DrbStateModel>) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      associatedItems: []
    });
    ctx.dispatch(ToggleHide);
  }

  @Action(ReloadDrb)
  public async reloadDrb(ctx: StateContext<DrbStateModel>, {bundle_name}) {
    this.toastrService.show('', 'Fetch Initiated', { status: 'info' });
    this.service.loadDrbAmplify(bundle_name, true);
  }

  @Action(ReloadDrbSuccess)
  public reloadDrbSuccess(ctx: StateContext<DrbStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      drb: payload
    });
    ctx.dispatch(TableSpinerReloadHide);
  }

  @Action(PostDrb)
  public async postDrb(ctx: StateContext<DrbStateModel>, { payload, bundle_name }: any) {
    this.service.postDrbAmplify(payload, bundle_name);
  }

  @Action(UpdateDrbSuccess)
  public async updateDrbComplete(ctx: StateContext<DrbStateModel>, { payload }: any) {
    ctx.setState(
      patch({
        drb: updateItem((item) => item.drb_id === payload.drb_id, payload)
      })
    );
    this.toastrService.show('', 'Update: Complete', { status: 'success' });
  }

  @Action(SaveComplete)
  public drbComplete(ctx: StateContext<DrbStateModel>, { data }: any) {
    const state = ctx.getState();
    const xliff = data['xliff'] ? data['xliff'] : { en: null };
    const source = data['source'] ? data['source'] : { files: null, images: null, links: null, videos: null };
    ctx.setState({
      ...state,
      drb: data,
      xliff: xliff['en'],
      sourceFiles: source['files'],
      sourceImages: source['images'],
      sourceLinks: source['links'],
      sourceVideos: source['videos'],
      noDrbDraft: false
    });
    this.toastrService.show('', 'Save: Complete', { status: 'success' });
    ctx.dispatch(ToggleHide);
  }

  @Action(SaveXliffState)
  public saveXliff(ctx: StateContext<DrbStateModel>, { data, bundle_name }: any) {

    ctx.dispatch(ToggleShow);
    const draftDrb = this.getDrbObject(ctx);
    draftDrb.xliff = { en: data };
    draftDrb.last_updated_by = data[0]['last_updated_by'];
    draftDrb.last_updated_ts = data[0]['last_updated_ts'];
    draftDrb.bundle_name = bundle_name;

    this.putDrb(draftDrb);
    this.updateIds(ctx, data);
  }

  @Action(SaveSourceFilesState)
  public saveSourceFiles(ctx: StateContext<DrbStateModel>, {data, bundle_name}: any) {

    ctx.dispatch(ToggleShow);
    const draftDrb = this.getDrbObject(ctx);
    draftDrb.source.files = data;
    if(data.length > 0) {
      draftDrb.last_updated_by = data[0]['last_updated_by'];
      draftDrb.last_updated_ts = data[0]['last_updated_ts'];
    }
    draftDrb.bundle_name = bundle_name;

    this.putDrb(draftDrb);
    this.updateIds(ctx, data);
  }

  @Action(SaveSourceImagesState)
  public saveSourceImages(ctx: StateContext<DrbStateModel>, {data, bundle_name}: any) {


    ctx.dispatch(ToggleShow);
    const draftDrb = this.getDrbObject(ctx);
    draftDrb.source.images = data;

    if(data.length > 0) {
      draftDrb.last_updated_by = data[0]['last_updated_by'];
      draftDrb.last_updated_ts = data[0]['last_updated_ts'];
    }
    draftDrb.bundle_name = bundle_name;

    this.putDrb(draftDrb);
    this.updateIds(ctx, data);
  }

  @Action(SaveSourceLinksState)
  public saveSourceLinks(ctx: StateContext<DrbStateModel>, { data, bundle_name }: any) {

    ctx.dispatch(ToggleShow);
    const draftDrb = this.getDrbObject(ctx);
    draftDrb.source.links = data;

    if(data.length > 0) {
      draftDrb.last_updated_by = data[0]['last_updated_by'];
      draftDrb.last_updated_ts = data[0]['last_updated_ts'];
    }
    draftDrb.bundle_name = bundle_name;

    this.putDrb(draftDrb);
    this.updateIds(ctx, data);
  }

  @Action(SaveSourceVideosState)
  public saveSourceVideos(ctx: StateContext<DrbStateModel>, { data, bundle_name }: any) {

    ctx.dispatch(ToggleShow);
    const draftDrb = this.getDrbObject(ctx);
    draftDrb.source.videos = data;

    if(data.length > 0) {
      draftDrb.last_updated_by = data[0]['last_updated_by'];
      draftDrb.last_updated_ts = data[0]['last_updated_ts'];
    }
    draftDrb.bundle_name = bundle_name;

    this.putDrb(draftDrb);
    this.updateIds(ctx, data);
  }

  @Action(SaveDrbState)
  public saveDrb(ctx: StateContext<DrbStateModel>, { data }: any) {
    ctx.dispatch(ToggleShow);
    this.putDrb(data);
    this.updateIds(ctx, data);
  }

  @Action(SaveFail)
  public drbFail(ctx: StateContext<DrbStateModel>, { payload }: any) {
    const state = ctx.getState();
    const error = this.utilityService.convertErrorToMessage(payload);
    ctx.setState({
      ...state,
      error
    });
    ctx.dispatch(ToggleHide);
    this.toastrService.show('', 'Save: Fail - ' + error, { status: 'danger' });
  }

  @Action(BuildDrb)
  public buildDrb(ctx: StateContext<DrbStateModel>, {bundle_name}: any) {
    ctx.dispatch(ToggleShow);
    const state = ctx.getState();
    this.service.buildDrb(bundle_name);

    //this.checkDeployStatus(ctx);

    if (
      state.diff['draft_status'] === 'DEPLOY_SUCCEEDED_PROD' ||
      state.diff['draft_status'] === 'DEPLOY_SUCCEEDED_STAGE'
    ) {
      ctx.dispatch(BuildDrbSuccess);
    }
    if (
      state.diff['draft_status'] === 'BUILD_FAILED' ||
      state.diff['draft_status'] === 'DEPLOY_FAILED_PROD' ||
      state.diff['draft_status'] === 'DEPLOY_FAILED_STAGE'
    ) {
      ctx.dispatch(BuildDrbFail);
    }
    // this.service.loadDiff();
    // this.service.loadDrb();
  }

  @Action(BuildDrbSuccess)
  public buildDrbSuccess(ctx: StateContext<DrbStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState(
      patch({
        deploying: false
      })
    );
    ctx.dispatch(ToggleHide);
  }
  @Action(BuildDrbFail)
  public buildDrbFail(ctx: StateContext<DrbStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState(
      patch({
        deploying: false
      })
    );
    ctx.dispatch(ToggleHide);
  }

  @Action(NoDrbDraft)
  public noDrbDraft(ctx: StateContext<DrbStateModel>) {
    this.toastrService.show('', 'DRB draft version not found. Create new draft.', { status: 'info' });
    ctx.setState(
      patch({
        noDrbDraft: true,
        deploying: false
      })
    );
    ctx.dispatch(ToggleHide);
  }

  @Action(NoDrbDiff)
  public NoDrbDiff(ctx: StateContext<DrbStateModel>) {
    this.toastrService.show('', 'DRB draft version not found for comparison.', { status: 'info' });
    ctx.setState(
      patch({
        diff: [],
        deploying: false
      })
    );
    ctx.dispatch(ToggleHide);
  }

  public async putDrb(payload: any) {
    this.service.putDrbAmplify(payload);
  }

  public getDrbObject(ctx: StateContext<DrbStateModel>) {
    const state = ctx.getState();
    const draftDrb = {
      version: state.drb['version'],
      bundle_name: '',
      created_by: state.drb['created_by'],
      created_ts: state.drb['created_ts'],
      status: state.drb['status'],
      last_updated_by: state.drb['last_updated_by'],
      last_updated_ts: state.drb['last_updated_ts'],
      owner: state.drb['owner'],
      contributors: state.drb['contributors'].filter((n) => n),
      xliff: { en: state.xliff },
      source: {
        files: state.sourceFiles,
        images: state.sourceImages,
        links: state.sourceLinks,
        videos: state.sourceVideos
      },
      draft: state.drb['draft'],
      deployed_to_stage: state.drb['deployed_to_stage'],
      deployed_to_stage_ts: state.drb['deployed_to_stage_ts'],
      deployed_to_prod: state.drb['deployed_to_prod'],
      deployed_to_prod_ts: state.drb['deployed_to_prod_ts'],

      translate: state.drb['translate'],
      translation_status: state.drb['translation_status'],
      translations_submitted: state.drb['translations_submitted'],
      translations_submitted_ts: state.drb['translations_submitted_ts'],
      translations_received: state.drb['translations_received'],
      translations_received_ts: state.drb['translations_received_ts']
    };

    return draftDrb;
  }

  public updateIds(ctx: StateContext<DrbStateModel>, data: any) {
    const state = ctx.getState();
    const newIds = JSON.parse(JSON.stringify(state.ids));
    if (data.length > 0) {
      newIds.push(data[data.length - 1].id);
      ctx.setState(
        patch({
          ids: newIds
        })
      );
    }
  }

  public checkDeployStatus(ctx: StateContext<DrbStateModel>, {bundle_name}: any) {
    const state = ctx.getState();

    this.service.loadDiff(bundle_name);
    while (
      state.diff['draft_status'] === 'BUILD_STARTED' ||
      state.diff['draft_status'] === 'DEPLOY_STARTED_PROD' ||
      state.diff['draft_status'] === 'DEPLOY_STARTED_STAGE'
    ) {
      ctx.setState(
        patch({
          deploying: true
        })
      );
      this.service.loadDiff(bundle_name);
      this.sleep(5);
    }
  }

  public sleep(sec) {
    return new Promise((resolve) => setTimeout(resolve, sec * 1000));
  }
}
