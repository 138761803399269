/* eslint-disable @typescript-eslint/dot-notation */
import { DecimalPipe } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { Actions, ofActionCompleted, Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DrbService } from 'src/app/core/services/drb.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { SourceImage } from 'src/app/models/drb-models/source-image';
import { NgxGenericModalComponent } from 'src/app/shared/ngx-generic-modal/ngx-generic-modal.component';
import { RouterState } from 'src/app/shared/redux/router-state';
import { SpinnerState } from 'src/app/shared/spinner/redux/spinner.state';
import { DrbState, LoadDrb, LoadDrbByVersionSuccess } from '../../redux';
import { EditImageComponent } from './edit-image/edit-image.component';
import { ViewImageComponent } from './view-image/view-image.component';
import { DeleteDrbComponent } from '../../delete-drb/delete-drb.component';

@Component({
  selector: 'app-source-images',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './source-images.component.html',
  styleUrls: ['./source-images.component.css'],
  providers: [DecimalPipe]
})
export class SourceImagesComponent implements OnInit, AfterViewInit {
  @Select(DrbState.drb) public drb$: Observable<any>;
  @Select(DrbState.sourceImages) public data$: Observable<any[]>;
  @Select(DrbState.view_sourceImages) public view_data$: Observable<any[]>;
  @Select(SpinnerState) public loading: Observable<boolean>;
  @Select(RouterState.username) public data_username$: Observable<any>;

  public tableData;
  public displayNewButton = true;
  public spin;
  public mode;
  public metaData;
  public username;
  public description;
  public type = 'image-source';
  public allKeys: string[] = [];
  public bundle_name: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: DrbService,
    private store: Store,
    private actions$: Actions,
    private dialogService: NbDialogService,
    public utility: UtilityService
  ) {
  }


  public ngAfterViewInit() {
    if (this.store.selectSnapshot(DrbState.sourceImages).length > 1) {
      this.service.buildKeysForTable(this.store.selectSnapshot(DrbState.sourceImages), this.allKeys, this.type);
    }else {
      const defaultKeys = [new SourceImage()];
      this.service.buildKeysForTable(defaultKeys, this.allKeys, this.type);
    }
  }

  public ngOnInit() {
    this.bundle_name = this.router.url.split('/')[2];
    if (this.router.url.endsWith('create/images')) {
      this.description = 'View, add and edit image data';
      this.data$.subscribe((data) => {
        this.tableData = data;
        this.service.buildKeysForTable(this.tableData, this.allKeys,  this.type);
      });

      this.drb$.subscribe((data) => {
        this.metaData = {
          version: data['version'],
          created_by: data['created_by'],
          created_ts: data['created_ts'],
          status: data['status'],
          last_updated_by: data['last_updated_by'],
          last_updated_ts: data['last_updated_ts'],
          owner: data['owner'],
          contributors: data['contributors'],
          draft: data['draft'],
          deployed_to_stage: data['deployed_to_stage'],
          deployed_to_stage_ts: data['deployed_to_stage_ts'],
          deployed_to_prod: data['deployed_to_prod'],
          deployed_to_prod_ts: data['deployed_to_prod_ts']
        };
      });

      this.data_username$.subscribe((data) => (this.username = data));
      this.checkOwnerOrContributor();
    } else if (this.router.url.endsWith('view/images')) {
      this.mode = 'view';
      this.displayNewButton = false;
      this.description = 'View image data';
      this.view_data$.subscribe((data) => {
        this.tableData = data;
        this.service.buildKeysForTable(this.tableData, this.allKeys,  this.type);
      });
      this.actions$.pipe(ofActionCompleted(LoadDrbByVersionSuccess)).subscribe((data) => {
        if (data) {
          this.view_data$.subscribe((loadedData) => {
            this.tableData = loadedData;
            this.service.buildKeysForTable(this.tableData, this.allKeys,  this.type);
          });
        }
      });
    }

    this.loading.subscribe((data) => (this.spin = data));
  }

  public newCreation() {
    this.newImage();
  }

  public refreshTableData() {
    this.dialogService
      .open(NgxGenericModalComponent, {
        context: {
          title: 'Confirm Refresh',
          message: 'Are you sure that you want to refresh draft version?'
        }
      })
      .onClose.subscribe((data) => {
        if (data) {
          this.store.dispatch(new LoadDrb(this.bundle_name));
        }
      });
  }

  public clickedButtonTable($event) {
    if ($event.action === 'view') {
      this.viewImage($event.data);
    }
    if ($event.action === 'edit') {
      this.editImage($event.data);
    }
    if ($event.action === 'delete') {
      this.deleteImage($event.data);
    }
  }

  public viewImage(image: SourceImage): void {
    this.dialogService.open(ViewImageComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        image: JSON.parse(JSON.stringify(image))
      }
    });
  }

  public editImage(image: SourceImage): void {
    this.dialogService.open(EditImageComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'Edit',
        image: JSON.parse(JSON.stringify(image)),
        bundle_name: this.bundle_name
      }
    });
  }

  public deleteImage(image: SourceImage): void {
    this.dialogService.open(DeleteDrbComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'Delete',
        item: JSON.parse(JSON.stringify(image)),
        type: this.type,
        bundle_name: this.bundle_name
      }
    });
  }

  public newImage(): void {
    this.dialogService.open(EditImageComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'New',
        image: new SourceImage(),
        bundle_name: this.bundle_name
      }
    });
  }

  public checkOwnerOrContributor() {
    if (this.username === this.metaData.owner || this.metaData.contributors.includes(this.username)) {
      this.mode = 'edit';
      this.displayNewButton = true;
    } else {
      this.mode = 'view';
      this.displayNewButton = false;
    }
  }
}
