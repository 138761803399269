import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { HTARecommendation } from 'src/app/models/hta-recommendation';

@Component({
    selector: 'app-view-hta-recommendation',
    templateUrl: './view-hta-recommendation.component.html',
    styleUrls: ['./view-hta-recommendation.component.css']
})
export class ViewHtaRecommendationComponent implements OnInit {
  public htaRecommendation: HTARecommendation;

  constructor(protected ref: NbDialogRef<ViewHtaRecommendationComponent>) { }

  public ngOnInit() {
  }

  public close() {
      this.ref.close();
  }
}
