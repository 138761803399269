import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Survey } from 'src/app/models/survey';

@Component({
    selector: 'app-view-survey',
    templateUrl: './view-survey.component.html',
    styleUrls: ['./view-survey.component.css']
})
export class ViewSurveyComponent implements OnInit {
  public survey: Survey;

  constructor(protected ref: NbDialogRef<ViewSurveyComponent>) { }

  public ngOnInit() {
      if(this.survey.deleted === null) {
          this.survey.deleted = false;
      }
      if(this.survey.questions.length > 1) {
          this.survey.questions.forEach(question => {
              if(question.deleted === null) {
                  question.deleted = false;
              }
              if(question.answers.length > 1) {
                  question.answers.forEach(answer => {
                      if(answer.deleted === null) {
                          answer.deleted = false;
                      }
                      if(answer.actions.length > 1) {
                          answer.actions.forEach(action => {
                              if(action.deleted === null) {
                                  action.deleted = false;
                              }
                              if(action.action_payload.deleted === null) {
                                  action.action_payload.deleted = false;
                              }
                          });
                      }
                  });
              }
          });
      }
  }

  public close() {
      this.ref.close();
  }
}
