import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
    selector: 'app-ngx-generic-modal',
    templateUrl: './ngx-generic-modal.component.html',
    styleUrls: ['./ngx-generic-modal.component.css']
})
export class NgxGenericModalComponent implements OnInit {
  public title: string;
  public message: string;
  public yesButton = 'Yes';
  public noButton = 'No';

  constructor(protected ref: NbDialogRef<NgxGenericModalComponent>) { }

  public ngOnInit() {
  }

  public clickedYes() {
      this.ref.close(true);
  }

  public clickedNo(){
      this.ref.close(false);
  }
}
