export const translationLanguages = Object.freeze({
  ar_SA: 'Arabic (Saudi Arabia)',
  cs: 'Czech',
  da: 'Danish',
  de: 'German',
  en_GB: 'English (United Kingdom)',
  es_419: 'Spanish (Latin America)',
  es: 'Spanish',
  fi: 'Finnish',
  fr_CA: 'French (Canada)',
  fr: 'French',
  he: 'Hebrew',
  it: 'Italian',
  ja: 'Japanese',
  ko: 'Korean',
  nb: 'Norwegian Bokmål',
  nl: 'Dutch',
  pl: 'Polish',
  pt_BR: 'Portuguese (Brazil)',
  pt_PT: 'Portuguese (Portugal)',
  ru: 'Russian',
  sv: 'Swedish',
  tr_TR: 'Turkish (Turkey)',
  zh_Hans: 'Chinese (Simplified)',
  zh_Hant: 'Chinese (Traditional)'
});
