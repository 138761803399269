import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Campaign } from 'src/app/models/campaign';
import { SpinnerState } from 'src/app/shared/spinner/redux/spinner.state';
import { CampaignState, LoadCampaign } from '../../campaigns/redux';
import { LoadAllRobots, LoadKOZRs, QaState } from '../redux';

@Component({
  selector: 'app-qa-testing',
  templateUrl: './qa-testing.component.html',
  styleUrls: ['./qa-testing.component.css']
})
export class QaTestingComponent implements OnInit {
  @Select(CampaignState.campaign) public data_campaigns$: Observable<any[]>;
  @Select(QaState.robots) public data_allRobots$: Observable<any[]>;
  @Select(QaState.ownerRobots) public data_ownerRobots$: Observable<any[]>;
  @Select(SpinnerState) public loading: Observable<boolean>;
  public spin;
  public formRobotData;
  public campaigns: Campaign[];

  constructor(private store: Store) {
    this.store
      .select((state) => state.campaign.campaign)
      .subscribe((data) => {
        if (data.length < 1) {
          this.store.dispatch(LoadCampaign);
        }
      });
    this.store
      .select((state) => state.qa.Robots)
      .subscribe((data) => {
        if (data.length < 1) {
          this.store.dispatch(LoadAllRobots);
        }
      });
    this.store
      .select((state) => state.qa.CannedKOZRbots)
      .subscribe((data) => {
        if (data.length < 1) {
          this.store.dispatch(LoadKOZRs);
        }
      });
  }

  public ngOnInit() {
    this.data_allRobots$.subscribe((data) => (this.formRobotData = data));
    this.data_campaigns$.subscribe((data) => (this.campaigns = data));
    this.loading.subscribe((data) => (this.spin = data));
  }
}
