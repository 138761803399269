// v2
export class SmartScheduleRecommendation {
  public id: string = null;
  public schedulingVersion = '';
  public robotId: string;
  public timezone: string;
  public recommendedSchedule?: RegionRecommendations;
}

export class RegionRecommendations {
  public regions: RegionSchedule[];
}

export class RegionSchedule {
  public schedule: { [key: string]: ScoredSchedule };
  public region_id = '__all__';
  public pmap_id?: string;
  public pmapv_id?: string;
}

export interface Region {
  schedule: {
    Mon: ScoredSchedule;
    Tue: ScoredSchedule;
    Wed: ScoredSchedule;
    Thur: ScoredSchedule;
    Fri: ScoredSchedule;
    Sat: ScoredSchedule;
    Sun: ScoredSchedule;
  };
  pmapv_id: string;
  pmap_id: string;
  region_id: string;
}

export class ScoredSchedule {
  public start_time?: number = null;
  public score? = null;
  public day?: string; // only used by UI
  public hour?: number; // only used by UI
  public minute?: number; // only used by UI
}
