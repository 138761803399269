<nb-card
  style="width: 100%; height: 100%; background-color: transparent;"
  class="own-scroll"
>
  <table style="background-color: transparent;">
    <tr>
      <th (click)="sort('extent_type')" class="metadata-cell-header">
        <div class="metadata-cell-header-box">
          Extent Type
          <div *ngIf="extent_type_sort !== null"  class="metadata-cell-header-box">
            <nb-icon class="metadata-cell-icon" *ngIf="extent_type_sort" icon="arrow-down"></nb-icon>
            <nb-icon class="metadata-cell-icon" *ngIf="!extent_type_sort" icon="arrow-up"></nb-icon>
          </div>
        </div>
        
      </th>
      <th (click)="sort('confidence')" class="metadata-cell-header">
        <div  class="metadata-cell-header-box">
          Minimum Confidence 
          <div *ngIf="confidence_sort !== null"  class="metadata-cell-header-box">
            <nb-icon class="metadata-cell-icon"  *ngIf="confidence_sort" icon="arrow-down"></nb-icon>
            <nb-icon class="metadata-cell-icon"  *ngIf="!confidence_sort" icon="arrow-up"></nb-icon>
          </div>
        </div>
      </th>
      <th (click)="sort('priority')" class="metadata-cell-header">
        <div  class="metadata-cell-header-box">
          Priority 
          <div  *ngIf="priority_sort !== null"  class="metadata-cell-header-box">
            <nb-icon class="metadata-cell-icon" *ngIf="priority_sort" icon="arrow-down"></nb-icon>
            <nb-icon class="metadata-cell-icon" *ngIf="!priority_sort" icon="arrow-up"></nb-icon>
          </div>
        </div>
      </th>
    </tr>
    <tr *ngFor="let data of renderValue">
      <td id="metadata-extent-type-td" *ngIf="data !== null && data !== ''">
        {{ data.extent_type }}
      </td>
      <td id="metadata-min-confidence-td" *ngIf="data !== null && data !== ''">
        {{ data.confidence }}
      </td>
      <td id="metadata-priority-td" *ngIf="data !== null && data !== ''">
        {{ data.priority }}
      </td>
    </tr>
  </table>
</nb-card>
