
<div style="min-height: 550px">
  <nb-card>
      <nb-card-header>
          <h3 style="position: relative;">Campaign Analytics</h3>
      </nb-card-header>
      <nb-card-body>
        <nb-route-tabset [tabs]="tabs"></nb-route-tabset>
      </nb-card-body>
  </nb-card>
</div>