import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { TableSpinerReloadActive, TableSpinerReloadHide } from './table-spinner.actions';

@State<boolean>({
    name: 'tablespinner',
    defaults: null
})

@Injectable()
export class TableSpinnerState {
  @Action(TableSpinerReloadActive)
    public toggleShow(store: StateContext<boolean>) {
        store.setState(true);
    }

  @Action(TableSpinerReloadHide)
  public toggleHide(store: StateContext<boolean>) {
      store.setState(false);
  }
}
