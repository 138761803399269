// Modules
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import {
  NbButtonModule,
  NbCalendarModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbDialogModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbRouteTabsetModule,
  NbSelectModule,
  NbTabsetModule,
  NbTooltipModule,
  NbCardModule
} from '@nebular/theme';
import { NgSelectModule } from '@ng-select/ng-select';
import { TagInputModule } from 'ngx-chips';
import { ValidatorDirective } from 'src/app/shared/validation/validation_campaign_name_regex';
import { MatDatepickerModule } from '@matheo/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CoreModule } from '../../core/core.module';
import { ChartsModule } from '../../@theme/charts/charts.module';
import { SharedModule } from '../../shared/shared.module';
import { routedComponents } from './campaigns-routing.module';
import { ViewCampaignComponent } from './campaign-table/view-campaign/view-campaign.component';
import { StatsCampaignComponent } from './campaign-table/stats-campaign/stats-campaign.component';
import { EditCampaignComponent } from './campaign-table/edit-campaign/edit-campaign.component';
import { CampaignsComponent } from './campaign-table/campaigns.component';
import { CampaignDeliveriesComponent } from './campaign-analytics/campaign-deliveries/campaign-deliveries.component';
import { CampaignDashboardComponent } from './campaign-analytics/campaign-dashboard/campaign-dashboard.component';
import { CampaignAnalyticsComponent } from './campaign-analytics/campaign-analytics.component';
import { CampaignWebsocketWindowComponent } from './campaign-table/campaign-websocket-window/campaign-websocket-window.component';
import { CampaignPromotionComponent } from './campaign-table/view-campaign/campaign-promotion-component/campaign-promotion-component.component';
import { CampaignStatusHandlerComponent } from './campaign-table/view-campaign/campaign-status-handler/campaign-status-handler.component';
import { CampaignTerminateModalComponent } from './campaign-table/campaign-terminate-modal/campaign-terminate-modal.component';
import { DeleteCampaignComponent } from './campaign-table/delete-campaign/delete-campaign.component';

const COMPONENTS = [
  CampaignsComponent,
  ViewCampaignComponent,
  StatsCampaignComponent,
  EditCampaignComponent,
  CampaignAnalyticsComponent,
  CampaignDashboardComponent,
  CampaignDeliveriesComponent,
  CampaignStatusHandlerComponent,
  CampaignWebsocketWindowComponent,
  CampaignPromotionComponent,
  CampaignTerminateModalComponent
];

@NgModule({
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    ChartsModule,
    SharedModule,
    CommonModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    NbDialogModule,
    NbEvaIconsModule,
    NbIconModule,
    NbButtonModule,
    NbCheckboxModule,
    NbInputModule,
    NbSelectModule,
    NbCalendarModule,
    NbDatepickerModule,
    NbFormFieldModule,
    NgSelectModule,
    NbTabsetModule,
    NbRouteTabsetModule,
    NbCardModule,
    TagInputModule,
    RouterModule,
    NbTooltipModule
  ],
  declarations: [...routedComponents, ...COMPONENTS, ValidatorDirective, DeleteCampaignComponent]
})
export class CampaignsModule {}
