<nb-card
  accent="success"
  style="width: 90%; height: 90%; min-width: 700px; max-height: 800px; max-width: 1600px; margin: auto"
  class="own-scroll"
>
  <nb-card-header>
    <div class="row">
      <div class="col-auto mr-auto" *ngIf="title === 'New'">
        <nb-icon icon="plus-outline"></nb-icon>
      </div>
      <div class="col-auto mr-auto" *ngIf="title === 'Edit' || title === 'Clone'">
        <nb-icon icon="edit-2-outline"></nb-icon>
      </div>
      <div class="col-auto mr-auto">{{ title }} Survey: {{ survey.survey_id }}</div>
      <div class="col-auto"><button nbButton (click)="close()">X</button></div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div style="height: 700px; padding: 1.143em; margin: -1.143em">
      <form [formGroup]="surveyForm" (ngSubmit)="onSubmit()">
        <!-- Survey Form -->
        <br />

        <div class="row">
          <div class="col">
            <label>Survey ID<span style="color: red">*</span>:</label>
          </div>
          <div class="col-9">
            <input nbInput placeholder="Survey ID" style="width: 100%" formControlName="survey_id" />
            <div *ngIf="surveyForm.get('survey_id').hasError('pattern')">
              <p class="error">ID allows alphanumeric characters, underscore and colon.</p>
            </div>
          </div>
        </div>
        <br />

        <div class="row">
          <div class="col">
            <label>Survey Name<span style="color: red">*</span>:</label>
          </div>
          <div class="col-9">
            <input nbInput placeholder="Survey Name" style="width: 100%" formControlName="survey_name" />
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col">
            <label>Survey Type:</label>
          </div>
          <div class="col-9">
            <nb-select placeholder="Survey Type" formControlName="survey_type" style="width: 100%" ngDefaultControl>
              <nb-option *ngFor="let survey of surveyType" [value]="survey">
                {{ survey }}
              </nb-option>
            </nb-select>
          </div>
        </div>
        <!-- Questions Form -->
        <div formArrayName="questions">
          <div *ngFor="let question of questions().controls; let qi = index">
            <div [formGroupName]="qi">
              <br />
              <nb-accordion>
                <nb-accordion-item>
                  <nb-accordion-item-header (click)="$event.stopPropagation()">
                    <div class="col-auto mr-auto">
                      <label *ngIf="survey.questions[qi]?.question_id">
                        Question: {{ survey.questions[qi].question_id }}
                      </label>
                      <label *ngIf="!survey.questions[qi]?.question_id"> Question {{ [qi + 1] }} </label>
                    </div>
                    <div class="col-auto" style="padding-right: 40px">
                      <button
                        nbTooltip="Delete Question"
                        nbButton
                        size="small"
                        status="danger"
                        (click)="removeQuestion(qi)"
                      >
                        <nb-icon icon="trash-2-outline"></nb-icon>
                      </button>
                    </div>
                  </nb-accordion-item-header>
                  <nb-accordion-item-body>
                    <nb-card>
                      <nb-card-body>
                        <div class="row">
                          <div class="col"><label>Visible</label></div>
                          <div class="col-9">
                            <nb-checkbox formControlName="visible"></nb-checkbox>
                          </div>
                        </div>
                        <br />

                        <div class="row">
                          <div class="col">
                            <label>Question ID<span style="color: red">*</span>:</label>
                          </div>
                          <div class="col-9">
                            <input
                              nbInput
                              placeholder="Question ID"
                              style="width: 100%"
                              formControlName="question_id"
                            />
                            <div *ngIf="question.get('question_id').hasError('pattern')">
                              <p class="error">
                                ID allows alphanumeric characters, underscore and colon.
                              </p>
                            </div>
                          </div>
                        </div>
                        <br />
                        <div class="row">
                          <div class="col">
                            <label>Question Type<span style="color: red">*</span>:</label>
                          </div>
                          <div class="col-9">
                            <nb-select placeholder="Question Type" formControlName="question_type" style="width: 100%">
                              <nb-option *ngFor="let question of questionType" [value]="question">
                                {{ question }}
                              </nb-option>
                            </nb-select>
                          </div>
                        </div>
                        <br />

                        <div class="row">
                          <div class="col"><label>Question Name:</label></div>
                          <div class="col-9">
                            <input
                              formControlName="question_name"
                              nbInput
                              placeholder="Question Name"
                              style="width: 100%"
                            />
                          </div>
                        </div>
                        <br />
                        <div class="row">
                          <div class="col"><label>Question Image ID:</label></div>
                          <div class="col-9">
                            <input
                              formControlName="image_id"
                              nbInput
                              placeholder="Question Image ID"
                              style="width: 100%"
                            />
                          </div>
                        </div>
                        <br />
                        <div class="row">
                          <div class="col"><label>Question Title Text:</label></div>
                          <div class="col-9">
                            <input
                              formControlName="value"
                              nbInput
                              placeholder="Question Title Text"
                              style="width: 100%"
                            />
                          </div>
                        </div>
                        <br />
                        <div class="row">
                          <div class="col"><label>Question Title ID:</label></div>
                          <div class="col-9">
                            <input
                              formControlName="title_id"
                              nbInput
                              placeholder="Question Title ID"
                              style="width: 100%"
                            />
                          </div>
                        </div>
                        <br />
                        <div class="row">
                          <div class="col"><label>Question Subtitle ID:</label></div>
                          <div class="col-9">
                            <input
                              formControlName="subtitle_id"
                              nbInput
                              placeholder="Question Subtitle ID"
                              style="width: 100%"
                            />
                          </div>
                        </div>
                        <br />
                        <div class="row">
                          <div class="col"><label>Question Display Order:</label></div>
                          <div class="col-9">
                            <input
                              formControlName="display_order"
                              nbInput
                              type="number"
                              placeholder="Question Display Order"
                              style="width: 100%"
                            />
                          </div>
                        </div>

                        <!-- Answers Form-->
                        <div formArrayName="answers">
                          <div *ngFor="let answer of answers(qi).controls; let ai = index">
                            <div [formGroupName]="ai">
                              <nb-accordion>
                                <nb-accordion-item>
                                  <nb-accordion-item-header (click)="$event.stopPropagation()">
                                    <div class="col-auto mr-auto">
                                      <label *ngIf="survey.questions[qi]?.answers[ai]?.answer_id">
                                        Answer: {{ survey.questions[qi].answers[ai].answer_id }}
                                      </label>
                                      <label *ngIf="!survey.questions[qi]?.answers[ai]?.answer_id">
                                        Answer {{ [ai + 1] }}
                                      </label>
                                    </div>
                                    <div class="col-auto" style="padding-right: 40px">
                                      <button
                                        nbTooltip="Delete Answer"
                                        nbButton
                                        size="small"
                                        status="danger"
                                        (click)="removeAnswer(qi, ai)"
                                      >
                                        <nb-icon icon="trash-2-outline"></nb-icon>
                                      </button>
                                    </div>
                                  </nb-accordion-item-header>
                                  <nb-accordion-item-body>
                                    <nb-card>
                                      <nb-card-body>
                                        <div class="row">
                                          <div class="col">
                                            <label>Answer ID<span style="color: red">*</span>:</label>
                                          </div>
                                          <div class="col-9">
                                            <input
                                              nbInput
                                              placeholder="Answer ID"
                                              style="width: 100%"
                                              formControlName="answer_id"
                                            />
                                            <div *ngIf="answer.get('answer_id').hasError('pattern')">
                                              <p class="error">
                                                ID allows alphanumeric characters, underscore and colon.
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <br />

                                        <br />
                                        <div class="row">
                                          <div class="col">
                                            <label>Answer Type:</label>
                                          </div>
                                          <div class="col-9">
                                            <nb-select
                                              placeholder="Answer Type"
                                              formControlName="answer_type"
                                              style="width: 100%"
                                            >
                                              <nb-option *ngFor="let answer of answerType" [value]="answer">
                                                {{ answer }}
                                              </nb-option>
                                            </nb-select>
                                          </div>
                                        </div>
                                        <br />

                                        <div class="row">
                                          <div class="col"><label>Value:</label></div>
                                          <div class="col-9">
                                            <input
                                              nbInput
                                              placeholder="Answer Value"
                                              style="width: 100%"
                                              formControlName="value"
                                            />
                                          </div>
                                        </div>
                                        <br />

                                        <div class="row">
                                          <div class="col"><label>Value ID:</label></div>
                                          <div class="col-9">
                                            <input
                                              nbInput
                                              placeholder="Answer Value ID"
                                              style="width: 100%"
                                              formControlName="value_id"
                                            />
                                          </div>
                                        </div>
                                        <br />

                                        <div class="row">
                                          <div class="col"><label>Minimum Value:</label></div>
                                          <div class="col-9">
                                            <input
                                              nbInput
                                              placeholder="Minimum Value"
                                              style="width: 100%"
                                              formControlName="min_value"
                                            />
                                          </div>
                                        </div>
                                        <br />

                                        <div class="row">
                                          <div class="col"><label>Minimum Value Description:</label></div>
                                          <div class="col-9">
                                            <input
                                              nbInput
                                              placeholder="Minimum Value Description"
                                              style="width: 100%"
                                              formControlName="min_value_text"
                                            />
                                          </div>
                                        </div>
                                        <br />

                                        <div class="row">
                                          <div class="col"><label>Maximum Value:</label></div>
                                          <div class="col-9">
                                            <input
                                              nbInput
                                              placeholder="Maximum Value"
                                              style="width: 100%"
                                              formControlName="max_value"
                                            />
                                          </div>
                                        </div>
                                        <br />

                                        <div class="row">
                                          <div class="col"><label>Maximum Value Description:</label></div>
                                          <div class="col-9">
                                            <input
                                              nbInput
                                              placeholder="Maximum Value Description"
                                              style="width: 100%"
                                              formControlName="max_value_text"
                                            />
                                          </div>
                                        </div>
                                        <br />

                                        <div class="row">
                                          <div class="col"><label>Answer Image ID:</label></div>
                                          <div class="col-9">
                                            <input
                                              nbInput
                                              placeholder="Answer Image ID"
                                              style="width: 100%"
                                              formControlName="image_id"
                                            />
                                          </div>
                                        </div>
                                        <br />
                                        <br />

                                        <!-- Actions Form -->
                                        <div formArrayName="actions">
                                          <div *ngFor="let action of actions(qi, ai).controls; let aci = index">
                                            <div [formGroupName]="aci">
                                              <nb-accordion>
                                                <nb-accordion-item>
                                                  <nb-accordion-item-header (click)="$event.stopPropagation()">
                                                    <div class="col-auto mr-auto">
                                                      <label
                                                        *ngIf="
                                                          survey.questions[qi]?.answers[ai]?.actions[aci]?.action_type
                                                        "
                                                      >
                                                        Action:
                                                        {{
                                                          survey.questions[qi]?.answers[ai]?.actions[aci]?.action_type
                                                        }}
                                                      </label>
                                                      <label
                                                        *ngIf="
                                                          !survey.questions[qi]?.answers[ai]?.actions[aci]?.action_type
                                                        "
                                                      >
                                                        Action {{ [aci + 1] }}
                                                      </label>
                                                    </div>
                                                    <div class="col-auto" style="padding-right: 40px">
                                                      <button
                                                        nbTooltip="Delete Action"
                                                        nbButton
                                                        size="small"
                                                        status="danger"
                                                        (click)="removeAction(qi, ai, aci)"
                                                      >
                                                        <nb-icon icon="trash-2-outline"></nb-icon>
                                                      </button>
                                                    </div>
                                                  </nb-accordion-item-header>
                                                  <nb-accordion-item-body>
                                                    <br />
                                                    <div class="row">
                                                      <div class="col">
                                                        <label>Action Type<span style="color: red">*</span>:</label>
                                                      </div>
                                                      <div class="col-9">
                                                        <nb-select
                                                          placeholder="Action Type"
                                                          formControlName="action_type"
                                                          style="width: 100%"
                                                        >
                                                          <nb-option
                                                            *ngFor="let actionType of actionTypes"
                                                            [value]="actionType"
                                                          >
                                                            {{ actionType }}
                                                          </nb-option>
                                                        </nb-select>
                                                      </div>
                                                    </div>
                                                    <br />

                                                    <div class="row">
                                                      <div class="col"><label>Action Value:</label></div>
                                                      <div class="col-9">
                                                        <input
                                                          nbInput
                                                          placeholder="Action Value"
                                                          style="width: 100%"
                                                          formControlName="action_value"
                                                        />
                                                      </div>
                                                    </div>
                                                    <br />

                                                    <h6 class="text-uppercase">Action Payload</h6>

                                                    <div formGroupName="action_payload">
                                                      <div class="row">
                                                        <div class="col"><label>Deleted</label></div>
                                                        <div class="col-9">
                                                          <nb-checkbox formControlName="deleted"></nb-checkbox>
                                                        </div>
                                                      </div>
                                                      <br />

                                                      <div class="row">
                                                        <div class="col"><label>URL:</label></div>
                                                        <div class="col-9">
                                                          <input
                                                            nbInput
                                                            placeholder="URL"
                                                            style="width: 100%"
                                                            formControlName="url"
                                                          />
                                                        </div>
                                                      </div>
                                                      <br />
                                                    </div>
                                                  </nb-accordion-item-body>
                                                </nb-accordion-item>
                                              </nb-accordion>
                                            </div>
                                            <br />
                                          </div>
                                        </div>
                                        <div class="row">
                                          <div class="col-auto">
                                            <button
                                              nbTooltip="Create New Action"
                                              nbButton
                                              size="small"
                                              type="button"
                                              status="success"
                                              (click)="addAction(qi, ai)"
                                            >
                                              <nb-icon icon="plus-square-outline"></nb-icon>New Action
                                            </button>
                                          </div>
                                        </div>
                                      </nb-card-body>
                                    </nb-card>
                                  </nb-accordion-item-body>
                                </nb-accordion-item>
                              </nb-accordion>
                            </div>
                          </div>
                        </div>
                        <br />
                        <div class="row">
                          <div class="col-auto">
                            <button
                              nbButton
                              nbTooltip="Create New Answer"
                              size="small"
                              type="button"
                              status="success"
                              (click)="addAnswer(qi)"
                            >
                              <nb-icon icon="plus-square-outline"></nb-icon>New Answer
                            </button>
                          </div>
                        </div>
                        <br />
                      </nb-card-body>
                    </nb-card>
                  </nb-accordion-item-body>
                </nb-accordion-item>
              </nb-accordion>
            </div>
          </div>
        </div>

        <br />
        <div class="row">
          <div class="col-auto">
            <button
              nbButton
              nbTooltip="Create New Question"
              size="small"
              status="success"
              type="button"
              (click)="addQuestion()"
            >
              <nb-icon icon="plus-square-outline"></nb-icon>New Question
            </button>
          </div>
        </div>
        <br />
      </form>
    </div>
  </nb-card-body>

  <nb-card-footer>
    <div class="d-flex flex-row-reverse">
      <div class="p-2">
        <button nbButton status="success" (click)="onSubmit()" [disabled]="!surveyForm.valid">Save</button>
      </div>
      <div class="p-2"><button nbButton (click)="close()">Close</button></div>
    </div>
  </nb-card-footer>
</nb-card>
