import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';

import { Store } from '@ngxs/store';
import { API } from '@aws-amplify/api';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import {
  LoadInsightsInactive,
  LoadInsightsInactiveSuccess,
  LoadInsightsSuccess,
  ManageInsight,
  ReloadInsights,
  SaveInsightComplete,
  SaveInsightFail,
  StoreListOfFailedJobs
} from 'src/app/components/insights/redux/insights.actions';
import { CogwheelService } from 'src/app/core/services/cogwheel.service';
import { environment } from 'src/environments/environment';
import { Insight } from '../../models/insight';
import { TimestampDatePipe } from '../../shared/custom-pipe/timestamp-date.pipe';
import { AmplifyServiceBuilder } from '../services/amplify.service.builder';

@Injectable({ providedIn: 'root' })
export class InsightService {
  public owner: string;
  public signedRequests: any;
  public allKeys: string[] = [];
  public selectedKeys: string[] = [];
  public insights_api = 'insights_api';
  public jobs_api_failed = 'get-failed-jobs';
  public jobs_api = 'insight_job_api_start';
  public individual_jobs_api = 'single_jobs_api';
  public type = 'insights';

  constructor(
    private store: Store,
    private toastrService: NbToastrService,
    private formatDatePipe: TimestampDatePipe,
    private router: Router,
    private cogWheelService: CogwheelService
  ) {
    setInterval(() => {
      this.loadInsightsAmplify(false, true);
    }, 300000);
  }

  public buildKeysForTable(insights: Insight[]) {
      this.cogWheelService.buildKeysForTable(insights, this.allKeys, this.store, this.type, false);

  }

  //Reload is simple boolean true false. If it is called by ReloadInsight action it simply shows the user that it finished action.
  public loadInsightsAmplify(reload?, enabled?) {
    const requestData = AmplifyServiceBuilder(this.insights_api, false, null, {
      enabled
    });

    API.get(this.insights_api, `/${environment.stage}/registrations`, requestData)
      .then((response) => {
        if (reload) {
          this.toastrService.show(status, 'Fetching Insights Complete', {
            status: 'success'
          });
        }
        this.buildKeysForTable(response);
        response.forEach((element) => {
          element.local_created_dttm = this.formatDatePipe.transform(element?.created_dttm, 'timestampToDate');
          element.local_last_updated_dttm = this.formatDatePipe.transform(
            element?.last_updated_dttm,
            'timestampToDate'
          );
          element.local_start_date = this.formatDatePipe.transform(element?.start_date, 'timestampToDate');
          element.local_end_date = this.formatDatePipe.transform(element?.end_date, 'timestampToDate');
          element.local_next_run_time = this.formatDatePipe.transform(element?.next_run_time, 'timestampToDate');
          element.local_last_run_time = this.formatDatePipe.transform(element?.last_run_time, 'timestampToDate');
        });

        if (!enabled) {
          this.store.dispatch(new LoadInsightsInactiveSuccess(response));
        } else {
          this.store.dispatch(new LoadInsightsSuccess(response));
        }
      })
      .catch((error) => {
        console.error(error);
        this.toastrService.show(status, 'Fetching Insights List Failed', {
          status: 'danger'
        });
      });
  }

  //Activate is used when user wants to activate Campaign and Insight from View Campaign component
  public postInsightsAmplify(insight: Insight, activate?: boolean) {
    const requestData = AmplifyServiceBuilder(this.insights_api, false, insight);
    API.post(this.insights_api, `/${environment.stage}/registrations`, requestData)
      .then((response) => {
        if (activate) {
          this.store.dispatch(new ManageInsight(response));
          this.toastrService.show(status, 'Insight activation complete', {
            status: 'success'
          });
        } else if (activate !== undefined) {
          this.store.dispatch(new ManageInsight(response));
          this.toastrService.show(status, 'Insight deactivation complete', {
            status: 'success'
          });
        } else {
          this.store.dispatch(new SaveInsightComplete(response, insight));
          this.store.dispatch(LoadInsightsInactive);
          this.store.dispatch(ReloadInsights);
        }
      })
      .catch((error) => {
        console.error(error);
        this.store.dispatch(new SaveInsightFail(error));
      });
  }

  public deleteInsightsAmplify(insight: Insight) {
    const requestData = AmplifyServiceBuilder(this.insights_api, false, insight);
    API.del(this.insights_api, `/${environment.stage}/registrations`, requestData)
      .then((response) => {
        this.store.dispatch(new SaveInsightComplete(true, insight));
      })
      .catch((error) => {
        console.error(error);
        this.store.dispatch(new SaveInsightFail(error));
      });
  }

  public loadFailedJobsAmplify(reload?) {
    const queryStringParameters = {
      start_date: moment().subtract(7, 'd').unix() * 1000,
      end_date: moment().unix() * 1000
    };
    const requestData = AmplifyServiceBuilder(this.jobs_api_failed, true, false, queryStringParameters);

    API.get(this.jobs_api_failed, `/${environment.stage}/jobs/get-failed-jobs`, requestData)
      .then((response) => {
        if (reload) {
          this.toastrService.show(status, 'Fetching Failed Jobs Complete', {
            status: 'info'
          });
        }
        this.store.dispatch(new StoreListOfFailedJobs(response));
      })
      .catch((error) => {
        console.error(error);
        this.toastrService.show(status, 'Fetching Failed Jobs Failed', {
          status: 'danger'
        });
      });
  }

  public runInsightJob(insight) {
    const requestData = AmplifyServiceBuilder(this.jobs_api, true);

    return new Observable<any>((observer) => {
      API.post(this.jobs_api, `/${environment.stage}/jobs/insights/` + insight.id, requestData)
        .then((response) => {
          observer.next(response);
          this.toastrService.show(status, 'Starting Insight' + insight.id + ' Success', { status: 'info' });
          observer.complete();
        })
        .catch((error) => {
          console.error(error);
          const message =
            error.response && error.response.data
              ? error.response.data.message
                ? error.response.data.message
                : error.response.data
              : 'Error!';
          this.toastrService.show(status, 'Starting Insight' + insight.id + ' Failed: ' + message, {
            status: 'danger'
          });
        });
    });
  }

  public terminateInsightJob(job_id, terminating_reason) {
    const body = { reason: terminating_reason ? terminating_reason : 'User Cancelled' };
    const requestData = AmplifyServiceBuilder(this.individual_jobs_api, true, body);

    return new Observable<any>((observer) => {
      API.del(this.individual_jobs_api, `/${environment.stage}/jobs/` + job_id, requestData)
        .then((response) => {
          observer.next(response);
          observer.complete();
        })
        .catch((error) => {
          console.error(error);
          const message =
            error.response && error.response.data
              ? error.response.data.message
                ? error.response.data.message
                : error.response.data
              : 'Error!';
          this.toastrService.show(status, 'Stoping Insight' + job_id + 'Failed: ' + message, {
            status: 'danger'
          });
        });
    });
  }
}
