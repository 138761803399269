import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SpinnerState } from 'src/app/shared/spinner/redux/spinner.state';
import { CleanScoreState, LoadSmartCleanRecords, RemoveCleanScore } from '../clean-score/redux';
import { SmartCleanService } from 'src/app/core/services/smart-clean.service';
import { LoadMessMakers, PatchMessMakers } from './redux/mess-makers.actions';
import { MessMakersState } from './redux/mess-makers.state';
import { MessMakersService } from 'src/app/core/services/mess-makers.service';
import { RouterState } from 'src/app/shared/redux';

@Component({
  selector: 'app-mess-makers',
  templateUrl: './mess-makers.component.html',
  styleUrls: ['./mess-makers.component.css']
})
export class MessMakersComponent implements OnInit, AfterViewInit  {
  @Select(CleanScoreState.smartCleanRecords) public data$: Observable<any[]>;
  @Select(MessMakersState.messMakersRecords) public messMakersData$: Observable<any[]>;
  @Select(SpinnerState) public loading: Observable<boolean>;
  @Select(RouterState.username) public data_username$: Observable<any>;


  public Object = Object;
  public spin;
  public cleanScoreRecords = [];
  public selectedPmaps = '';
  public messMakers = [
                      {name: 'Kids', value: 0},
                      {name: 'High Pollen', value: 0},
                      {name: 'Pets', value: 0}
                    ];

  public loadedMessMakers = [];

  public selectedRobots: string[] = [];
  public loadedRobots: string[] = [];
  public username =  '';

  constructor(private store: Store,
              private service: SmartCleanService,
              private messMakerService: MessMakersService) { }

  public ngAfterViewInit(): void {
    this.robotIdClear();
  }

  public ngOnInit(): void {
    this.data_username$.subscribe((data) => (this.username = data));

    this.data$.subscribe((data) => {
      if(data.length > 0) {
        this.service.populateFields(data, this.cleanScoreRecords);
        this.loadedRobots = this.selectedRobots;
      }
    });

    this.messMakersData$.subscribe((messMakersData) => {
      if(messMakersData.length > 0) {
        this.loadedMessMakers = messMakersData;
      } else {
        this.loadedMessMakers = this.messMakers;
      }
    });

    this.loading.subscribe((data) => (this.spin = data));
  }


  public robotIdAdd(event) {
    this.store.dispatch(new LoadSmartCleanRecords(event.label));
  }

  public pmapIdChange(event) {
    if(event !== undefined) {
      this.store.dispatch(new LoadMessMakers(event));
    }

  }


  public robotIdRemove(event) {
    delete this.cleanScoreRecords[event.label];
    delete this.selectedPmaps;
    this.loadedRobots = this.selectedRobots;
}


  public onSubmit() {
    const massMaker = {
      pk: 'pmapid#' + this.selectedPmaps + '#messmakers',
      mess_makers: this.loadedMessMakers,
      last_updated_by: this.username,
      last_updated_ts: Math.floor(Date.now() / 1000)
    };
    this.store.dispatch(new PatchMessMakers(massMaker));
  }

  public robotIdClear() {
    this.store.dispatch(new RemoveCleanScore());
    this.cleanScoreRecords = [];
    this.selectedPmaps = '';
    this.loadedRobots = [];
    this.selectedRobots = [];
    this.loadedMessMakers = this.messMakers;
  }

  public pmapIdClear() {
    this.selectedPmaps = '';
    this.loadedMessMakers = this.messMakers;
  }

}
