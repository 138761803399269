import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Store } from '@ngxs/store';
import { API } from '@aws-amplify/api';

import { QAPostNotificationFail, QAPostNotificationSuccess, RtpSendFailed, RtpSendSuccess } from 'src/app/components/qa-testing/redux/qa.actions';

import { environment } from 'src/environments/environment';

import { AmplifyServiceBuilder } from '../services/amplify.service.builder';

import { QAInsightEvent } from 'src/app/models/qa-models';
import { RealTimePersonalizationEvent } from 'src/app/models/rtp-models';
@Injectable({
  providedIn: 'root'
})
export class QaServiceService {
  public submittedNotification;
  public timelineNotifications: any;
  public sentEstimates;
  public timeline_notifications_api = 'timeline_notifications_api';
  public notifications_api = 'notifications_api';
  public cleaning_estimates_api = 'cleaning_estimates_api';
  public rtp_api = 'qa_rtp_api';

  constructor(private toastrService: NbToastrService, private store: Store) {}

  public loadTimelineNotificationsAmplify(selectedRobot) {
    const requestData = AmplifyServiceBuilder(this.timeline_notifications_api, true);

    API.get(
      this.timeline_notifications_api,
      `/circuit/robot/${selectedRobot.id || selectedRobot.robotid}/timeline/hkc`,
      requestData
    )
      .then((response) => {
        this.timelineNotifications = response;
      })
      .catch((error) => {
        console.error(error);
        this.toastrService.show(status, 'Load Timeline Notifications: Fail', {
          status: 'danger'
        });
      });
  }

  public submitNotification(notification: QAInsightEvent): void {
    const body = notification;
    const requestData = AmplifyServiceBuilder(this.notifications_api, true, body);

    API.post(this.notifications_api, `/${environment.stage}/campaigns/qa/notifications`, requestData)
      .then((response) => {
        this.submittedNotification = response;
        this.store.dispatch(new QAPostNotificationSuccess());
      })
      .catch((error) => {
        this.store.dispatch(new QAPostNotificationFail(error));
      });
  }

  public submitRTPNotification(event: RealTimePersonalizationEvent): void {

    const body = event;
    const requestData = AmplifyServiceBuilder(this.rtp_api, true, body);

    API.put(this.rtp_api, `/${environment.stage}/campaigns/qa/rtp`, requestData)
      .then((response) => {
        this.store.dispatch(new RtpSendSuccess());
      })
      .catch((error) => {
        this.store.dispatch(new RtpSendFailed());
      });
  }

  public submitCleaningEstimateAmplify(estimate) {
    const body = estimate;
    const requestData = AmplifyServiceBuilder(this.cleaning_estimates_api, true, body);

    API.post(this.cleaning_estimates_api, `/${environment.stage}/insights/qa/cleaning-estimates`, requestData)
      .then((response) => {
        this.sentEstimates = estimate;
      })
      .catch((error) => {
        console.error('Error occured', error);
      });
  }

  public submitRobotHealthAmplify(robot_id: string,campaign_id: string, counters: any[]) {
    const body = {
      robot_id,
      campaign_id,
      robot_health_notification: {
        counters
      }
    };
    const requestData = AmplifyServiceBuilder(this.notifications_api, true, body);

    API.post(this.notifications_api, `/${environment.stage}/campaigns/qa/robot-health`, requestData)
      .then((response) => {
        this.store.dispatch(new QAPostNotificationSuccess());
      })
      .catch((error) => {
        this.store.dispatch(new QAPostNotificationFail(error));
        console.error('Error occured', error);
      });
  }
}
