<nb-card accent="success" style="margin: auto; height: 100%" class="own-scroll">
  <nb-card-header>
    <div class="row">
      <div class="col-auto mr-auto">
        <nb-icon icon="search-outline"></nb-icon>
      </div>
      <div class="col-auto mr-auto">Campaign ID: {{ campaign.id }} Stats</div>
      <div class="col-auto"><button nbButton (click)="close()">X</button></div>
    </div>
  </nb-card-header>
  <nb-card-body class="own-scroll">
    <h6 class="text-uppercase">Campaign Details</h6>
    <hr />
    <dl class="row">
      <div class="col" *ngIf="campaign.name">
      <dt class="col">Campaign name:</dt>
      <dd class="col">{{ campaign.name }}</dd>
      </div>
      <div class="col" *ngIf="campaign.created_by">
      <dt class="col">Creator:</dt>
      <dd class="col">{{ campaign.created_by }}</dd>
      </div>
      <div class="col" *ngIf="campaign.created_dttm">
      <dt class="col">Time created:</dt>
      <dd class="col">{{ campaign.created_dttm * 1000 | date: 'medium' }}</dd>
      </div>

    </dl>
    <h6 class="text-uppercase">Stats</h6>
    <hr />
    <nb-card >  
      <nb-card-header class="d-flex">
        <div class="col-auto mr-auto">Deliveries</div>
        <div class="col-6">
          <ng-select
            id="timeFrame"
            name="timeFrame"
            (change)="fetchDeliveries()"
            placeholder="Select timeFrame"
            [(ngModel)]="choosenTime"
            style="width: 105%"
            [searchable]="false"
            [clearable]="false"
            required
          >
            <ng-option *ngFor="let time of timeFrame" [value]="time.value">{{ time.name }}</ng-option>
          </ng-select>
        </div>
      </nb-card-header>
      <span *ngIf="chartData.data">
        <nb-card-body *ngIf="chartData.data.length > 0">
          <chart type="bar" [data]="data" [options]="options" ></chart>
        </nb-card-body>
        
      </span>
      <nb-card-body *ngIf="!chartData.data || chartData.data.length <= 0">
        <div class="col-auto mr-auto">There is no data for the selected time frame.</div>
      </nb-card-body>
    </nb-card>
    <dl class="d-flex">
      <dt class="col">Total executions:</dt>
      <dd class="col">{{ campaign.executions }}</dd>

      <dt class="col">Avg. execution time:</dt>
      <dd class="col">{{ getAverageExecDuration() }}</dd>
    </dl>
    <dl class="d-flex">
      
      <dt class="col">Last campaign run:</dt>
      <dd class="col">{{ campaign.last_run_time * 1000 | date: 'medium' }}</dd>

          <dt class="col">Total Deliveries:</dt>
          <dd class="col">{{ count }}</dd>
    </dl>
  </nb-card-body>
  <nb-card-footer>
    <div class="d-flex flex-row-reverse">
      <div class="p-2">
        <button nbButton status="info" (click)="close()">Close</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
