<div class="row removed-background">
  <div class="row first-element">
    <div class="col-2">
      <label>ID:</label>
    </div>
    <div class="col-6">
      <p>{{ item.id }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-2">
      <label>Version:</label>
    </div>
    <div class="col-6">
      <p>{{ item.version }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-2">
      <label>Source:</label>
    </div>
    <div class="col-6">
      <p>{{ item.source }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-2">
      <label>Note:</label>
    </div>
    <div class="col-6">
      <p>{{ item.note }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-2">
      <label>Created by:</label>
    </div>
    <div class="col-6">
      <p>{{ item.created_by }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-2">
      <label>Created on:</label>
    </div>
    <div class="col-6">
      <p>{{ utility.convertUnixTime_global(item.created_ts) }}</p>
    </div>
  </div>
</div>
