<nb-card accent="success" style="width: 90%; height: 90%; max-height: 800px; max-width: 1600px; margin: auto">
  <nb-card-header>
    <div class="row">
      <div class="col-auto mr-auto">
        <nb-icon icon="search-outline"></nb-icon>
      </div>
      <div class="col-auto mr-auto">Content ID: {{ content.content_id }}</div>
      <div class="col-auto"><button nbButton (click)="close()">X</button></div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div style="height: 700px; padding: 1.143em; margin: -1.143em">
      <h5 class="text-uppercase">Content Details</h5>
      <dl class="row small">
        <dt class="col-3">Created By</dt>
        <dd *ngIf="content.created_by !== null; else noUser" class="col-9">
          {{ content.created_by }}
        </dd>
      </dl>
      <dl class="row small">
        <dt class="col-3">Created On</dt>
        <dd class="col-9">{{ formatted_created_time }}</dd>
      </dl>
      <dl class="row small">
        <dt class="col-3">Last Updated By</dt>
        <dd *ngIf="content.last_updated_by !== null; else noUser" class="col-9">
          {{ content.last_updated_by }}
        </dd>
      </dl>
      <dl class="row small">
        <dt class="col-3">Last Updated On</dt>
        <dd class="col-9">{{ formatted_updated_date }}</dd>
      </dl>
      <hr />

      <ng-template #noUser>
        <dd class="col-9">Unknown</dd>
      </ng-template>

      <ng-container *ngFor="let item of content | keyvalue">
        <dl class="row small">
          <ng-container
            *ngIf="
              item.value !== null && item.value !== '' && item.key !== 'payload_records' && item.key !== 'action_button'
            "
          >
            <dt class="col-3">
              {{ item.key.split('_').join(' ') | titlecase }}
            </dt>
            <dd class="col-9" style="overflow-wrap: break-word">
              {{ item.value }}
            </dd>
          </ng-container>
        </dl>

        <ng-container *ngIf="item.key === 'action_button' && item.value !== null">
          <h6 *ngIf="item.value.length !== 0" class="text-uppercase">Action Button</h6>
          <div *ngFor="let action_button of item.value; let i = index">
            <div *ngFor="let item of action_button | keyvalue">
              <div *ngIf="item.value !== null && item.value !== ''">
                <dl class="row small">
                  <dt class="col-3">
                    {{ item.key.split('_').join(' ') | titlecase }}
                  </dt>
                  <dd class="col-9" style="overflow-wrap: break-word">
                    {{ item.value }}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <hr />
        </ng-container>

        <ng-container *ngIf="item.key === 'payload_records' && item.value?.length > 0">
          <hr />
          <h6 class="text-uppercase">Payload Records</h6>
          <div *ngFor="let payload_record of item.value; let i = index">
            <nb-card accent="info">
              <nb-card-header>
                <div class="row">
                  <div *ngIf="payload_record.payload_record_type !== 'banner'" class="col-auto mr-auto">
                    {{ payload_record.payload_record_type.split('_').join(' ') | titlecase }}
                  </div>
                  <div *ngIf="payload_record.payload_record_type === 'banner'" class="col-auto mr-auto">
                    {{ payload_record.payload_record_type | titlecase }}
                  </div>
                </div>
              </nb-card-header>
              <nb-card-body>
                <div *ngFor="let item of payload_record | keyvalue">
                  <div *ngIf="item.value !== null && item.value !== ''">
                    <dl class="row small">
                      <dt class="col-auto mr-auto">
                        {{ item.key.split('_').join(' ') | titlecase }}
                      </dt>
                      <dd class="col-auto" style="overflow-wrap: break-word">
                        {{ item.value }}
                      </dd>
                    </dl>
                  </div>
                </div>
              </nb-card-body>
            </nb-card>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div class="d-flex flex-row-reverse">
      <div class="p-2">
        <button nbButton status="info" (click)="close()">Close</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
