import { Action, Selector, State, StateContext } from '@ngxs/store';
import { MessMakersStateModel } from './mess-makers-state.model';
import { Injectable } from '@angular/core';
import { ToggleShow, ToggleHide } from 'src/app/shared/spinner/redux/spinner.actions';
import { NbToastrService } from '@nebular/theme';
import { LoadMessMakers, LoadMessMakersFail, LoadMessMakersSuccess, PatchMessMakers, PatchMessMakersFail, PatchMessMakersSuccess } from './mess-makers.actions';
import { MessMakersService } from 'src/app/core/services/mess-makers.service';

@State<MessMakersStateModel>({
  name: 'messMakersRecord',
  defaults: {
    messMakersRecords: [],
    error: null
  }
})
@Injectable()
export class MessMakersState {

  constructor(private service: MessMakersService,
              private toastrService: NbToastrService) {}

  @Selector()
  public static messMakersRecords(state: MessMakersStateModel) {
    return state.messMakersRecords;
  }

  @Action(LoadMessMakers)
  public async loadMessMakersRecords(ctx: StateContext<MessMakersStateModel>, { payload }: any) {
    ctx.dispatch(ToggleShow);
    this.service.loadMessMakersAmplify(payload);
  }

  @Action(LoadMessMakersSuccess)
  public loadMessMakersRecordsSuccess(ctx: StateContext<MessMakersStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      messMakersRecords: payload
    });
    ctx.dispatch(ToggleHide);
  }

  @Action(LoadMessMakersFail)
  public loadMessMakersRecordsFail(ctx: StateContext<MessMakersStateModel>, { payload }: any) {
    this.toastrService.show(status, 'Fetching Mess Makers Record Failed: ' + payload, {
      status: 'danger'
    });
    ctx.dispatch(ToggleHide);
  }


  @Action(PatchMessMakers)
  public async patchMessMakers(ctx: StateContext<MessMakersStateModel>, { payload }: any) {
    ctx.dispatch(ToggleShow);
    this.service.patchMessMakersAmplify(payload);
  }

  @Action(PatchMessMakersSuccess)
  public async patchMessMakersSuccess(ctx: StateContext<MessMakersStateModel>, { payload }: any) {

    this.toastrService.show(status, 'Update: Complete', { status: 'success', preventDuplicates: true });
    ctx.dispatch(ToggleHide);
  }

  @Action(PatchMessMakersFail)
  public async PatchMessMakersFail(ctx: StateContext<MessMakersStateModel>, { payload }: any) {

    this.toastrService.show(status, 'Patching Mess Makers Record Failed: ' + payload, {
      status: 'danger'
    });
    ctx.dispatch(ToggleHide);
}
}
