/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { Actions, ofActionCompleted, Select, Store } from '@ngxs/store';
import { first, Observable } from 'rxjs';
import { EditContentComponent } from 'src/app/components/content/edit-content/edit-content.component';
import { ContentState } from 'src/app/components/content/redux/content.state';
import { InsightsState, LoadInsights, LoadInsightsInactiveSuccess, LoadInsightsSuccess, ReloadInsights } from 'src/app/components/insights/redux';
import { SurveysState } from 'src/app/components/surveys/redux';
import { Content } from 'src/app/models/content';
import { Insight } from 'src/app/models/insight';
import { NgxGenericModalComponent } from 'src/app/shared/ngx-generic-modal/ngx-generic-modal.component';
import { CampaignState } from '../../redux';
import { AppendCampaignEventTriggers, PostCampaign } from '../../redux/campaign.actions';
import { RouterState } from '../../../../shared/redux';
import { EditInsightComponent } from './../../../insights/edit-insight/edit-insight.component';

@Component({
  selector: 'app-edit-campaign',
  templateUrl: './edit-campaign.component.html',
  styleUrls: ['./edit-campaign.component.css', '../../../../shared/common_syles.css']
})
export class EditCampaignComponent implements OnInit {
  @Select(ContentState.content) public data$: Observable<any[]>;
  @Select(InsightsState.insightsActive) public dataInsights$: Observable<any[]>;
  @Select(CampaignState.eventTriggers) public dataEventTriggers$: Observable<any[]>;
  @Select(SurveysState.surveys) public dataSurveys$: Observable<any[]>;
  @Select(InsightsState.insightsInactive) public dataInsightsInactive$: Observable<any[]>;
  @Select(RouterState.username) public data_username$: Observable<any>;

  public insights;

  public campaign;
  public title;
  public content;
  public surveys = [];
  public selectedSurvey = [];

  public username = '';

  //Hold value for Jira tag dropdown
  public items;
  //Hold new values added by user in Jira like tag dropdown. We sent this to redux to add new values for reuse
  public newItems = [];

  public pushNotificationTypes = [
    'push_dock_update_alert',
    'push_important_generic',
    'push_product_announcement',
    'push_mission_survey',
    'push_dynamic'
  ];

  public metadataTypes = [
    { value: 'mca', name: 'Message Center Alert PN' },
    { value: 'mkt_mca', name: 'Marketing Message Center Alert PN' },
    { value: 'mca_pn_hd', name: 'Product Health Message Center Alert PN' }
  ];

  public campaignTypes = [
    { value: 'Notification', name: 'Notification' },
    { value: 'DataCollection', name: ' Data Collection' },
    { value: 'BetaSurvey', name: 'Beta Survey' },
    { value: 'SpaceChanged', name: 'Space Changed' },
    { value: 'ObjectZoneRecommendation', name: 'Object Zone Recommendation' },
    {
      value: 'SmartScheduleRecommendation',
      name: 'Smart Schedule Recommendation'
    },
    { value: 'MissionSurvey', name: 'Mission Survey' },
    { value: 'eCommerce', name: 'eCommerce' },
    { value: 'MapRecommendation', name: 'Map Recommendation' },
    {
      value: 'HighTrafficZoneRecommendation',
      name: 'High Traffic Zone Recommendation'
    },
    { value: 'Notification2', name: 'Notification2' },
    { value: 'FavoriteRecommendation', name: 'Favorite Recommendation' },
    { value: 'ExperimentControl', name: 'ExperimentControl' },
    { value: 'RobotHealthNotification', name: 'RobotHealthNotification' },
    { value: 'ImageReview', name: 'Image Review' },
    { value: 'ContextualMessage', name: 'Contextual Message' },
    { value: 'Banner', name: 'Banner' },
    { value: 'ContextualMessageNonDismissible', name: 'ContextualMessageNonDismissible' }
  ];

  public campaignChannels = [
    { value: 'message_center', name: 'Message Center' },
    { value: 'Circuit', name: 'Circuit' },
    { value: 'robot_health', name: 'Robot Health' }
  ];

  public campaignCategories = [
    { value: 'coach', name: 'Coach' },
    { value: 'ecommerce', name: 'ECommerce' },
    { value: 'smart_home', name: 'SmartHome' },
    { value: 'smart_map', name: 'SmartMap' },
    { value: 'robot_release', name: 'RobotRelease' }
  ];

  public campaignSubCategories = [
    { value: 'introduce', name: 'Introduce' },
    { value: 'announce', name: 'Announce' },
    { value: 'educate', name: 'Educate' },
    { value: 'recommend', name: 'Recommend' },
    { value: 'promote', name: 'Promote' },
    { value: 'maintain', name: 'Maintain' },
    { value: 'survey', name: 'Survey' }
  ];

  constructor(
    protected ref: NbDialogRef<EditCampaignComponent>,
    private store: Store,
    private actions$: Actions,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService
  ) {}

  public ngOnInit() {
    this.dataSurveys$.subscribe((data) =>
      data.forEach((item) => {
        const obj = { name: item.survey_name, id: item.survey_id };
        this.surveys.push(obj);
      })
    );
    this.data_username$.subscribe((data) => (this.username = data));

    if (this.campaign.responses) {
      this.campaign.responses.forEach((item) => {
        const obj = { name: item.name, id: item.id };
        this.selectedSurvey.push(obj);
      });
    }
    this.data$.subscribe((data) => (this.content = data));
    this.dataInsights$.subscribe((data) => (this.insights = data));
    this.dataInsightsInactive$.subscribe((data) => (this.insights = this.insights.concat(data)));
    this.dataEventTriggers$.subscribe((data) => (this.items = data));
  }

  public save() {
    let edit;
    if (this.title === 'Edit') {
      edit = true;
      this.campaign.last_updated_by = this.username;
    } else {
      this.campaign.created_by = this.username;
      this.campaign.last_updated_by = this.username;
    }

    this.campaign.responses = this.selectedSurvey;
    this.store.dispatch(new PostCampaign(this.campaign, edit));
    this.store.dispatch(new AppendCampaignEventTriggers(this.newItems));
    this.ref.close();
  }

  public close() {
    this.dialogService
      .open(NgxGenericModalComponent, {
        context: {
          title: 'Confirm Close',
          message: 'Are you sure that you want to close the window? Any changes that you have made will be lost.'
        }
      })
      .onClose.subscribe((data) => {
        if (data) {
          this.ref.close();
        }
      });
  }

  /** This method is just for parse checking. May be needed later on tho! */
  public pushChanged($event) {
    this.campaign.push_notification_type = $event;
  }

  /** Used for NGX-Chips - Jira like tags for campaign event triggers */
  public onItemRemoved(event) {
    this.campaign.event_triggers = Object.assign([], this.campaign.event_triggers);
    this.campaign.event_triggers = this.campaign.event_triggers.filter((e) => e !== event);
    this.newItems = this.newItems.filter((e) => e !== event);
  }

  /** Used for NGX-Chips - Jira like tags for campaign event triggers */
  public onItemAdded(event) {
    this.campaign.event_triggers = Object.assign([], this.campaign.event_triggers);
    this.campaign.event_triggers.push(event.value);
    this.newItems.push(event.value);
  }

  public editContent(event) {
    const found = this.content.find((element) => element.content_id === event);
    if (found) {
      this.dialogService.open(EditContentComponent, {
        context: {
          title: 'Edit',
          content: <Content>Content.copy(found)
        }
      });
    } else {
      this.toastrService.show(status, 'Content with this ID does not exist', {
        status: 'danger'
      });
    }
  }

  public editInsight(event) {

    this.store.dispatch(ReloadInsights);
    this.store.dispatch(LoadInsights);
    this.actions$.pipe(ofActionCompleted(LoadInsightsInactiveSuccess)).pipe(first()).subscribe((data) => {
      this.openEditInsight(event);
    });

  }

  private openEditInsight(event) {
    const found = this.insights.find((element) => element.id === event);

    if (found) {
      this.dialogService.open(EditInsightComponent, {
        context: {
          insight: <Insight>Insight.copy(found)
        }
      });
    } else {
      this.toastrService.show(status, 'Insight with this ID does not exist, try to refresh the page if the Insight was recently created/activated', {
        status: 'danger'
      });
    }
  }
}
