import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ContentState } from 'src/app/components/content/redux/content.state';
import { InsightsState } from 'src/app/components/insights/redux';
import { SurveysState } from 'src/app/components/surveys/redux';
import { Content } from 'src/app/models/content';
import { Insight } from 'src/app/models/insight';
import { Survey } from 'src/app/models/survey';
import { CampaignState, LoadAffectedCampaigns, DeepDeleteCampaign } from '../../redux';
import { RouterState } from '../../../../shared/redux';
import { ViewInsightComponent } from 'src/app/components/insights/view-insight/view-insight.component';
import { ViewContentComponent } from 'src/app/components/content/view-content/view-content.component';
import { ViewSurveyComponent } from 'src/app/components/surveys/view-survey/view-survey.component';

@Component({
  selector: 'app-delete-campaign',
  templateUrl: './delete-campaign.component.html',
  styleUrls: ['./delete-campaign.component.css']
})
export class DeleteCampaignComponent implements OnInit {
  @Select(CampaignState.campaign) public dataCampaigns$: Observable<any>;
  @Select(InsightsState.insightsActive) public dataInsights$: Observable<any[]>;
  @Select(InsightsState.insightsInactive) public dataInsightsInactive$: Observable<any[]>;
  @Select(ContentState.content) public dataContents$: Observable<any[]>;
  @Select(SurveysState.surveys) public dataSurveys$: Observable<any[]>;
  @Select(RouterState.username) public data_username$: Observable<any>;
  @Select(CampaignState.affectedCampaigns) public dataAffectedCampaigns$: Observable<any>;
  @Select(CampaignState.spin) public dataSpin$: Observable<any>;

  public campaigns;
  public insights;
  public contents;
  public surveys;

  public affectedCampaigns;
  public campaign;
  public content;
  public insight;

  public spin;
  public title;
  public username = '';
  public delete = {
    insight: false,
    content: false,
    surveys: {}
  };
  public confirmation = '';

  constructor(
    protected ref: NbDialogRef<DeleteCampaignComponent>,
    private store: Store,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService
  ) {}

  public ngOnInit() {
    this.dataCampaigns$.subscribe((data) => (this.campaigns = data));
    this.dataInsights$.subscribe((data) => (this.insights = data));
    this.dataInsightsInactive$.subscribe((data) => (this.insights = this.insights.concat(data)));
    this.dataContents$.subscribe((data) => (this.contents = data));
    this.dataSurveys$.subscribe((data) => (this.surveys = data));
    this.data_username$.subscribe((data) => (this.username = data));
    this.dataAffectedCampaigns$.subscribe((data) => (this.affectedCampaigns = data));
    this.dataSpin$.subscribe((data) => (this.spin = data));

    this.store.dispatch(new LoadAffectedCampaigns(this.campaign));

    this.insight = this.insights.find(({ id }) => id === this.campaign.trigger_id);
    if (!this.insight) {
      this.insight = {
        name: 'Insight not found'
      };
    }

    this.campaign.responses.forEach((response) => {
      this.delete.surveys[response.id] = false;
    });
  }

  public deepDelete() {
    const payload = {
      campaign_id: this.campaign.id,
      deleted_by: this.username,
      delete_insight: this.delete.insight,
      delete_content: this.delete.content,
      delete_surveys: Object.keys(this.delete.surveys).filter((survey_id) => (this.delete.surveys[survey_id]))
    };
    this.store.dispatch(new DeepDeleteCampaign(payload));
    this.ref.close();
  }

  public close() {
    this.ref.close();
  }

  public viewContent(event) {
    const found = this.contents.find((element) => element.content_id === event);
    if (found) {
      this.dialogService.open(ViewContentComponent, {
        context: {
          content: Content.copy(found) as Content
        }
      });
    } else {
      this.toastrService.show(status, 'Content with this ID does not exist', {
        status: 'danger'
      });
    }
  }

  public viewInsight(event) {
    const found = this.insights.find((element) => element.id === event);
    if (found) {
      this.dialogService.open(ViewInsightComponent, {
        context: {
          insight: Insight.copy(found) as Insight
        }
      });
    } else {
      this.toastrService.show(status, 'Insight with this ID does not exist', {
        status: 'danger'
      });
    }
  }

  public viewSurvey(event) {
    const found = this.surveys.find((element) => element.survey_id === event);
    if (found) {
      this.dialogService.open(ViewSurveyComponent, {
        context: {
          survey: Survey.copy(found) as Survey
        }
      });
    } else {
      this.toastrService.show(status, 'Survey with this ID does not exist', {
        status: 'danger'
      });
    }
  }

  public surveysIncludes(responseId) {
    return this.surveys.find((survey) => survey.survey_id === responseId) !== undefined;
  }

  public contentsIncludes(contentId) {
    return this.contents.find((content) => content.content_id === contentId) !== undefined;
  }

  public anyCampaignsActive(insightId?, contentId?, surveyId?) {
    let active = 0;

    if (insightId) {
      this.affectedCampaigns.insight.forEach((campaign) => {
        if (campaign.enabled) {
          active += 1;
        }
      });
    }
    if (contentId) {
      this.affectedCampaigns.content.forEach((campaign) => {
        if (campaign.enabled) {
          active += 1;
        }
      });
    }
    if (surveyId) {
      this.affectedCampaigns.surveys[surveyId].forEach((campaign) => {
        if (campaign.enabled) {
          active += 1;
        }
      });
    }

    return active;
  }
}
