import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { UtilityService } from 'src/app/core/services/utility.service';
import { SourceImage } from 'src/app/models/drb-models/source-image';
@Component({
  selector: 'app-view-image',
  templateUrl: './view-image.component.html',
  styleUrls: ['./view-image.component.css']
})
export class ViewImageComponent implements OnInit {
  public image: SourceImage;
  public showSpinner = false;
  constructor(
    protected ref: NbDialogRef<ViewImageComponent>,
    private dialogService: NbDialogService,
    public utility: UtilityService
  ) {}

  public ngOnInit() {}

  public close() {
    this.ref.close();
  }
}
