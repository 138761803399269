import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {NbTagComponent, NbTagInputDirective, NbToastrService} from '@nebular/theme';
import {Select} from '@ngxs/store';
import {randomUUID} from 'crypto';
import {MapsService} from '../../../../core/services/maps.service';
import {UtilityService} from '../../../../core/services/utility.service';
import {CommandDef, Region} from '../../../../models/qa-models/favorite-recommendation';
import {MapRegion, RobotMaps, RobotMapVersion} from '../../../../models/qa-models/robot-maps';
import {QaState} from '../../redux';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-qa-favorite-form',
    templateUrl: './qa-favorite-form.component.html',
    styleUrls: ['./qa-favorite-form.component.css']
})
export class QaFavoriteFormComponent implements OnInit {
  @Input() public campaign;
  @Input() public robotId;

  @Output() public outputFavoriteValues = new EventEmitter();

  @Select(QaState.robotMaps) public robot_maps$: Observable<any[]>;
  @Select(QaState.robotMapVersions) public robot_map_versions$: Observable<any[]>;
  @Select(QaState.robotMapRegions) public robot_map_regions$: Observable<any[]>;

  @ViewChild(NbTagInputDirective, { read: ElementRef }) private tagInput: ElementRef<HTMLInputElement>;

  public campName: string;
  public localParamArray = [];
  public localValueArray = [];

  public pmapId: string;
  public pmapvId: string;
  public isOrdered: string;
  public command: string;
  public regions = new Set<MapRegion>();

  public maps = [];
  public mapVersions = [];
  public mapRegions = [];

  public regionOptions = [];
  public formGroup: FormGroup;
  public verify = true;
  public btnVerifyDisable = true;

  constructor(public mapsService: MapsService,
              private utility: UtilityService,
              private toastrService: NbToastrService) { }

  public ngOnInit(): void {
      this.robot_maps$.subscribe((data) => {
          if(Array.isArray(data)) {
              const rm: RobotMaps = data.find(r => r.robotId === this.robotId);
              if (Boolean(rm)) {
                  this.maps = [''].concat(rm.maps);
              }
          }
      });
      this.robot_map_versions$.subscribe((data) => {
          if(Array.isArray(data)) {
              const rmv: RobotMapVersion = data.find(r => r.robotId === this.robotId && r.pmapId === this.pmapId);
              if (Boolean(rmv)) {
                  this.mapVersions = rmv.versions;
              }
          }
      });
      this.robot_map_regions$.subscribe((data) => {
          if(Array.isArray(data)) {
              if (Boolean(data)) {
                  this.mapRegions = data;
                  this.regionOptions = this.mapRegions;
                  this.regions = new Set<MapRegion>(this.mapRegions);
              }
          }
      });
      if(this.maps.length === 0) {
          this.mapsService.loadMapsForRobotAmplify(this.robotId);
      }
  }

  public updateMapVersions(): void {
      if(this.mapVersions.length === 0) {
          this.mapsService.loadMapVersionsForRobotAmplify(this.robotId, this.pmapId);
      }

      this.updateParameters();
  }

  public updateMapRegions() {
      this.mapsService.loadMapRegionsAmplify(this.robotId, this.pmapId, this.pmapvId);
      this.updateParameters();
  }

  public onTagRemove(tagToRemove: NbTagComponent): void {
      console.log(tagToRemove.text);
      const mri = this.mapRegions.findIndex(r => r.regionId === tagToRemove.text);
      this.regions.delete(this.mapRegions[mri]);
      this.regionOptions = this.mapRegions.filter(mr => !this.regions.has(mr));

      this.updateParameters();
  }

  public onTagAdd(value: string): void {
    if (value) {
        // find the region where regionId === value
        // add that region back to this.regions
        const mri = this.mapRegions.findIndex(r => r.regionId === value);
        this.regions.add(this.mapRegions[mri]);
        this.regionOptions = this.mapRegions.filter(mr => !this.regions.has(mr));
    }

    // The following clears the input's text value and leaves only the tags
    this.tagInput.nativeElement.value = '';

    this.updateParameters();
  }

  public updateParameters(): void {
      const def = new CommandDef();

      def.id = this.utility.createRandomMockID();
      def.robot_id = this.robotId;
      def.pmap_id = this.pmapId;
      def.user_pmapv_id = this.pmapvId;
      def.command = this.command;
      def.regions = [...this.regions].map(mr => new Region(mr.regionId, mr.regionType));

      if(this.isOrdered === 'Yes') {
          def.ordered = 1;
      } else {
          def.ordered = 0;
      }

      this.outputFavoriteValues.emit({commanddefs: [def]});
  }

}
