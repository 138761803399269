import { Component, Input, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/core/services/utility.service';

@Component({
  selector: 'app-source-removed',
  templateUrl: './source-removed.component.html',
  styleUrls: ['../drb-diff.component.css']
})
export class SourceRemovedComponent{

  @Input() public item;

  constructor(public utility: UtilityService) {}
}
