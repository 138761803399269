import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { Select, Store } from '@ngxs/store';
import { Auth } from '@aws-amplify/auth';
import { API } from '@aws-amplify/api';
import { Observable } from 'rxjs';
import {
  LoadCampaignSuccess,
  LoadAffectedCampaignsSuccess,
  ManageCampaign,
  PostSaveComplete,
  PostSaveFail,
  PromotionSuccess,
  DeepDeleteCampaignSuccess
} from 'src/app/components/campaigns/redux/campaign.actions';
import { InsightsState } from 'src/app/components/insights/redux/insights.state';
import { CogwheelService } from 'src/app/core/services/cogwheel.service';
import { environment } from 'src/environments/environment';
import { Campaign } from '../../models/campaign';
import { TimestampDatePipe } from '../../shared/custom-pipe/timestamp-date.pipe';
import { AmplifyServiceBuilder } from '../services/amplify.service.builder';
import { Insight } from 'src/app/models/insight';

@Injectable({ providedIn: 'root' })
export class CampaignService {
  @Select(InsightsState.allInsights) public all_insights$: Observable<any>;
  @Select(InsightsState.insightsInactive) public inactive_insights$: Observable<any>;

  public signedRequests: any;
  public owner: string;
  public allKeys: string[] = [];
  public selectedKeys: string[] = [];
  public type = 'campaigns';

  public insight;
  public inactive_insights;

  public campaigns: any;

  // promotion process flags
  public promotionResponse: any = false;
  public promotionError: any = false;
  public promotionSpinner: any = false;

  public campaigns_api = 'campaigns_api';
  public jobs_api = 'campaign_job_api_start';
  public individual_jobs_api = 'single_jobs_api';
  public campaign_deliveries_api = 'campaign_deliveries_api';

  constructor(
    private store: Store,
    private toastrService: NbToastrService,
    private router: Router,
    private formatDatePipe: TimestampDatePipe,
    private cogWheelService: CogwheelService
  ) {
    this.all_insights$.subscribe((data) => {
      this.insight = data;
    });
    this.inactive_insights$.subscribe((data) => {
      this.inactive_insights = data;
    });
  }


  public buildKeysForTable(campaign: Campaign[]) {
    this.cogWheelService.buildKeysForTable(campaign, this.allKeys, this.store, this.type, true);
  }

  public loadCampaignsAmplify(reload?) {
    const requestData = AmplifyServiceBuilder(this.campaigns_api, true);

    if (requestData) {
      API.get(this.campaigns_api, `/${environment.stage}/campaigns`, requestData)
        .then((response) => {
          if (reload) {
            this.toastrService.show(status, 'Fetching Campaigns Complete', {
              status: 'success'
            });
          }
          this.buildKeysForTable(response);
          response.campaigns.forEach((element) => {
            element.local_created_dttm = this.formatDatePipe.transform(element?.created_dttm, 'timestampToDate');
            element.local_last_updated_dttm = this.formatDatePipe.transform(
              element?.last_updated_dttm,
              'timestampToDate'
            );
            element.local_start_date = this.formatDatePipe.transform(element?.start_date, 'timestampToDate');
            element.local_end_date = this.formatDatePipe.transform(element?.end_date, 'timestampToDate');
            element.local_next_run_time = this.formatDatePipe.transform(element?.next_run_time, 'timestampToDate');
            element.local_last_run_time = this.formatDatePipe.transform(element?.last_run_time, 'timestampToDate');
            this.addInsightName(element);
          });
          this.store.dispatch(new LoadCampaignSuccess(response));
        })
        .catch((error) => {
          this.toastrService.show(status, 'Fetching Campaigns FAILED', {
            status: 'danger'
          });
          console.error(error);
        });
    } else {
      this.router.navigate(['/login'], { replaceUrl: true });
    }
  }

  public loadAffectedCampaignsAmplify(campaign: Campaign, reload?) {
    const queryStringParameters = { campaign_id: campaign.id };
    const requestData = AmplifyServiceBuilder(this.campaigns_api, true, false, queryStringParameters);
    API.get(this.campaigns_api, `/${environment.stage}/campaigns/deep-delete`, requestData)
      .then((response) => {
        if (reload) {
          this.toastrService.show(status, 'Fetching Affected Campaigns Complete', {
            status: 'success'
          });
        }
        this.store.dispatch(new LoadAffectedCampaignsSuccess(response));
      })
      .catch((error) => {
        this.toastrService.show(status, 'Fetching Affected Campaigns FAILED', {
          status: 'danger'
        });
        console.error(error);
      });
  }

  public loadAffectedCampaignsByInsightAmplify(insight: Insight, reload?) {
    const queryStringParameters = { trigger_id: insight.id };
    const requestData = AmplifyServiceBuilder(this.campaigns_api, true, false, queryStringParameters);
    API.get(this.campaigns_api, `/${environment.stage}/campaigns/deep-delete`, requestData)
      .then((response) => {
        if (reload) {
          this.toastrService.show(status, 'Fetching Affected Campaigns By Insight Complete', {
            status: 'success'
          });
        }
        this.store.dispatch(new LoadAffectedCampaignsSuccess(response));
      })
      .catch((error) => {
        this.toastrService.show(status, 'Fetching Affected Campaigns By Insight FAILED', {
          status: 'danger'
        });
        console.error(error);
      });
  }

  //Activate is used when user wants to activate Campaign and Insight from View Campaign component
  public postCampaignsAmplify(campaign: Campaign, edit?: boolean, activate?: boolean) {
    const requestData = AmplifyServiceBuilder(this.campaigns_api, true, campaign);
    API.post(this.campaigns_api, `/${environment.stage}/campaigns`, requestData)
      .then((response) => {
        if (activate) {
          response = this.addInsightName(response);
          this.store.dispatch(new ManageCampaign(response));
          this.toastrService.show(status, 'Campaign activation complete', {
            status: 'success'
          });
        } else if (activate !== undefined) {
          response = this.addInsightName(response);
          this.store.dispatch(new ManageCampaign(response));
          this.toastrService.show(status, 'Campaign deactivation complete', {
            status: 'success'
          });
        } else {
          response = this.addInsightName(response);
          this.store.dispatch(new PostSaveComplete(edit, response));
        }
      })
      .catch((error) => {
        this.store.dispatch(new PostSaveFail(error));
      });
  }

  public deepDeleteCampaignAmplify(payload) {
    const requestData = AmplifyServiceBuilder(this.campaigns_api, true, payload);
    API.post(this.campaigns_api, `/${environment.stage}/campaigns/deep-delete`, requestData)
      .then((response) => {
          this.toastrService.show(status, 'Deep Delete Campaign ' + payload.campaign_id + ' Success', { status: 'success' });
          this.store.dispatch(new DeepDeleteCampaignSuccess(payload));
      })
      .catch((error) => {
        console.error(error);
        const message =
          error.response && error.response.data
            ? error.response.data.message
              ? error.response.data.message
              : error.response.data
            : 'Error!';
        this.toastrService.show(status, 'Deep Delete Campaign ' + payload.campaign_id + ' Failed: ' + message, { status: 'danger' });
      });
  }

  public async postPromoteCampaign(campaign_id: string, username: string) {
    // Fetch token for authorizer lambda pass the token in the API request
    await Auth.currentSession().then((res) => {
      const accessToken = res.getAccessToken();
      const token = accessToken.getJwtToken();
      this.postPromoteCampaignEndpoint(campaign_id, username, token);
    });
  }

  public postPromoteCampaignEndpoint(campaign_id: string, username: string, token: string): any {
    // Promotion API Request
    const body = { campaign_id, promoter_username: username };
    const requestData = AmplifyServiceBuilder(this.campaigns_api, true, body);

    this.promotionSpinner = true;
    API.post(this.campaigns_api, `/${environment.stage}/promotion?token=${token}`, requestData)
      .then((response) => {
        this.promotionResponse = response;
        this.promotionSpinner = false;
        this.toastrService.show(status, 'Campaign has been promoted.', {
          status: 'success'
        });
        this.store.dispatch(new PromotionSuccess('success'));
      })
      .catch((error) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        (this.promotionError =
          error.response && error.response.data
            ? error.response.data.message
              ? error.response.data.message
              : error.response.data
            : 'Error!'),
          (this.promotionSpinner = false);
      });
  }

  public getCampaignDeliveries(campaign_id, startDate?, endDate?) {
    const queryStringParameters = {
      campaign_id,
      start_date: startDate,
      end_date: endDate
    };
    const requestData = AmplifyServiceBuilder(this.campaign_deliveries_api, true, false, queryStringParameters);

    this.toastrService.show(status, 'Fetching deliveries for ' + campaign_id, {
      status: 'info'
    });

    return API.get(this.campaign_deliveries_api, `/${environment.stage}/deliveries`, requestData);
  }

  public runCampaignJob(campaign) {
    return new Observable<any>((observer) => {
      const requestData = AmplifyServiceBuilder(this.jobs_api, true);

      API.post(this.jobs_api, `/${environment.stage}/jobs/campaigns/` + campaign.id, requestData)
        .then((response) => {
          observer.next(response);
          this.toastrService.show(status, 'Starting Campaign ' + campaign.id + ' Success', { status: 'info' });
          observer.complete();
        })
        .catch((error) => {
          console.error(error);
          const message =
            error.response && error.response.data
              ? error.response.data.message
                ? error.response.data.message
                : error.response.data
              : 'Error!';
          this.toastrService.show(status, 'Starting Campaign ' + campaign.id + ' Failed:' + message, {
            status: 'danger'
          });
        });
    });
  }

  public terminateCampaignJob(job_id, terminating_reason) {
    const body = { reason: terminating_reason ? terminating_reason : 'User Cancelled' };

    return new Observable<any>((observer) => {
      const requestData = AmplifyServiceBuilder(this.jobs_api, true, body);

      API.del(this.individual_jobs_api, `/${environment.stage}/jobs/` + job_id, requestData)
        .then((response) => {
          observer.next(response);
          observer.complete();
        })
        .catch((error) => {
          console.error(error);
          const message =
            error.response && error.response.data
              ? error.response.data.message
                ? error.response.data.message
                : error.response.data
              : 'Error!';
          this.toastrService.show(status, 'Stopping Campaign ' + job_id + ' Failed' + message, {
            status: 'danger'
          });
        });
    });
  }

  public addInsightName(item) {

    const insight = this.insight.find(({ id }) => id === item.trigger_id);
      if (insight) {
        item.insight_name = insight.name;
      } else if (this.insight.length <= 0 || this.inactive_insights.length <= 0) {
        item.insight_name = '-';
      }
      else {
        item.insight_name = 'Insight was deleted.';
      }

    return item;
  }
}
