<nb-card
  accent="success"
  style="width: 90%; height: 90%; min-width: 700px; max-height: 800px; max-width: 1600px; margin: auto"
  class="own-scroll"
>
  <nb-card-header>
    <div class="row">
      <div class="col-auto mr-auto" *ngIf="!robotFamilyMetadata.created_dttm">
        <nb-icon icon="plus-outline"></nb-icon>
      </div>
      <div class="col-auto mr-auto" *ngIf="robotFamilyMetadata.created_dttm">
        <nb-icon icon="edit-2-outline"></nb-icon>
      </div>
      <div class="col-auto mr-auto">{{ title }} Robot Family Metadata</div>
      <div class="col-auto"><button nbButton (click)="close()">X</button></div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div style="height: 700px; padding: 1.143em; margin: -1.143em">
      <form [formGroup]="metadataForm" (ngSubmit)="save()">
        <!-- Robot Family Metadata Form -->
        <br />

        <div class="row">
          <div class="col">
            <label>Robot Family<span style="color: red">*</span>:</label>
          </div>
          <div class="col-9">
            <input nbInput placeholder="Robot Family" style="width: 100%" formControlName="robot_family" />
          </div>
          <div *ngIf="metadataForm.get('robot_family').hasError('required') && metadataForm.get('robot_family').touched ">
            <p class="error"> Robot Family is a required field</p>
          </div>
          <div *ngIf="metadataForm.get('robot_family').hasError('pattern')">
            <p class="error">
              White spaces are not allowed.
            </p>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col">
            <label>Metadata Type<span style="color: red">*</span>:</label>
          </div>
          <div class="col-9">
            <nb-select
                placeholder="Metadata Type"
                formControlName="metadata_type"
                style="width: 100%"
              >
                <nb-option
                  *ngFor="let metadataType of metadataTypes"
                  [value]="metadataType"
                >
                  {{ metadataType }}
                </nb-option>
            </nb-select>
          </div>
          <div *ngIf="metadataForm.get('metadata_type').hasError('required') && metadataForm.get('metadata_type').touched">
            <p class="error"> Metadata Type is a required field</p>
          </div>
          <div *ngIf="metadataForm.hasError('validate_key')">
            <p class="error">
             This combination of robot family and metadata type already exist.
            </p>
          </div>
        </div>
        <br />


        <!-- Metadatas Form -->
        <div formArrayName="metadata">
          <div *ngFor="let met of metadata().controls; let qi = index">
            <div [formGroupName]="qi">
              <br />
              <nb-accordion>
                <nb-accordion-item>
                  <nb-accordion-item-header (click)="$event.stopPropagation()">
                    <div class="col-auto mr-auto">
                      <label> Extent Type {{ [qi+1] }} </label>
                    </div>
                    <div class="col-auto" style="padding-right: 40px">
                      <button
                        nbTooltip="Delete Metadata"
                        nbButton
                        size="small"
                        status="danger"
                        (click)="removeMetadata(qi)"
                      >
                        <nb-icon icon="trash-2-outline"></nb-icon>
                      </button>
                    </div>
                  </nb-accordion-item-header>
                  <nb-accordion-item-body>
                    <nb-card>
                      <nb-card-body>

                        <div class="row">
                          <div class="col">
                            <label>Extent Type<span style="color: red">*</span>:</label>
                          </div>
                          <div class="col-9">
                            <nb-select
                                placeholder="Extent Type"
                                formControlName="extent_type"
                                style="width: 100%"
                              >
                                <nb-option
                                  *ngFor="let extentType of extentTypes"
                                  [value]="extentType"
                                >
                                  {{ extentType }}
                                </nb-option>
                            </nb-select>
                            <div *ngIf="met.get('extent_type').hasError('required') && met.get('extent_type').touched">
                              <p class="error">
                                Extent Type is a required field.
                              </p>
                            </div>
                          </div>
                        </div>
                        <br />
                        <div class="row">
                          <div class="col">
                            <label>Minimum Confidence<span style="color: red">*</span>:</label>
                          </div>
                          <div class="col-9">
                            <input
                              formControlName="confidence"
                              nbInput
                              min="0"
                              max="100"
                              type="number"
                              placeholder="Minimum Confidence"
                              style="width: 100%"
                            />
                          </div>
                        </div>
                        <br />

                        <div class="row">
                          <div class="col">
                            <label>Priority:</label>
                          </div>
                          <div class="col-9">
                            <input
                              formControlName="priority"
                              nbInput
                              [defaultValue]=null
                              min="1"
                              type="number"
                              placeholder="Priority is an optional field."
                              style="width: 100%"
                            />
                          </div>
                        </div>
                        <br />
                      </nb-card-body>
                    </nb-card>
                  </nb-accordion-item-body>
                </nb-accordion-item>
              </nb-accordion>
            </div>
          </div>
        </div>
        <div *ngIf="metadataForm.hasError('validate_metadata')">
          <p class="error">
           Metadata extent_type must be unique.
          </p>
        </div>
        <div *ngIf="metadataForm.hasError('validate_metadata_exist')">
          <p class="error">
           Metadata is a required field.
          </p>
        </div>

        <br />
        <div class="row">
          <div class="col-auto">
            <button
              nbButton
              nbTooltip="Create New Metadata"
              size="small"
              status="success"
              type="button"
              (click)="addMetadata()"
            >
              <nb-icon icon="plus-square-outline"></nb-icon>Add Extent Type
            </button>
          </div>
        </div>
        <br />
      </form>
    </div>
  </nb-card-body>

  <nb-card-footer>
    <div class="d-flex flex-row-reverse">
      <div class="p-2">
        <button nbButton status="success" (click)="save()" >Save</button>
      </div>
      <div class="p-2"><button nbButton (click)="close()">Close</button></div>
    </div>
  </nb-card-footer>
</nb-card>
