import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-campaign-analytics',
    templateUrl: './campaign-analytics.component.html',
    styleUrls: ['./campaign-analytics.component.css']
})
export class CampaignAnalyticsComponent implements OnInit {
  public tabs: any[] = [
      {
          title: 'Dashboard',
          route: '/campaigns/analytics/dashboard'
      },
      {
          title: 'Deliveries',
          route: '/campaigns/analytics/deliveries'
      }
  ];

  constructor() { }

  public ngOnInit() {
  }

}
