// Modules
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import {
  NbButtonModule,
  NbCalendarModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbDialogModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbRouteTabsetModule,
  NbSelectModule,
  NbTabsetModule,
  NbTooltipModule,
  NbCardModule,
  NbAccordionModule,
  NbToggleModule
} from '@nebular/theme';
import { NgSelectModule } from '@ng-select/ng-select';
import { TagInputModule } from 'ngx-chips';
import { MatDatepickerModule } from '@matheo/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CoreModule } from '../../core/core.module';
import { ChartsModule } from '../../@theme/charts/charts.module';
import { SharedModule } from '../../shared/shared.module';
import { DrbSourcesComponent } from './drb-sources/drb-sources.component';
import { SourceImagesComponent } from './drb-sources/source-images/source-images.component';
import { DrbXliffComponent } from './drb-xliff/drb-xliff.component';
import { DrbDiffComponent } from './drb-diff/drb-diff.component';
import { ViewXliffComponent } from './drb-xliff/view-xliff/view-xliff.component';
import { EditXliffComponent } from './drb-xliff/edit-xliff/edit-xliff.component';
import { EditImageComponent } from './drb-sources/source-images/edit-image/edit-image.component';
import { ViewImageComponent } from './drb-sources/source-images/view-image/view-image.component';
import { ManageContributorsComponent } from './drb-sources/manage-contributors/manage-contributors.component';
import { ViewDrbComponent } from './view-drb/view-drb.component';
import { SourceAddedComponent } from './drb-diff/source-added/source-added.component';
import { XliffAddedComponent } from './drb-diff/xliff-added/xliff-added.component';
import { ChangedComponent } from './drb-diff/changed/changed.component';
import { NewDrbComponent } from './new-drb/new-drb.component';
import { StringTranslationComponent } from './drb-xliff/edit-xliff/string-translation/string-translation.component';
import { EditSourceItemComponent } from './drb-sources/edit-source-item/edit-source-item.component';
import { ViewSourceItemComponent } from './drb-sources/view-source-item/view-source-item.component';
import { SourceItemComponent } from './drb-sources/source-item/source-item.component';
import { DeleteDrbComponent } from './delete-drb/delete-drb.component';
import { SourceRemovedComponent } from './drb-diff/source-removed/source-removed.component';
import { XliffRemovedComponent } from './drb-diff/xliff-removed/xliff-removed.component';

const COMPONENTS = [
  DrbSourcesComponent,
  ViewDrbComponent,
  ManageContributorsComponent,
  EditImageComponent,
  EditSourceItemComponent,
  ViewImageComponent,
  ViewSourceItemComponent,
  SourceImagesComponent,
  SourceItemComponent,
  DrbXliffComponent,
  ViewXliffComponent,
  EditXliffComponent,
  DrbDiffComponent,
  SourceAddedComponent,
  XliffAddedComponent,
  ChangedComponent,
  NewDrbComponent,
  StringTranslationComponent,
  DeleteDrbComponent
];

@NgModule({
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    ChartsModule,
    SharedModule,
    CommonModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    NbDialogModule,
    NbEvaIconsModule,
    NbIconModule,
    NbButtonModule,
    NbCheckboxModule,
    NbInputModule,
    NbSelectModule,
    NbCalendarModule,
    NbDatepickerModule,
    NbFormFieldModule,
    NgSelectModule,
    NbTabsetModule,
    NbRouteTabsetModule,
    NbAccordionModule,
    NbCardModule,
    TagInputModule,
    RouterModule,
    NbTooltipModule,
    NbToggleModule
  ],
  declarations: [...COMPONENTS, SourceRemovedComponent, XliffRemovedComponent]
})
export class DrbModule {}
