import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-okta-login',
    templateUrl: './okta-login.component.html',
    styleUrls: ['./okta-login.component.css']
})
export class OktaLoginComponent implements OnInit {

    constructor() { }

    public ngOnInit(): void {
        this.signIn();
    }

    public signIn(){
        window.location.assign(environment.loginUrl);
    }
}
