import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SurveyService } from 'src/app/core/services/survey.service';
import { Survey } from 'src/app/models/survey';
import { SpinnerState } from 'src/app/shared/spinner/redux/spinner.state';
import { OktaRestriction } from 'src/app/shared/okta-restrictions/okta-restrictions';
import { NgxGenericModalComponent } from 'src/app/shared/ngx-generic-modal/ngx-generic-modal.component';
import { EditSurveyComponent } from './edit-survey/edit-survey.component';
import { DeleteSurveys, LoadSurveys, ReloadSurveys, SurveysState } from './redux';
import { ViewSurveyComponent } from './view-survey/view-survey.component';

@Component({
  selector: 'app-surveys',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './surveys.component.html',
  styleUrls: ['./surveys.component.css']
})
export class SurveysComponent implements OnInit, AfterViewInit {
  @Select(SurveysState.surveys) public data$: Observable<any[]>;
  @Select(SpinnerState) public loading: Observable<boolean>;
  public tableData;
  public spin;
  public displayNewButton = false;

  constructor(
    private store: Store,
    private service: SurveyService,
    private dialogService: NbDialogService,
    private restrictionService: OktaRestriction
  ) {
    if (this.store.selectSnapshot(SurveysState.surveys).length < 1) {
      this.store.dispatch(LoadSurveys);
    }
  }

  public ngAfterViewInit() {
    if (this.store.selectSnapshot(SurveysState.surveys).length > 1) {
      this.service.buildKeysForTable(this.store.selectSnapshot(SurveysState.surveys));
    }
  }

  public ngOnInit() {
    this.data$.subscribe((data) => {
      this.tableData = data;
      this.service.buildKeysForTable(data);
    });
    this.displayNewButton = this.restrictionService.manager_permission;
    this.loading.subscribe((data) => (this.spin = data));
  }

  public clickedButtonTable($event) {
    if ($event.action === 'view') {
      this.viewSurvey($event.data);
    }
    if ($event.action === 'edit') {
      this.editSurvey($event.data);
    }
    if ($event.action === 'clone') {
      this.cloneSurvey($event.data);
    }
    if ($event.action === 'delete') {
      this.deleteSurvey($event.data);
    }
  }

  public newCreation() {
    this.newSurvey();
  }

  public refreshTableData() {
    this.store.dispatch(ReloadSurveys);
  }

  public newSurvey() {
    this.dialogService.open(EditSurveyComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'New',
        survey: new Survey()
      }
    });
  }

  public viewSurvey(survey: Survey) {
    this.dialogService.open(ViewSurveyComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        survey: Survey.copy(survey)
      }
    });
  }

  public editSurvey(survey: Survey) {
    this.dialogService.open(EditSurveyComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'Edit',
        survey: Survey.copy(survey)
      }
    });
  }

  public cloneSurvey(survey: Survey) {
    this.dialogService.open(EditSurveyComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'Clone',
        survey: Survey.copy(survey)
      }
    });
  }

  public deleteSurvey(survey: Survey) {
    this.dialogService
      .open(NgxGenericModalComponent, {
        closeOnEsc: false,
        closeOnBackdropClick: false,
        context: {
          title: 'Are you sure you want to delete Survey',
          message: survey.survey_id + ' - ' + survey.survey_name
        }
      })
      .onClose.subscribe((data) => {
        if (data === true) {
          const surveyToDelete = Survey.copy(survey);
          surveyToDelete.deleted = true;
          this.store.dispatch(new DeleteSurveys(surveyToDelete, true));
        }
      });
  }
}
