import { OnInit, Component } from '@angular/core';
import { NbColorHelper, NbDialogRef, NbThemeService, NbToastrService } from '@nebular/theme';
import { Select, Store } from '@ngxs/store';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { CampaignService } from 'src/app/core/services/campaign.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { Campaign } from 'src/app/models/campaign';
import { StatisticsModel } from 'src/app/models/statistics';
import { CampaignState, SaveCampaignDeliveries } from '../../redux';

@Component({
  selector: 'app-view-stats',
  templateUrl: './stats-campaign.component.html',
  styleUrls: ['./stats-campaign.component.css']
})
export class StatsCampaignComponent extends StatisticsModel implements OnInit {
  @Select(CampaignState.deliveries) public dataDeliveries$: Observable<any[]>;

  public campaign: Campaign;
  public count = 0;
  public data: any;
  public options: any;
  public themeSubscription: any;
  public chartData = {
    data: null,
    dates: [],
    count: []
  };
  public timeFrame = [
    {name: '1 Week', value: 7},
    {name: '2 Weeks', value: 14},
    {name: '1 Month', value: 30}
  ];
  public choosenTime = 14;

  private maxValue: any;

  constructor(public utilityService: UtilityService,
              private store: Store,
              private service: CampaignService,
              private toastrService: NbToastrService,
              protected ref: NbDialogRef<StatsCampaignComponent>,
              private theme: NbThemeService) {
    super();
  }

  public async ngOnInit() {

    this.fetchDeliveries();

    this.dataDeliveries$.subscribe((data) => {
      if (data.length > 0) {
        const found = data.find((element) => element.campaign_id === this.campaign.id);

        if (found && found.data.length > 0) {

        this.chartData.data = found.data;
        this.prepareData();
        }
      }
    });
  }

  /**************************************************************/
  public async fetchDeliveries(){
    const formatDate = 'YYYYMMDD';
    const dateStart = moment(new Date(Date.now() - this.choosenTime * 24 * 60 * 60 * 1000)).format(formatDate); //two weeks before current time
    const dateEnd = moment(Date.now()).format(formatDate);
    const data = await this.service.getCampaignDeliveries(this.campaign.id, dateStart, dateEnd);

    this.toastrService.show(status, 'Fetch: Complete', { status: 'success' });

    if (data) {
      const object = {
        data,
        campaign_id: this.campaign.id
      };
      this.store.dispatch(new SaveCampaignDeliveries(object));
    }
  }

  public close(): void {
    this.ref.close();
  }

  /**************************************************************/
  public getAverageExecDuration(): string | boolean {
    const average = super.getAverageExecDuration(this.campaign.total_duration, this.campaign.executions)
    if(typeof average === 'string') {

      return average.replace(/\(\d* seconds\)/, '');
    } else{

      return 'N/A';
    }
  }

  /**************************************************************/
  public prepareData() {
    this.chartData.dates = [];
    this.chartData.count = [];
    this.count = 0;

    this.maxValue = Math.round(Math.max(...this.chartData.data.map(element => element.count))/100)*10;


    this.chartData.data.forEach((element) => {
      const formatedDate = element.delivery_date.toString().replace(/(\d{4})(\d{2})(\d{2})/g, '$2-$3');
      this.chartData.dates.push(formatedDate);
      this.chartData.count.push(element.count);
      this.count = this.count + element.count;
    });

    this.createCharts();
  }

  public createCharts() {

    this.themeSubscription = this.theme.getJsTheme().subscribe((config) => {

      const colors: any = config.variables;
      const chartjs: any = config.variables.chartjs;

      this.data = {
        labels: this.chartData.dates,
        datasets: [
          {
            data: this.chartData.count,
            label: 'Number of deliveries',
            backgroundColor: NbColorHelper.hexToRgbA(colors.primaryLight, 0.7),
            borderColor: NbColorHelper.hexToRgbA(colors.primaryLight, 1),
            fill: true
          }
        ]
      };

      this.options = {
        maintainAspectRatio: true,
        responsive: true,
        legend: {
          labels: {
            fontColor: chartjs.textColor
          }
        },
        scales: {
          yAxes: [{
              ticks: {
                stepSize: this.maxValue
              }}]
        }
      };
    });
  }
}
