import { Component, OnInit } from '@angular/core';
import { NbDialogRef} from '@nebular/theme';
import { UtilityService } from 'src/app/core/services/utility.service';
import { SourceItem } from 'src/app/models/drb-models/source-item';
@Component({
  selector: 'app-view-source-item',
  templateUrl: './view-source-item.component.html',
  styleUrls: ['./view-source-item.component.css']
})
export class ViewSourceItemComponent implements OnInit {
  public sourceItem: SourceItem;
  public showSpinner = false;
  public type;

  constructor(
    protected ref: NbDialogRef<ViewSourceItemComponent>,
    public utility: UtilityService
  ) {}

  public ngOnInit() {}

  public close() {
    this.ref.close();
  }

  public titleCaseWord(word: string) {
    if (!word) {
      return word;
    }

    return word[0].toUpperCase() + word.substring(1).toLowerCase();
  }
}
