<nb-card accent="success">
    <nb-card-header>
      <div class="row">
        <div class="col-auto mr-auto"><nb-icon icon="pie-chart-outline"></nb-icon></div>
        <div class="col-auto mr-auto">Insights</div>
        <div class="col-auto"><button nbButton (click)="close()">X</button></div>
      </div>
    </nb-card-header>
    <nb-card-body>
        <h6 class="text-uppercase">Details</h6>
        <hr>
        <dl class="row small">
          <dt class="col-2">Insight name: </dt>
          <dd class="col-10">{{insight.name}}</dd>
          <br>
          <dt class="col-2">Creator: </dt>
          <dd class="col-10">{{insight.created_by}}</dd>
          <br>
          <dt class="col-2">Time created: </dt>
          <dd class="col-10">{{convertUnixTime(insight.created_dttm) }}</dd>
          <br>
          <!--<dt class="col-2">Owner: </dt>
          <dd class="col-10">{{insight.owner}}</dd>-->
          <br>
        </dl>
        <h6 class="text-uppercase">Stats</h6>
        <hr>
        <dl class="row small">
            <dt class="col-3">Total executions:</dt>
            <dd class="col-9">{{ insight.executions }}</dd>
            <br>
            <dt class="col-3">Avg. execution time:</dt>
            <dd class="col-9">{{ getAverageExecDuration() }}</dd>
            <br>
            <dt class="col-3">Total publishes:</dt>
            <dd class="col-9">{{ insight.total_results }}</dd>
            <br>
            <dt class="col-3">Avg. publishes per execution: </dt>
            <dd class="col-9">{{ getAveragePublishes() }}</dd>
            <br>
            <dt class="col-3">Last insight run: </dt>
            <dd class="col-9">{{ convertUnixTime(insight.last_run_time) }}</dd>
        </dl>
    </nb-card-body>
    <nb-card-footer>
      <div class="float-right">
        <button nbButton status="info" (click)="close()">Close</button>
      </div>
    </nb-card-footer>
  </nb-card>
