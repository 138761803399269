import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replaceunderscore'
})
export class ReplaceUnderscorePipe implements PipeTransform {

    public transform(value: any, args?: any): any {

        const result = value.replace(/_/g, ' ');

        return result;
    }

}
