import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CampaignAnalyticsComponent } from './components/campaigns/campaign-analytics/campaign-analytics.component';
import { CampaignDashboardComponent } from './components/campaigns/campaign-analytics/campaign-dashboard/campaign-dashboard.component';
import { CampaignDeliveriesComponent } from './components/campaigns/campaign-analytics/campaign-deliveries/campaign-deliveries.component';

import { CampaignsComponent } from './components/campaigns/campaign-table/campaigns.component';
import { ModalContainerComponent } from './components/campaigns/campaign-table/view-campaign/modal-container/modal-container.component';
import { ContentComponent } from './components/content/content.component';
import { DrbDiffComponent } from './components/drbs/drb-diff/drb-diff.component';
import { DrbSourcesComponent } from './components/drbs/drb-sources/drb-sources.component';
import { SourceImagesComponent } from './components/drbs/drb-sources/source-images/source-images.component';
import { DrbXliffComponent } from './components/drbs/drb-xliff/drb-xliff.component';
import { ViewDrbComponent } from './components/drbs/view-drb/view-drb.component';
import { InsightsComponent } from './components/insights/insights.component';
import { CannedKozrsComponent } from './components/qa-testing/canned_kozrs/canned-kozrs.component';
import { ViewCleanScoreComponent } from './components/qa-testing/clean-score/view-clean-score/view-clean-score.component';
import { CreateCleanScoreComponent } from './components/qa-testing/clean-score/create-clean-score/create-clean-score.component';
import { CleanTimeEstimatesComponent } from './components/qa-testing/clean-time-estimates/clean-time-estimates.component';
import { HtaRecommendationComponent } from './components/qa-testing/hta-recommendation/hta-recommendation.component';
import { MyRobotsComponent } from './components/qa-testing/my-robots/my-robots.component';
import { QaTestingComponent } from './components/qa-testing/qa-tool/qa-testing.component';
import { RobotHealthDashboardComponent } from './components/qa-testing/robot-health-dashboard/robot-health-dashboard.component';
import { RtpTestingComponent } from './components/qa-testing/rtp-testing/rtp-testing.component';
import { TimelineNotificationsComponent } from './components/qa-testing/timeline-notifications/timeline-notifications.component';
import { SurveysComponent } from './components/surveys/surveys.component';
import { AmplifyAuthGuardService } from './core/guards/amplify-auth-guard.service';
import { OktaAuthGuardService } from './core/guards/okta-auth-guard.service';
import { OktaLoginComponent } from './shared/okta-login/okta-login.component';
import { OktaRedirectComponent } from './shared/okta-redirect/okta-redirect.component';
import { SignoutComponent } from './shared/signout/signout.component';
import { RobotFamilyMetadataComponent } from './components/robot-family-metadata/robot-family-metadata.component';
import { SourceItemComponent } from './components/drbs/drb-sources/source-item/source-item.component';
import { MessMakersComponent } from './components/qa-testing/mess-makers/mess-makers.component';
import { ScriptedNavComponent } from './components/scripted-nav/scripted-nav.component';

const routes: Routes = [
  { path: 'login', component: OktaLoginComponent },
  { path: 'redirect', component: OktaRedirectComponent },
  {
    path: 'insights',
    canActivate: [AmplifyAuthGuardService],
    children: [
      {
        path: 'active',
        pathMatch: 'full',
        component: InsightsComponent,
        canActivate: [AmplifyAuthGuardService]
      },
      {
        path: 'inactive',
        pathMatch: 'full',
        component: InsightsComponent,
        canActivate: [AmplifyAuthGuardService]
      }
    ]
  },
  {
    path: 'content/table',
    component: ContentComponent,
    canActivate: [AmplifyAuthGuardService]
  },
  {
    path: 'content/scripted-nav',
    component: ScriptedNavComponent,
    canActivate: [AmplifyAuthGuardService]
  },
  {
    path: 'campaigns/table',
    component: CampaignsComponent,
    children: [
      {
        path: ':id',
        component: ModalContainerComponent,
        canActivate: [AmplifyAuthGuardService]
      }
    ]
  },
  {
    path: 'campaigns/analytics',
    component: CampaignAnalyticsComponent,
    canActivate: [AmplifyAuthGuardService],
    children: [
      {
        path: 'dashboard',
        pathMatch: 'full',
        component: CampaignDashboardComponent,
        canActivate: [AmplifyAuthGuardService]
      },
      {
        path: 'deliveries',
        pathMatch: 'full',
        component: CampaignDeliveriesComponent,
        canActivate: [AmplifyAuthGuardService]
      }
    ]
  },
  {
    path: 'surveys',
    component: SurveysComponent,
    canActivate: [AmplifyAuthGuardService]
  },
  {
    path: 'robot-family-metadata/table',
    component: RobotFamilyMetadataComponent,
    canActivate: [AmplifyAuthGuardService]
  },
  //QA
  {
    path: 'qa-testing/canned-kozrs',
    component: CannedKozrsComponent,
    canActivate: [OktaAuthGuardService]
  },
  {
    path: 'qa-testing/clean-score/create',
    component: CreateCleanScoreComponent,
    canActivate: [OktaAuthGuardService]
  },
  {
    path: 'qa-testing/clean-score/view',
    component: ViewCleanScoreComponent,
    canActivate: [OktaAuthGuardService]
  },
  {
    path: 'qa-testing/hta-recommendation',
    component: HtaRecommendationComponent,
    canActivate: [OktaAuthGuardService]
  },
  {
    path: 'qa-testing/qa-tool',
    component: QaTestingComponent,
    canActivate: [OktaAuthGuardService]
  },
  {
    path: 'qa-testing/mess-makers',
    component: MessMakersComponent,
    canActivate: [OktaAuthGuardService]
  },
  {
    path: 'qa-testing/my-robots',
    component: MyRobotsComponent,
    canActivate: [OktaAuthGuardService]
  },
  {
    path: 'qa-testing/timeline-notifications',
    component: TimelineNotificationsComponent,
    canActivate: [OktaAuthGuardService]
  },
  {
    path: 'qa-testing/rtp-testing',
    component: RtpTestingComponent,
    canActivate: [OktaAuthGuardService]
  },
  {
    path: 'qa-testing/robot-health-dashboard',
    component: RobotHealthDashboardComponent,
    canActivate: [OktaAuthGuardService]
  },
  {
    path: 'qa-testing/cleaning-estimates',
    component: CleanTimeEstimatesComponent,
    canActivate: [OktaAuthGuardService]
  },
  //DRB
  {
    path: 'drb/:bundle_name/view',
    component: ViewDrbComponent,
    canActivate: [AmplifyAuthGuardService],
    children: [
      {
        path: 'strings',
        pathMatch: 'full',
        component: DrbXliffComponent,
        canActivate: [AmplifyAuthGuardService]
      },
      {
        path: 'files',
        pathMatch: 'full',
        component: SourceItemComponent,
        canActivate: [AmplifyAuthGuardService],
        data: { type: 'file' }
      },
      {
        path: 'images',
        pathMatch: 'full',
        component: SourceImagesComponent,
        canActivate: [AmplifyAuthGuardService]
      },
      {
        path: 'links',
        pathMatch: 'full',
        component: SourceItemComponent,
        canActivate: [OktaAuthGuardService],
        data: { type: 'link' }
      },
      {
        path: 'videos',
        pathMatch: 'full',
        component: SourceItemComponent,
        canActivate: [OktaAuthGuardService],
        data: { type: 'video' }
      }
    ]
  },
  {
    path: 'drb/:bundle_name/create',
    component: DrbSourcesComponent,
    canActivate: [OktaAuthGuardService],
    children: [
      {
        path: 'strings',
        pathMatch: 'full',
        component: DrbXliffComponent,
        canActivate: [OktaAuthGuardService]
      },
      {
        path: 'files',
        pathMatch: 'full',
        component: SourceItemComponent,
        canActivate: [OktaAuthGuardService],
        data: { type: 'file' }
      },
      {
        path: 'images',
        pathMatch: 'full',
        component: SourceImagesComponent,
        canActivate: [OktaAuthGuardService]
      },
      {
        path: 'links',
        pathMatch: 'full',
        component: SourceItemComponent,
        canActivate: [OktaAuthGuardService],
        data: { type: 'link' }
      },
      {
        path: 'videos',
        pathMatch: 'full',
        component: SourceItemComponent,
        canActivate: [OktaAuthGuardService],
        data: { type: 'video' }
      }
    ]
  },
  {
    path: 'drb/:bundle_name/deploy',
    pathMatch: 'full',
    component: DrbDiffComponent,
    canActivate: [OktaAuthGuardService]
  },
  { path: 'signout', component: SignoutComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
