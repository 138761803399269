<app-spinner *ngIf="spin"></app-spinner>
<app-ngx-table
  type={{service.type}}
  description="Insights table shows a table of all insights and allows insight and insight action manipulation."
  [buttonEnabled]="displayNewButton"
  [tableData]="tableData"
  *ngIf="!spin"
  (clickedButton)="clickedButtonTable($event)"
  [failedInsightCount]="failedInsightCount"
  (createNew)="newCreation()"
  (refreshTable)="refreshTableData()"
  (viewFailedInsight)="viewFailedInsight()"
></app-ngx-table>
