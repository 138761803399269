import { InsightService } from 'src/app/core/services/insight.service';
import { Insight } from 'src/app/models/insight';

import { Component, OnInit } from '@angular/core';
import { NbDateService, NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';

import { Select, Actions, ofActionCompleted } from '@ngxs/store';
import { Observable } from 'rxjs';
import { InsightsState } from 'src/app/components/insights/redux/insights.state';
import { CampaignService } from 'src/app/core/services/campaign.service';
import { Campaign } from 'src/app/models/campaign';
import { FREQUENCIES } from 'src/app/models/frequencies';
import { NgxGenericModalComponent } from 'src/app/shared/ngx-generic-modal/ngx-generic-modal.component';
import { EditInsightComponent } from 'src/app/components/insights/edit-insight/edit-insight.component';
import { ReloadInsights } from 'src/app/components/insights/redux';
import { RouterState } from '../../../../../shared/redux';
import { ManageCampaign, PostSaveComplete } from '../../../redux';
/*
This component acts as a handler for activating and deactivating campagins
*/

@Component({
  selector: 'app-campaign-status-handler',
  templateUrl: './campaign-status-handler.component.html',
  styleUrls: ['./campaign-status-handler.component.css']
})
export class CampaignStatusHandlerComponent implements OnInit {
  @Select(InsightsState.insightsActive) public data$: Observable<any[]>;
  @Select(InsightsState.insightsInactive) public dataInsightsInactive$: Observable<any[]>;
  @Select(RouterState.username) public data_username$: Observable<any>;

  public insight;
  public title;
  public campaign: Campaign;
  public disableDatepicker = false;
  public FREQUENCIES = FREQUENCIES;
  public username = '';

  public startDateForDatepicker: Date;
  public endDateForDatepicker: Date;

  //Using to disable future dates in datepicker
  public minStartDate: Date;
  public minEndDate: Date;
  public requiredTimes = false;

  constructor(
    protected ref: NbDialogRef<CampaignStatusHandlerComponent>,
    private service: CampaignService,
    private insightService: InsightService,
    private actions$: Actions,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
    protected dateService: NbDateService<Date>
  ) {
    this.minStartDate = this.dateService.addMonth(this.dateService.today(), 0);
    this.minEndDate = this.dateService.addDay(this.dateService.today(), 1);
  }

  public ngOnInit() {
    this.data$.subscribe((data) => (this.insight = data));
    this.dataInsightsInactive$.subscribe((data) => (this.insight = this.insight.concat(data)));
    this.data_username$.subscribe((data) => (this.username = data));
    this.convertDateForPicker();
    if (this.title === 'Deactivate') {
      this.campaign.enabled = false;
      this.campaign.start_date = null;
      this.campaign.end_date = null;
      this.campaign.next_run_time = null;
      this.disableDatepicker = true;
    }
    this.actions$.pipe(ofActionCompleted(ReloadInsights)).subscribe((data) => {
      this.data$.subscribe((data_active) => (this.insight = data_active));
      this.dataInsightsInactive$.subscribe((data_inactive) => (this.insight = this.insight.concat(data_inactive)));
    });
    this.actions$.pipe(ofActionCompleted(PostSaveComplete)).subscribe((data) => {
      if (data) {
        this.ref.close();
      }
    });
    this.actions$.pipe(ofActionCompleted(ManageCampaign)).subscribe((data) => {
      if (data) {
        this.ref.close();
      }
    });
  }

  public clickedStart() {
    this.campaign.start_date = Math.round(this.startDateForDatepicker.getTime() / 1000);
    this.campaign.end_date = Math.round(this.endDateForDatepicker.getTime() / 1000);
    this.campaign.enabled = true;
    if (!this.campaign.start_date || !this.campaign.end_date) {
      this.toastrService.show(status, 'Start and End Times have to be provided to activate a Campaign.', {
        status: 'danger',
        duration: 6000
      });

      return;
    }
    this.dialogService
      .open(NgxGenericModalComponent, {
        context: {
          title: 'Before activation you need to confirm you want to Activate": ',
          message: this.campaign.id
        }
      })
      .onClose.subscribe((data) => {
        if (data) {
          this.campaign.next_run_time = this.campaign.start_date;
          this.postCampaign(true);
        }
      });
  }

  public clickedStop() {
    this.dialogService
      .open(NgxGenericModalComponent, {
        context: {
          title: 'Before deactivation you need to confirm you want to Deactivate": ',
          message: this.campaign.id
        }
      })
      .onClose.subscribe((data) => {
        if (data) {
          this.postCampaign(false);
        }
      });
  }

  public clickedCancel() {
    this.ref.close();
  }

  public convertDateForPicker() {
    if (this.campaign.start_date) {
      const date = new Date(0);
      date.setUTCSeconds(this.campaign.start_date);
      this.startDateForDatepicker = date;
    }
    if (this.campaign.end_date) {
      const date = new Date(0);
      date.setUTCSeconds(this.campaign.end_date);
      this.endDateForDatepicker = date;
    }

    if (this.campaign.start_date < this.campaign.end_date) {
      this.minEndDate = this.dateService.addDay(new Date(this.campaign.start_date * 1000), 1);
    }
  }

  public changeEvent() {
    if (this.startDateForDatepicker && this.endDateForDatepicker) {
      this.requiredTimes = true;
    }
    this.compareStartEndDate();
  }

  public compareStartEndDate() {
    if (this.endDateForDatepicker) {
      if (this.startDateForDatepicker.getTime() > this.endDateForDatepicker.getTime()) {
        this.endDateForDatepicker = this.dateService.addDay(new Date(this.startDateForDatepicker.getTime()), 1);
        this.minEndDate = this.dateService.addDay(new Date(this.startDateForDatepicker.getTime()), 1);
      } else {
        this.minEndDate = this.dateService.addDay(new Date(this.startDateForDatepicker.getTime()), 1);
      }
    } else {
      this.minEndDate = this.dateService.addDay(new Date(this.startDateForDatepicker.getTime()), 1);
    }
  }

  public postCampaign(activate) {
    let insightObject: Insight;
    if (this.insight.some(({ id }) => id === this.campaign.trigger_id)) {
      insightObject = Object.assign(
        {},
        this.insight.find(({ id }) => id === this.campaign.trigger_id)
      );
      if (this.title !== 'Deactivate') {
        if (insightObject.start_date && insightObject.start_date < this.campaign.start_date && insightObject.enabled) {
          this.campaign.last_updated_by = this.username;
          this.service.postCampaignsAmplify(this.campaign, null, activate);
        } else {
          this.dialogService
            .open(EditInsightComponent, {
              closeOnEsc: false,
              closeOnBackdropClick: false,
              context: {
                insight: insightObject
              }
            })
            .onClose.subscribe((data) => {
              if (data) {
                insightObject = Object.assign(
                  {},
                  this.insight.find(({ id }) => id === this.campaign.trigger_id)
                );
              }
            });
          this.toastrService.show(
            status,
            'Insight Associated with the Campaign should be Activated and have Start Date before the Start Date of the Campaign.',
            {
              status: 'danger',
              duration: 10000
            }
          );
        }
      } else {
        this.campaign.last_updated_by = this.username;
        this.service.postCampaignsAmplify(this.campaign, null, activate);
        insightObject.start_date = null;
        insightObject.end_date = null;
        insightObject.enabled = false;
        insightObject.next_run_time = null;
        this.insightService.postInsightsAmplify(insightObject, activate);
      }
    }
  }
}
