<nb-card accent="success" style="width: 700px; margin: auto">
  <nb-card-header>
    <div class="row">
      <div class="col-auto mr-auto" *ngIf="!robot?.id">
        <nb-icon icon="plus-outline"></nb-icon>
      </div>
      <div class="col-auto mr-auto" *ngIf="!robot?.id">Create New Robot</div>
      <div class="col-auto mr-auto" *ngIf="robot?.id">
        <nb-icon icon="edit-2-outline"></nb-icon>
      </div>
      <div class="col-auto mr-auto" *ngIf="robot?.id">Robot: {{ robot.id }}</div>
      <div class="col-auto"><button nbButton (click)="close()">X</button></div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <strong class="text-uppercase">Robot Details</strong>
    <hr />
    <form #robotForm="ngForm">
      <div class="row">
        <div class="col" style="padding-top: 10px">
          <label>Robot ID<span style="color: red">*</span>:</label>
        </div>
        <div class="col-9">
          <input
            id="id"
            name="id"
            #name="ngModel"
            [(ngModel)]="robot.id"
            nbInput
            class="form-control"
            style="width: 100%"
            [disabled]="isEdit"
            required
          />
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col" style="padding-top: 10px">
          <label>Robot Name<span style="color: red">*</span>:</label>
        </div>
        <div class="col-9">
          <input
            id="name"
            name="name"
            #name="ngModel"
            [(ngModel)]="robot.name"
            nbInput
            class="form-control"
            style="width: 100%"
            required
          />
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col" style="padding-top: 10px">
          <label>SKU<span style="color: red">*</span>:</label>
        </div>
        <div class="col-9">
          <input
            id="sku"
            name="sku"
            #name="ngModel"
            [(ngModel)]="robot.sku"
            nbInput
            class="form-control"
            style="width: 100%"
            required
          />
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col" style="padding-top: 10px">
          <label>Serial No. :</label>
        </div>
        <div class="col-9">
          <input
            id="serial_number"
            name="serial_number"
            #name="ngModel"
            [(ngModel)]="robot.serial_number"
            nbInput
            class="form-control"
            style="width: 100%"
          />
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col" style="padding-top: 10px">
          <label>Series<span style="color: red">*</span>:</label>
        </div>
        <div class="col-9">
          <nb-select
            id="series"
            name="series"
            #name="ngModel"
            [(ngModel)]="robot.series"
            class="custom small"
            style="width: 100%"
            required
          >
            <nb-option *ngFor="let option of seriesList" [value]="option">{{ option }}</nb-option>
          </nb-select>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col" style="padding-top: 10px">
          <label>Family<span style="color: red">*</span>:</label>
        </div>
        <div class="col-9">
          <nb-select
            id="family"
            name="family"
            #name="ngModel"
            [(ngModel)]="robot.family"
            class="custom small"
            style="width: 100%"
            required
          >
            <nb-option *ngFor="let option of families" [value]="option">{{ option }}</nb-option>
          </nb-select>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col" style="padding-top: 10px">
          <label>Timezone<span style="color: red">*</span>:</label>
        </div>
        <div class="col-9">
          <nb-select
            id="timezone"
            name="timezone"
            #name="ngModel"
            [(ngModel)]="robot.timezone"
            class="custom small"
            style="width: 100%"
            required
          >
            <nb-option *ngFor="let option of timezones" [value]="option">{{ option }}</nb-option>
          </nb-select>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col" style="padding-top: 10px">
          <label>Registration date<span style="color: red">*</span>:</label>
        </div>
        <div class="col-9">
          <mat-form-field appearance="outline">
            <mat-label>Choose a date</mat-label>
            <input matInput required [matDatepicker]="picker" [(ngModel)]="dateForDatepickerStart" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker type="datetime">
              <mat-datepicker-actions>
                <div class="d-flex flex-row-reverse">
                  <div class="p-2">
                    <button nbButton status="success" matDatepickerApply>Apply</button>
                  </div>
                  <div class="p-2">
                    <button nbButton matDatepickerCancel>Cancel</button>
                  </div>
                </div>
              </mat-datepicker-actions>
            </mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <br />
    </form>
  </nb-card-body>
  <nb-card-footer>
    <div class="d-flex flex-row-reverse">
      <div class="p-2">
        <label style="margin-top: 10px">{{ errorMsg }}</label>
        <button
          nbButton
          status="success"
          (click)="save()"
          style="margin: 5px"
          [disabled]="robotForm.valid && isRobotValid ? null : ''"
        >
          Save
        </button>
        <button nbButton (click)="close()" style="margin: 5px">Close</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
