import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbColorHelper, NbDateService, NbThemeService, NbToastrService } from '@nebular/theme';
import { Select, Store } from '@ngxs/store';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { Observable } from 'rxjs';
import { CampaignService } from 'src/app/core/services/campaign.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { LoadCampaign } from '../../redux/campaign.actions';
import { CampaignState } from '../../redux/campaign.state';
import { SaveCampaignDeliveries } from './../../redux/campaign.actions';

const moment = extendMoment(Moment);

@Component({
  selector: 'app-campaign-deliveries',
  templateUrl: './campaign-deliveries.component.html',
  styleUrls: ['./campaign-deliveries.component.css']
})
export class CampaignDeliveriesComponent implements OnInit, OnDestroy {
  @Select(CampaignState.campaign) public data$: Observable<any[]>;
  @Select(CampaignState.deliveries) public dataDeliveries$: Observable<any[]>;

  public formatDate = 'YYYYMMDD';

  //Using to disable future dates in datepicker
  public max: Date;
  public showSpinner = false;
  public refreshButton = true;

  public currentTime: number = new Date().getTime();
  public weeksTimeLimit: number;
  public weeksTime_string;

  public allCampaigns;
  public selectedCampaignID: string;
  public isSelected = false;
  public count = 0;

  //This is only used for date picker to store and show picked date
  public start;
  public end;

  //This hold array of dates that we calculate in calculateDate function
  public chartData = {
    data: null,
    dates: [],
    count: []
  };

  public data: any;
  public options: any;
  public themeSubscription: any;

  constructor(
    private theme: NbThemeService,
    public utilityService: UtilityService,
    private store: Store,
    private service: CampaignService,
    private toastrService: NbToastrService,
    protected dateService: NbDateService<Date>
  ) {
    //Nebular service calculate current date to disable datepicker future dates
    this.max = this.dateService.addMonth(this.dateService.today(), 0);

    if (this.store.selectSnapshot(CampaignState.campaign).length < 1) {
      this.store.dispatch(LoadCampaign);
    }
  }
  public ngOnInit(): void {
    this.data$.subscribe((data) => {
      this.allCampaigns = data;
    });

    this.weeksTimeLimit = this.currentTime / 1000 - 604800;
    this.weeksTime_string = this.utilityService.convertUnixTime_global(this.weeksTimeLimit);
  }

  public ngOnDestroy(): void {
    if (this.themeSubscription) {
      this.themeSubscription.unsubscribe();
    }
  }

  public async selected() {
    if (this.selectedCampaignID) {
      this.refreshButton = false;
      let storeData;
      this.dataDeliveries$.subscribe((data) => (storeData = data));
      const found = storeData.find((element) => element.campaign_id === this.selectedCampaignID);
      if (found) {
        if (this.start && this.end) {
          if (
            found.data.some((e) => e.delivery_date === parseInt(moment(this.start).format(this.formatDate), 10)) &&
            found.data.some((e) => e.delivery_date === parseInt(moment(this.end).format(this.formatDate), 10))
          ) {
            this.chartData.data = found.data;
            this.prepareData();
          } else {
            this.fetchFromApi();
          }
        } else {
          this.chartData.data = found.data;
          this.prepareData();
        }
      } else {
        this.fetchFromApi();
      }
    } else {
      this.start = null;
      this.end = null;
      this.isSelected = false;
      this.refreshButton = true;
    }
  }

  public async fetchFromApi() {
    this.showSpinner = true;
    let dateStart;
    let dateEnd;

    if (this.start && this.end) {
      dateStart = moment(this.start).format(this.formatDate);
      dateEnd = moment(this.end).format(this.formatDate);
    }

    this.chartData.data = await this.service.getCampaignDeliveries(this.selectedCampaignID, dateStart, dateEnd);
    this.toastrService.show(status, 'Fetch: Complete', { status: 'success' });

    if (this.chartData.data) {
      const object = {
        data: this.chartData.data,
        campaign_id: this.selectedCampaignID
      };
      this.store.dispatch(new SaveCampaignDeliveries(object));
    }
    this.showSpinner = false;
    this.prepareData();
  }

  public prepareData() {
    this.chartData.dates = [];
    this.chartData.count = [];
    this.count = 0;

    this.chartData.data.forEach((element) => {
      const formatedDate = element.delivery_date.toString().replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
      this.chartData.dates.push(formatedDate);
      this.chartData.count.push(element.count);
      this.count = this.count + element.count;
    });

    this.isSelected = true;
    this.createCharts();
  }

  public createCharts() {
    this.themeSubscription = this.theme.getJsTheme().subscribe((config) => {
      const colors: any = config.variables;
      const chartjs: any = config.variables.chartjs;

      this.data = {
        labels: this.chartData.dates,
        datasets: [
          {
            data: this.chartData.count,
            label: 'Number of deliveries',
            backgroundColor: NbColorHelper.hexToRgbA(colors.primaryLight, 0.8)
          }
        ]
      };

      this.options = {
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          labels: {
            fontColor: chartjs.textColor
          }
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
                color: chartjs.axisLineColor
              },
              ticks: {
                fontColor: chartjs.textColor
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                color: chartjs.axisLineColor
              },
              ticks: {
                fontColor: chartjs.textColor
              }
            }
          ]
        }
      };
    });
  }

  public startDate(date: Date) {
    this.start = date;
  }

  public endDate(date: Date) {
    this.end = date;
  }

  public refresh() {
    this.refreshButton = true;
    this.fetchFromApi();

    setTimeout(() => {
      this.refreshButton = false;
    }, 30000);
  }
}
