export const RobotParts = [
  {
    series: 'i7',
    parts: [
      {
        part_name: 'care_filter_dmc',
        part_id: '6',
        clean_interval: '',
        clean_interval_text_id: 'clean_interval_1_to_2_weeks_dmc',
        replace_interval: '',
        replace_interval_text_id: 'replace_interval_2_months_dmc',
        image: 'care_lewis_filter_dmc',
        guide_url:
          'https://prod-help-content.care.irobotapi.com/web/parts/28377.html?utm_medium=app&utm_source=app&utm_campaign=care'
      },
      {
        part_name: 'care_side_brush_dmc',
        part_id: '7',
        clean_interval: '',
        clean_interval_text_id: 'clean_interval_1_month_dmc',
        replace_interval: '',
        replace_interval_text_id: 'replace_interval_as_needed_dmc',
        image: 'care_lewis_side_brush_dmc',
        guide_url:
          'https://prod-help-content.care.irobotapi.com/web/parts/28379.html?utm_medium=app&utm_source=app&utm_campaign=care'
      },
      {
        part_name: 'care_rubber_brushes_dmc',
        part_id: '8',
        clean_interval: '',
        clean_interval_text_id: 'clean_interval_1_to_2_weeks_dmc',
        replace_interval: '',
        replace_interval_text_id: 'replace_interval_6_to_12_months_dmc',
        image: 'care_lewis_main_brushes_dmc',
        guide_url:
          'https://prod-help-content.care.irobotapi.com/web/parts/28378.html?utm_medium=app&utm_source=app&utm_campaign=care'
      },
      {
        part_name: 'care_front_wheel_dmc',
        part_id: '9',
        clean_interval: '',
        clean_interval_text_id: 'clean_interval_2_weeks_dmc',
        replace_interval: '',
        replace_interval_text_id: 'replace_interval_12_months_dmc',
        image: 'care_lewis_front_wheel_dmc',
        guide_url:
          'https://prod-help-content.care.irobotapi.com/web/parts/28380.html?utm_medium=app&utm_source=app&utm_campaign=care'
      },
      {
        part_name: 'care_robot_sensors_d1mc',
        part_id: '10',
        clean_interval: '',
        clean_interval_text_id: 'clean_interval_2_weeks_dmc',
        replace_interval: '',
        replace_interval_text_id: null,
        image: 'care_lewis_robot_sensors_dmc',
        guide_url:
          'https://prod-help-content.care.irobotapi.com/web/parts/28381.html?utm_medium=app&utm_source=app&utm_campaign=care'
      }
    ]
  },
  {
    series: 's9',
    parts: [
      {
        part_name: 'care_filter_dmc',
        part_id: '11',
        clean_interval: '',
        clean_interval_text_id: 'clean_interval_1_to_2_weeks_dmc',
        replace_interval: '',
        replace_interval_text_id: 'replace_interval_2_months_dmc',
        image: 'care_soho_filter_dmc',
        guide_url:
          'https://prod-help-content.care.irobotapi.com/web/parts/28395.html?utm_medium=app&utm_source=app&utm_campaign=care',
        robot_health_image: null,
        robot_health_description_id: null
      },
      {
        part_name: 'care_side_brush_dmc',
        part_id: '12',
        clean_interval: '',
        clean_interval_text_id: 'clean_interval_1_month_dmc',
        replace_interval: '',
        replace_interval_text_id: 'replace_interval_as_needed_dmc',
        image: 'care_soho_side_brush_dmc',
        guide_url:
          'https://prod-help-content.care.irobotapi.com/web/parts/28394.html?utm_medium=app&utm_source=app&utm_campaign=care',
        robot_health_image: null,
        robot_health_description_id: null
      },
      {
        part_name: 'care_rubber_brushes_dmc',
        part_id: '13',
        clean_interval: '',
        clean_interval_text_id: 'clean_interval_1_to_2_weeks_dmc',
        replace_interval: '',
        replace_interval_text_id: 'replace_interval_6_to_12_months_dmc',
        image: 'care_soho_main_brushes_dmc',
        guide_url:
          'https://prod-help-content.care.irobotapi.com/web/parts/28393.html?utm_medium=app&utm_source=app&utm_campaign=care',
        robot_health_image: null,
        robot_health_description_id: null
      },
      {
        part_name: 'care_front_wheel_dmc',
        part_id: '14',
        clean_interval: '',
        clean_interval_text_id: 'clean_interval_2_weeks_dmc',
        replace_interval: '',
        replace_interval_text_id: 'replace_interval_12_months_dmc',
        image: 'care_soho_front_wheel_dmc',
        guide_url:
          'https://prod-help-content.care.irobotapi.com/web/parts/28392.html?utm_medium=app&utm_source=app&utm_campaign=care',
        robot_health_image: null,
        robot_health_description_id: null
      },
      {
        part_name: 'care_robot_sensors_dmc',
        part_id: '15',
        clean_interval: '',
        clean_interval_text_id: 'clean_interval_2_weeks_dmc',
        replace_interval: '',
        replace_interval_text_id: null,
        image: 'care_soho_robot_sensors_dmc',
        guide_url:
          'https://prod-help-content.care.irobotapi.com/web/parts/28391.html?utm_medium=app&utm_source=app&utm_campaign=care',
        robot_health_image: null,
        robot_health_description_id: null
      }
    ]
  },
  {
    series: 'j7',
    parts: [
      {
        part_name: 'care_filter_dmc',
        part_id: '46',
        clean_interval: '',
        clean_interval_text_id: 'clean_interval_1_to_2_weeks_dmc',
        replace_interval: '',
        replace_interval_text_id: 'replace_interval_2_months_dmc',
        image: 'care_lewis_filter_dmc',
        guide_url: 'https://prod-help-content.care.irobotapi.com/web/betaContent/sapphire/web/en-US/31232.html',
        robot_health_image: 'robot_health_lewis_filter_dmc',
        robot_health_description_id: 'robot_health_filter_description_dmc'
      },
      {
        part_name: 'care_side_brush_dmc',
        part_id: '47',
        clean_interval: '',
        clean_interval_text_id: 'clean_interval_1_month_dmc',
        replace_interval: '',
        replace_interval_text_id: 'replace_interval_as_needed_dmc',
        image: 'care_lewis_side_brush_dmc',
        guide_url: 'https://prod-help-content.care.irobotapi.com/web/betaContent/sapphire/web/en-US/31233.html',
        robot_health_image: 'robot_health_lewis_side_brush_dmc',
        robot_health_description_id: 'robot_health_side_brush_description_dmc'
      },
      {
        part_name: 'care_rubber_brushes_dmc',
        part_id: '48',
        clean_interval: '',
        clean_interval_text_id: 'clean_interval_1_to_2_weeks_dmc',
        replace_interval: '',
        replace_interval_text_id: 'replace_interval_6_to_12_months_dmc',
        image: 'care_lewis_main_brushes_dmc',
        guide_url: 'https://prod-help-content.care.irobotapi.com/web/betaContent/sapphire/web/en-US/31234.html',
        robot_health_image: 'robot_health_lewis_rubber_brushes_dmc',
        robot_health_description_id: 'robot_health_rubber_brushes_description_dmc'
      },
      {
        part_name: 'care_front_wheel_dmc',
        part_id: '49',
        clean_interval: '',
        clean_interval_text_id: 'clean_interval_2_weeks_dmc',
        replace_interval: '',
        replace_interval_text_id: 'replace_interval_12_months_dmc',
        image: 'care_lewis_front_wheel_dmc',
        guide_url: 'https://prod-help-content.care.irobotapi.com/web/betaContent/sapphire/web/en-US/31235.html',
        robot_health_image: null,
        robot_health_description_id: null
      },
      {
        part_name: 'care_robot_sensors_dmc',
        part_id: '50',
        clean_interval: '',
        clean_interval_text_id: 'clean_interval_2_weeks_dmc',
        replace_interval: '',
        replace_interval_text_id: null,
        image: 'care_lewis_robot_sensors_dmc',
        guide_url: 'https://prod-help-content.care.irobotapi.com/web/betaContent/sapphire/web/en-US/31236.html',
        robot_health_image: null,
        robot_health_description_id: null
      }
    ]
  },
  {
    series: 'i1',
    parts: [
      {
        part_name: 'care_filter_dmc',
        part_id: '35',
        clean_interval: '',
        clean_interval_text_id: 'clean_interval_1_to_2_weeks_dmc',
        replace_interval: '',
        replace_interval_text_id: 'replace_interval_2_months_dmc',
        image: 'care_lewis_filter_dmc',
        guide_url:
          'https://prod-help-content.care.irobotapi.com/web/parts/28377.html?utm_medium=app&utm_source=app&utm_campaign=care',
        robot_health_image: 'robot_health_lewis_filter_dmc',
        robot_health_description_id: 'robot_health_filter_description_dmc'
      },
      {
        part_name: 'care_side_brush_dmc',
        part_id: '36',
        clean_interval: '',
        clean_interval_text_id: 'clean_interval_1_month_dmc',
        replace_interval: '',
        replace_interval_text_id: 'replace_interval_as_needed_dmc',
        image: 'care_lewis_side_brush_dmc',
        guide_url:
          'https://prod-help-content.care.irobotapi.com/web/parts/28379.html?utm_medium=app&utm_source=app&utm_campaign=care',
        robot_health_image: 'robot_health_lewis_side_brush_dmc',
        robot_health_description_id: 'robot_health_side_brush_description_dmc'
      },
      {
        part_name: 'care_rubber_brushes_dmc',
        part_id: '37',
        clean_interval: '',
        clean_interval_text_id: 'clean_interval_1_to_2_weeks_dmc',
        replace_interval: '',
        replace_interval_text_id: 'replace_interval_6_to_12_months_dmc',
        image: 'care_lewis_main_brushes_dmc',
        guide_url:
          'https://prod-help-content.care.irobotapi.com/web/parts/28378.html?utm_medium=app&utm_source=app&utm_campaign=care',
        robot_health_image: 'robot_health_lewis_rubber_brushes_dmc',
        robot_health_description_id: 'robot_health_rubber_brushes_description_dmc'
      },
      {
        part_name: 'care_front_wheel_dmc',
        part_id: '38',
        clean_interval: '',
        clean_interval_text_id: 'clean_interval_2_weeks_dmc',
        replace_interval: '',
        replace_interval_text_id: 'replace_interval_12_months_dmc',
        image: 'care_lewis_front_wheel_dmc',
        guide_url:
          'https://prod-help-content.care.irobotapi.com/web/parts/28380.html?utm_medium=app&utm_source=app&utm_campaign=care',
        robot_health_image: null,
        robot_health_description_id: null
      },
      {
        part_name: 'care_robot_sensors_dmc',
        part_id: '39',
        clean_interval: '',
        clean_interval_text_id: 'clean_interval_2_weeks_dmc',
        replace_interval: '',
        replace_interval_text_id: null,
        image: 'care_lewis_robot_sensors_dmc',
        guide_url:
          'https://prod-help-content.care.irobotapi.com/web/parts/28381.html?utm_medium=app&utm_source=app&utm_campaign=care',
        robot_health_image: null,
        robot_health_description_id: null
      }
    ]
  },
  {
    series: 'i3',
    parts: [
      {
        part_name: 'care_filter_dmc',
        part_id: '35',
        clean_interval: '',
        clean_interval_text_id: 'clean_interval_1_to_2_weeks_dmc',
        replace_interval: '',
        replace_interval_text_id: 'replace_interval_2_months_dmc',
        image: 'care_lewis_filter_dmc',
        guide_url:
          'https://prod-help-content.care.irobotapi.com/web/parts/28377.html?utm_medium=app&utm_source=app&utm_campaign=care',
        robot_health_image: 'robot_health_lewis_filter_dmc',
        robot_health_description_id: 'robot_health_filter_description_dmc'
      },
      {
        part_name: 'care_side_brush_dmc',
        part_id: '36',
        clean_interval: '',
        clean_interval_text_id: 'clean_interval_1_month_dmc',
        replace_interval: '',
        replace_interval_text_id: 'replace_interval_as_needed_dmc',
        image: 'care_lewis_side_brush_dmc',
        guide_url:
          'https://prod-help-content.care.irobotapi.com/web/parts/28379.html?utm_medium=app&utm_source=app&utm_campaign=care',
        robot_health_image: 'robot_health_lewis_side_brush_dmc',
        robot_health_description_id: 'robot_health_side_brush_description_dmc'
      },
      {
        part_name: 'care_rubber_brushes_dmc',
        part_id: '37',
        clean_interval: '',
        clean_interval_text_id: 'clean_interval_1_to_2_weeks_dmc',
        replace_interval: '',
        replace_interval_text_id: 'replace_interval_6_to_12_months_dmc',
        image: 'care_lewis_main_brushes_dmc',
        guide_url:
          'https://prod-help-content.care.irobotapi.com/web/parts/28378.html?utm_medium=app&utm_source=app&utm_campaign=care',
        robot_health_image: 'robot_health_lewis_rubber_brushes_dmc',
        robot_health_description_id: 'robot_health_rubber_brushes_description_dmc'
      },
      {
        part_name: 'care_front_wheel_dmc',
        part_id: '38',
        clean_interval: '',
        clean_interval_text_id: 'clean_interval_2_weeks_dmc',
        replace_interval: '',
        replace_interval_text_id: 'replace_interval_12_months_dmc',
        image: 'care_lewis_front_wheel_dmc',
        guide_url:
          'https://prod-help-content.care.irobotapi.com/web/parts/28380.html?utm_medium=app&utm_source=app&utm_campaign=care',
        robot_health_image: null,
        robot_health_description_id: null
      },
      {
        part_name: 'care_robot_sensors_dmc',
        part_id: '39',
        clean_interval: '',
        clean_interval_text_id: 'clean_interval_2_weeks_dmc',
        replace_interval: '',
        replace_interval_text_id: null,
        image: 'care_lewis_robot_sensors_dmc',
        guide_url:
          'https://prod-help-content.care.irobotapi.com/web/parts/28381.html?utm_medium=app&utm_source=app&utm_campaign=care',
        robot_health_image: null,
        robot_health_description_id: null
      }
    ]
  }
];
