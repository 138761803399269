
export class SetComponentTableKeys {
    public static readonly type = '[Coghweel] Set Component Table Keys';
    constructor(public payload: any, public type: string) {}
}

export class SetComponentCurrentTableKeys {
    public static readonly type = '[Coghweel] Set Component Current Table Keys';
    constructor(public payload: any) {}
}

export class SetCurrentType {
    public static readonly type = '[Coghweel] Set Current Type';
    constructor(public payload: any) {}
}

