<app-spinner *ngIf="spin"></app-spinner>
<app-ngx-table
  type={{service.type}}
  description="Robot Family Metadata table shows a list of robot metadata and allows their manipulation."
  secondary_description="The Metadata table cells can be sorted separately by Extent type, Min. Confidence or Priority."
  [tableData]="tableData"
  [buttonEnabled]="displayNewButton"
  *ngIf="!spin"
  (clickedButton)="clickedButtonTable($event)"
  (createNew)="newCreation()"
  (refreshTable)="refreshTableData()"
></app-ngx-table>
