import { DatePipe, TitleCasePipe } from '@angular/common';
import { LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'format',
  pure: true
})
export class FormatPipe implements PipeTransform {
  private mappings: Map<string, string> = new Map([['dttm', 'datetime']]);

  public transform(
    value: any,
    args: string,
    length: string = 'short',
    transformer?: (value: string, context?: any) => string,
    context?: any
  ): any {
    if (args === 'custom') {
      return transformer(value, context);
    }
    if (args === 'key') {
      const titlecase = new TitleCasePipe();

      return titlecase.transform(
        value
          .split('_')
          .map((el) => {
            if (this.mappings.has(el)) {
              return this.mappings.get(el);
            }

            return el;
          })
          .join(' ')
      );
    }
    if (args.includes('dttm') || args.includes('date')) {
      // for now we'll always use en-US for the locale
      // if that ever becomes an issue, figure out how
      // to supply the current locale to DatePipe
      const date = new DatePipe('en-US');

      return date.transform(parseInt(value, 10) * 1000, 'short');
    }
    if (args.includes('json')) {
      value = JSON.stringify(value, null, '  ');
    }
    if (args === 'count') {
      if (value && value instanceof Array) {
        return value.length;
      }
    }
    if (length === 'long') {
      return value;
    }
    if (value && value.length > 43) {
      return value.slice(0, 40) + '...';
    }

    return value;
  }
}
