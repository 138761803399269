import { Asset } from './asset';
import { MapGeometry } from './mapGeometry';

export class HTARecommendation extends Asset {
    public hta_id: number;
    public robotid: number;
    public pmap_id: string;
    public active_pmapv_id: string;
    public pmapv_ids: Array<string>;
    public hta_geom: Array<MapGeometry>;
    public related_events_geojson: Array<MapGeometry>;
    public event_types: Array<string>;
    public score: number;
    public comments: Array<string>;
    public create_timestamp: number;

    constructor() {
        super();
    }

    public get label() {
        return ('' + this.hta_id).padStart(3, '0') + ' - ' + this.active_pmapv_id;
    }

    public static clone(HtaRecommendation: HTARecommendation) {
        const clonedHtaRecommendation: HTARecommendation = new HTARecommendation();
        Object.assign(clonedHtaRecommendation, HtaRecommendation);
        clonedHtaRecommendation.active_pmapv_id = 'Clone - ' + HtaRecommendation.active_pmapv_id;

        return clonedHtaRecommendation;
    }
}
