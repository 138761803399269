import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Store } from '@ngxs/store';
import { API } from '@aws-amplify/api';
import {
  HtaRecommendationDeleteSuccess,
  HtaRecommendationLoadSucess,
  HtaRecommendationReload
} from 'src/app/components/qa-testing/redux/qa.actions';
import { CogwheelService } from 'src/app/core/services/cogwheel.service';
import { environment } from 'src/environments/environment';
import { AmplifyServiceBuilder } from '../services/amplify.service.builder';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class HtaRecommendationService {
  public allKeys: string[] = [];
  public selectedKeys: string[] = [];
  public high_traffic_area_api = 'high_traffic_area_api';
  public type = 'hta-recommendation';

  constructor(private store: Store,
    private toastrService: NbToastrService,
    private utilityService: UtilityService,
    private cogWheelService: CogwheelService) {
    }

  public buildKeysForTable(hta: any[]) {
    this.cogWheelService.buildKeysForTable(hta, this.allKeys, this.store, this.type, true);

  }

  public loadHtaRecommendationAmplify(reload?) {
    const requestData = AmplifyServiceBuilder(this.high_traffic_area_api, true);

    API.get(this.high_traffic_area_api, `/${environment.stage}/insights/qa/htars`, requestData)
      .then((response) => {
        if (reload) {
          this.toastrService.show(status, 'Fetching HTA Recommendations Complete', {
            status: 'success'
          });
        }
        this.buildKeysForTable(response);
        this.store.dispatch(new HtaRecommendationLoadSucess(response));
      })
      .catch((error) => {
        this.toastrService.show(
          status,
          'Fetching HTA Recommendations Failed ' + this.utilityService.convertErrorToMessage(error),
          {
            status: 'danger'
          }
        );
      });
  }

  //HTA = High Traffic Area
  public submitHtaRecommendationAmplify(hta) {
    const body = hta;
    const requestData = AmplifyServiceBuilder(this.high_traffic_area_api, true, body);

    API.post(this.high_traffic_area_api, `/${environment.stage}/insights/qa/htars`, requestData)
      .then((response) => {
        this.toastrService.show(status, 'Saved HTA Recommendation', { status: 'success' });
        this.store.dispatch(new HtaRecommendationReload());
      })
      .catch((error) => {
        this.toastrService.show(
          status,
          'Saving HTA Recommendation Failed ' + this.utilityService.convertErrorToMessage(error),
          {
            status: 'danger'
          }
        );
      });
  }

  public deleteHtaRecommendationAmplify(hta) {
    const body = hta;
    const requestData = AmplifyServiceBuilder(this.high_traffic_area_api, true, body);

    API.del(this.high_traffic_area_api, `/${environment.stage}/insights/qa/htars`, requestData)
      .then((response) => {
        this.store.dispatch(new HtaRecommendationDeleteSuccess(hta));
      })
      .catch((error) => {
        this.toastrService.show(
          status,
          'Deleting HTA Recommendation Failed ' + this.utilityService.convertErrorToMessage(error),
          {
            status: 'danger'
          }
        );
      });
  }
}
