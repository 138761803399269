import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Insights',
    icon: 'bulb-outline',
    children: [
      {
        title: 'Active Insights',
        link: '/insights/active',
        pathMatch: 'full',
        home: true
      },
      {
        title: 'Inactive Insights',
        link: '/insights/inactive',
        pathMatch: 'full'
      }
    ]
  },
  {
    title: 'Campaigns',
    icon: 'trending-up',
    children: [
      {
        title: 'Campaigns Analytics',
        link: '/campaigns/analytics',
        pathMatch: 'full'
      },
      {
        title: 'Manage Campaigns',
        link: '/campaigns/table',
        pathMatch: 'full'
      }
    ]
  },
  {
    title: 'Content',
    icon: 'file-text-outline',
    children: [
      {
        title: 'Content',
        link: '/content/table',
        pathMatch: 'full'
      },
      {
        title: 'Scripted Navigation',
        link: '/content/scripted-nav',
        pathMatch: 'full'
      }
    ]
  },
  {
    title: 'QA Tools',
    icon: 'hard-drive-outline',
    children: [
      {
        title: 'Canned KOZRs',
        link: '/qa-testing/canned-kozrs',
        pathMatch: 'full'
      },
      {
        title: 'Clean Score',
        children: [
          {
            title: 'Create/Edit',
            link: '/qa-testing/clean-score/create',
            pathMatch: 'full'
          },
          {
            title: 'View',
            link: '/qa-testing/clean-score/view',
            pathMatch: 'full'
          }
        ]
      },
      {
        title: 'HTA Recommendation',
        link: '/qa-testing/hta-recommendation',
        pathMatch: 'full'
      },
      {
        title: 'Cleaning Time Estimates',
        link: '/qa-testing/cleaning-estimates',
        pathMatch: 'full'
      },
      {
        title: 'Mess Makers',
        link: '/qa-testing/mess-makers',
        pathMatch: 'full'
      },
      {
        title: 'My Robots',
        link: '/qa-testing/my-robots',
        pathMatch: 'full'
      },
      {
        title: 'QA Notification Tests',
        link: '/qa-testing/qa-tool',
        pathMatch: 'full'
      },
      {
        title: 'Real-Time Personalization',
        link: '/qa-testing/rtp-testing',
        pathMatch: 'full'
      },
      {
        title: 'Timeline Notifications',
        link: '/qa-testing/timeline-notifications',
        pathMatch: 'full'
      },
      {
        title: 'Robot Health Dashboard',
        link: '/qa-testing/robot-health-dashboard',
        pathMatch: 'full'
      }
    ]
  },
  {
    title: 'Surveys',
    icon: 'checkmark-circle-outline',
    link: '/surveys',
    pathMatch: 'full'
  },
  {
    title: 'Robot Family Metadata',
    icon: 'pricetags-outline',
    link: '/robot-family-metadata/table',
    pathMatch: 'full'
  },
  {
    title: 'DRBs',
    icon: 'briefcase-outline',
    children: []
  }
];

export const MENU_ITEMS_PROD: NbMenuItem[] = [
  {
    title: 'Insights',
    icon: 'bulb-outline',
    children: [
      {
        title: 'Active Insights',
        link: '/insights/active',
        pathMatch: 'full',
        home: true
      },
      {
        title: 'Inactive Insights',
        link: '/insights/inactive',
        pathMatch: 'full'
      }
    ]
  },
  {
    title: 'Campaigns',
    icon: 'trending-up',
    children: [
      {
        title: 'Campaigns Analytics',
        link: '/campaigns/analytics',
        pathMatch: 'full'
      },
      {
        title: 'Manage Campaigns',
        link: '/campaigns/table',
        pathMatch: 'full'
      }
    ]
  },
  {
    title: 'Content',
    icon: 'file-text-outline',
    link: '/content/table',
    pathMatch: 'full'
  },
  {
    title: 'QA Tools',
    icon: 'hard-drive-outline',
    children: [
      {
        title: 'Canned KOZRs',
        link: '/qa-testing/canned-kozrs',
        pathMatch: 'full'
      },
      {
        title: 'HTA Recommendation',
        link: '/qa-testing/hta-recommendation',
        pathMatch: 'full'
      },
      {
        title: 'My Robots',
        link: '/qa-testing/my-robots',
        pathMatch: 'full'
      },
      {
        title: 'QA Notification Tests',
        link: '/qa-testing/qa-tool',
        pathMatch: 'full'
      },
      {
        title: 'Real-Time Personalization',
        link: '/qa-testing/rtp-testing',
        pathMatch: 'full'
      },
      {
        title: 'Timeline Notifications',
        link: '/qa-testing/timeline-notifications',
        pathMatch: 'full'
      },
      {
        title: 'Robot Health Dashboard',
        link: '/qa-testing/robot-health-dashboard',
        pathMatch: 'full'
      }
    ]
  },
  {
    title: 'Surveys',
    icon: 'checkmark-circle-outline',
    link: '/surveys',
    pathMatch: 'full'
  },
  {
    title: 'Robot Family Metadata',
    icon: 'pricetags-outline',
    link: '/robot-family-metadata/table',
    pathMatch: 'full'
  },
  {
    title: 'DRBs',
    icon: 'briefcase-outline',
    children: [
    ]
  }
];
