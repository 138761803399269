import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-insight-websocket-window',
    templateUrl: './insight-websocket-window.component.html',
    styleUrls: ['./insight-websocket-window.component.scss']
})
export class InsightWebsocketWindowComponent implements OnInit {
  public results;

  constructor() { }

  public ngOnInit() {
  }
}
