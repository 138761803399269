<nb-card accent="success" style="width: 70%; margin: auto">
  <nb-card-header>
    <div class="row">
      <div class="col-auto mr-auto">
        <nb-icon icon="search-outline"></nb-icon>
      </div>
      <div class="col-auto mr-auto">Canned Kozr - {{ cannedKozr.koz_id }}</div>
      <div class="col-auto"><button nbButton (click)="close()">X</button></div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <dl class="row small">
      <dt class="col-2">KOZ ID</dt>
      <dd class="col-10">{{ cannedKozr.koz_id }}</dd>
      <dt class="col-2">Robot ID</dt>
      <dd class="col-10">{{ cannedKozr.robotid }}</dd>
      <dt class="col-2">Pmap ID</dt>
      <dd class="col-10">{{ cannedKozr.pmap_id }}</dd>
      <dt class="col-2">Active Pmapv Id</dt>
      <dd class="col-10">{{ cannedKozr.active_pmapv_id }}</dd>
      <dt class="col-2">Pmapv_ids</dt>
      <dd class="col-10">{{ cannedKozr.pmapv_ids }}</dd>
      <dt class="col-2">KOZ Geometry</dt>
      <dd class="col-10">{{ cannedKozr.koz_geom | json }}</dd>
      <dt class="col-2">related_events_geojson</dt>
      <dd class="col-10">{{ cannedKozr.related_events_geojson | json }}</dd>
      <dt class="col-2">event_types</dt>
      <dd class="col-10">{{ cannedKozr.event_types }}</dd>
      <dt class="col-2">Score</dt>
      <dd class="col-10">{{ cannedKozr.score }}</dd>
      <dt class="col-2">cumul_orientation_rad</dt>
      <dd class="col-10">{{ cannedKozr.cumul_orientation_rad }}</dd>
      <dt class="col-2">Comments</dt>
      <dd class="col-10">{{ cannedKozr.comments }}</dd>
      <dt class="col-2">Created at</dt>
      <dd class="col-10">
        {{ cannedKozr.create_timestamp * 1000 | date: "short" }}
      </dd>
    </dl>
  </nb-card-body>
  <nb-card-footer>
    <div class="d-flex flex-row-reverse">
      <div class="p-2">
        <button nbButton status="info" (click)="close()">Close</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
