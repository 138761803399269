import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-qa-s-scheduling',
  templateUrl: './qa-s-scheduling.component.html',
  styleUrls: ['./qa-s-scheduling.component.css']
})
export class QaSSchedulingComponent implements OnInit {
  @Input() public campaign;
  @Input() public campaignVersion = '';

  @Output() public scheduleChanged: EventEmitter<any> = new EventEmitter();
  @Output() public regionSchedule: EventEmitter<any> = new EventEmitter();
  public hourCounter = Array;
  public scheduleSize_v1 = 0;
  public scheduleSize_v2 = 0;
  public displayMaxSizeError = false;

  public pmap_id = '';
  public pmapv_id = '';
  public region_id = '';

  public scoreList_v1 = [];
  public scoreList_v2 = [];

  // v1
  public sRecommendationForm = this.fb.group({
    regions: this.fb.array([])
  });

  // v2
  public rRecommendationForm = this.fb.group({
    regions: this.fb.array([])
  });

  constructor(private fb: FormBuilder) {}

  /*********************************************************
   * Schedule recommendations: v1
   *********************************************************/
  public get schedules() {
    return this.sRecommendationForm.get('regions') as FormArray;
  }

  /*********************************************************
   * Schedule recommendations: v1
   *********************************************************/
  public get regions() {
    return this.rRecommendationForm.get('regions') as FormArray;
  }

  /*********************************************************
   *
   *              Schedule recommendations: v1
   *
   *********************************************************/

  public addSchedules() {
    if (this.scheduleSize_v1 < 10) {
      this.schedules.push(
        this.fb.group({
          pmap_id: null,
          pmapv_id: null,
          region_id: '',
          schedule: this.fb.group({
            day: ['', Validators.required],
            hour: ['', Validators.required],
            minute: ['', Validators.required],
            score: this.scoreList_v1[this.scheduleSize_v1]
          })
        })
      );
      this.scheduleChanged.emit({ regions: this.schedules.value });
      this.scheduleSize_v1++;
    } else {
      this.displayMaxSizeError = true;
    }
  }

  public deleteSchedule(i) {
    this.schedules.removeAt(i);
    this.scheduleChanged.emit({ regions: this.schedules.value });
    this.scheduleSize_v1--;
    if (this.scheduleSize_v1 < 10) {
      this.displayMaxSizeError = false;
    }
  }

  public onScheduleChange() {
    this.scheduleChanged.emit({ regions: this.schedules.value });
  }

  /*********************************************************
   *
   *              Schedule recommendations: v2
   *
   *********************************************************/

  /******************************************************
   * Adds a region schedule to the RegionRecommendation
   * model.
   *
   * This method is used for V2 ScheduleRecommendation,
   * which is an integration of Room-Level schedule
   * recommendations.
   *****************************************************/

  public addRegion() {
    if (this.scheduleSize_v2 < 10) {
      this.regions.push(
        this.fb.group({
          pmap_id: this.pmap_id,
          pmapv_id: this.pmapv_id,
          region_id: '',
          schedule: this.fb.group({
            day: ['', Validators.required],
            hour: ['', Validators.required],
            minute: ['', Validators.required],
            score: this.scoreList_v2[this.scheduleSize_v2]
          })
        })
      );
      this.regionSchedule.emit({ regions: this.regions.value });
      this.scheduleSize_v2++;
    } else {
      this.displayMaxSizeError = true;
    }
  }

  public deleteRegions(i) {
    this.regions.removeAt(i);
    this.regionSchedule.emit({ regions: this.regions.value });
  }

  public onRegionChanged() {
    this.regionSchedule.emit({ regions: this.regions.value });
  }

  /************************************************/

  public ngOnInit() {
    if (this.campaign.name.includes('v1')) {
      this.campaignVersion = 'v1';
    }
    if (this.campaign.name.includes('v2') || this.campaign.name.includes('DRC')) {
      this.campaignVersion = 'v2';
    }
  }
}
