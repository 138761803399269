import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { TimestampDatePipe } from '../../../shared/custom-pipe/timestamp-date.pipe';

@Component({
  selector: 'app-view-robot-family-metadata',
  templateUrl: './view-robot-family-metadata.component.html',
  styleUrls: ['./view-robot-family-metadata.component.css']
})
export class ViewRobotFamilyMetadataComponent implements OnInit {

  public metadata;

  public localeDisplays: string[] = [];

  public formatted_created_time = '';
  public formatted_updated_date = '';

  constructor(protected ref: NbDialogRef<ViewRobotFamilyMetadataComponent>,
     private formatDatePipe: TimestampDatePipe) { }

  public ngOnInit() {
    this.handleDate();
  }

  public formatDate = (time) => {
    const formatted_time = this.formatDatePipe.transform(time, 'timestampToDate');
    if (formatted_time) {
      return formatted_time;
    } else {
      return '';
    }
  };

  public handleDate() {
    const created_date = this.metadata.created_dttm;
    this.formatted_created_time = this.formatDate(created_date);

    const updated_date = this.metadata.last_updated_dttm;
    this.formatted_updated_date = this.formatDate(updated_date);
  }

  public close() {
    this.ref.close();
  }
}
