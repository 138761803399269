export class HighTraffic {
  public normal = 'normal';
  public dirty = 'dirty';
}

export class SmartCleanPipeline {
  public rt_map = 'rt_map';
  public rt_mission = 'rt_mission';
  public batch_decay = 'batch_decay';
  public batch_canned_data = 'batch_canned_data';
  public insights_integration_test = 'insights_integration_test';
  public qa_api = 'qa_api';
}

export class MissionInfo {
  public startTime: number;
  public nMssn: number;
  public missionId?: string;
}

export class CleanScoreRegion {
  public region_id: string;
  public clean_score: number;
  public updated_ts: number;
  public last_updated_by: SmartCleanPipeline;
  public high_traffic_enum: HighTraffic;
  public mission_last_cleaned?: MissionInfo;
  public mission_last_unfinished?: MissionInfo;
}

export class Command {
  public start = 'start';
}
export class CommandDefRegionType {
  public rid = 'rid';
}


export class CommandDefRegion {
    public region_id: string;
    public type: CommandDefRegionType;
}

export class CommandDef {
  public id: string;
  public robot_id: string;
  public command: Command;
  public pmap_id: string;
  public ordered: number;
  public regions: CommandDefRegion[] = [];
  public smart_clean_id: string;
}


export class CleanScore {
  public pmap_id: string;
  public smart_clean_id: string;
  public active_pmapv_id?: string;
  public user_pmapv_id?: string;
  public mission_last_processed?: MissionInfo;
  public regions: CleanScoreRegion[] = [];
}

export class DDBRegionsList {
  public pk: string;
  public last_updated_ts: number;
  public last_updated_by: SmartCleanPipeline;
  public active_pmapv_id: string;
  public user_pmapv_id: string;
  public smart_clean_id: string;
  public available_regions: [] = [];
  public ttl?: number;
  public deleted?: boolean = false;
}

export class DDBCleanScoreRegion {
  public pk: string;
  public last_updated_ts: number;
  public last_updated_by: SmartCleanPipeline;
  public robot_id: string;
  public smart_clean_id: string;
  public region_score: number;
  public region_high_traffic_enum: HighTraffic;
  public region_mission_last_cleaned?: MissionInfo;
  public region_mission_last_unfinished?: MissionInfo;
  public ttl?: number;
  public deleted?: boolean = false;
  //not sure
  public item_type?: string;
  public pmap_id?: string;
  public robot_type?: string;
  public room_category?: string;
}

export class DDBSmartCleanCommand {
  public pk: string;
  public last_updated_ts: number;
  public last_updated_by: SmartCleanPipeline;
  public robot_id: string;
  public smart_clean_id: string;
  public pmap_mission_last_cleaned?: MissionInfo;
  public commanddefs: CommandDef[] = [];
  public ttl?: number;
  public deleted?: boolean = false;
}

export class QARegionItem {
    public region_id: string;
    public clean_score: number;
  }


export class QACleanScoreItem {
  public pmap_id: string;
  public regions: QARegionItem[] = [];
}
