<nb-card
  accent="success"
  style="
    width: 90%;
    height: 90%;
    min-width: 700px;
    min-height: 450px;
    max-height: 800px;
    max-width: 1000px;
    margin: auto;
  "
>
  <nb-card-header>
    <div class="row">
      <div class="col-auto mr-auto">
        <nb-icon icon="search-outline"></nb-icon>
      </div>
      <div class="col-auto mr-auto">View {{ type | titlecase }} Source</div>
      <div class="col-auto">
        <button nbButton (click)="close()">X</button>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class="wrapper">
      <div class="overlay" *ngIf="showSpinner">
        <div class="spinner-wrapper">
          <app-spinner></app-spinner>
        </div>
      </div>

      <div class="loaded-content" [class.blurred]="showSpinner">
        <h6 class="text-uppercase">{{ type }} Details</h6>
        <dl class="row small">
          <dt class="col-2">ID</dt>
          <dd class="col-10">{{ sourceItem.id }}</dd>
          <dt class="col-2">Location</dt>
          <dd class="col-10">{{ sourceItem.location }}</dd>
          <dt class="col-2">Created by</dt>
          <dd class="col-10">{{ sourceItem.created_by }}</dd>
          <dt class="col-2">Created on</dt>
          <dd class="col-10">{{ utility.convertUnixTime_global(sourceItem.created_ts) }}</dd>
          <dt class="col-2">Last updated by</dt>
          <dd class="col-10">{{ sourceItem.last_updated_by }}</dd>
          <dt class="col-2">Last updated on</dt>
          <dd class="col-10">{{ utility.convertUnixTime_global(sourceItem.last_updated_ts) }}</dd>
        </dl>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div class="d-flex flex-row-reverse">
      <div class="p-2">
        <button nbButton status="info" (click)="close()">Close</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
