export class FavoriteRecommendation {
  public id: string;
  public display_order: number;
  public modification_secs: number;
  public robot_commanddefs: Array<RobotCommandDef> = [];
  public commanddefs: Array<CommandDef> = [];
  public name?: string;
  public color?: string;
}

export class RobotCommandDef {
  public ids: Array<string>;
  public order: string;
}

export class CommandDef {
  public id: string;
  public robot_id: string;
  public pmap_id: string;
  public ordered: number;
  public regions: Array<Region>;
  public user_pmapv_id?: string;
  public command?: string;
  public params?: object;
}

export class Region {
  public region_id: string;
  public type: string;


  constructor(region_id: string, type: string) {
    this.region_id = region_id;
    this.type = type;
  }
}
