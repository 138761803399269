<nb-card accent="success" style="width: 80%; margin: auto" class="own-scroll">
  <nb-card-header>
    <div class="row">
      <div class="col-auto mr-auto">Job {{ failedJob.job_id }}</div>
      <div class="col-auto"><button nbButton (click)="close()">X</button></div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div
      style="height: 700px; width: 60vw; overflow-y: auto; overflow-x: hidden"
    >
      <div class="row">
        <div class="col-auto">
          <h4>Job name</h4>
          <p>{{ failedJob.job_name }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-auto">
          <h4>Date created</h4>
          <p>{{ failedJob.date_created | date: "short" }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-auto">
          <h4>Job status</h4>
          <p>{{ failedJob.status }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-auto">
          <h4>Reason for failure</h4>
          <p>{{ failedJob.job_status.status_reason }}</p>
        </div>
      </div>

      <div class="row">
        <!-- <hr> -->
        <div class="col-auto">
          <h4>List of job logs</h4>
          <div style="overflow: auto">
            <div *ngIf="showLogs">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Log number</th>
                    <th scope="col">Ingestion time</th>
                    <th scope="col">Message</th>
                    <th scope="col">Timestamp</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let failedJob of failedJob.job_logs; index as i">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>
                      {{ failedJob.ingestionTime | date: "short" }}
                    </td>
                    <td>{{ failedJob.message }}</td>
                    <td>{{ failedJob.timestamp | date: "short" }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="!showLogs">Logs not found.</div>
          </div>
        </div>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div class="d-flex flex-row-reverse">
      <div class="p-2">
        <button nbButton status="info" (click)="close()">Close</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
