import { Injectable } from '@angular/core';


@Injectable({ providedIn: 'root' })
export class SortingService {

  private Statuses = new Map(Object.entries({
    FAILED: 0,
    RUNNING: 1,
    SUCCEEDED: 2,
    ACTIVE: 3,
    INACTIVE: 4
  }));

  public sortStatusColumn(direction: number, a: any, b: any) {
    if(this.Statuses.get(a) < this.Statuses.get(b)) {

      return -1 * direction;
    }
    if(this.Statuses.get(a) > this.Statuses.get(b)) {

      return direction;
    }

    return 0;
  }
}
