<nb-card accent="success" style="width: 90%; height: 90%; max-height: 800px; max-width: 1600px; margin: auto">
  <nb-card-header>
    <div class="row">
      <div class="col-auto mr-auto">
        <nb-icon icon="search-outline"></nb-icon>
      </div>
      <div class="col-auto mr-auto">Insight ID: {{ insight.id }}</div>
      <div class="col-auto"><button nbButton (click)="close()">X</button></div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <h6 class="text-uppercase">Insight Details</h6>
    <dl class="row small">
      <dt class="col-3">Name</dt>
      <dd *ngIf="!insight_name_validator" class="col-9">{{ insight.name }}</dd>
      <dd *ngIf="insight_name_validator"  class="col-9 error">
        {{ insight.name }} (invalid insight name)
      </dd>
      <dt class="col-3">Created By</dt>
      <dd *ngIf="insight.created_by !== null; else noUser" class="col-9">
        {{ insight.created_by }}
      </dd>
      <ng-template #noUser>
        <dd class="col-9">Unknown</dd>
      </ng-template>
      <dt class="col-3">Created On</dt>
      <dd class="col-9">{{ formatted_created_date }}</dd>
      <dt class="col-3">Last Updated By</dt>
      <dd *ngIf="insight.last_updated_by !== null; else noUser" class="col-9">
        {{ insight.last_updated_by }}
      </dd>
      <dt class="col-3">Last Updated On</dt>
      <dd class="col-9">{{ formatted_updated_date }}</dd>
      <dt class="col-3">Description</dt>
      <dd class="col-9">{{ insight.description }}</dd>
    </dl>

    <hr />
    <dl class="row small">
      <dt class="col-3">Activated</dt>
      <dd class="col-9">
        <nb-checkbox status="info" [checked]="insight.enabled" disabled></nb-checkbox>
      </dd>
      <ng-container *ngIf="insight.schedule_seconds !== null">
        <dt class="col-3">Schedule</dt>
        <dd class="col-9">{{ scheduleLabel }}</dd>
      </ng-container>
      <ng-container *ngIf="insight.next_run_time !== null">
        <dt class="col-3">Next run time</dt>
        <dd class="col-9">{{ formatted_next_run_time }}</dd>
      </ng-container>
      <ng-container *ngIf="insight.start_date !== null">
        <dt class="col-3">Start Time</dt>
        <dd class="col-9">{{ formatted_start_date }}</dd>
      </ng-container>
      <ng-container *ngIf="insight.end_date !== null">
        <dt class="col-3">End Time</dt>
        <dd class="col-9">{{ formatted_end_date }}</dd>
      </ng-container>
      <dt *ngIf="insight?.status" class="col-3">Status</dt>
      <dd *ngIf="insight?.status" class="col-9">
        {{ insight.status | titlecase }}
      </dd>
      <dt class="col-3">Trigger Query:</dt>
      <dd *ngIf="insight.query" class="col-9">
        <ngx-codemirror
          #codeeditor
          id="query"
          name="query"
          [(ngModel)]="insight.query"
          [options]="{
            lineNumbers: true,
            theme: 'default',
            mode: 'sql',
            readOnly: 'true'
          }"
        ></ngx-codemirror>
      </dd>
    </dl>
  </nb-card-body>
  <nb-card-footer>
    <div class="d-flex flex-row-reverse">
      <div class="p-2">
        <button nbButton status="info" (click)="close()">Close</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
