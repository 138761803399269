/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/no-shadow */
import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { Actions, Select, ofActionCompleted } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ContentState } from 'src/app/components/content/redux/content.state';
import { ViewContentComponent } from 'src/app/components/content/view-content/view-content.component';
import { CampaignService } from 'src/app/core/services/campaign.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { Campaign } from 'src/app/models/campaign';
import { environment } from 'src/environments/environment';
import { OktaRestriction } from 'src/app/shared/okta-restrictions/okta-restrictions';
import { ReloadInsights } from 'src/app/components/insights/redux';
import { TimestampDatePipe } from '../../../../shared/custom-pipe/timestamp-date.pipe';
import { RouterState } from '../../../../shared/redux/router-state';
import { ViewInsightComponent } from '../../../insights/view-insight/view-insight.component';
import { CampaignState } from '../../redux';
import { ManageCampaign, PromotionSuccess } from '../../redux/campaign.actions';
import { SurveysState } from 'src/app/components/surveys/redux';
import { Content } from './../../../../models/content';
import { Insight } from './../../../../models/insight';
import { InsightsState } from './../../../insights/redux/insights.state';
import { CampaignPromotionComponent } from './campaign-promotion-component/campaign-promotion-component.component';
import { CampaignStatusHandlerComponent } from './campaign-status-handler/campaign-status-handler.component';
import { ViewSurveyComponent } from 'src/app/components/surveys/view-survey/view-survey.component';
import { Survey } from 'src/app/models/survey';
@Component({
  selector: 'app-view-campaign',
  templateUrl: './view-campaign.component.html',
  styleUrls: ['./view-campaign.component.css', '../../../../shared/common_syles.css']
})
export class ViewCampaignComponent implements OnInit {
  @Select(ContentState.content) public data$: Observable<any[]>;
  @Select(InsightsState.insightsActive) public data_insights$: Observable<any[]>;
  @Select(SurveysState.surveys) public dataSurveys$: Observable<any[]>;
  @Select(RouterState.username) public data_username$: Observable<any>;
  @Select(CampaignState.campaign) public data_campaign$: Observable<any[]>;
  @Select(InsightsState.insightsInactive) public data_insights_inactive$: Observable<any[]>;

  public campaign: Campaign;
  public content: Content[];
  public insight: Insight[];
  public surveys = [];
  public selectedSurvey = [];
  // User data
  public username = '';
  // Promotion logic
  public show_promoter_username = false;
  public display_promotion_button = false;
  public disable_promotion_button = false;

  public sendNewLabel: string = null;
  public sendRepeatLabel: string = null;
  public showSpinner = false;
  public campaign_name_validator = false;
  public scheduleLabel: string;
  public formatted_start_time = '';
  public formatted_end_time = '';
  public formatted_created_time = '';
  public formatted_updated_date = '';
  public formatted_next_run_time = '';

  public display_management_buttons = false;

  constructor(
    private utilityService: UtilityService,
    protected ref: NbDialogRef<ViewCampaignComponent>,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
    private formatDatePipe: TimestampDatePipe,
    private actions$: Actions,
    private service: CampaignService,
    private restrictionService: OktaRestriction
  ) {
    this.data_username$.subscribe((data) => (this.username = data));
  }

  public regexValidator(nameRe: RegExp, value: string) {
    const validation = nameRe.test(value);

    return validation ? false : true;
  }
  public ngOnInit() {

    this.dataSurveys$.subscribe((data) =>
      data.forEach((item) => {
        const obj = Survey.copy(item);
        this.surveys.push(obj);
      })
    );

    if (this.campaign.responses) {
      this.campaign.responses.forEach((item) => {
        const obj = { name: item.name, id: item.id };
        this.selectedSurvey.push(obj);
      });
    }

    const regex = String.raw`^[a-zA-Z0-9][a-z\d\-_:\s]*$`;
    this.data$.subscribe((data) => (this.content = data));
    this.actions$.pipe(ofActionCompleted(PromotionSuccess)).subscribe((data) => {
      if (data) {
        this.service.loadCampaignsAmplify();
        this.data_campaign$.subscribe((data) => {
          const campaign = data.find((campaign) => campaign.id === this.campaign.id);
          this.campaign = campaign;
          this.handleUpdate();
        });
      }
    });
    this.display_management_buttons = this.restrictionService.manager_permission;
    this.data_insights$.subscribe((data) => (this.insight = data));
    this.scheduleLabel = this.utilityService.getMatchedFrequencyLabel(this.campaign.schedule_seconds);
    this.campaign_name_validator = this.regexValidator(new RegExp(regex, 'i'), this.campaign.name);
    this.data_insights_inactive$.subscribe((data) => (this.insight = this.insight.concat(data)));

    this.actions$.pipe(ofActionCompleted(ManageCampaign)).subscribe((data) => {
      this.showSpinner = true;
      setTimeout(() => {
        this.campaign = data.action.payload;
        this.showSpinner = false;

        this.handleDate();
      }, 300);
    });

    this.actions$.pipe(ofActionCompleted(ReloadInsights)).subscribe((data) => {
      this.data_insights$.subscribe((data) => (this.insight = data));
      this.data_insights_inactive$.subscribe((data) => (this.insight = this.insight.concat(data)));
    });

    this.show_promoter_username = this.campaign.promoted ? true : false;
    if (environment.stage !== 'prod') {
      const foundInsight = this.insight.find((element) => element.id === Number(this.campaign.trigger_id));
      const foundContet = this.content.find((element) => element.content_id === this.campaign.content_id);
      // Disable promotion button if no insight or no content or the campaign is promoted.
      this.disable_promotion_button =
        foundContet && foundInsight && !this.campaign.promoted && !this.campaign.is_campaign_promotion_in_progress
          ? true
          : false;
      // Do not show button based on user Okta group
      this.display_promotion_button = this.restrictionService.promotion_permission;
    }
    this.handleDate();
  }
  public handleUpdate() {
    this.show_promoter_username = this.campaign.promoted ? true : false;
    const foundInsight = this.insight.find((element) => element.id === Number(this.campaign.trigger_id));
    const foundContet = this.content.find((element) => element.content_id === this.campaign.content_id);
    this.disable_promotion_button =
      foundContet && foundInsight && !this.campaign.promoted && !this.campaign.is_campaign_promotion_in_progress
        ? true
        : false;
  }
  public handleDate() {
    const created_date = this.campaign.created_dttm;
    this.formatted_created_time = this.formatDate(created_date);

    const updated_date = this.campaign.last_updated_dttm;
    this.formatted_updated_date = this.formatDate(updated_date);

    const end_time = this.campaign.end_date;
    this.formatted_end_time = this.formatDate(end_time);

    const start_time = this.campaign.start_date;
    this.formatted_start_time = this.formatDate(start_time);

    const next_run_time = this.campaign.next_run_time;
    this.formatted_next_run_time = this.formatDate(next_run_time);
  }

  public formatDate = (time) => {
    const formatted_time = this.formatDatePipe.transform(time, 'timestampToDate');
    if (formatted_time) {
      return formatted_time;
    } else {
      return '';
    }
  };

  public viewInsight(event) {
    const found = this.insight.find((element) => element.id === event);
    if (found) {
      this.dialogService.open(ViewInsightComponent, {
        closeOnEsc: false,
        closeOnBackdropClick: false,
        context: {
          insight: found
        }
      });
    } else {
      this.toastrService.show(status, 'Insight with this ID does not exist', {
        status: 'danger'
      });
    }
  }

  public viewContent(event) {
    const found = this.content.find((element) => element.content_id === event);
    if (found) {
      this.dialogService.open(ViewContentComponent, {
        closeOnEsc: false,
        closeOnBackdropClick: false,
        context: {
          content: found
        }
      });
    } else {
      this.toastrService.show(status, 'Content with this ID does not exist', {
        status: 'danger'
      });
    }
  }

  public viewSurvey(event) {
    const found = this.surveys.find((element) => element.survey_id === event.id);
    if (found) {
      this.dialogService.open(ViewSurveyComponent, {
        context: {
          survey: Survey.copy(found) as Survey
        }
      });
    } else {
      this.toastrService.show(status, 'Survey with this ID does not exist', {
        status: 'danger'
      });
    }
  }

  public activateCampaign() {
    const associatedInsight = this.insight.find((element) => element.id === Number(this.campaign.trigger_id));
    if (!associatedInsight) {
      this.toastrService.show(status, 'No Insight Associated with the Campaign.', {
        status: 'danger',
        duration: 6000
      });
    }

    this.dialogService.open(CampaignStatusHandlerComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'Activate',
        campaign: <Campaign>Campaign.copy(this.campaign)
      }
    });
  }

  public deactivateCampaign() {
    this.dialogService.open(CampaignStatusHandlerComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'Deactivate',
        campaign: <Campaign>Campaign.copy(this.campaign)
      }
    });
  }

  public promoteCampaign() {
    const content = this.content.find((element) => element.content_id === this.campaign.content_id);
    this.dialogService.open(CampaignPromotionComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'Promote',
        campaign: <Campaign>Campaign.copy(this.campaign),
        insight: this.insight,
        content,
        username: this.username
      }
    });
  }

  public close() {
    this.ref.close();
  }
}
