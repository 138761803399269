import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-qa-image-form',
  templateUrl: './qa-image-form.component.html',
  styleUrls: ['./qa-image-form.component.css']
})
export class QaImageFormComponent implements OnInit {
  @Input() public campaign;
  @Input() public parsedCampaignValues;

  @Output() public outputCampaignValues = new EventEmitter();

  public campName: string;
  public localParamArray = [];
  public localValueArray = [];

  public formGroup: FormGroup;
  public verify = true;

  constructor() {}

  public ngOnInit(): void {
    this.fillParameters();
  }

  public fillParameters() {
    let index = 0;
    for (const parameter of this.parsedCampaignValues) {
      this.localParamArray[index] = parameter;
      index++;
    }
    this.localValueArray.length = this.localParamArray.length;
  }

  public parameterValueChanged(arrayPosition, newChange) {
    if (typeof newChange === 'object') {
      this.localValueArray[arrayPosition] = newChange.target.value;
    } else {
      this.localValueArray[arrayPosition] = newChange;
    }
    this.outputCampaignValues.emit(this.localValueArray);
  }
}
