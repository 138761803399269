<app-spinner *ngIf="spin"></app-spinner>
<app-ngx-table
  type={{service.type}}
  description="Campaign management system shows a table of all robot campaigns and allows campaign manipulation."
  [buttonEnabled]="displayNewButton"
  [tableData]="tableData"
  *ngIf="!spin"
  (clickedButton)="clickedButtonTable($event)"
  (createNew)="newCreation()"
  (refreshTable)="refreshTableData()"
></app-ngx-table>
<router-outlet></router-outlet>
