import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-campaign-websocket-window',
    templateUrl: './campaign-websocket-window.component.html',
    styleUrls: ['./campaign-websocket-window.component.scss']
})
export class CampaignWebsocketWindowComponent implements OnInit {
  public results;

  constructor() { }

  public ngOnInit() {
  }
}
