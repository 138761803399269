<nb-card accent="success" style="width: 1100px; margin: auto">
  <nb-card-header>
    <div class="row">
      <div class="col-auto mr-auto">
        <nb-icon icon="search-outline"></nb-icon>
      </div>
      <div class="col-auto mr-auto">{{ title }} Canned Kozr</div>
      <div class="col-auto"><button nbButton (click)="close()">X</button></div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <form #cannedKozrFrom="ngForm" style="height: 700px; padding: 1.143em; margin: -1.143em">
      <strong class="text-uppercase">Canned Kozr Details</strong>
      <hr />
      <div class="form-group row">
        <label for="robotid" class="col-sm-3 col-form-label col-form-label-sm"
          >Robot ID<span style="color: red">*</span>:</label
        >
        <div class="input-group col-sm-9">
          <input
            nbInput
            id="robotid"
            required
            [(ngModel)]="cannedKozr.robotid"
            class="form-control form-control-sm"
            type="text"
            placeholder="Robot ID"
            name="robotid"
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="pmap_id" class="col-sm-3 col-form-label col-form-label-sm"
          >Pmap ID<span style="color: red">*</span>:</label
        >
        <div class="input-group col-sm-9">
          <input
            nbInput
            id="pmap_id"
            required
            [(ngModel)]="cannedKozr.pmap_id"
            class="form-control form-control-sm"
            type="text"
            placeholder="Pmap ID"
            name="pmap_id"
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="active_pmapv_id" class="col-sm-3 col-form-label col-form-label-sm">Active Pmap:</label>
        <div class="input-group col-sm-9 align-self-center">
          <input
            nbInput
            id="active_pmapv_id"
            [(ngModel)]="cannedKozr.active_pmapv_id"
            class="form-control form-control-sm"
            type="text"
            placeholder="Active Pmap ID"
            name="active_pmapv_id"
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="score" class="col-sm-3 col-form-label col-form-label-sm">Pmapv_ids:</label>
        <div class="input-group col-sm-9 align-self-center">
          <div *ngFor="let p of pmapv_ids; let i = index; trackBy: trackByIndex" class="mt-2 mr-2">
            <div class="input-group">
              <div class="input-group-append">
                <button
                  nbButton
                  (click)="removeField('pmapv_ids', i)"
                  class="btn btn-sm btn-danger"
                  size="small"
                  status="danger"
                >
                  <nb-icon icon="close-outline"></nb-icon>
                </button>
              </div>
              <input
                nbInput
                id="{{ 'pmapv_ids' + i }}"
                [(ngModel)]="pmapv_ids[i]"
                class="form-control form-control-sm"
                type="text"
                placeholder="pmapv_ids {{ i }}"
                name="{{ 'pmapv_ids' + i }}"
              />
            </div>
          </div>
          <button
            nbButton
            (click)="addField('pmapv_ids')"
            style="max-height: 32.5px; margin-top: auto"
            class="btn btn-sm btn-warning"
            size="small"
            status="warning"
          >
            <nb-icon icon="plus-circle-outline"></nb-icon>
          </button>
        </div>
      </div>
      <div class="form-group row">
        <label for="koz_geom" class="col-sm-3 col-form-label col-form-label-sm"
          >KOZ Geometry<span style="color: red">*</span>:</label
        >
        <div class="input-group col-sm-9 align-self-center">
          <textarea
            fullWidth
            required
            style="width: 100%"
            (input)="updateModel('koz_geom', $event)"
            id="koz_geom"
            class="form-control form-control-sm"
            name="koz_geom"
            rows="10"
            >{{ cannedKozr.koz_geom | json }}</textarea
          >
        </div>
        <div class="col-sm-3 col-form-label col-form-label-sm"></div>
        <div class="input-group col-sm-9 align-self-center error" *ngIf="!valid_koz_geom">
          Invalid JSON
        </div>
      </div>
      <div class="form-group row">
        <label for="score" class="col-sm-3 col-form-label col-form-label-sm">related_events_geojson:</label>
        <div class="input-group col-sm-9 align-self-center">
          <div *ngFor="let p of related_events_geojson; let i = index; trackBy: trackByIndex" class="mt-2 mr-2">
            <div class="input-group">
              <textarea
                fullWidth
                style="width: 100%"
                id="related_events_geojson"
                (input)="updateModel('related_events_geojson', $event, i)"
                class="form-control form-control-sm"
                name="related_events_geojson"
                rows="10"
                >{{ related_events_geojson[i] | json }}</textarea
              >
              <button
                nbButton
                (click)="removeField('related_events_geojson', i)"
                class="btn btn-sm btn-danger"
                style="width: 100%"
                size="small"
                status="danger"
              >
                <nb-icon icon="close-outline"></nb-icon>
              </button>
              <div *ngIf="!valid_related_events" style="width: 100%; font-size: 10px; color: red">Invalid JSON</div>
            </div>
          </div>
          <button
            nbButton
            (click)="addField('related_events_geojson')"
            class="btn btn-sm btn-warning"
            size="small"
            status="warning"
          >
            <nb-icon icon="plus-circle-outline"></nb-icon>
          </button>
        </div>
      </div>
      <div class="form-group row">
        <label for="score" class="col-sm-3 col-form-label col-form-label-sm">event_types:</label>
        <div class="input-group col-sm-9 align-self-center">
          <div *ngFor="let p of event_types; let i = index; trackBy: trackByIndex" class="mt-2 mr-2">
            <div class="input-group">
              <div class="input-group-append">
                <button
                  nbButton
                  (click)="removeField('event_types', i)"
                  class="btn btn-sm btn-danger"
                  size="small"
                  status="danger"
                >
                  <nb-icon icon="close-outline"></nb-icon>
                </button>
              </div>
              <input
                nbInput
                id="{{ 'event_types' + i }}"
                [(ngModel)]="event_types[i]"
                class="form-control form-control-sm"
                type="text"
                placeholder="event_types {{ i }}"
                name="{{ 'event_types' + i }}"
              />
            </div>
          </div>
          <button
            nbButton
            (click)="addField('event_types')"
            class="btn btn-sm btn-warning"
            style="max-height: 32.5px; margin-top: auto"
            size="small"
            status="warning"
          >
            <nb-icon icon="plus-circle-outline"></nb-icon>
          </button>
        </div>
      </div>
      <div class="form-group row">
        <label for="robotid" class="col-sm-3 col-form-label col-form-label-sm">Score:</label>
        <div class="input-group col-sm-9">
          <input
            nbInput
            id="score"
            type="number"
            [(ngModel)]="cannedKozr.score"
            class="form-control form-control-sm"
            placeholder="Score"
            name="score"
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="cumul_orientation_rad" class="col-sm-3 col-form-label col-form-label-sm"
          >cumul_orientation_rad</label
        >
        <div class="input-group col-sm-6 align-self-center">
          <input
            id="name"
            class="form-control form-control-sm"
            type="number"
            name="name"
            step="0.5"
            placeholder="0"
            min="-180"
            max="180"
            [maxLength]="7"
            #name="ngModel"
            nbInput
            [(ngModel)]="cannedKozr.cumul_orientation_rad"
            validNumberRange="180"
          />
        </div>
        <div class="col-sm-3">
          <div *ngIf="name.errors?.rangeValidator" class="error">
            Accepted range is from -180 to 180.
          </div>
          <div *ngIf="name.errors?.numberValidator" class="error">
            Only two decimal places allowed.
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="score" class="col-sm-3 col-form-label col-form-label-sm">Comments:</label>
        <div class="input-group col-sm-9 align-self-center">
          <div *ngFor="let c of comments; let i = index; trackBy: trackByIndex" class="mt-2 mr-2">
            <input
              nbInput
              id="{{ 'comment' + i }}"
              [(ngModel)]="comments[i]"
              class="form-control form-control-sm"
              type="text"
              placeholder="Comment {{ i }}"
              name="{{ 'comment' + i }}"
            />
          </div>
          <button
            nbButton
            (click)="addField('comments')"
            class="btn btn-sm btn-warning"
            style="max-height: 32.5px; margin-top: auto"
            size="small"
            status="warning"
          >
            <nb-icon icon="plus-circle-outline"></nb-icon>
          </button>
        </div>
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer>
    <div class="d-flex flex-row-reverse">
      <div class="p-2">
        <button
          nbButton
          status="success"
          [disabled]="!cannedKozrFrom.form.valid || !valid_koz_geom || !valid_related_events"
          (click)="save()"
        >
          Save
        </button>
      </div>
      <div class="p-2">
        <button nbButton status="info" (click)="close()">Close</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
