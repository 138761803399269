<div>
  <div style="height: 580px; padding: 1.143em; margin: -1.143em">
    <div class="row">
      <div>
        <h5>Kozr - Robot ID:</h5>
      </div>
    </div>
    <!-- Robot selection -->
    <div class="row">
      <div class="col">
        <ng-select
          fullWidth
          [(ngModel)]="selectedKozrs"
          (change)="testUpdate()"
          [multiple]="true"
          placeholder="Select Robot IDs"
        >
          <ng-option *ngFor="let p of kozrBots" [value]="p"> {{ p.robotid }} - {{ p.koz_id }} </ng-option>
        </ng-select>
      </div>
    </div>
    <div *ngFor="let kozr of selectedKozrs">
      <hr />
      <div class="row">
        <h6>Robot - {{ kozr.robotid }}</h6>
      </div>
      <br />
      <div *ngIf="kozrs_selected">
        <div class="row">
          <h6>Recommendation details</h6>
        </div>
        <br />
        <div class="row">
          <label class="col-6"><strong>KOZR ID:</strong></label>
          <label class="col-6">{{ kozr.koz_id }}</label>
        </div>
        <div class="row">
          <label class="col-6"><strong>PMap ID:</strong></label>
          <label class="col-6">{{ kozr.pmap_id }}</label>
        </div>
        <div class="row">
          <label class="col-6"><strong>Geometry:</strong></label>
          <label class="col-6">{{ kozr.koz_geom | json }}</label>
        </div>
        <div class="row">
          <label class="col-6"><strong>Event types:</strong></label>
          <label class="col-6">{{ kozr.event_types }}</label>
        </div>
        <div class="row">
          <label class="col-6"><strong>Related geom. events:</strong></label>
          <label class="col-6">{{ kozr.related_events_geojson | json }}</label>
        </div>
        <div class="row">
          <label class="col-6"><strong>Score:</strong></label>
          <label class="col-6">{{ kozr.score }}</label>
        </div>
        <div class="row" *ngIf="kozr.cumul_orientation_rad">
          <label class="col-6"><strong>cumul_orientation_rad:</strong></label>
          <label class="col-6">{{ kozr.cumul_orientation_rad }}</label>
        </div>
        <div class="row">
          <label class="col-6"><strong>Time created:</strong></label>
          <label class="col-6">{{ utilityService.convertUnixTime_global(kozr.create_timestamp / 1000) }}</label>
        </div>
      </div>
      <br />
      <div class="row">
        <label><strong>Select KOZR campaign:</strong></label>
      </div>
      <div class="row">
        <nb-select
          class="col"
          fullWidth
          placeholder="Select a campaign..."
          [multiple]="false"
          [(selected)]="selectedCampaign[kozr.robotid]"
        >
          <nb-option *ngFor="let camp of kozrCampaigns" [value]="camp">
            {{ camp.name }}
          </nb-option>
        </nb-select>
      </div>
    </div>
    <br />
    <div *ngIf="showKozrSubmit()" style="margin-top: 15px">
      <button nbButton [status]="'success'" (click)="submitKozRecommendations()">Submit notification</button>
    </div>
    <br />
    <div class="alert alert-success" *ngIf="kozrService.isPublished && kozrs_selected">
      <h3>Notification was submitted successfully!</h3>
      <hr />
      <pre>{{ kozrService.isPublished | json }}</pre>
    </div>
  </div>
</div>
