import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RobotsService } from 'src/app/core/services/robots.service';
import { Robot } from 'src/app/models/robot';

import { DecimalPipe } from '@angular/common';
import { NbDialogService } from '@nebular/theme';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SpinnerState } from 'src/app/shared/spinner/redux/spinner.state';
import { NgxGenericModalComponent } from 'src/app/shared/ngx-generic-modal/ngx-generic-modal.component';
import { DeleteRobots, LoadOwnerRobots, QaState, ReloadOwnerRobots, LoadAllRobots } from '../redux';
import { ViewMyRobotsComponent } from './view-my-robots/view-my-robots.component';
import { EditMyRobotsComponent } from './edit-my-robots/edit-my-robots.component';

@Component({
  selector: 'app-my-robots',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './my-robots.component.html',
  styleUrls: ['./my-robots.component.css'],
  providers: [DecimalPipe]
})
export class MyRobotsComponent implements OnInit, AfterViewInit {
  @Select(QaState.ownerRobots) public data_robots$: Observable<any[]>;
  @Select(QaState.robots) public data_allRobots$: Observable<any[]>;
  @Select(SpinnerState) public loading: Observable<boolean>;
  public tableData;
  public spin;

  constructor(private store: Store, private dialogService: NbDialogService, private robotService: RobotsService) {
    this.store.dispatch(LoadOwnerRobots);
  }

  public ngAfterViewInit() {
    if (this.store.selectSnapshot(QaState.robots).length > 1) {
      this.robotService.buildKeysForTable(this.store.selectSnapshot(QaState.robots));
    }
  }

  public ngOnInit() {
    this.data_robots$.subscribe((data) => {
      this.tableData = data;
    });
    this.data_allRobots$.subscribe((data) =>{
      this.robotService.buildKeysForTable(data);
    });
    this.loading.subscribe((data) => (this.spin = data));
  }

  /***********************************************************************/

  public clickedButtonTable($event) {
    if ($event.action === 'view') {
      this.viewRobot($event.data);
    }
    if ($event.action === 'edit') {
      this.editRobot($event.data);
    }
    if ($event.action === 'delete') {
      this.deleteRobot($event.data);
    }
  }

  /***********************************************************************/

  public newCreation() {
    this.newRobot();
  }

  public refreshTableData() {
    this.store.dispatch(ReloadOwnerRobots);
  }

  /***********************************************************************/

  public newRobot(): void {
    this.dialogService.open(EditMyRobotsComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'New',
        robot: new Robot()
      }
    });
  }

  /***********************************************************************/

  public viewRobot(robot: Robot) {
    this.dialogService.open(ViewMyRobotsComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        //title: 'View',
        robot
      }
    });
  }

  /***********************************************************************/

  public editRobot(robot: Robot) {
    const editBot: Robot = robot;

    this.dialogService.open(EditMyRobotsComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'Edit',
        robot: Robot.copy(editBot)
      }
    });
  }

  /***********************************************************************/

  public deleteRobot(robot) {
    this.dialogService
      .open(NgxGenericModalComponent, {
        closeOnEsc: false,
        closeOnBackdropClick: false,
        context: {
          title: 'Are you sure you want to delete Robot',
          message: robot.name
        }
      })
      .onClose.subscribe((data) => {
        if (data === true) {
          const robotToDelete = Robot.copy(robot);
          robotToDelete.deleted = true;
          this.store.dispatch(new DeleteRobots(robotToDelete));
        }
      });
  }
}
