import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Robot } from 'src/app/models/robot';

import { NbToastrService } from '@nebular/theme';
import { Select, Store } from '@ngxs/store';
import { API } from '@aws-amplify/api';
import { CogwheelService } from 'src/app/core/services/cogwheel.service';
import { RouterState } from 'src/app/shared/redux/router-state';
import { ToggleHide } from 'src/app/shared/spinner/redux/spinner.actions';
import { environment } from 'src/environments/environment';
import { AmplifyServiceBuilder } from '../services/amplify.service.builder';
import {
  DeleteRobotsSuccess,
  LoadOwnerRobotsSuccess,
  LoadRobotsSuccess,
  SaveRobotComplete,
  SaveRobotFail
} from './../../components/qa-testing/redux/qa.actions';

@Injectable({
  providedIn: 'root'
})
export class RobotsService {
  @Select(RouterState.username) public data_username$: Observable<any>;

  public owner: string;
  public signedRequests: any;
  public selectedKeys: string[] = [];
  public allKeys: string[] = [];
  public type = 'my robots';

  public robots: any;
  public robots_api = 'robots_api';

  constructor(private store: Store,
              private toastrService: NbToastrService,
              private cogWheelService: CogwheelService) {
    this.data_username$.subscribe((data) => (this.owner = data));
  }

  public loadAllRobotsAmplify(reload?) {
    const requestData = AmplifyServiceBuilder(this.robots_api, true);

    API.get(this.robots_api, `/${environment.stage}/insights/qa/robots`, requestData)
      .then((response) => {
        if (reload) {
          this.toastrService.show(status, 'Fetching Robots Complete', {
            status: 'info'
          });
        }
        this.store.dispatch(new LoadRobotsSuccess(response));
      })
      .catch((error) => {
        this.store.dispatch(ToggleHide);
        this.toastrService.show(status, 'Failed to fetch robot list', { status: 'danger' });
        console.error('Error occured', error);
      });
  }

  public postRobotAmplify(newRobot: Robot, isEdit?: boolean) {
    const body = newRobot;
    const requestData = AmplifyServiceBuilder(this.robots_api, true, body);

    API.post(this.robots_api, `/${environment.stage}/insights/qa/robots`, requestData)
      .then((response) => {
        this.store.dispatch(new SaveRobotComplete(isEdit, newRobot));
      })
      .catch((error) => {
        this.store.dispatch(new SaveRobotFail(error));
      });
  }

  public deleteRobotAmplify(robot: Robot) {
    const body = robot;
    const requestData = AmplifyServiceBuilder(this.robots_api, true, body);

    API.del(this.robots_api, `/${environment.stage}/insights/qa/robots`, requestData)
      .then((response) => {
        this.store.dispatch(new DeleteRobotsSuccess(robot));
      })
      .catch((error) => {
        this.store.dispatch(new SaveRobotFail(error));
      });
  }

  public loadOwnerRobotsAmplify() {
    const queryStringParameters = {
      owner: this.owner
    };
    const requestData = AmplifyServiceBuilder(this.robots_api, true, false, queryStringParameters);

    API.get('robots_api', `/${environment.stage}/insights/qa/robots`, requestData)
      .then((response) => {
        this.toastrService.show(status, 'Fetch owner robots complete', { status: 'success' });
        this.store.dispatch(new LoadOwnerRobotsSuccess(response));
      })
      .catch((error) => {
        this.toastrService.show(status, 'Failed to fetch owner robots', { status: 'danger' });
        this.store.dispatch(ToggleHide);
        console.error('Error occured', error);
      });
  }

  public buildKeysForTable(robots: Robot[]) {
    this.cogWheelService.buildKeysForTable(robots, this.allKeys, this.store, this.type, false);
  }
}
