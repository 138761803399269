<app-spinner *ngIf="spin"></app-spinner>
<nb-card *ngIf="!spin">
    <nb-card-header>
        <h3 style="position: relative">Real Time Personalization</h3>
    </nb-card-header>
    <nb-card-body>
        <div class="container" style="max-width: 800px; height: 700px">
            <div style="margin: 10px">
                <h6>Select notification type:</h6>
                <nb-select
                    [(ngModel)]="optionSelect"
                    id="regions"
                    style="width: 100%"
                    (selectedChange)="onEventTypeSelect($event)"
                >
                    <nb-option *ngFor="let p of event_types" [value]="p">
                        {{ p }}
                    </nb-option>
                </nb-select>
            </div>
            <div style="margin: 10px">
                <ng-container *ngIf="selectedEventType !== ''">
                    <div class="formgroupmodal">
                        <!-- <h4 style="padding: 10px;">Event type: &nbsp;&nbsp;&nbsp; {{selectedEventType}}</h4> -->
                        <!-- <hr> -->
                        <div *ngIf="selectedEventType === 'NewUserRobotProvisioned'">
                            <div>
                                <h6>Select a robot:</h6>
                                <br />
                                <ng-select
                                    [(ngModel)]="selectBot"
                                    id="regions"
                                    style="width: 43%"
                                    (change)="onRobotSelect(selectBot)"
                                    [groupBy]="groupByFn(myRobots)"
                                >
                                    <ng-option *ngFor="let p of myRobots" [value]="p">
                                        {{ p.name }} - {{ p.id }}
                                    </ng-option>
                                    <ng-option *ngFor="let p of commonRobots" [value]="p">
                                        {{ p.name }} - {{ p.id }}
                                    </ng-option>
                                </ng-select>
                            </div>
                            <br />
                            <div *ngIf="robotSelected !== false">
                                <div class="row">
                                    <div class="col">
                                        <h6 class="bold-head">Robot name:</h6>
                                        <label class="label-left-margin">{{ selectedRobot.name }}</label>
                                    </div>
                                    <div class="col">
                                        <h6 class="bold-head">Robot ID:</h6>
                                        <label class="label-left-margin">{{ selectedRobot.id }}</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <h6 class="bold-head">SKU:</h6>
                                        <label class="label-left-margin">{{ selectedRobot.sku }}</label>
                                    </div>
                                    <div class="col">
                                        <h6 class="bold-head">Timezone:</h6>
                                        <label class="label-left-margin">{{ selectedRobot.timezone }}</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <h6 class="bold-head">Registration date:</h6>
                                        <label class="label-left-margin">
                                            {{
                                                utilityService.convertUnixTime_global(
                                                    selectedRobot.registration_date / 1000,
                                                    'full'
                                                )
                                            }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="selectedEventType === 'UserAppUpgraded'">
                            <div>
                                <h6>Select a robot:</h6>
                                <br />
                                <ng-select
                                    [(ngModel)]="selectBot"
                                    id="regions"
                                    style="width: 43%"
                                    (change)="onRobotSelect(selectBot)"
                                    [groupBy]="groupByFn(myRobots)"
                                >
                                    <ng-option *ngFor="let p of myRobots" [value]="p">
                                        {{ p.name }} - {{ p.id }}
                                    </ng-option>
                                    <ng-option *ngFor="let p of commonRobots" [value]="p">
                                        {{ p.name }} - {{ p.id }}
                                    </ng-option>
                                </ng-select>
                            </div>
                            <br />
                            <div *ngIf="robotSelected !== false">
                                <div class="row">
                                    <div class="col">
                                        <h6 class="bold-head">Robot name:</h6>
                                        <label class="label-left-margin">{{ selectedRobot.name }}</label>
                                    </div>
                                    <div class="col">
                                        <h6 class="bold-head">Robot ID:</h6>
                                        <label class="label-left-margin">{{ selectedRobot.id }}</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <h6 class="bold-head">SKU:</h6>
                                        <label class="label-left-margin">{{ selectedRobot.sku }}</label>
                                    </div>
                                    <div class="col">
                                        <h6 class="bold-head">Timezone:</h6>
                                        <label class="label-left-margin">{{ selectedRobot.timezone }}</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <h6 class="bold-head">App ID:</h6>
                                        <!-- <label class="label-left-margin">{{selectedRobot.id}}</label> -->
                                        <input
                                            nbInput
                                            type="text"
                                            class="form-control"
                                            [(ngModel)]="app_id"
                                            placeholder="Enter app ID..."
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <h6 class="bold-head">Old version:</h6>
                                        <!-- <label class="label-left-margin">{{selectedRobot.sku}}</label> -->
                                        <input
                                            nbInput
                                            type="text"
                                            class="form-control"
                                            [(ngModel)]="old_version"
                                            placeholder="Enter old app version..."
                                        />
                                    </div>
                                    <div class="col">
                                        <h6 class="bold-head">New version:</h6>
                                        <input
                                            nbInput
                                            type="text"
                                            class="form-control"
                                            [(ngModel)]="new_version"
                                            placeholder="Enter new app version..."
                                        />
                                        <!-- <label class="label-left-margin">{{selectedRobot.timezone}}</label> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="selectedEventType === 'MissionEndEvent'">
                            <div>
                                <h6>Select a robot:</h6>
                                <br />
                                <ng-select
                                    [(ngModel)]="selectBot"
                                    id="regions"
                                    style="width: 43%"
                                    (change)="onRobotSelect(selectBot)"
                                    [groupBy]="groupByFn(myRobots)"
                                >
                                    <ng-option *ngFor="let p of myRobots" [value]="p">
                                        {{ p.name }} - {{ p.id }}
                                    </ng-option>
                                    <ng-option *ngFor="let p of commonRobots" [value]="p">
                                        {{ p.name }} - {{ p.id }}
                                    </ng-option>
                                </ng-select>
                            </div>
                            <br />
                            <div *ngIf="robotSelected !== false">
                                <div class="row">
                                    <div class="col">
                                        <h6 class="bold-head">Robot name:</h6>
                                        <label class="label-left-margin">{{ selectedRobot.name }}</label>
                                    </div>
                                    <div class="col">
                                        <h6 class="bold-head">Robot ID:</h6>
                                        <label class="label-left-margin">{{ selectedRobot.id }}</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <h6 class="bold-head">SKU:</h6>
                                        <label class="label-left-margin">{{ selectedRobot.sku }}</label>
                                    </div>
                                    <div class="col">
                                        <h6 class="bold-head">Timezone:</h6>
                                        <label class="label-left-margin">{{ selectedRobot.timezone }}</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <h6 class="bold-head">No. of completed missions:</h6>
                                        <input nbInput type="number" [(ngModel)]="numberOfMissions" />
                                    </div>
                                    <div class="col">
                                        <h6 class="bold-head">Mission done code:</h6>
                                        <nb-select [(ngModel)]="missionsDone" class="col-6" style="margin-left: -15px">
                                            <nb-option-group title="Success">
                                                <nb-option
                                                    *ngFor="let option of mission_end_events_success"
                                                    value="{{ option }}"
                                                >
                                                    {{ option }}
                                                </nb-option>
                                            </nb-option-group>
                                            <nb-option-group title="Cancellation">
                                                <nb-option
                                                    *ngFor="let option of mission_end_events_cancellation"
                                                    value="{{ option }}"
                                                >
                                                    {{ option }}
                                                </nb-option>
                                            </nb-option-group>
                                        </nb-select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <h6 class="bold-head"> Successful mission count:</h6>
                                        <input nbInput min="1" type="number" [(ngModel)]="numberOfSuccessfulMissions" />
                                    </div>
                                    <div class="col">
                                        <h6 class="bold-head"> Cancelled mission count:</h6>
                                        <input nbInput min="1" type="number" [(ngModel)]="numberOfCancelledMissions" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col-6">
                                <h6 class="bold-head">Endpoint ID:</h6>
                                <label style="font-size: smaller"
                                    >Endpoint may include a Robot ID, Cognito ID, or even an E-mail address.</label
                                >
                                <!-- <label class="label-left-margin">{{selectedRobot.sku}}</label> -->
                                <input
                                    nbInput
                                    type="text"
                                    class="form-control"
                                    [(ngModel)]="endpoint_id"
                                    placeholder="Enter endpoint..."
                                    required
                                    name="endpoint"
                                    #endpoint="ngModel"
                                />
                                <ng-container *ngIf="(endpoint.dirty || endpoint.touched) && endpoint.invalid">
                                    <p class="error">Endpoint ID is a required value</p>
                                </ng-container>
                            </div>
                            <div class="col-6">
                                <h6 class="bold-head">Endpoint type:</h6>
                                <label style="font-size: smaller"
                                    >Regards the endpoint ID; what type of an endpoint is required?</label
                                >
                                <nb-select
                                    [(ngModel)]="selectedEndpointType"
                                    name="endpoint_type"
                                    #endpoint_type="ngModel"
                                    style="width: 70%"
                                    placeholder="Enter endpoint type.."
                                >
                                    <nb-option *ngFor="let endpoint_type of endpoint_types" [value]="endpoint_type">
                                        {{ endpoint_type }}
                                    </nb-option>
                                </nb-select>
                                <ng-container
                                    *ngIf="(endpoint_type.dirty || endpoint_type.touched) && endpoint_type.invalid"
                                >
                                    <p class="error">Endpoint type is a required value</p>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div style="margin-top: 10px">
                        <button class="btn btn-primary" (click)="onSubmit()" [disabled]="!enableSubmit()">
                            Submit
                        </button>
                    </div>
                    <div class="alert alert-success mt-4" *ngIf="submissionSent">
                        <h4>Notification submitted!</h4>
                        <hr />
                        <pre>{{ payroll | json }}</pre>
                    </div>
                    <div class="alert alert-danger mt-4" *ngIf="!submissionSent && submissionSent !== null">
                        <h4>Notification failed!</h4>
                        <hr />
                        <pre>{{ payroll | json }}</pre>
                    </div>
                </ng-container>
            </div>
        </div>
    </nb-card-body>
</nb-card>
