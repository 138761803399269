
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext} from '@ngxs/store';
import { CogwheelStateModel } from './cogwheel-state.model';
import { SetComponentCurrentTableKeys, SetComponentTableKeys, SetCurrentType } from './cogwheel.actions';


@State<CogwheelStateModel>({
    name: 'cogwheel',
    defaults: {
        componentKeys: new Map<string, string[]>([]),
        componentCurrentKeys: new Map<string, string[]>([]),
        allKeys: [],
        currentKeys: [],
        type: null
    }
})

@Injectable()
export class CogwheelState {
    constructor() {

    }

    @Selector()
    public static componentKeys(state: CogwheelStateModel) {

        return state.componentKeys.get(state.type);
    }

    @Selector()
    public static componentCurrentKeys(state: CogwheelStateModel) {

        return state.componentCurrentKeys.get(state.type);
    }

    @Selector()
    public static type(state: CogwheelStateModel) {
        return state.type;
    }

    @Action(SetComponentTableKeys, {cancelUncompleted: true})
    public setComponentTableKeys(ctx: StateContext<CogwheelStateModel>, {payload, type}: any) {
        const state = ctx.getState();
        const newKeys: Map<string, string[]> = state.componentKeys;

        if((newKeys.get(state.type) === undefined ||
         newKeys.get(state.type).length <= 0 ||
         Object.keys(payload).length > 0) &&
         state.type === type) {

            newKeys.set(state.type, payload);
            ctx.setState({
                ...state,
                componentKeys: newKeys
            });
        }
    }

    @Action(SetComponentCurrentTableKeys, {cancelUncompleted: true})
    public setComponentCurrentTableKeys(ctx: StateContext<CogwheelStateModel>, {payload}: any) {
        const state = ctx.getState();
        const newKeys: Map<string, string[]> = state.componentCurrentKeys;

        if(Object.keys(payload).length > 0) {
            newKeys.set(state.type, payload);
            ctx.setState({
                ...state,
                componentCurrentKeys: newKeys
            });
        }
    }

    @Action(SetCurrentType, {cancelUncompleted: true})
    public setCurrentType(ctx: StateContext<CogwheelStateModel>, {payload}: any) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            type: payload
        });
    }
}
