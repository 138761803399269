export class Asset {
  public deleted = false;

  protected constructor() {}

  public static copy(sourceAsset: Asset) {
    const targetAsset = new Asset();
    Object.assign(targetAsset, sourceAsset);

    return targetAsset;
  }
}
