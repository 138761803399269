<div>
  <div
    placement="top"
    nbTooltip="{{ renderValue | titlecase }}"
    nbTooltipStatus="control"
    class="dot"
    [ngClass]="{
      'dot-blue': renderValue === 'ACTIVE',
      'blob-green': renderValue === 'RUNNING',
      'dot-green': renderValue === 'SUCCEEDED',
      'dot-gray': renderValue === 'INACTIVE',
      'blob-red': renderValue === 'FAILED'
    }"
  ></div>
</div>
