import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Auth } from '@aws-amplify/auth';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AmplifyAuthGuardService implements CanActivate {
  constructor(private router: Router) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return Auth.currentAuthenticatedUser()
      .then(() => true)
      .catch(() => {
        this.router.navigate(['/login']);

        return false;
      });
  }
}
