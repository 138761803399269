import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Store } from '@ngxs/store';
import { CannedKozr } from 'src/app/models/cannedKozr';
import { NgxGenericModalComponent } from 'src/app/shared/ngx-generic-modal/ngx-generic-modal.component';
import { PostKOZRs } from '../../redux/qa.actions';

@Component({
  selector: 'app-edit-canned-kozr',
  templateUrl: './edit-canned-kozr.component.html',
  styleUrls: ['./edit-canned-kozr.component.css', '../../../../shared/common_syles.css']
})
export class EditCannedKozrComponent implements OnInit {
  public cannedKozr: CannedKozr;
  public title;
  public comments;
  public pmapv_ids;
  public related_events_geojson;
  public event_types;
  public valid_koz_geom;
  public valid_related_events;
  constructor(
    protected ref: NbDialogRef<EditCannedKozrComponent>,
    private store: Store,
    private dialogService: NbDialogService
  ) {}

  public ngOnInit() {
    this.cannedKozr.koz_geom = this.cannedKozr.koz_geom ? this.cannedKozr.koz_geom : [JSON.parse('{"coordinates":[]}')];
    this.comments = this.cannedKozr.comments ? [...this.cannedKozr.comments] : [];
    this.pmapv_ids = this.cannedKozr.pmapv_ids ? [...this.cannedKozr.pmapv_ids] : [];
    this.related_events_geojson = this.cannedKozr.related_events_geojson
      ? [...this.cannedKozr.related_events_geojson]
      : [];
    this.event_types = this.cannedKozr.event_types ? [...this.cannedKozr.event_types] : [];
    this.cannedKozr.cumul_orientation_rad = this.title === 'Edit' ? this.cannedKozr.cumul_orientation_rad : 0;
    this.valid_koz_geom = this.isJson(this.cannedKozr.koz_geom);
    const validatorField = this.cannedKozr.related_events_geojson
      ? this.cannedKozr.related_events_geojson.map((event) => this.isJson(event))
      : [true];
    this.valid_related_events = validatorField.every(Boolean);
  }

  public isJson(data) {
    const test = data === Object(data) ? JSON.stringify(data) : data;
    try {
      JSON.parse(test);
    } catch (e) {
      return false;
    }

    return true;
  }
  public close() {
    this.dialogService
      .open(NgxGenericModalComponent, {
        context: {
          title: 'Confirm Close',
          message: 'Are you sure that you want to close the window? Any changes that you have made will be lost.'
        }
      })
      .onClose.subscribe((data) => {
        if (data) {
          this.ref.close();
        }
      });
  }

  public save() {
    let edit = false;
    if (this.title === 'Edit') {
      edit = true;
    }
    this.cannedKozr.comments = this.comments;
    this.cannedKozr.pmapv_ids = this.pmapv_ids;
    this.cannedKozr.related_events_geojson = this.related_events_geojson;
    this.cannedKozr.event_types = this.event_types;
    const epochMiliseconds = Date.now();
    const timestamp = Math.floor(epochMiliseconds / 1000);
    this.cannedKozr.create_timestamp = timestamp;
    for (const [key, value] of Object.entries(this.cannedKozr)) {
      if (value === null) {
        delete this.cannedKozr[key];
      }
    }
    this.store.dispatch(new PostKOZRs(this.cannedKozr, edit));
    this.ref.close();
  }

  public trackByIndex(index: number, obj: any): any {
    return index;
  }

  public updateModel(key, event, index?) {
    if (key === 'koz_geom') {
      this.valid_koz_geom = this.isJson(event.target.value);
      if (this.valid_koz_geom) {
        this.cannedKozr[key] = JSON.parse(event.target.value);
      }
    } else {
      this.valid_related_events = this.isJson(event.target.value);
      if (this.valid_related_events) {
        this.related_events_geojson[index] = JSON.parse(event.target.value);
      }
    }
  }
  public addField(field) {
    if (field === 'comments') {
      this.comments.push('');
    } else if (field === 'pmapv_ids') {
      this.pmapv_ids.push('');
    } else if (field === 'related_events_geojson') {
      this.related_events_geojson.push(JSON.parse('{"coordinates":[]}'));
    } else if (field === 'event_types') {
      this.event_types.push('');
    }
  }

  public removeField(field, index) {
    if (field === 'comments') {
      this.comments.splice(index, 1);
    } else if (field === 'pmapv_ids') {
      this.pmapv_ids.splice(index, 1);
    } else if (field === 'related_events_geojson') {
      this.related_events_geojson.splice(index, 1);
    } else if (field === 'event_types') {
      this.event_types.splice(index, 1);
    }
  }
}
