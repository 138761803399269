import { DecimalPipe } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { NbDialogService } from '@nebular/theme';
import { Select, Store } from '@ngxs/store';
import { SpinnerState } from 'src/app/shared/spinner/redux/spinner.state';
import { RobotFamilyMetadataService } from 'src/app/core/services/robot-family-metadata.service';
import { RobotFamilyMetadata } from 'src/app/models/robot-family-metadata';
import { OktaRestriction } from 'src/app/shared/okta-restrictions/okta-restrictions';
import { NgxGenericModalComponent } from 'src/app/shared/ngx-generic-modal/ngx-generic-modal.component';
import { RobotFamilyMetadataState, DeleteMetadata, LoadMetadata, ReloadMetadata } from './redux';
import { ViewRobotFamilyMetadataComponent } from './view-robot-family-metadata/view-robot-family-metadata.component';
import { EditRobotFamilyMetadataComponent } from './edit-robot-family-metadata/edit-robot-family-metadata.component';

@Component({
  selector: 'app-robot-family-metadata',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './robot-family-metadata.component.html',
  styleUrls: ['./robot-family-metadata.component.css'],
  providers: [DecimalPipe]
})
export class RobotFamilyMetadataComponent implements OnInit, AfterViewInit {
  @Select(RobotFamilyMetadataState.robotFamilyMetadata) public data$: Observable<any[]>;
  @Select(SpinnerState) public loading: Observable<boolean>;

  public spin;
  public tableData;
  public displayNewButton = false;

  constructor(
    private service: RobotFamilyMetadataService,
    private store: Store,
    private dialogService: NbDialogService,
    private restrictionService: OktaRestriction) {
      if (this.store.selectSnapshot(RobotFamilyMetadataState.robotFamilyMetadata).length < 1) {
        this.store.dispatch(LoadMetadata);
      }
    }

  public ngAfterViewInit() {
    if (this.store.selectSnapshot(RobotFamilyMetadataState.robotFamilyMetadata).length > 1) {
      this.service.buildKeysForTable(this.store.selectSnapshot(RobotFamilyMetadataState.robotFamilyMetadata));
    }
  }

  public ngOnInit(): void {
    this.data$.subscribe((data) => {
      this.tableData = data;
      this.service.buildKeysForTable(data);
    });
    this.loading.subscribe((data) => (this.spin = data));
    this.displayNewButton = this.restrictionService.manager_permission;
  }

  public refreshTableData() {
    this.store.dispatch(ReloadMetadata);
  }

  public clickedButtonTable($event) {
    if ($event.action === 'view') {
      this.viewMetadata($event.data);
    }
    if ($event.action === 'edit') {
      this.editMetadata($event.data);
    }
    if ($event.action === 'clone') {
      this.cloneMetadata($event.data);
    }
    if ($event.action === 'delete') {
      this.deleteMetadata($event.data);
    }
  }

  public newCreation() {
    this.newMetadata();
  }

  public newMetadata() {
    this.dialogService.open(EditRobotFamilyMetadataComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'New',
        robotFamilyMetadata: new RobotFamilyMetadata(),
        allRFM: this.tableData
      }
    });
  }

  public viewMetadata(metadata: RobotFamilyMetadata) {
    this.dialogService.open(ViewRobotFamilyMetadataComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        metadata
      }
    });
  }

  public editMetadata(metadata: RobotFamilyMetadata) {
    this.dialogService.open(EditRobotFamilyMetadataComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'Edit',
        robotFamilyMetadata: RobotFamilyMetadata.copy(metadata),
        allRFM: this.tableData
      }
    });
  }

  public cloneMetadata(metadata: RobotFamilyMetadata) {
    const clonedMetadata = RobotFamilyMetadata.clone(metadata);
    clonedMetadata.robot_family = 'Clone-' + clonedMetadata.robot_family;
    this.dialogService.open(EditRobotFamilyMetadataComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'Clone',
        robotFamilyMetadata: clonedMetadata,
        allRFM: this.tableData
      }
    });
  }

  public deleteMetadata(metadata: RobotFamilyMetadata){
    this.dialogService
    .open(NgxGenericModalComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'Are you sure you want to delete Robot Family Metadata',
        message: metadata.robot_family + ' - ' + metadata.metadata_type
      }
    })
    .onClose.subscribe((data) => {
      if (data === true) {
        const metadataToDelete = RobotFamilyMetadata.copy(metadata);
        metadataToDelete.deleted = true;
        this.store.dispatch(new DeleteMetadata(metadataToDelete));
      }
    });
  }

}
