import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch, removeItem, updateItem } from '@ngxs/store/operators';
import { SurveyService } from 'src/app/core/services/survey.service';
import { TableSpinerReloadHide } from 'src/app/shared/ngx-table/ngx-table/redux/table-spinner.actions';
import { ToggleHide } from 'src/app/shared/spinner/redux/spinner.actions';
import { UtilityService } from 'src/app/core/services/utility.service';
import { SurveysStateModel } from './surveys-state.model';
import {
  DeleteSurveys,
  LoadSurveys,
  LoadSurveysSuccess,
  PostSurveys,
  ReloadSurveys,
  ReloadSurveysSuccess,
  SaveSurveysComplete,
  SaveSurveysFail
} from './surveys.actions';

@State<SurveysStateModel>({
  name: 'surveys',
  defaults: {
    surveys: [],
    error: null
  }
})
@Injectable()
export class SurveysState {
  constructor(
    private service: SurveyService,
    private toastrService: NbToastrService,
    private utilityService: UtilityService
  ) {}

  @Selector()
  public static surveys(state: SurveysStateModel) {
    return state.surveys;
  }

  @Action(LoadSurveys)
  public async loadSurveys(ctx: StateContext<SurveysStateModel>) {
    this.service.loadSurveysAmplify();
  }

  @Action(LoadSurveysSuccess)
  public loadSurveysSuccess(ctx: StateContext<SurveysStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      surveys: payload.surveys
    });
    ctx.dispatch(ToggleHide);
  }

  @Action(ReloadSurveys)
  public async reloadSurveys(ctx: StateContext<SurveysStateModel>) {
    this.toastrService.show(status, 'Fetch Initiated', { status: 'info' });
    this.service.loadSurveysAmplify(true);
  }

  @Action(ReloadSurveysSuccess)
  public reloadSurveysSuccess(ctx: StateContext<SurveysStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      surveys: payload
    });
    ctx.dispatch(TableSpinerReloadHide);
  }

  @Action(PostSurveys)
  public async postSurveys(ctx: StateContext<SurveysStateModel>, { payload, edit }: any) {
    this.service.postSurveysAmplify(payload, edit);
  }

  @Action(DeleteSurveys)
  public deleteSurveys(ctx: StateContext<SurveysStateModel>, { payload, edit }: any) {
    this.service.postSurveysAmplify(payload, edit);
  }

  @Action(SaveSurveysComplete)
  public surveysComplete(ctx: StateContext<SurveysStateModel>, { data, surveys, edit }: any) {
    const state = ctx.getState();

    if (data.errorMessage) {
      ctx.dispatch(new SaveSurveysFail(data));

      return;
    }
    if (edit === true) {
      if (surveys.deleted) {
        ctx.setState(
          patch({
            // @ts-ignore
            surveys: removeItem((item) => item.survey_id === surveys.survey_id)
          })
        );
        this.toastrService.show(status, 'Delete: Complete', { status: 'success' });

        return;
      } else {
        ctx.setState(
          patch({
            surveys: updateItem((item) => item.survey_id === surveys.survey_id, surveys)
          })
        );
        this.toastrService.show(status, 'Update: Complete', { status: 'success' });

        return;
      }
    }
    if (edit === false) {
      ctx.setState(
        patch({
          surveys: [...state.surveys, data]
        })
      );
      this.toastrService.show(status, 'Save: Complete', { status: 'success' });

      return;
    }
  }

  @Action(SaveSurveysFail)
  public surveysFail(ctx: StateContext<SurveysStateModel>, { payload }: any) {
    const state = ctx.getState();
    const error = this.utilityService.convertErrorToMessage(payload);
    ctx.setState({
      ...state,
      error
    });
    if (error) {
      this.toastrService.show(status, 'Save: Fail -' + error, { status: 'danger' });
    }
  }
}
