/*********************************************
 * Parent class for the statistics view,
 * currently for insights and campaign
 ********************************************/

import { Directive, OnInit } from '@angular/core';

@Directive()
export class StatisticsModel implements OnInit {

    public ngOnInit(): void {
        throw new Error('Method not implemented.');
    }

    /************************************************************************/

    //constructor () {}

    /************************************************************************/

    public getAverageExecDuration(toDivide: number, diviser: number): string | boolean {
        if (toDivide!==0 && diviser!==0) {
            let averageDuration: string;
            const averageExecutinSeconds: number = Math.round(toDivide / diviser);
            averageDuration =  this.calculateHumanTime(averageExecutinSeconds);
            averageDuration += ' (' + averageExecutinSeconds + ' seconds)';

            return averageDuration;

        } else { return false; }
    }

    /************************************************************************/

    public getAverageValue(toDivide: number, divisor: number): number {
        if (toDivide!==0 && divisor!==0) {
            return Math.round(toDivide / divisor);
        } else { return 0; }
    }

    /************************************************************************/

    private calculateHumanTime(inlet: number): string {
        const roundedParsedInput: number = Math.round(inlet);

        let minutes = 0;
        let hours = 0;
        let days = 0;
        let parsedInputDecrementor: number = roundedParsedInput;

        // Seconds
        if (roundedParsedInput<60) {

            return (roundedParsedInput+'s');

            // Minutes
        } else if (roundedParsedInput>=60 && roundedParsedInput<3600) {

            do {
                parsedInputDecrementor = parsedInputDecrementor-60;
                minutes++;
            } while (parsedInputDecrementor>=60);

            return (`${minutes}m ${parsedInputDecrementor}s`);
            // Hours
        } else if (roundedParsedInput>=3600 && roundedParsedInput<86400) {

            do {
                parsedInputDecrementor = parsedInputDecrementor-60;
                minutes++;

                if (minutes === 60) {
                    hours++;
                    minutes = 0;
                }

            } while (parsedInputDecrementor>=60);

            return (`${hours}h ${minutes}m ${parsedInputDecrementor}s`);
            // Days
        } else if (roundedParsedInput>=86400) {

            do {
                parsedInputDecrementor = parsedInputDecrementor-60;
                minutes++;

                if (minutes === 60) {

                    hours++;
                    minutes = 0;

                    if (hours>23) {
                        days++;
                        hours = 0;
                    }
                }
            } while (parsedInputDecrementor>=60);

            return (` ${days}d ${hours}h ${minutes}m ${parsedInputDecrementor}s`);
        }

    }
}
