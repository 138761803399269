<app-spinner *ngIf="spin"></app-spinner>
<div style="min-height: 550px">
  <nb-card>
      <nb-card-header>
          <h3 style="position: relative;">Mess Makers</h3>
      </nb-card-header>
      <nb-card-body>
          <div>
            <div class="row">
              <div>
                <h5>Robot ID:</h5>
              </div>
            </div>
            <!-- Robot selection -->
            <div class="row">
                <div class="col-9">
                  <ng-select
                  class="testisd"
                    ngDefaultControl
                    id="robot_id"
                    name="robot_id"
                    placeholder="Insert Robot IDs"
                    multiple="true"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="selectedRobots"
                    [addTag]="true" 
                    [selectOnTab]="true"
                    [isOpen]="false"
                    fullWidth
                    (add)="robotIdAdd($event)"
                    (remove)="robotIdRemove($event)"
                    (clear)="robotIdClear()"
                  >
            </ng-select>
          </div>
            </div>
            <br />
            <div *ngIf="Object.keys(this.cleanScoreRecords).length > 0">
              <div *ngFor="let robot of loadedRobots">
                <div class="row">
                  <h6>Select Pmap id for {{ robot.label }}:</h6>
                </div>
                <ng-select
                    fullWidth
                    [(ngModel)]="selectedPmaps"
                    placeholder="Select Pmap IDs"
                    (change)="pmapIdChange($event)"
                    (clear)="pmapIdClear()"
                  >
                  <ng-option *ngFor="let p of Object.keys(this.cleanScoreRecords[robot.label])" [value]="p"> {{ p }} </ng-option>
                </ng-select>
                <br />
                <div *ngIf="selectedPmaps !== ''">
                  <div class="row">
                    <p><strong>Mess Makers for </strong>{{ selectedPmaps }}</p> 
                  </div>

                  <div class="row" *ngFor="let mm of loadedMessMakers">

                    <div class="col">
                      {{mm.name}} 
                    </div>
                    <div class="col-9">
                      <input
                      id={{mm.name}}
                      name={{mm.name}}
                      placeholder="0"
                      type="number"
                      min="0"
                      max="2"
                      class="form-control form-control-sm"
                      [(ngModel)]="mm.value"
                      nbInput
                      style="width: 100%"
                    />
                    </div>
                  </div>
                </div>
                <br />
                <hr />
              </div>
            </div>
            <br />
          </div>
          <div style="margin-top: 10px">
            <button class="btn btn-primary" (click)="onSubmit()">
                Submit
            </button>
          </div>
      </nb-card-body>
  </nb-card>
</div>
