<div>
  <div style="height: 580px; overflow: auto; padding: 1.143em; margin: -1.143em">
    <div class="row">
      <div>
        <h5>Common - Robot ID:</h5>
      </div>
    </div>
    <!-- Robot selection -->
    <div class="row">
      <div class="col">
        <ng-select
          fullWidth
          [(ngModel)]="selectedRobots"
          [multiple]="true"
          placeholder="Select Robot IDs"
          [groupBy]="groupByFn()"
        >
          <ng-option *ngFor="let p of formRobotData" [value]="p"> {{ p.id }} - {{ p.name }} </ng-option>
        </ng-select>
      </div>
    </div>
    <br />
    <div *ngFor="let robot of selectedRobots">
      <hr />
      <div class="row">
        <h6>Select campaign for {{ robot.id }} - {{ robot.name }}:</h6>
      </div>
      <div class="row">
        <ng-select
          class="col"
          fullWidth
          placeholder="Select a campaign..."
          [multiple]="true"
          [(ngModel)]="selectedCampaigns[robot.id]"
        >
          <ng-option *ngFor="let camp of campaigns" [value]="camp">
            {{ camp.name }} || Content ID: {{ camp.content_id }}
          </ng-option>
        </ng-select>
      </div>
      <br />
      <div *ngFor="let campaign of selectedCampaigns[robot.id]">
        <div [ngSwitch]="campaign.campaign_type">
          <!-- Campaign Type: SpaceChanged -->
          <div *ngSwitchCase="'SpaceChanged'">
            <app-qa-cl-form
              [campaign]="campaign"
              [parsedCampaignValues]="['pmap_id', 'pmapv_id', 'change_type', 'mission_number', 'mission_timestamp']"
              (outputCampaignValues)="receiveChildParameters($event)"
            >
            </app-qa-cl-form>
          </div>
          <!-- Campaign Type:  ObjectZoneRecommendation-->
          <div *ngSwitchCase="'ObjectZoneRecommendation'">
            <app-qa-cl-form
              [campaign]="campaign"
              [parsedCampaignValues]="[
                'pmap_id',
                'pmapv_id',
                'observed_zone_id',
                'region_id',
                'primary_related_object',
                'primary_object_type',
                'suggestion_id',
                'confidence'
              ]"
              (outputCampaignValues)="receiveChildParameters($event)"
            ></app-qa-cl-form>
          </div>
          <!-- Campaign Type:  SmartScheduleRecommendation-->
          <div *ngSwitchCase="'SmartScheduleRecommendation'">
            <app-qa-s-scheduling
              [campaign]="campaign"
              (scheduleChanged)="onScheduleChanged(robot, $event)"
              (regionSchedule)="onRegionChanged(robot, $event)"
              (seasonalChanged)="seasonalChanged($event)"
            ></app-qa-s-scheduling>
          </div>
          <!-- Campaign Type:  ImageReview-->
          <div *ngSwitchCase="'ImageReview'">
            <app-qa-image-form
              [campaign]="campaign"
              [parsedCampaignValues]="['mission_id']"
              (outputCampaignValues)="receiveChildParameters($event)"
            >
            </app-qa-image-form>
          </div>
          <!-- TODO Update Component for Favorites -->
          <div *ngSwitchCase="'FavoriteRecommendation'">
            <app-qa-favorite-form
              [campaign]="campaign"
              [robotId]="robot.id"
              (outputFavoriteValues)="receiveChildParameters($event)"
            ></app-qa-favorite-form>
          </div>

          <!-- By default nothing happens -->
          <div *ngSwitchDefault></div>
        </div>
      </div>
    </div>
    <div *ngIf="submitBtn_show">
      <button nbButton [disabled]="submitBtn_enable" (click)="createQANotification()" [status]="'success'">
        Submit notification
      </button>
    </div>

    <div class="alert alert-success mt-4" *ngIf="service.submittedNotification" #responseMessage>
      <h4>Notification was submitted successfully!</h4>
      <hr />
      <p>Response:</p>
      <pre>{{ service.submittedNotification | json }}</pre>
    </div>
  </div>
</div>
