import { Injectable } from '@angular/core';
import { TimestampDatePipe } from '../custom-pipe/timestamp-date.pipe';
import { StatusLightComponent } from '../status-light/status-light.component';
import { OktaRestriction } from '../okta-restrictions/okta-restrictions';
import { FormatPipe } from '../custom-pipe/format.pipe';
import { metadataTableCellComponent } from '../metadata-table-cell/metadata-table-cell.component';
import { SortingService } from 'src/app/core/services/sorting.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class NgxTableService {

  constructor(
    private pipe: FormatPipe,
    private formatDatePipe: TimestampDatePipe,
    private restrictionService: OktaRestriction,
    private sortService: SortingService
  ) {}


  // Property must be written in same way as it cames from service (backend). It is case sensitive.
  // Example id represent id from model (backend). Writing Id / ID while in model is id will result in not showing data
  public buttonRestriction(buttons) {
    if (!this.restrictionService.manager_permission) {
      buttons = buttons.filter(
        (item) =>
          item.name !== 'edit' &&
          item.name !== 'stats' &&
          item.name !== 'delete' &&
          item.name !== 'clone' &&
          item.name !== 'activate' &&
          item.name !== 'deactivate'
      );
    }

    return buttons;
  }


  public buildChosenColumns(type, columns){

    switch (type) {
      case 'insights':
        if(columns.status !== undefined) {
          columns.status = {title: 'Status',
                            type: 'custom',
                            sort: true,
                            sortDirection: 'asc',
                            compareFunction: (direction: any, a: any, b: any) => this.sortService.sortStatusColumn(direction,a,b),
                            renderComponent: StatusLightComponent,
                            onComponentInitFunction: (instance) => {} };
        }
        break;

      case 'campaigns':
        if(columns.responses !== undefined) {
          columns.responses = {title: 'Responses', valuePrepareFunction: (created) => this.pipe.transform(created, 'count')};
        }
        if(columns.status !== undefined) {
          columns.status = {title: 'Status',
                            type: 'custom',
                            sort: true,
                            sortDirection: 'asc',
                            compareFunction: (direction: any, a: any, b: any) => this.sortService.sortStatusColumn(direction,a,b),
                            renderComponent: StatusLightComponent,
                            onComponentInitFunction: (instance) => {} };
        }
        break;
      case 'surveys':
        if(columns.questions !== undefined) {
          columns.questions = columns.questions = columns.questions === undefined ? undefined : {
            title: 'Questions',
            valuePrepareFunction: (created) => this.pipe.transform(created, 'count')
          };
        }
        break;
      case 'canned-kozrs':
        if(columns.create_timestamp !== undefined) {
          columns.create_timestamp = {title: 'Date', valuePrepareFunction: (created) => this.formatDatePipe.transform(created, 'timestampToDate')};
        }
        break;
      case 'hta-recommendation':
        if(columns.create_timestamp !== undefined) {
          columns.create_timestamp = {title: 'Date', valuePrepareFunction: (created) => this.formatDatePipe.transform(created, 'timestampToDate')};
        }
        break;
      case 'robot-family-metadata':

        if(columns.create_timestamp !== undefined) {
          columns.robot_family = {title: 'Robot Family'};
        }
        if(columns.metadata_type !== undefined) {
          columns.metadata_type = columns.metadata_type = columns.metadata_type === undefined ? undefined : {title: 'Metadata type'};
        }
        if(columns.metadata !== undefined) {
          columns.metadata = columns.metadata === undefined ? undefined : {
                                title: 'Metadata',
                                type: 'custom',
                                filter:true,
                                filterFunction: (data, search) => {
                                  if(search !== '') {
                                      const c = data.filter(d=>
                                        d.extent_type.includes(search) ||
                                        d.confidence.toString().includes(search) ||
                                        (d.priority !== null && d.priority.toString().includes(search))
                                      );
                                      if(c.length !== 0) {

                                      return true;
                                      }
                                  }

                                  return false;
                                },
                                renderComponent: metadataTableCellComponent,
                                valuePrepareFunction: (data) => data
                              };
        }
        break;
    }

    return columns;
  }

  public buildColumnDefault(type) {
    switch (type) {
      case 'insights':
        return {
          id: { title: 'ID' },
          name: { title: 'Name' },
          executions: { title: 'Executions' },
          total_duration: { title: 'Total Duration' },
          total_results: { title: 'Total Results' },
          category: { title: 'Category' },
          status: {
            title: 'Status',
            type: 'custom',
            sort: true,
            sortDirection: 'asc',
            compareFunction: (direction: any, a: any, b: any) => this.sortService.sortStatusColumn(direction,a,b),
            renderComponent: StatusLightComponent,
            onComponentInitFunction: (instance) => {}
          }
        };
      case 'content':
        return {
          content_id: { title: 'Content Id' },
          category_title_text: { title: 'Category' },
          headline_text: { title: 'Headline Text' },
          min_app_version: { title: 'Min App Version' }
        };
      case 'robot-family-metadata':
        return {
          robot_family: {title: 'Robot Family'},
          metadata_type: {title: 'Metadata type'},
          metadata: {
            title: 'Metadata',
            type: 'custom',
            filter: true,
            filterFunction: (data, search) => {
              if(search !== '') {
                  const c = data.filter(d=>
                    d.extent_type.includes(search) ||
                    d.confidence.toString().includes(search) ||
                    (d.priority !== null && d.priority.toString().includes(search))
                  );
                  if(c.length !== 0) {

                  return true;
                  }
              }

              return false;
            },
            renderComponent: metadataTableCellComponent,
            valuePrepareFunction: (data) => data
          }
        };
      case 'campaigns':
        return {
          name: { title: 'Name' },
          total_results: { title: 'Total Result' },
          executions: { title: 'Executions' },
          last_run_time: { title: 'Last Run Time' },
          next_run_time: { title: 'Next Run Time' },
          responses: {
            title: 'Responses',
            valuePrepareFunction: (created) => this.pipe.transform(created, 'count')
          },
          status: {
            title: 'Status',
            type: 'custom',
            sort: true,
            sortDirection: 'asc',
            compareFunction: (direction: any, a: any, b: any) => this.sortService.sortStatusColumn(direction,a,b),
            renderComponent: StatusLightComponent,
            onComponentInitFunction: (instance) => {}
          }
        };
      case 'surveys':
        return {
          questions: {
            title: 'Questions',
            valuePrepareFunction: (created) => this.pipe.transform(created, 'count')
          },
          locale: { title: 'Locale' },
          language: { title: 'Language' },
          survey_type: { title: 'Survey Type' },
          survey_name: { title: 'Survey Name' }
        };
      case 'my robots':
        return {
          id: { title: 'ID' },
          name: { title: 'Name' },
          family: { title: 'Family' },
          series: { title: 'Series' },
          sku: { title: 'Sku' }
        };
      case 'canned-kozrs':
        return {
          koz_id: { title: 'CannedKozr ID' },
          robotid: { title: 'Robot ID' },
          score: { title: 'Score' },
          active_pmapv_id: { title: 'Active Pmap ID' },
          pmap_id: { title: 'Pmap ID' },
          create_timestamp: {
            title: 'Date',
            valuePrepareFunction: (created) => this.formatDatePipe.transform(created, 'timestampToDate')
          }
        };
      case 'hta-recommendation':
        return {
          hta_id: { title: 'HTA Recommendation ID' },
          robotid: { title: 'Robot ID' },
          score: { title: 'Score' },
          active_pmapv_id: { title: 'Active Pmap ID' },
          pmap_id: { title: 'Pmap ID' },
          create_timestamp: {
            title: 'Date',
            valuePrepareFunction: (created) => this.formatDatePipe.transform(created, 'timestampToDate')
          }
        };
      case 'file-source':
        return {
          id: { title: 'ID' },
          location: { title: 'Location' }
        };
      case 'image-source':
        return {
          id: { title: 'ID' },
          file: { title: 'File' },
          location: { title: 'Location' }
        };
      case 'link-source':
        return {
          id: { title: 'ID' },
          location: { title: 'Location' }
        };
      case 'video-source':
        return {
          id: { title: 'ID' },
          location: { title: 'Location' }
        };
      case 'string':
        return {
          id: { title: 'ID' },
          version: { title: 'Version' },
          source: { title: 'Source' },
          note: { title: 'Note' }
        };

      default:
        return null;
    }
  }

  // It builds custom button per table type
  public buildButtons(type, mode) {
    switch (type) {
      case 'insights':
        const insight_buttons = [
          { name: 'view', title: '<i class="nb-search" ></i>' },
          { name: 'edit', title: '<i class="nb-edit"></i>' },
          { name: 'clone', title: '<i class="nb-plus-circled"></i>' },
          { name: 'stats', title: '<i class="nb-bar-chart"></i>' },
          { name: 'activate', title: '<i class="nb-play-outline"></i>' },
          { name: 'deactivate', title: '<i class="nb-power"></i>' },
          { name: 'delete', title: '<i class="nb-trash"></i>' }
        ];

        return this.buttonRestriction(insight_buttons);

      case 'content':
        const content_buttons = [
          { name: 'view', title: '<i class="nb-search" ></i>' },
          { name: 'edit', title: '<i class="nb-edit"></i>' },
          { name: 'clone', title: '<i class="nb-plus-circled"></i>' },
          { name: 'delete', title: '<i class="nb-trash"></i>' }
        ];

        return this.buttonRestriction(content_buttons);

        case 'robot-family-metadata':
          const metadata_buttons = [
            { name: 'view', title: '<i class="nb-search" ></i>' },
            { name: 'edit', title: '<i class="nb-edit"></i>' },
            { name: 'clone', title: '<i class="nb-plus-circled"></i>' },
            { name: 'delete', title: '<i class="nb-trash"></i>' }
          ];

          return this.buttonRestriction(metadata_buttons);
      case 'campaigns':
        const campaign_buttons = [
          { name: 'view', title: '<i class="nb-search" ></i>' },
          { name: 'edit', title: '<i class="nb-edit"></i>' },
          { name: 'clone', title: '<i class="nb-plus-circled"></i>' },
          { name: 'stats', title: '<i class="nb-bar-chart"></i>' },
          { name: 'activate', title: '<i class="nb-play-outline"></i>' },
          { name: 'deactivate', title: '<i class="nb-power"></i>' },
          { name: 'delete', title: '<i class="nb-trash"></i>' }
        ];

        return this.buttonRestriction(campaign_buttons);
      case 'surveys':
        const survey_buttons = [
          { name: 'view', title: '<i class="nb-search" ></i>' },
          { name: 'edit', title: '<i class="nb-edit"></i>' },
          { name: 'clone', title: '<i class="nb-plus-circled"></i>' },
          { name: 'delete', title: '<i class="nb-trash"></i>' }
        ];

        return this.buttonRestriction(survey_buttons);
      case 'my robots':
        const robots_buttons = [
          { name: 'view', title: '<i class="nb-search" ></i>' },
          { name: 'edit', title: '<i class="nb-edit"></i>' },
          { name: 'delete', title: '<i class="nb-trash"></i>' }
        ];

        return robots_buttons;
      case 'canned-kozrs':
        const canned_buttons = [
          { name: 'view', title: '<i class="nb-search" ></i>' },
          { name: 'edit', title: '<i class="nb-edit"></i>' },
          { name: 'clone', title: '<i class="nb-plus-circled"></i>' },
          { name: 'stats', title: '<i class="nb-bar-chart"></i>' },
          { name: 'delete', title: '<i class="nb-trash"></i>' }
        ];

        return canned_buttons;
      case 'hta-recommendation':
        const hta_buttons = [
          { name: 'view', title: '<i class="nb-search" ></i>' },
          { name: 'edit', title: '<i class="nb-edit"></i>' },
          { name: 'clone', title: '<i class="nb-plus-circled"></i>' },
          { name: 'stats', title: '<i class="nb-bar-chart"></i>' },
          { name: 'delete', title: '<i class="nb-trash"></i>' }
        ];

        return hta_buttons;

      case 'file-source':
        let file_buttons = [];
        if (mode === 'edit') {
          file_buttons = [
            { name: 'view', title: '<i class="nb-search" ></i>' },
            { name: 'edit', title: '<i class="nb-edit" ></i>' }
          ];

          if (environment.stage === 'stage') {

            file_buttons.push({ name: 'delete', title: '<i class="nb-trash"></i>' });
            }
        } else if (mode === 'view') {
          file_buttons = [{ name: 'view', title: '<i class="nb-search" ></i>' }];
        }

        return file_buttons;

      case 'image-source':
        let image_buttons = [];
        if (mode === 'edit') {
          image_buttons = [
            { name: 'view', title: '<i class="nb-search" ></i>' },
            { name: 'edit', title: '<i class="nb-edit" ></i>' }
          ];

          if (environment.stage === 'stage') {

            image_buttons.push({ name: 'delete', title: '<i class="nb-trash"></i>' });
            }
        } else if (mode === 'view') {
          image_buttons = [{ name: 'view', title: '<i class="nb-search" ></i>' }];
        }

        return image_buttons;

      case 'link-source':
        let link_buttons = [];
        if (mode === 'edit') {
          link_buttons = [
            { name: 'view', title: '<i class="nb-search" ></i>' },
            { name: 'edit', title: '<i class="nb-edit" ></i>' }
          ];

          if (environment.stage === 'stage') {

            link_buttons.push({ name: 'delete', title: '<i class="nb-trash"></i>' });
            }
        } else if (mode === 'view') {
          link_buttons = [{ name: 'view', title: '<i class="nb-search" ></i>' }];
        }

        return link_buttons;

      case 'video-source':
        let video_buttons = [];
        if (mode === 'edit') {
          video_buttons = [
            { name: 'view', title: '<i class="nb-search" ></i>' },
            { name: 'edit', title: '<i class="nb-edit" ></i>' }
          ];
          if (environment.stage === 'stage') {

            video_buttons.push({ name: 'delete', title: '<i class="nb-trash"></i>' });
            }
        } else if (mode === 'view') {
          video_buttons = [{ name: 'view', title: '<i class="nb-search" ></i>' }];
        }

        return video_buttons;

      case 'string':
        let xliff_buttons = [];
        if (mode === 'edit') {
          xliff_buttons = [
            { name: 'view', title: '<i class="nb-search" ></i>' },
            { name: 'edit', title: '<i class="nb-edit" ></i>' }
          ];
          if (environment.stage === 'stage') {

            xliff_buttons.push({ name: 'delete', title: '<i class="nb-trash"></i>' });
            }
        } else if (mode === 'view') {
          xliff_buttons = [{ name: 'view', title: '<i class="nb-search" ></i>' }];
        }

        return xliff_buttons;

      default:
        return null;
    }
  }
}
