<nb-card style="height: 550px; padding: 5px">
  <nb-card-header>
    <div class="row">
      <div class="col-auto mr-auto">
        {{ this.utilityService.convertUnixTime_global(weeksTimeLimit) }} -
        {{ this.utilityService.convertUnixTime_global(currentTime / 1000) }}
      </div>
      <div class="col-auto">
        <button
          nbSpinnerStatus="success"
          nbButton
          outline
          [disabled]="refreshButton"
          status="info"
          shape="round"
          size="small"
          (click)="refresh()"
          nbTooltip="Refresh deliveries"
        >
          <nb-icon icon="refresh-outline"></nb-icon>
        </button>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class="row">
      <div class="col"><label>Start:</label></div>
      <div class="col-9">
        <nb-form-field>
          <nb-icon nbPrefix icon="calendar-outline" pack="eva"></nb-icon>
          <input
            nbInput
            [nbDatepicker]="datepicker"
            style="width: 100%"
            icon="at-outline"
            [(ngModel)]="start"
            [ngModelOptions]="{ standalone: true }"
          />
          <nb-datepicker #datepicker id="start" (dateChange)="startDate($event)" [max]="max"></nb-datepicker>
        </nb-form-field>
      </div>
    </div>
    <br />

    <div class="row">
      <div class="col"><label>End:</label></div>
      <div class="col-9">
        <nb-form-field>
          <nb-icon nbPrefix icon="calendar-outline" pack="eva"></nb-icon>
          <input
            nbInput
            [nbDatepicker]="datepickerEnd"
            style="width: 100%"
            [(ngModel)]="end"
            [ngModelOptions]="{ standalone: true }"
          />
          <nb-datepicker #datepickerEnd id="finish" (dateChange)="endDate($event)" [max]="max"></nb-datepicker>
        </nb-form-field>
      </div>
    </div>
    <br />

    <div class="row">
      <div class="col">
        <label>Select Campaign:</label>
      </div>
      <div class="col-9">
        <div style="width: 320px">
          <ng-select
            [(ngModel)]="selectedCampaignID"
            (change)="selected()"
            [multiple]="false"
            placeholder="Select Campaign"
          >
            <ng-option *ngFor="let p of allCampaigns" [value]="p.id">
              {{ p.name }}
            </ng-option>
          </ng-select>
        </div>
      </div>
    </div>
    <br />
    <hr />

    <div *ngIf="isSelected" class="row">
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <div class="col-lg-12">
            <nb-card>
              <nb-card-header
                ><label class="row" style="font-weight: bold; position: relative; left: 20px"
                  >Total deliveries: {{ count }}</label
                ></nb-card-header
              >
              <nb-card-body>
                <chart type="bar" [data]="data" [options]="options"></chart>
              </nb-card-body>
            </nb-card>
          </div>
        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>
