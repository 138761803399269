<hr>
<label><strong>{{ campaign.name }}</strong></label>
<form class="form-group">
  <div class="row" style="margin: 10px;">
    <label for="favCommand" class="col-6" style="margin-top: 10px;">Select a Robot Command</label>
    <select class="form-control" name="favCommand" nbInput id="favCommand" [(ngModel)]="command" (change)="updateParameters()">
      <option selected>start</option>
    </select>
  </div>
  <div class="row" style="margin: 10px;">
    <label for="favPmap" class="col-6" style="margin-top: 10px;">Select a Map</label>
    <select required [(ngModel)]="pmapId" name=favPmap class="form-control" nbInput id="favPmap" (change)="updateMapVersions()">
      <option *ngFor="let m of maps">{{m}}</option>
    </select>
  </div>
  <div class="row" style="margin: 10px;">
    <label for="favPmapV" class="col-6" style="margin-top: 10px;">Select a User PMap Version</label>
    <select required [(ngModel)]="pmapvId" name=favPmapV class="form-control" nbInput id="favPmapV" (change)="updateMapRegions()">
      <option></option>
      <option *ngFor="let v of mapVersions">{{v}}</option>
    </select>
  </div>
  <div class="row" style="margin: 10px;">
    <label for="favDrcOrdered" class="col-6" style="margin-top: 10px;">Is DRC Ordered?</label>
    <select class="form-control" name="favDrcOrdered" [(ngModel)]="isOrdered" nbInput id="favDrcOrdered" (change)="updateParameters()">
      <option selected>Yes</option>
      <option>No</option>
    </select>
  </div>
  <div class="row" style="margin: 10px;">
    <label for="favRegions" class="col-6" style="margin-top: 10px;">Select the Regions to Clean</label>
    <nb-tag-list (tagRemove)="onTagRemove($event)">
      <nb-tag *ngFor="let r of regions" [text]="r.regionId" removable></nb-tag>
      <input type="text" id="favRegions" name="favRegions" nbTagInput fullWidth (tagAdd)="onTagAdd($event.value)" [nbAutocomplete]="autocomplete">
    </nb-tag-list>
    <nb-autocomplete #autocomplete (selectedChange)="onTagAdd($event)">
      <nb-option *ngFor="let r of regionOptions" [value]="r.regionId">{{ r.regionId }}</nb-option>
    </nb-autocomplete>
  </div>
</form>
