import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { HtaRecommendationService } from 'src/app/core/services/hta-recommendation.service';
import { NgxGenericModalComponent } from 'src/app/shared/ngx-generic-modal/ngx-generic-modal.component';
import { SpinnerState } from 'src/app/shared/spinner/redux/spinner.state';
import { HtaRecommendationDelete, HtaRecommendationLoad, HtaRecommendationReload } from '../redux/qa.actions';
import { QaState } from '../redux/qa.state';
import { HTARecommendation } from './../../../models/hta-recommendation';
import { EditHtaRecommendationComponent } from './edit-hta-recommendation/edit-hta-recommendation.component';
import { ViewHtaRecommendationComponent } from './view-hta-recommendation/view-hta-recommendation.component';
import { ViewStatsHtaComponent } from './view-stats-hta/view-stats-hta.component';

@Component({
  selector: 'app-hta-recommendation',
  templateUrl: './hta-recommendation.component.html',
  styleUrls: ['./hta-recommendation.component.css']
})
export class HtaRecommendationComponent implements OnInit, AfterViewInit {
  @Select(QaState.htaRecommendation) public data$: Observable<any[]>;
  @Select(SpinnerState) public loading: Observable<boolean>;

  public tableData = [];
  public spin = false;

  constructor(private dialogService: NbDialogService, private store: Store, private service: HtaRecommendationService) {
    if (this.store.selectSnapshot(QaState.htaRecommendation).length < 1) {
      this.store.dispatch(HtaRecommendationLoad);
    }
    if (this.store.selectSnapshot(QaState.htaRecommendation).length > 1) {
      this.service.buildKeysForTable(this.store.selectSnapshot(QaState.htaRecommendation));
    }
  }

  public ngAfterViewInit() {
    if (this.store.selectSnapshot(QaState.htaRecommendation).length > 1) {
      this.service.buildKeysForTable(this.store.selectSnapshot(QaState.htaRecommendation));
    }
  }

  public ngOnInit() {
    this.data$.subscribe((data) => {
      this.tableData = data;
      this.service.buildKeysForTable(data);
    });
    this.loading.subscribe((data) => (this.spin = data));
  }

  public newCreation() {
    this.newHtaRecommendation();
  }

  public clickedButtonTable($event) {
    if ($event.action === 'view') {
      this.viewHtaRecommendation($event.data);
    }
    if ($event.action === 'edit') {
      this.editHtaRecommendation($event.data);
    }
    if ($event.action === 'stats') {
      this.viewHtaRecommendation($event.data);
    }
    if ($event.action === 'clone') {
      this.cloneHtaRecommendation($event.data);
    }
    if ($event.action === 'delete') {
      this.deleteHtaRecommendation($event.data);
    }
  }

  public newHtaRecommendation() {
    this.dialogService.open(EditHtaRecommendationComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'New',
        htaRecommendation: new HTARecommendation()
      }
    });
  }

  public refreshTableData() {
    this.store.dispatch(HtaRecommendationReload);
  }

  public viewHtaRecommendation(HTA: HTARecommendation) {
    this.dialogService.open(ViewHtaRecommendationComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        htaRecommendation: HTA
      }
    });
  }

  public viewStats(HTA: HTARecommendation) {
    this.dialogService.open(ViewStatsHtaComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        htaRecommendation: HTA
      }
    });
  }

  public editHtaRecommendation(HTA: HTARecommendation) {
    const clonedHTA = HTARecommendation.clone(HTA);
    this.dialogService.open(EditHtaRecommendationComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'Edit',
        htaRecommendation: clonedHTA
      }
    });
  }

  public cloneHtaRecommendation(HTA: HTARecommendation) {
    const clonedHTA = HTARecommendation.clone(HTA);
    clonedHTA.hta_id = null;
    this.dialogService.open(EditHtaRecommendationComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'Clone',
        htaRecommendation: clonedHTA
      }
    });
  }

  public deleteHtaRecommendation(HTA: HTARecommendation) {
    this.dialogService
      .open(NgxGenericModalComponent, {
        closeOnEsc: false,
        closeOnBackdropClick: false,
        context: {
          title: 'Are you sure you want to delete High Traffic Area',
          message: HTA.hta_id + ' - ' + HTA.active_pmapv_id
        }
      })
      .onClose.subscribe((data) => {
        if (data === true) {
          const HTAToDelete = HTARecommendation.copy(HTA);
          HTAToDelete.deleted = true;
          this.store.dispatch(new HtaRecommendationDelete(HTAToDelete));
        }
      });
  }
}
