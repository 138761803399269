export class RobotMaps {
    public robotId: string;
    public maps: string[];


    constructor(robotId: string, maps: string[]) {
        this.robotId = robotId;
        this.maps = maps;
    }
}

export class RobotMapVersion {
    public robotId: string;
    public pmapId: string;
    public versions: string[];


    constructor(robotId: string, pmapId: string, versions: string[]) {
        this.robotId = robotId;
        this.pmapId = pmapId;
        this.versions = versions;
    }
}

export class MapRegion {
    public regionId: string;
    public regionType: string;


    constructor(regionId: string, regionType: string) {
        this.regionId = regionId;
        this.regionType = regionType;
    }
}
