import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { QaServiceService } from 'src/app/core/services/qa-service.service';
import { LoadAllRobots } from '../../redux';
import { QaState } from '../../redux/qa.state';

@Component({
  selector: 'app-timeline-common-bots',
  templateUrl: './timeline-common-bots.component.html',
  styleUrls: ['./timeline-common-bots.component.scss']
})
export class TimelineCommonBotsComponent implements OnInit {
  @Select(QaState.robots) public data_common$: Observable<any[]>;

  public selectedRobots;
  public commonRobots;
  public timelineNotification;
  public notificationMessage;
  public show = false;

  constructor(private store: Store, private service: QaServiceService) {
    if (this.store.selectSnapshot(QaState.robots).length < 1) {
      store.dispatch(new LoadAllRobots());
    }
  }

  public ngOnInit() {
    this.data_common$.subscribe((data) => (this.commonRobots = data));
  }

  public onRobotSelect() {
    this.timelineNotification = this.service.loadTimelineNotificationsAmplify(this.selectedRobots);

    if (this.isEmpty(this.timelineNotification)) {
      this.show = true;
      this.notificationMessage = `There are no Timeline notifications for * ${this.selectedRobots.id} * Robot Id.`;
    } else {
      this.show = false;
    }
  }

  public isEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }

    return true;
  }
}
