import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Store } from '@ngxs/store';
import { API } from '@aws-amplify/api';
import {
  LoadSurveysSuccess,
  SaveSurveysComplete,
  SaveSurveysFail
} from 'src/app/components/surveys/redux/surveys.actions';
import { Survey } from 'src/app/models/survey';
import { CogwheelService } from 'src/app/core/services/cogwheel.service';
import { environment } from 'src/environments/environment';
import { AmplifyServiceBuilder } from '../services/amplify.service.builder';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  public signedRequests: any;
  public owner: string;
  public selectedKeys: string[] = [];
  public allKeys: string[] = [];
  public surveys_api = 'surveys_api';
  public responses_api = 'responses_api';
  public type = 'surveys';

  constructor(private store: Store,
              private toastrService: NbToastrService,
              private cogWheelService: CogwheelService) {}

  public buildKeysForTable(survey: Survey[]) {
    this.cogWheelService.buildKeysForTable(survey, this.allKeys, this.store, this.type, true);
  }

  public loadSurveysAmplify(reload?) {
    API.get(this.surveys_api, `/${environment.stage}/surveys`, '')
      .then((response) => {
        if (reload) {
          this.toastrService.show(status, 'Fetching Surveys Complete', {
            status: 'success'
          });
        }
        this.store.dispatch(new LoadSurveysSuccess(response));
      })
      .catch((error) => {
        console.error(error);
        const message =
          error.response && error.response.data
            ? error.response.data.message
              ? error.response.data.message
              : error.response.data
            : 'Error!';
        this.toastrService.show(status, 'Fetch Failed: ' + message, { status: 'danger' });
      });
  }

  public postSurveysAmplify(survey: Survey, edit: boolean = false) {
    const body = survey;
    const requestData = AmplifyServiceBuilder(this.surveys_api, false, body);
    API.post(this.surveys_api, `/${environment.stage}/surveys`, requestData)
      .then((response) => {
        this.store.dispatch(new SaveSurveysComplete(response, survey, edit));
      })
      .catch((error) => {
        this.store.dispatch(new SaveSurveysFail(error));
      });
  }
}
