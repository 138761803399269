<nb-card
  accent="success"
  style="width: 100%; height: 100%; min-width: 700px; max-height: 800px; max-width: 1600px; margin: auto">
  <nb-card-header>
    <div class="row">
      <div class="col-auto mr-auto">
        <nb-icon icon="nb-trash"></nb-icon>
      </div>
      <div class="col-auto mr-auto">{{ title }}</div>
      <div class="col-auto"><button nbButton (click)="close()">X</button></div>
    </div>
    <div class="row">
      <div class="col-auto mr-auto" style="margin-top: 1rem">{{ item.id }}</div>
    </div>
  </nb-card-header>
  <nb-card-body>
    
    <div style="height: 100%; padding: 1.143em; margin: -1.143em">
          <div class="deep-delete-spinner" *ngIf="spin">
            <app-spinner></app-spinner>
          </div>
          <form #campaignForm="ngForm" *ngIf="!spin">
            <strong class="text-uppercase">Campaigns and Contents associated with this DRB {{ type }} in STAGE</strong>
            <hr />

            <div class="row">
              <div class="col">
                <p style="color: green" *ngIf="affectedCampaignsStage.length === 0">
                  There is no campaign associated to this drb {{ type }}
                </p>
                <p style="color: green" *ngIf="affectedContentStage.length === 0">
                  There is no content associated to this drb {{ type }}
                </p>
                <div *ngIf="affectedCampaignsStage.length > 0">
                  <p style="color: orange" *ngIf="affectedCampaignsStage.length === 1">
                    There is <strong>one</strong> campaign associated to this drb {{ type }}.
                  </p>
                  <p style="color: orange" *ngIf="affectedCampaignsStage.length > 1">
                    There are <strong>{{ affectedCampaignsStage.length }} </strong> campaigns associated to this drb {{ type }}.
                  </p>
                  <ul *ngFor="let campaign of affectedCampaignsStage; index as i">
                    <li>
                      <label style="padding-top: 6px">ID: {{ campaign.id }} - <strong>{{ campaign.status }}</strong></label>
                      <button
                        nbButton
                        size="medium"
                        ghost
                        status="info"
                        (click)="viewCampaign(campaign.id)" >
                      <nb-icon icon="external-link-outline" nbTooltip="Open Selected Content"></nb-icon>
                      </button>
                    </li>
                  </ul>
                  </div>
                </div>

                <div *ngIf="affectedContentStage.length > 0">
                  <p style="color: orange" *ngIf="affectedContentStage.length === 1">
                    There is one content associated to this drb {{ type }} 
                  </p>
                  <p style="color: orange" *ngIf="affectedContentStage.length > 1">
                    There are {{ affectedContentStage.length }} contents associated to this drb {{ type }}.
                  </p>
                  <ul *ngFor="let content of affectedContentStage; index as i">
                    <li>
                      <label style="padding-top: 6px">ID: {{ content.content_id }} - {{ content.headline_text }}</label>
                      <button
                        nbButton
                        size="medium"
                        ghost
                        status="info"
                        (click)="viewContent(content.id)" >
                      <nb-icon icon="external-link-outline" nbTooltip="Open Selected Content"></nb-icon>
                      </button>
                    </li>
                  </ul>
                </div>

              </div>
            <br />


            <strong class="text-uppercase">Campaigns and Contents associated with this DRB {{ type }} in PROD</strong>

            <p style="color: red" *ngIf="affectedCampaignsProdIDs.length === 1">Associated items in production can't be previewed.</p>
            <hr />

            <div class="row">
              <div class="col">
                <p style="color: green" *ngIf="affectedCampaignsProdIDs.length === 0">
                  There is no campaign associated to this drb  {{ type }}
                </p>
                <p style="color: green" *ngIf="affectedContentProdIDs.length === 0">
                  There is no content associated to this drb {{ type }}
                </p>
                <div *ngIf="affectedCampaignsProdIDs.length > 0">
                  <p style="color: orange" *ngIf="affectedCampaignsProdIDs.length === 1">
                    There is <strong>one</strong> campaign associated to this drb {{ type }}.
                  </p>
                  <p style="color: orange" *ngIf="affectedCampaignsProdIDs.length > 1">
                    There are <strong>{{ affectedCampaignsProdIDs.length }} </strong> campaigns associated to this drb {{ type }}. 
                  </p>

                  <ul *ngFor="let campaign of affectedCampaignsProdIDs; index as i">
                    <li>
                      <label style="padding-top: 6px">ID: {{ campaign }}</label>
                    </li>
                  </ul>
                  </div>
                </div>

                <div *ngIf="affectedContentProdIDs.length > 0">
                  <p style="color: orange" *ngIf="affectedContentProdIDs.length === 1">
                    There is one content associated to this drb {{ type }}.
                  </p>
                  <p style="color: orange" *ngIf="affectedContentProdIDs.length > 1">
                    There are {{ affectedContentProdIDs.length }} contents associated to this drb {{ type }}.
                  </p>
                  <ul *ngFor="let content of affectedContentProdIDs; index as i">
                    <li>
                      <label style="padding-top: 6px">ID: {{ content}}</label>
                    </li>
                  </ul>
                </div>

              </div>
            <br />

          </form>
    </div>
  </nb-card-body>
  <nb-card-footer>

    <div class="d-flex flex-row-reverse">
        <button
          nbButton
          status="success"
          [disabled]="deletionEnabled()"
          (click)="confirmDeletion()">Delete</button>
      <div class="p-2">
        <button nbButton 
        (click)="close()">Close</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
