/* eslint-disable @typescript-eslint/no-shadow */
import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { InsightService } from 'src/app/core/services/insight.service';

import { Router } from '@angular/router';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { WebSocketService } from 'src/app/core/services/insight-web-socket-service';
import { OktaRestriction } from 'src/app/shared/okta-restrictions/okta-restrictions';
import { ViewInsightComponent } from './view-insight/view-insight.component';
import { StatsInsightComponent } from './stats-insight/stats-insight.component';
import { EditInsightComponent } from './edit-insight/edit-insight.component';
import { LoadInsightsInactive } from './redux/insights.actions';
import { Insight } from './../../models/insight';
import { NgxGenericModalComponent } from './../../shared/ngx-generic-modal/ngx-generic-modal.component';
import { FailedJobInsightListComponent } from './failed-job-insight-list/failed-job-insight-list.component';
import { DeleteInsights, InsightsState, LoadListOfFailedJobs, ReloadInsights } from './redux';
import { InsightTerminateModalComponent } from './insight-terminate-modal/insight-terminate-modal.component';
import { DeleteInsightComponent } from './delete-insight/delete-insight.component';

@Component({
  selector: 'app-insights',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.css']
})
export class InsightsComponent implements OnInit, AfterViewInit {
  @Select(InsightsState.insightsActive) public data$: Observable<any[]>;
  @Select(InsightsState.insightsInactive) public dataInactive$: Observable<any[]>;
  @Select(InsightsState.insightSpinner) public loading: Observable<boolean>;
  @Select(InsightsState.failedJobs) public failedJobs: Observable<any[]>;
  @Select(InsightsState.insightsJobs) public insightJobsData$: Observable<any[]>;

  public tableData;
  public spin;
  public failedInsightCount;
  public listOfFailedJobs;
  public insightJobs;
  public displayNewButton = false;

  constructor(
    private store: Store,
    private dialogService: NbDialogService,
    private socketService: WebSocketService,
    private toastrService: NbToastrService,
    private service: InsightService,
    private router: Router,
    private restrictionService: OktaRestriction
  ) {
  }

  public ngAfterViewInit() {
    if (this.store.selectSnapshot(InsightsState.insightsActive).length > 1) {
      this.service.buildKeysForTable(this.store.selectSnapshot(InsightsState.insightsActive));
    }
  }

  public ngOnInit() {
    if (this.router.url.includes('inactive')) {
      if (this.store.selectSnapshot(InsightsState.insightsInactive).length < 1) {
        this.store.dispatch(LoadInsightsInactive);
      }
      this.dataInactive$.subscribe((data) => {
        this.tableData = data;
      });
    } else {
      this.data$.subscribe((data) => {
        this.tableData = data;
        this.service.buildKeysForTable(data);
        this.insightJobsData$.subscribe((data) => (this.insightJobs = data));
      });
    }
    this.store.dispatch(LoadListOfFailedJobs);
    this.failedJobs.subscribe((data) => {
      const failedInsights = data.filter((job) => job?.type === 'insight');
      this.failedInsightCount = failedInsights.length;
      this.listOfFailedJobs = failedInsights.slice().sort(this.sortingByDate);
    });
    this.displayNewButton = this.restrictionService.manager_permission;
    this.loading.subscribe((data) => (this.spin = data));
  }

  public clickedButtonTable($event) {
    if ($event.action === 'view') {
      this.viewInsight($event.data);
    }
    if ($event.action === 'edit') {
      if ($event.data.status === 'RUNNING') {
        this.toastrService.show(status, 'Cannot Edit a running Insight', { status: 'danger' });
      } else {
        this.editInsight($event.data);
      }
    }
    if ($event.action === 'clone') {
      this.cloneInsight($event.data);
    }
    if ($event.action === 'stats') {
      this.viewStats($event.data);
    }
    if ($event.action === 'activate') {
      if ($event.data.status === 'RUNNING') {
        this.toastrService.show(status, 'Cannot Start a running Insight', { status: 'danger' });
      } else {
        this.runInsightJob($event.data);
      }
    }
    if ($event.action === 'deactivate') {
      if ($event.data.status !== 'RUNNING') {
        this.toastrService.show(status, 'Insight is not running', { status: 'danger' });
      } else {
        this.terminateInsightJob($event.data);
      }
    }
    if ($event.action === 'delete') {
      this.deleteInsight($event.data);
    }
  }

  public newCreation() {
    this.newInsight();
  }

  public refreshTableData() {
    if (this.router.url.includes('inactive')) {
      this.store.dispatch(LoadInsightsInactive);
    } else {
      this.store.dispatch(ReloadInsights);
    }
  }

  /***********************************************************************/
  public newInsight(): void {
    this.dialogService.open(EditInsightComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        insight: new Insight()
      }
    });
  }

  /***********************************************************************/
  public viewInsight(insight: Insight) {
    this.dialogService.open(ViewInsightComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        insight
      }
    });
  }

  /***********************************************************************/
  public editInsight(insight: Insight) {
    this.dialogService.open(EditInsightComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        insight: Insight.copy(insight)
      }
    });
  }


  /***********************************************************************/
  public cloneInsight(insight: Insight) {
    const clonedInsight = Insight.clone(insight);
    clonedInsight.id = null;
    this.dialogService.open(EditInsightComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'Clone',
        insight: clonedInsight
      }
    });
  }


  /***********************************************************************/
  public deleteInsight(insight: Insight) {
    this.dialogService
      .open(DeleteInsightComponent, {
        closeOnEsc: false,
        closeOnBackdropClick: false,
        context: {
          title: 'Are you sure you want to delete Insight ',
          insight: Insight.copy(insight) as Insight
        }
    });
  }
  /***********************************************************************/

  /***********************************************************************/
  public viewStats(insight: Insight): void {
    this.dialogService.open(StatsInsightComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        insight
      }
    });
  }

  /***********************************************************************/
  public viewFailedInsight() {
    this.dialogService.open(FailedJobInsightListComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        failedJobsList: this.listOfFailedJobs
      }
    });
  }
  public runInsightJob(insight: Insight): void {
    this.dialogService
      .open(NgxGenericModalComponent, {
        closeOnEsc: false,
        closeOnBackdropClick: false,
        context: {
          title: 'Are you sure you want to "Start Insight"? ',
          message: insight.id.toString()
        }
      })
      .onClose.subscribe((data) => {
        if (data) {
          this.socketService.setUp();
          this.service.runInsightJob(insight).subscribe((data) => {
            setTimeout(() => {
              this.socketService.sendMessage(data.job_id, insight.id);
            }, 2000);
          });
        }
      });
  }

  public terminateInsightJob(insight: Insight): void {
    this.dialogService.open(InsightTerminateModalComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'Are you sure you want to "Terminate": ',
        message: insight.id.toString(),
        job_id: insight.batch_job_id,
        insight_name: insight.name
      }
    });
  }

  public sortingByDate(a, b) {
    if (a.date_created > b.date_created) {
      return -1;
    }
    if (a.date_created < b.date_created) {
      return 1;
    }

    return 0;
  }
}
