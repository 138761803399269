export class LoadSurveys {
  public static readonly type = '[Surveys] Load Surveys';
  constructor() {}
}

export class LoadSurveysSuccess {
  public static readonly type = '[Surveys] Load Surveys SUCCESS';
  constructor(public payload: any) {}
}

export class LoadSurveysFail {
  public static readonly type = '[Surveys] Load Surveys FAIL';
  constructor() {}
}

export class ReloadSurveys {
  public static readonly type = '[Surveys] Reload Surveys';
  constructor() {}
}

export class ReloadSurveysSuccess {
  public static readonly type = '[Surveys] Reload Surveys SUCCESS';
  constructor(public payload: any) {}
}

export class ReloadSurveysFail {
  public static readonly type = '[Surveys] Reload Surveys FAIL';
  constructor() {}
}

export class PostSurveys {
  public static readonly type = '[Surveys] Post Surveys';
  constructor(public payload: any, public edit?: boolean) {}
}

export class DeleteSurveys {
  public static readonly type = '[Surveys] Delete Surveys';
  constructor(public payload: any, public edit?: boolean) {}
}

export class SaveSurveysComplete {
  public static readonly type = '[Surveys] Surveys Save Complete';
  constructor(public data: any, public surveys: any, public edit?: boolean) {}
}

export class SaveSurveysFail {
  public static readonly type = '[Surveys] Surveys Save Fail';
  constructor(public payload: any) {}
}
