<nb-card
  accent="success"
  style="
    width: 90%;
    height: 90%;
    min-height: 450px;
    min-width: 700px;
    max-height: 800px;
    max-width: 1000px;
    margin: auto;
  "
>
  <nb-card-header>
    <div class="row">
      <div class="col-auto mr-auto" *ngIf="title === 'New'">
        <nb-icon icon="plus-outline"></nb-icon>
      </div>
      <div class="col-auto mr-auto" *ngIf="title === 'Edit'">
        <nb-icon icon="edit-2-outline"></nb-icon>
      </div>
      <div class="col-auto mr-auto" *ngIf="title === 'New'">Create New String</div>
      <div class="col-auto mr-auto" *ngIf="title === 'Edit'">Edit String</div>
      <div class="col-auto"><button nbButton (click)="close()">X</button></div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div style="padding: 1.143em; margin: -1.143em">
      <strong class="text-uppercase">String Details</strong>
      <hr />
      <form #xliffForm="ngForm">
        <div class="row">
          <div class="col">
            <label>ID<span style="color: red">*</span>:</label>
          </div>
          <div class="col-9">
            <div class="input-box">
              <span class="prefix" *ngIf="title === 'New'">{{prefix()}}</span>
              <input
                id="id"
                name="id"
                nbInput
                fullWidth
                style="width: 100%"
                [(ngModel)]="xliff.id"
                #xliffId="ngModel"
                [disabled]="title === 'Edit'"
                required
                [pattern]="patternId"
              />
              <span class="sufix" *ngIf="title === 'New'">_dmc</span>
            </div>
            <div *ngIf="xliffId.errors?.pattern">
              <p class="error">String ID not valid! ID allows only alphanumeric characters and underscore.</p>
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col">
            <label>Source<span style="color: red">*</span>:</label>
          </div>
          <div class="col-9">
            <input
              id="source"
              name="source"
              nbInput
              fullWidth
              placeholder="Source"
              style="width: 100%"
              [(ngModel)]="xliff.source"
              #source="ngModel"
              [pattern]="patternFirstChar"
              required
            />
            <div *ngIf="source.errors?.pattern">
              <p class="error">Invalid Source. Value cannot be empty.</p>
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col">
            <label>Note<span style="color: red">*</span>:</label>
          </div>
          <div class="col-9">
            <input
              id="note"
              name="note"
              nbInput
              fullWidth
              placeholder="Note"
              style="width: 100%"
              [(ngModel)]="xliff.note"
              #note="ngModel"
              [pattern]="patternFirstChar"
              required
              [disabled]="editable"
              value='{{editable ? "Do not translate" : xliff.note}}'
            />
            <div *ngIf="note.errors?.pattern">
              <p class="error">Invalid Note. Value cannot be empty.</p>
            </div>
          </div>
        </div>
        <br />
        <div class="row">
        <strong class="text-uppercase col">Translations</strong> 
        <nb-toggle
          class="col"
          style="padding-right: 0; margin-right: 0; text-align: right;"
          labelPosition="start"
          status="success"
          [(ngModel)]="editable"
          nbTooltip="Manually translating the strings will skip the paid translation process."
          nbTooltipPlacement="right">
        <strong class="text-uppercase">Manual Translation</strong></nb-toggle>
        </div>

        <hr />
        <div class="row">
          <dl class="row small" *ngFor="let language of translationLanguages | keyvalue : descOrder">
            <app-string-translation 
              [editable]="editable" 
              [language]="language" 
              [xliff]="xliff">
          </app-string-translation>
          </dl>
        </div>
        <br />
      </form>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div class="d-flex flex-row-reverse">
      <div class="p-2">
        <button nbButton status="success" (click)="save()" [disabled]="!xliffForm.form.valid">Save</button>
      </div>

      <div class="p-2">
        <button nbButton (click)="close()">Close</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
