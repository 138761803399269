<div class="row">
  <dt class="col-2">{{ languageDisplay }}</dt>
  <dd *ngIf="!editable" class="col-7">{{ xliff[language['key']] }}</dd>
  <input 
        class="col-7" 
        *ngIf="editable"
        id="translation"
        name="translation"
        nbInput
        placeholder="{{languageDisplay}} translation"
        [(ngModel)]="xliff[language['key']]"
        #note="ngModel"
        required
            />
</div>
