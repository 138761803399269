<nb-card accent="success" style="width: 700px; margin: auto" class="own-scroll">
  <nb-card-header>
    <div class="row">
      <div class="col-auto mr-auto">List of failed insights jobs</div>
      <div class="col-auto"><button nbButton (click)="close()">X</button></div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div
      style="height: 700px; overflow: auto; padding: 1.143em; margin: -1.143em"
    >
      <div *ngIf="failedJobsList">
        <nb-list style="overflow: hidden; width: 100%">
          <nb-list-item
            style="overflow: hidden; width: 100%"
            class="row justify-content-between"
            *ngFor="let failedJob of failedJobsList"
          >
            <p class="col-auto" style="margin-right: 10px">
              <b>{{ failedJob.job_name }}</b> -
              {{ failedJob.date_created | date: "short" }}
            </p>
            <button
              class="col-auto"
              nbButton
              (click)="viewFailedJob(failedJob)"
            >
              View
            </button>
          </nb-list-item>
        </nb-list>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div class="d-flex flex-row-reverse">
      <div class="p-2"><button nbButton (click)="close()">Close</button></div>
    </div>
  </nb-card-footer>
</nb-card>
