<app-spinner *ngIf="spin"></app-spinner>
<app-ngx-table
  type={{type}}
  [mode]="mode"
  [description]="description"
  [buttonEnabled]="displayNewButton"
  [tableData]="tableData"
  *ngIf="!spin"
  (clickedButton)="clickedButtonTable($event)"
  (createNew)="newCreation()"
  (refreshTable)="refreshTableData()"
></app-ngx-table>
<router-outlet></router-outlet>
