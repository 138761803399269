import { Injectable } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { RealTimePersonalizationEvent } from 'src/app/models/rtp-models';
import { EventBridgeClient, PutEventsCommand } from '@aws-sdk/client-eventbridge';
import { Store } from '@ngxs/store';
import { RtpSendFailed, RtpSendSuccess } from 'src/app/components/qa-testing/redux/qa.actions';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private store: Store) {}

  public sendToEventBridge(event: RealTimePersonalizationEvent): void {
    Auth.currentCredentials().then((credentials) => {
      const creds = Auth.essentialCredentials(credentials);

      const myObj = {
        accessKeyId: creds.accessKeyId,
        sessionToken: creds.sessionToken,
        secretAccessKey: creds.secretAccessKey
      };

      const client = new EventBridgeClient({
        credentials: myObj,
        region: 'us-east-1'
      });

      const params = {
        Entries: []
      };

      params.Entries.push(event);
      const command = new PutEventsCommand(params);
      client
        .send(command)
        .then((data) => {
          this.store.dispatch(new RtpSendSuccess());
        })
        .catch((error) => {
          this.store.dispatch(new RtpSendFailed());
        });
    });
  }
}
