import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { Actions, Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SourceImage } from 'src/app/models/drb-models/source-image';
import { NgxGenericModalComponent } from 'src/app/shared/ngx-generic-modal/ngx-generic-modal.component';
import { RouterState } from 'src/app/shared/redux/router-state';
import { DrbState, SaveSourceImagesState } from '../../../redux';

@Component({
  selector: 'app-edit-image',
  templateUrl: './edit-image.component.html',
  styleUrls: ['./edit-image.component.css', '../../../../../shared/common_syles.css']
})
export class EditImageComponent implements OnInit {
  @Select(DrbState.sourceImages) public data$: Observable<any[]>;
  @Select(DrbState.ids) public ids$: Observable<any[]>;
  @Select(RouterState.username) public data_username$: Observable<any>;

  public title;
  public image: SourceImage;
  public sourceImageState: {};
  public username = '';
  public ids;
  public patternId = '^[a-zA-Z0-9_]+$';
  public patternLocation = '^[^\'"]*$';
  public bundle_name: string;

  constructor(
    private store: Store,
    protected ref: NbDialogRef<EditImageComponent>,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
    private actions$: Actions
  ) {}

  public ngOnInit() {
    this.data_username$.subscribe((data) => (this.username = data));
    this.data$.subscribe((data) => (this.sourceImageState = data));
    this.ids$.subscribe((data) => (this.ids = data));
  }

  public save() {
    const localSourceImage = JSON.parse(JSON.stringify(this.sourceImageState));

    if (this.title === 'New') {
      const imageId = this.prefix() + this.image.id + '_dmc';
      if (this.ids.some((item) => item === imageId)) {
        this.toastrService.show('', imageId + ' already exists!', { status: 'danger' });
      } else {
        this.image.id = imageId;
        this.image.created_by = this.username;
        this.image.created_ts = Math.floor(Date.now() / 1000);
        this.image.last_updated_by = this.username;
        this.image.last_updated_ts = Math.floor(Date.now() / 1000);

        localSourceImage.push(this.image);
        this.store.dispatch(new SaveSourceImagesState(localSourceImage, this.bundle_name));
        this.ref.close();
      }
    } else if (this.title === 'Edit') {
      const index = localSourceImage.findIndex((item) => item.id === this.image.id);
      localSourceImage[index].location = this.image.location;
      localSourceImage[index].file = this.image.file;
      localSourceImage[index].last_updated_by = this.username;
      localSourceImage[index].last_updated_ts = Math.floor(Date.now() / 1000);

      this.store.dispatch(new SaveSourceImagesState(localSourceImage, this.bundle_name));
      this.ref.close();
    }
  }
  public close() {
    this.dialogService
      .open(NgxGenericModalComponent, {
        context: {
          title: 'Confirm Close',
          message: 'Are you sure that you want to close the window? Any changes that you have made will be lost.'
        }
      })
      .onClose.subscribe((data) => {
        if (data) {
          this.ref.close();
        }
      });
  }

  public prefix() {
    if(this.bundle_name === 'marketing') {

      return 'mkt_';
    } else if(this.bundle_name === 'marketing_translations') {

      return 'mkt_t_';
    }
  }
}
