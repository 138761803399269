import { Component, NgZone, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { AmplifyService } from 'src/app/core/services/amplify.service';
import { SetUserGroups, SetUsername } from '../redux';

@Component({
  selector: 'app-okta-redirect',
  templateUrl: './okta-redirect.component.html',
  styleUrls: ['./okta-redirect.component.css']
})
export class OktaRedirectComponent implements OnInit {
  constructor(private service: AmplifyService, private zone: NgZone, private store: Store) {}

  public ngOnInit(): void {
    // Used for listening to login events
    Hub.listen('auth', ({ payload: { event, data } }) => {
      if (event === 'cognitoHostedUI' || event === 'signedIn') {
        this.setUsername();
        this.zone.run(() => this.service.getAPis());
      } else {
      }
    });

    //currentAuthenticatedUser: when user comes to login page again
    Auth.currentAuthenticatedUser()
      .then(
        (user) => {
          this.setUsername();
          this.service.getAPis();
        },
        (error) => {}
      )
      .catch((err) => {});
  }

  public async setUsername() {
    const tokens = await Auth.currentSession();
    const okta_groups = tokens.getIdToken().payload['custom:groups'];
    const userName = tokens.getIdToken().payload.email;
    this.store.dispatch(new SetUsername(userName));
    this.store.dispatch(new SetUserGroups(JSON.parse(okta_groups)));
  }
}
