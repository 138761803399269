import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { Store } from '@ngxs/store';
import { LocalDataSource } from 'ng2-smart-table';
import { NgxCogwheelComponent } from '../../ngx-cogwheel/ngx-cogwheel.component';
import { SetComponentCurrentTableKeys, SetCurrentType } from '../../ngx-cogwheel/redux/cogwheel.actions';
import { NgxTableService } from '../ngx-table.service';

@Component({
  selector: 'app-ngx-table',
  templateUrl: './ngx-table.component.html',
  styleUrls: ['./ngx-table.component.scss']
})
export class NgxTableComponent implements OnInit, OnChanges {
  @Input() public buttonEnabled: boolean;
  @Input() public type: string;
  @Input() public mode: string;
  @Input() public description: string;
  @Input() public secondary_description: string;
  @Input() public tableData: any;
  @Input() public failedInsightCount: any;
  @Output() public clickedButton: EventEmitter<any> = new EventEmitter();
  @Output() public createNew: EventEmitter<any> = new EventEmitter();
  @Output() public refreshTable: EventEmitter<any> = new EventEmitter();
  @Output() public viewFailedInsight: EventEmitter<any> = new EventEmitter();

  public source: LocalDataSource;

  public settings = {
    actions: {
      custom: [],
      add: false,
      edit: false,
      delete: false,
      position: 'left'
    },
    columns: {}
  };

  constructor(
    private service: NgxTableService,
    private dialogService: NbDialogService,
    private store: Store,
    private router: Router
  ) {
    this.source = new LocalDataSource(); // create the source
    this.source.setSort([{ field: 'name', direction: 'asc' }]);
  }

  public async ngOnInit() {
    this.settings.actions.custom = this.service.buildButtons(this.type, this.mode);
    const columnConfig = JSON.parse(localStorage.getItem(this.type)) || {};
    const insightCampaignsTypes = ['robot-family-metadata', 'insights', 'campaigns'];
    const typesThatNeedColumnRebuild = ['surveys', 'hta-recommendation', 'canned-kozrs'];
    if (this.isEmpty(columnConfig)) {
      const newSettings = this.service.buildColumnDefault(this.type);
      this.settings.columns = Object.assign({}, newSettings);

      //Status is an object which throws error on saving with Redux action so we delete prior that
      if (insightCampaignsTypes.includes(this.type)) {
        delete newSettings.status;
        delete newSettings.metadata;
        Object.assign(newSettings, { status: { title: 'Status' } });
        Object.assign(newSettings, { metadata: { title: 'Metadata' } });
      }
      this.store.dispatch(new SetCurrentType(this.type));
      this.store.dispatch(new SetComponentCurrentTableKeys(newSettings));

    } else {

      if (insightCampaignsTypes.includes(this.type)) {
        const newConfig = this.service.buildChosenColumns(this.type, columnConfig);
        this.settings.columns = newConfig;
        this.sendReduxAction(newConfig);

      } else if (typesThatNeedColumnRebuild.includes(this.type)) {
        const newConfig = this.service.buildChosenColumns(this.type, columnConfig);
        this.settings.columns = newConfig;
        this.store.dispatch(new SetCurrentType(this.type));
        this.store.dispatch(new SetComponentCurrentTableKeys(newConfig));
      } else {
        this.settings.columns = columnConfig;
        this.store.dispatch(new SetCurrentType(this.type));
        this.store.dispatch(new SetComponentCurrentTableKeys(columnConfig));
      }
    }
  }

  public sendReduxAction(param) {
    const clonedParam = JSON.parse(JSON.stringify(param));
    delete clonedParam.status;
    Object.assign(clonedParam, { status: { title: 'Status' } });
    this.store.dispatch(new SetComponentCurrentTableKeys(clonedParam));
    this.store.dispatch(new SetCurrentType(this.type));
  }


  public ngOnChanges() {
    this.source.load(this.tableData).then(() => {
      const columnConfig = JSON.parse(localStorage.getItem(this.type)) || {};
      const newConfig = this.service.buildChosenColumns(this.type, columnConfig);
      this.settings.columns = newConfig;
      this.store.dispatch(new SetComponentCurrentTableKeys(newConfig));
  });
  }

  public isEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }

    return true;
  }

  public cogwheelMenu() {
    //Here we extract url after # so we can refresh whole component
    const url = this.router.routerState.snapshot.url;
    this.dialogService.open(NgxCogwheelComponent).onClose.subscribe((data) => {
      if (data) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          /*if (this.type == 'campaigns') {
            this.router.navigate([`/${this.type}` + '/table']);
            this.toastrService.show(status, 'Save: Success', { status: 'success' });
          } else {
            this.router.navigate([`/${this.type}`]);
            this.toastrService.show(status, 'Save: Success', { status: 'success' });
          }*/
          this.router.navigate([`/${url}`]);
        });
      }
    });
  }

  public customEventListener(event) {
    this.clickedButton.emit(event);
  }

  public createNewInstance() {
    this.createNew.emit();
  }

  public refreshTableData() {
    this.refreshTable.emit();
  }

  public viewFailedInsightEmit() {
    this.viewFailedInsight.emit();
  }
}
