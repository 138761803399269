import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  LoadMessMakersFail,
  LoadMessMakersSuccess,
  PatchMessMakersFail,
  PatchMessMakersSuccess
} from 'src/app/components/qa-testing/mess-makers/redux/mess-makers.actions';
import { environment } from 'src/environments/environment';
import { API } from '@aws-amplify/api';
import { AmplifyServiceBuilder } from './amplify.service.builder';
import { NbToastrService } from '@nebular/theme';
import { DDBMessMaker } from 'src/app/models/qa-models/mess-makers';

@Injectable({
  providedIn: 'root'
})
export class MessMakersService {

  public mess_makers_api = 'mess_makers_api';

  constructor(private toastrService: NbToastrService, private store: Store) {}


  public loadMessMakersAmplify(pmap_id) {
    API.get(this.mess_makers_api, `/${environment.apiAccountStage}/qa/mess-makers?pmap_id=${pmap_id}`, '')
      .then((response) => {
          this.store.dispatch(new LoadMessMakersSuccess(response));
      })
      .catch((error) => {
        this.store.dispatch(new LoadMessMakersSuccess([]));
        this.store.dispatch(new LoadMessMakersFail(error));
      });
  }

  public patchMessMakersAmplify(newRecords: DDBMessMaker) {
    const requestData = AmplifyServiceBuilder(this.mess_makers_api, false, newRecords);
    API.patch(this.mess_makers_api, `/${environment.apiAccountStage}/qa/mess-makers`, requestData)
      .then((response) => {
        this.store.dispatch(new PatchMessMakersSuccess(response));
      })
      .catch((error) => {
        console.error(error);
        const message =
          error.response && error.response.data
            ? error.response.data.message
              ? error.response.data.message
              : error.response.data
            : 'Error!';
        this.store.dispatch(new PatchMessMakersFail(message));
      });
  }
}
