/* eslint-disable @typescript-eslint/dot-notation */
import { DecimalPipe } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { Actions, ofActionCompleted, Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DrbService } from 'src/app/core/services/drb.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { NgxGenericModalComponent } from 'src/app/shared/ngx-generic-modal/ngx-generic-modal.component';
import { RouterState } from 'src/app/shared/redux/router-state';
import { SpinnerState } from 'src/app/shared/spinner/redux/spinner.state';
import { DrbState, LoadDrb, LoadDrbByVersionSuccess } from '../../redux';
import { SourceItem } from 'src/app/models/drb-models/source-item';
import { EditSourceItemComponent } from '../edit-source-item/edit-source-item.component';
import { ViewSourceItemComponent } from '../view-source-item/view-source-item.component';
import { DeleteDrbComponent } from '../../delete-drb/delete-drb.component';

@Component({
  selector: 'app-source-item',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './source-item.component.html',
  styleUrls: ['./source-item.component.css'],
  providers: [DecimalPipe]
})
export class SourceItemComponent implements OnInit, AfterViewInit {
  @Select(DrbState.drb) public drb$: Observable<any>;
  @Select(DrbState.sourceFiles) public data_file$: Observable<any[]>;
  @Select(DrbState.sourceLinks) public data_link$: Observable<any[]>;
  @Select(DrbState.sourceVideos) public data_video$: Observable<any[]>;
  @Select(DrbState.view_sourceFiles) public view_data_file$: Observable<any[]>;
  @Select(DrbState.view_sourceLinks) public view_data_link$: Observable<any[]>;
  @Select(DrbState.view_sourceVideos) public view_data_video$: Observable<any[]>;
  @Select(SpinnerState) public loading: Observable<boolean>;
  @Select(RouterState.username) public data_username$: Observable<any>;

  public tableData = [];
  public displayNewButton = true;
  public spin;
  public mode;
  public metaData;
  public username;
  public description;
  public type; //file-source, link-source, video-source
  public sourceType; // file, link, video
  public allKeys: string[] = [];
  public bundle_name: string;

  constructor(
    private router: Router,
    public route: ActivatedRoute,
    private service: DrbService,
    private store: Store,
    private actions$: Actions,
    private dialogService: NbDialogService,
    public utility: UtilityService
  ) {}

  public ngAfterViewInit() {
    // no need to check for files, links and videos since they all share the same keys
    if (this.store.selectSnapshot(DrbState.sourceFiles).length > 1) {
      this.service.buildKeysForTable(this.store.selectSnapshot(DrbState.sourceFiles), this.allKeys, this.type);
    } else {
      const defaultKeys = [new SourceItem()];
      this.service.buildKeysForTable(defaultKeys, this.allKeys, this.type);
    }
  }

  public ngOnInit() {
    this.route.data.subscribe((data) => (this.sourceType = data.type));
    this.bundle_name = this.router.url.split('/')[2];
    const createUrl = 'create/' + this.sourceType + 's';
    const viewUrl = 'view/' + this.sourceType + 's';
    this.type = this.sourceType + '-source';
    if (this.router.url.endsWith(createUrl)) {
      this.description = 'View, add and edit ' + this.sourceType + ' data';
      this.loadTableData();
      this.loadMetaData();

      this.data_username$.subscribe((data) => (this.username = data));
      this.checkOwnerOrContributor();
    } else if (this.router.url.endsWith(viewUrl)) {
      this.mode = 'view';
      this.displayNewButton = false;
      this.description = 'View ' + this.sourceType + ' data';

      this.loadTableData();

      this.actions$.pipe(ofActionCompleted(LoadDrbByVersionSuccess)).subscribe((data) => {
        if (data) {
          this.loadTableData();
        }
      });
    }
    this.loading.subscribe((data) => (this.spin = data));
  }

  public loadTableData() {
    if (this.mode === 'view') {
      if (this.sourceType === 'file') {
        this.view_data_file$.subscribe((loadedData) => {
          this.tableData = loadedData;
          this.service.buildKeysForTable(this.tableData, this.allKeys, this.type);
        });
      }
      if (this.sourceType === 'link') {
        this.view_data_link$.subscribe((loadedData) => {
          this.tableData = loadedData;
          this.service.buildKeysForTable(this.tableData, this.allKeys, this.type);
        });
      }
      if (this.sourceType === 'video') {
        this.view_data_video$.subscribe((loadedData) => {
          this.tableData = loadedData;
          this.service.buildKeysForTable(this.tableData, this.allKeys, this.type);
        });
      }
    } else {
      if (this.sourceType === 'file') {
        this.data_file$.subscribe((data) => {
          this.tableData = data;
          this.service.buildKeysForTable(this.tableData, this.allKeys, this.type);
        });
      }
      if (this.sourceType === 'link') {
        this.data_link$.subscribe((data) => {
          this.tableData = data;
          this.service.buildKeysForTable(this.tableData, this.allKeys, this.type);
        });
      }
      if (this.sourceType === 'video') {
        this.data_video$.subscribe((data) => {
          this.tableData = data;
          this.service.buildKeysForTable(this.tableData, this.allKeys, this.type);
        });
      }
    }
  }

  public loadMetaData() {
    this.drb$.subscribe((data) => {
      this.metaData = {
        version: data['version'],
        created_by: data['created_by'],
        created_ts: data['created_ts'],
        status: data['status'],
        last_updated_by: data['last_updated_by'],
        last_updated_ts: data['last_updated_ts'],
        owner: data['owner'],
        contributors: data['contributors'],
        draft: data['draft'],
        deployed_to_stage: data['deployed_to_stage'],
        deployed_to_stage_ts: data['deployed_to_stage_ts'],
        deployed_to_prod: data['deployed_to_prod'],
        deployed_to_prod_ts: data['deployed_to_prod_ts']
      };
    });
  }

  public refreshTableData() {
    this.dialogService
      .open(NgxGenericModalComponent, {
        context: {
          title: 'Confirm Refresh',
          message: 'Are you sure that you want to refresh draft version?'
        }
      })
      .onClose.subscribe((data) => {
        if (data) {
          this.store.dispatch(new LoadDrb(this.bundle_name));
        }
      });
  }

  public clickedButtonTable($event) {
    if ($event.action === 'view') {
      this.viewSourceItem($event.data);
    }
    if ($event.action === 'edit') {
      this.editSourceItem($event.data);
    }
    if ($event.action === 'delete') {
      this.deleteSourceItem($event.data);
    }
  }

  public viewSourceItem(sourceItem: SourceItem): void {
    this.dialogService.open(ViewSourceItemComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        sourceItem,
        type: this.sourceType
      }
    });
  }

  public editSourceItem(file: SourceItem): void {
    this.dialogService.open(EditSourceItemComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'Edit',
        sourceItem: JSON.parse(JSON.stringify(file)),
        type: this.sourceType,
        bundle_name: this.bundle_name
      }
    });
  }

  public deleteSourceItem(file: SourceItem): void {
    this.dialogService.open(DeleteDrbComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'Delete',
        item: JSON.parse(JSON.stringify(file)),
        type: this.sourceType,
        bundle_name: this.bundle_name
      }
    });
  }

  public newSourceItem(): void {
    this.dialogService.open(EditSourceItemComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'New',
        sourceItem: new SourceItem(),
        type: this.sourceType,
        bundle_name: this.bundle_name
      }
    });
  }

  public checkOwnerOrContributor() {
    if (this.username === this.metaData.owner || this.metaData.contributors.includes(this.username)) {
      this.mode = 'edit';
      this.displayNewButton = true;
    } else {
      this.mode = 'view';
      this.displayNewButton = false;
    }
  }
}
