export class ObjectZoneRecommendation {
  public pmap_id: string;
  public pmapv_id: string;
  public last_user_pmapv_id?: string;
  public zones: Array<ObservedZone> = [];
}

export class ObservedZone {
  public zone_id: string;
  public region_id: string;
  public primary_related_object?: string;
  public primary_object_type?: string;
  public suggestion_id?: string;
  public geometry?: [];
  public confidence?: string;
}
