<nb-card accent="success">
  <nb-card-header>
    {{ type | uppercase | replacedashes }}
    <div class="row">
      <div class="col-auto mr-auto">{{ description }}</div>
      <div class="col-auto" *ngIf="mode !== 'view'">
        <button
          nbButton
          outline
          status="info"
          shape="round"
          size="small"
          (click)="refreshTableData()"
          nbTooltip="Refresh table"
        >
          <nb-icon icon="refresh-outline"></nb-icon>
        </button>
      </div>
      <div class="mr-auto" style="font-size: 13px; font-style: italic; font-weight: lighter">
        {{ secondary_description }}
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-auto mr-auto" *ngIf="type !== 'my robots'">
        <div *ngIf="buttonEnabled">
          <button nbButton status="success" size="small" shape="round" (click)="createNewInstance()">
            New {{ type | replacedashes }}
          </button>
        </div>
      </div>

      <div class="col-auto mr-auto" *ngIf="type === 'my robots'">
        <button nbButton status="success" size="small" shape="round" (click)="createNewInstance()">New robot</button>
      </div>

      <div
        class="col-auto mr-auto"
        *ngIf="type === 'insights' && failedInsightCount !== 0 && failedInsightCount !== null"
      >
        <button nbButton status="warning" size="small" shape="round" (click)="viewFailedInsightEmit()">
          You have {{ failedInsightCount }} failed insights jobs in past 7 days
        </button>
      </div>

      <ng-template #tipContent>
        <div>
          <div class="row">
            <div class="col-auto"><i class="nb-search"></i>View</div>
            <div class="col-auto mr-auto"></div>
          </div>
        </div>

        <div *ngIf="mode !== 'view'">
          <div class="row">
            <div class="col-auto"><i class="nb-edit"></i>Edit</div>
            <div class="col-auto mr-auto"></div>
          </div>
        </div>

        <div
          *ngIf="
            type === 'content' ||
            type === 'campaigns' ||
            type === 'surveys' ||
            type === 'canned-kozrs' ||
            type === 'robot-family-metadata'
          "
        >
          <div class="row">
            <div class="col-auto"><i class="nb-plus-circled"></i>Clone</div>
            <div class="col-auto mr-auto"></div>
          </div>
        </div>

        <div *ngIf="type === 'insights' || type === 'campaigns' || type === 'canned-kozrs'">
          <div class="row">
            <div class="col-auto"><i class="nb-bar-chart"></i>Statistics</div>
            <div class="col-auto mr-auto"></div>
          </div>
        </div>

        <div *ngIf="type === 'insights' || type === 'campaigns'">
          <div class="row">
            <div class="col-auto"><i class="nb-play-outline"></i>Start</div>
            <div class="col-auto mr-auto"></div>
          </div>
        </div>

        <div *ngIf="type === 'insights' || type === 'campaigns'">
          <div class="row">
            <div class="col-auto"><i class="nb-power"></i>Stop</div>
            <div class="col-auto mr-auto"></div>
          </div>
        </div>

        <div
          *ngIf="
            !(
              type === 'file-source' ||
              type === 'link-source' ||
              type === 'image-source' ||
              type === 'video-source' ||
              type === 'string'
            )
          "
        >
          <div class="row">
            <div class="col-auto"><i class="nb-trash"></i>Delete</div>
            <div class="col-auto mr-auto"></div>
          </div>
        </div>
      </ng-template>

      <div class="col-auto">
        <button nbButton outline status="info" shape="round" size="small" [nbPopover]="tipContent" placement="left">
          <nb-icon icon="info-outline"></nb-icon>
        </button>
      </div>

      <div class="col-auto">
        <button
          nbButton
          outline
          status="info"
          shape="round"
          size="small"
          (click)="cogwheelMenu()"
          nbTooltip="Column Order"
        >
          <nb-icon icon="settings-outline"></nb-icon>
        </button>
      </div>
    </div>
  </nb-card-header>

  <nb-card-body>
    <ng2-smart-table [settings]="settings" [source]="source" (custom)="customEventListener($event)"> </ng2-smart-table>
  </nb-card-body>
  <nb-card-footer>
    <p>
      <i>All times displayed are in local timezone. </i>
    </p>
  </nb-card-footer>
</nb-card>
