<nb-card accent="success">
    <nb-card-header>Insight Engine: You are signed out</nb-card-header>
    <nb-card-body>
        <div class="row d-flex justify-content-center" style="margin-top: 200px;">
            <div class="col-md-6">
                <br>
                <img src="https://logos-download.com/wp-content/uploads/2016/05/IRobot_logo_green.png" width="1000px" height="200px">
                <br>
            </div>
        </div>
        <div class="row justify-content-center align-middle" style="margin-top: 280px;">
            <br>
            <button nbButton status="success" (click)="login()">Login</button>
        </div>
    </nb-card-body>
</nb-card>