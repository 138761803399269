<nb-card accent="success">
  <nb-card-header>
    <div class="row">
      <div class="col-auto mr-auto">
        <nb-icon icon="question-mark-circle-outline"></nb-icon>
      </div>
      <div class="col-auto mr-auto">{{ title }}</div>
      <div class="col-auto">
        <button nbButton (click)="clickedNo()">X</button>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div>Campaign {{ campaign_name }} ({{ message }})</div>
    <br />
    <div class="row">
      <div class="col-4 mr-auto">Provide a reason for terminating the run:</div>
      <div class="col-6 mr-auto">
        <input nbInput style="width: 100%" [(ngModel)]="reasonText" [ngModelOptions]="{ standalone: true }" />
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div class="d-flex flex-row-reverse">
      <div class="p-2">
        <button nbButton [disabled]="!reasonText" status="success" (click)="clickedYes()">
          {{ yesButton }}
        </button>
      </div>
      <div class="p-2">
        <button nbButton status="danger" (click)="clickedNo()">
          {{ noButton }}
        </button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
