import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SharedModule } from 'src/app/shared/shared.module';

import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import {
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDialogModule,
  NbIconModule,
  NbInputModule,
  NbAccordionModule,
  NbListModule,
  NbSelectModule
} from '@nebular/theme';
import { CoreModule } from 'src/app/core/core.module';

import { routedComponents } from './surveys-routing.module';
import { ViewSurveyComponent } from './view-survey/view-survey.component';
import { EditSurveyComponent } from './edit-survey/edit-survey.component';

const COMPONENTS = [ViewSurveyComponent, EditSurveyComponent];

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    CoreModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NbDialogModule,
    NbCardModule,
    NbEvaIconsModule,
    NbIconModule,
    NbButtonModule,
    NbCheckboxModule,
    NbInputModule,
    NbSelectModule,
    NbListModule,
    NbAccordionModule,
    CodemirrorModule
  ],
  providers: [],
  declarations: [...COMPONENTS, ...routedComponents]
})
export class SurveysModule {}
