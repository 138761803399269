import { Component, OnInit } from '@angular/core';
import { CleaningEstimate } from 'src/app/models/cleaning-estimate';
import { CleanTimeEstimatesComponent } from '../clean-time-estimates.component';

@Component({
    selector: 'app-clean-time-estimates-sub',
    templateUrl: './clean-time-estimates-sub.component.html',
    styleUrls: ['./clean-time-estimates-sub.component.scss']
})
export class CleanTimeEstimatesSubComponent implements OnInit {
  public unique_key: number;
  public parentRef: CleanTimeEstimatesComponent;

  public areaTypeValues = ['zone', 'region'];
  public areaIdValues = [1, 2, 3, 'etc'];

  public estimate: CleaningEstimate;

  constructor() { }

  public ngOnInit() {
      if (!this.estimate) {
          this.estimate = new CleaningEstimate();
          this.estimate.data_model_version = 'v2201';
      }
  }

  public remove_me() {
      this.parentRef.remove(this.unique_key);
  }
}
