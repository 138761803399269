export class LoadInsights {
  public static readonly type = '[Insights] Load Insights';
  constructor() {}
}

export class LoadInsightsSuccess {
  public static readonly type = '[Insights] Load Insights SUCCESS';
  constructor(public payload: any) {}
}

export class LoadInsightsFail {
  public static readonly type = '[Insights] Load Insights FAIL';
  constructor() {}
}

export class ReloadInsights {
  public static readonly type = '[Insights] Reload Insights';
  constructor() {}
}

export class ReloadInsightsSuccess {
  public static readonly type = '[Insights] Reload Insights SUCCESS';
  constructor(public payload: any) {}
}

export class ReloadInsightsFail {
  public static readonly type = '[Insights] Reload Insights FAIL';
  constructor() {}
}

export class PostInsights {
  public static readonly type = '[Insights] Post Insights';
  constructor(public payload: any) {}
}

export class DeleteInsights {
  public static readonly type = '[Insights] Delete Insights';
  constructor(public payload: any) {}
}

export class SaveInsightComplete {
  public static readonly type = '[Insights] Insight Save Complete';
  constructor(public data: any, public insights: any) {}
}

export class SaveInsightFail {
  public static readonly type = '[Insights] Insight Save Fail';
  constructor(public payload: any) {}
}

export class ManageInsight {
  public static readonly type = '[Insights] Manage Insight';
  constructor(public payload: any) {}
}

export class AddInsightJob {
  public static readonly type = '[Insights] Insight Job Added';
  constructor(public payload: any) {}
}

export class RemoveInsightJob {
  public static readonly type = '[Insights] Insight Job Removed';
  constructor(public payload: any) {}
}
export class IsightSpinShow {
  public static readonly type = '[Insights] Insight Spin Show';
  constructor(public payload: any) {}
}
export class InsightSpinHide {
  public static readonly type = '[Insights] Insight Spin Hide';
  constructor(public payload: any) {}
}

export class LoadListOfFailedJobs {
  public static readonly type = '[Insights] Load List of Failed Jobs SUCCESS';
  constructor() {}
}

export class StoreListOfFailedJobs {
  public static readonly type = '[Insights] Store List of Failed Jobs SUCCESS';
  constructor(public payload: any) {}
}

export class LoadInsightsInactive {
  public static readonly type = '[Insights] Load Insights Inactive';
  constructor(public payload: any) {}
}

export class LoadInsightsInactiveSuccess {
  public static readonly type = '[Insights] Load Insights Inactive SUCCESS';
  constructor(public payload: any) {}
}
