<nb-card accent="success" style="width: 70%; margin: auto">
  <nb-card-header>
    <div class="row">
      <div class="col-auto mr-auto">
        <nb-icon icon="search-outline"></nb-icon>
      </div>
      <div class="col-auto mr-auto">
        High Traffic Area - {{ htaRecommendation.hta_id }}
      </div>
      <div class="col-auto"><button nbButton (click)="close()">X</button></div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <dl class="row small">
      <dt class="col-2">Robot ID</dt>
      <dd class="col-10">{{ htaRecommendation.robotid }}</dd>
      <dt class="col-2">Pmap ID</dt>
      <dd class="col-10">{{ htaRecommendation.pmap_id }}</dd>
      <dt class="col-2">Active Pmapv Id</dt>
      <dd class="col-10">{{ htaRecommendation.active_pmapv_id }}</dd>
      <dt class="col-2">KOZ Geometry</dt>
      <dd class="col-10">{{ htaRecommendation.hta_geom | json }}</dd>
      <dt class="col-2">Comments</dt>
      <dd class="col-10">{{ htaRecommendation.comments }}</dd>
      <dt class="col-2">Created at</dt>
      <dd class="col-10">
        {{ htaRecommendation.create_timestamp * 1000 | date: "short" }}
      </dd>
    </dl>
  </nb-card-body>
  <nb-card-footer>
    <div class="d-flex flex-row-reverse">
      <div class="p-2">
        <button nbButton status="info" (click)="close()">Close</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
