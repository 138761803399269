import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

/** Value has to be between -number and number (inclusive)*/
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function validRangeValidator(rangeNumber: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const inputNumber = control.value;
    if (inputNumber === undefined || inputNumber === null) {
      return null;
    }
    const decimalWithTwo = /^-?\d+(\.\d{1,2})?$/;
    if (!decimalWithTwo.test(String(inputNumber))) {
      return { numberValidator: { value: inputNumber } };
    }
    const forbidden = inputNumber >= -rangeNumber && inputNumber <= rangeNumber ? false : true;

    return forbidden ? { rangeValidator: { value: inputNumber } } : null;
  };
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[validNumberRange]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ValidatorDirective, multi: true }]
})
export class ValidatorDirective implements Validator {
  @Input() public validNumberRange = '';

  public validate(control: AbstractControl): ValidationErrors | null {
    return this.validNumberRange ? validRangeValidator(Number(this.validNumberRange))(control) : null;
  }
}
