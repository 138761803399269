import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment';
import { RouterState } from '../redux/router-state';
@Injectable({ providedIn: 'root' })

// This class is used to restrict access to a certain feature on the UI based on the user's OKTA groups
export class OktaRestriction {
  @Select(RouterState.user_groups) public data$: Observable<any>;
  public user_groups = [];

  // This permission allows users to see create/edit/delete options on the UI
  public manager_permission = false;

  // This permission allows users to see the campaign promotion button
  public promotion_permission = false;

  // This permission allows users to see and access the QA Tools section of the UI
  public qa_permission = false;

  // This permission allows users to see and access the DRB section of the UI. Cannot deploy on prod
  public drb_user_permission = false;

  // This permission allows users to see and access the DRB section of the UI + deploy on prod
  public drb_manager_permission = false;

  constructor() {
    this.data$.subscribe((data) => (this.user_groups = data));
    this.manager_permission = this.user_groups.includes(environment.okta_groups.campaign_manager);
    this.promotion_permission = this.user_groups.includes(environment.okta_groups.promoter);
    this.qa_permission = this.user_groups.includes(environment.okta_groups.qa);
    this.drb_user_permission = this.user_groups.includes(environment.okta_groups.drb_user);
    this.drb_manager_permission = this.user_groups.includes(environment.okta_groups.drb_manager);
  }
}
