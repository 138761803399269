import { Component, Input } from '@angular/core';
import { UtilityService } from 'src/app/core/services/utility.service';

@Component({
  selector: 'app-source-added',
  templateUrl: './source-added.component.html',
  styleUrls: ['../drb-diff.component.css']
})
export class SourceAddedComponent {
  @Input() public item;

  constructor(public utility: UtilityService) {}
}
