import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Auth } from '@aws-amplify/auth';
import { OktaRestriction } from 'src/app/shared/okta-restrictions/okta-restrictions';

@Injectable({
  providedIn: 'root'
})
export class OktaAuthGuardService implements CanActivate {
  constructor(private router: Router, private restrictionService: OktaRestriction) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (state.url.startsWith('/qa-testing')) {
      return Auth.currentAuthenticatedUser()
        .then(async () => {
          if (this.restrictionService.qa_permission) {
            return true;
          } else {
            return false;
          }
        })
        .catch(() => {
          this.router.navigate(['/login']);

          return false;
        });
    }
    if (state.url.startsWith('/drb')) {
      return Auth.currentAuthenticatedUser()
        .then(async () => {
          if (this.restrictionService.drb_manager_permission || this.restrictionService.drb_user_permission) {
            return true;
          } else {
            return false;
          }
        })
        .catch(() => {
          this.router.navigate(['/login']);

          return false;
        });
    }
  }
}
