import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Drb } from 'src/app/models/drb-models/drb';
import { NgxGenericModalComponent } from 'src/app/shared/ngx-generic-modal/ngx-generic-modal.component';
import { RouterState } from 'src/app/shared/redux/router-state';
import { Router } from '@angular/router';
import { LoadDrbVersions, PostDrb, SaveDrbState } from './../redux';
import { UtilityService } from 'src/app/core/services/utility.service';

@Component({
  selector: 'app-new-drb',
  templateUrl: './new-drb.component.html',
  styleUrls: ['./new-drb.component.css', '../../../shared/common_syles.css']
})
export class NewDrbComponent implements OnInit {
  @Select(RouterState.username) public data_username$: Observable<any>;

  public contributors = ['', ''];
  public username;
  public errorMessage;
  public bundle_name: string;

  constructor(
    private store: Store,
    protected ref: NbDialogRef<NewDrbComponent>,
    private dialogService: NbDialogService,
    private router: Router,
    private utils: UtilityService
  ) {}

  public ngOnInit() {
    this.data_username$.subscribe((data) => (this.username = data));
  }

  public save() {
    const localDrb = new Drb();

    if (this.contributors.every(this.utils.verifyEmail)) {
      if (this.contributors.includes(this.username)) {
        this.errorMessage = this.username + ' is already the Owner.';

        return;
      }
      localDrb.owner = this.username;
      localDrb.contributors = this.contributors;

      const draftDrb = {
        created_by: localDrb.owner,
        contributors: localDrb.contributors.filter((n) => n),
        bundle_name: this.bundle_name
      };

      this.store.dispatch(new PostDrb(draftDrb, this.bundle_name));
      this.ref.close();
    } else {
      this.errorMessage = 'Please enter valid contributor email address. name@irobot.com';
    }
  }

  public close() {
    this.dialogService
      .open(NgxGenericModalComponent, {
        context: {
          title: 'Confirm Close',
          message:
            'Are you sure that you want to close the window? Any changes that you have made will be lost and you will be redirected to View section.'
        }
      })
      .onClose.subscribe((data) => {
        if (data) {
          this.router.navigate(['/drb/' + this.bundle_name  + '/view']);
          this.ref.close();
        }
      });
  }

  public trackByIndex(index: number, obj: any): any {
    return index;
  }
}
