import { Component, OnInit } from '@angular/core';
import { NbDialogService, NbDialogRef } from '@nebular/theme';
import { CreateScriptedNavComponent } from './create-scripted-nav/create-scripted-nav/create-scripted-nav.component';

@Component({
  selector: 'app-scripted-nav',
  templateUrl: './scripted-nav.component.html',
  styleUrls: ['./scripted-nav.component.css']
})
export class ScriptedNavComponent implements OnInit {
  public ref: NbDialogRef<ScriptedNavComponent>;
  constructor(private dialogService: NbDialogService) {}

  public ngOnInit(): void {
    this.dialogService.open(CreateScriptedNavComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        title: 'New'
      }
    });
  }
}
