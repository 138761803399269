/* eslint-disable @typescript-eslint/dot-notation */
import { Component, OnInit } from '@angular/core';
import { RouterState } from 'src/app/shared/redux/router-state';
import { Actions, ofActionCompleted, Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DrbService } from 'src/app/core/services/drb.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { SpinnerState } from 'src/app/shared/spinner/redux/spinner.state';
import { DrbState, LoadDrb, LoadDrbSuccess } from '../redux';
import { NbDialogService } from '@nebular/theme';
import { ManageContributorsComponent } from './manage-contributors/manage-contributors.component';
import { NewDrbComponent } from '../new-drb/new-drb.component';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-drb-sources',
  templateUrl: './drb-sources.component.html',
  styleUrls: ['./drb-sources.component.css']
})
export class DrbSourcesComponent implements OnInit {
  @Select(DrbState.drb) public data$: Observable<any[]>;
  @Select(DrbState.xliff) public xliffState$: Observable<any[]>;
  @Select(DrbState.sourceFiles) public sourceFilesState$: Observable<any[]>;
  @Select(DrbState.sourceImages) public sourceImagesState$: Observable<any[]>;
  @Select(DrbState.sourceLinks) public sourceLinksState$: Observable<any[]>;
  @Select(DrbState.sourceVideos) public sourceVideosState$: Observable<any[]>;
  @Select(DrbState.versions) public versions$: Observable<any[]>;
  @Select(RouterState.username) public data_username$: Observable<any>;
  @Select(SpinnerState) public loading$: Observable<boolean>;
  @Select(DrbState.deploying) public deploying$: Observable<any[]>;
  @Select(DrbState.noDrbDraft) public noDrbDraft$: Observable<any[]>;

  public spin;
  public displayVersionButton;
  public mode;
  public metaData;
  public resourceData;
  public xliff;
  public sourceFiles;
  public sourceImages;
  public sourceLinks;
  public sourceVideos;
  public username;
  public versions: any[];
  public versionSelected;
  public ownerOrContributor;
  public deployInProgres;
  public environment: string = environment.stage;
  public bundle_name: string;
  public old_bundle_name: string;

  public sourceTabs: any[] = [
    {
      title: 'Strings',
      route: './strings'
    },
    {
      title: 'Files',
      route: './files'
    },
    {
      title: 'Images',
      route: './images'
    },
    {
      title: 'Links',
      route: './links'
    },
    {
      title: 'Videos',
      route: './videos'
    }
  ];

  constructor(
    private router: Router,
    private service: DrbService,
    private route: ActivatedRoute,
    private store: Store,
    private actions$: Actions,
    public utility: UtilityService,
    private dialogService: NbDialogService
  ) {}


  public ngOnInit() {
    this.detectRouteChanges();
    this.mode = 'create';
    this.route.url.subscribe((event) => this.bundle_name = event[1].path);
    this.old_bundle_name = this.bundle_name;
    this.store.dispatch(new LoadDrb(this.bundle_name));
    this.data$.subscribe((data) => {
      if (!Array.isArray(data)) {
        this.set_metadata(data);
        this.ownerOrContributor = this.checkOwnerOrContributor();
        this.routeToPage();
      }

    });
    this.noDrbDraft$.subscribe((data) => {

      if (data) {
        this.metaData = null;
        this.dialogService.open(NewDrbComponent, {
          closeOnEsc: false,
          closeOnBackdropClick: false,
          context: {
            bundle_name: this.bundle_name
          }
        });
      }
    });
    this.xliffState$.subscribe((data) => {
      this.xliff = data;
    });
    this.sourceFilesState$.subscribe((data) => {
      this.sourceFiles = data;
    });
    this.sourceImagesState$.subscribe((data) => {
      this.sourceImages = data;
    });
    this.sourceLinksState$.subscribe((data) => {
      this.sourceLinks = data;
    });

    this.sourceVideosState$.subscribe((data) => {
      this.sourceVideos = data;
    });
    this.data_username$.subscribe((data) => (this.username = data));
    this.versions$.subscribe((data) => {
      this.versions = data;
    });
    this.loading$.subscribe((data) => (this.spin = data));

    this.deploying$.subscribe((data) => (this.deployInProgres = data));

    this.displayVersionButton = false;

    this.actions$.pipe(ofActionCompleted(LoadDrbSuccess)).subscribe((data) => {
      if (data) {
        this.ownerOrContributor = this.checkOwnerOrContributor();
      }
    });

  }

  public manageContributors(): void {
    this.dialogService.open(ManageContributorsComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        contributors: JSON.parse(JSON.stringify(this.metaData['contributors'])),
        username: this.username,
        bundle_name: this.bundle_name
      }
    });
  }

  public checkOwnerOrContributor() {
    if (this.username === this.metaData.owner || this.metaData.contributors.includes(this.username)) {
      return true;
    }
  }

  public reload() {
    this.store.dispatch(new LoadDrb(this.bundle_name));
  }

  public set_metadata(data) {
    this.metaData = {
      version: data['version'],
      created_by: data['created_by'],
      created_ts: data['created_ts'],
      status: data['status'],
      last_updated_by: data['last_updated_by'],
      last_updated_ts: data['last_updated_ts'],
      owner: data['owner'],
      contributors: data['contributors'],
      draft: data['draft'],
      deployed_to_stage: data['deployed_to_stage'],
      deployed_to_stage_ts: data['deployed_to_stage_ts'],
      deployed_to_prod: data['deployed_to_prod'],
      deployed_to_prod_ts: data['deployed_to_prod_ts']
    };
  }

  public displayContributors() {
    const contributors = JSON.parse(JSON.stringify(this.metaData['contributors'])).filter(
      (element) => element.length > 0
    );

    return contributors.join(', ');
  }

  public routeToPage() {
    if (this.router.url.includes('drb/' + this.bundle_name  + '/create')) {
      if (this.router.url.endsWith('files')) {
        this.router.navigate(['/drb/' + this.bundle_name  + '/create/files']);
      } else if (this.router.url.endsWith('images')) {
        this.router.navigate(['/drb/' + this.bundle_name  + '/create/images']);
      } else if (this.router.url.endsWith('links')) {
        this.router.navigate(['/drb/' + this.bundle_name  + '/create/links']);
      } else if (this.router.url.endsWith('videos')) {
        this.router.navigate(['/drb/' + this.bundle_name  + '/create/videos']);
      } else {
        this.router.navigate(['/drb/' + this.bundle_name  + '/create/strings']);
      }
    }
  }

  private detectRouteChanges(): void {
    this.router.events.subscribe((event) => {
      if(this.old_bundle_name !== this.bundle_name &&  event instanceof NavigationEnd) {
        this.old_bundle_name = this.bundle_name;
        this.store.dispatch(new LoadDrb(this.bundle_name));
      }
    });
  }
}
