import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replacedashes'
})
export class ReplaceDashesPipe implements PipeTransform {

    public transform(value: any, args?: any): any {

        const result = value.replace(/-/g, ' ');

        return result;
    }

}
