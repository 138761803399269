import { Component, Input } from '@angular/core';
import { UtilityService } from 'src/app/core/services/utility.service';

@Component({
  selector: 'app-xliff-added',
  templateUrl: './xliff-added.component.html',
  styleUrls: ['../drb-diff.component.css']
})
export class XliffAddedComponent {
  @Input() public item;

  constructor(public utility: UtilityService) {}
}
