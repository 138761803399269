import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CleanScoreStateModel } from './clean-score-state.model';
import { Injectable } from '@angular/core';
import { ToggleShow, ToggleHide } from 'src/app/shared/spinner/redux/spinner.actions';
import { NbToastrService } from '@nebular/theme';
import { LoadSmartCleanRecords, LoadSmartCleanRecordsFail, LoadSmartCleanRecordsSuccess, PatchCleanScore, PatchCleanScoreFail, PatchCleanScoreSuccess, RemoveCleanScore } from './clean-score.actions';
import { SmartCleanService } from 'src/app/core/services/smart-clean.service';

@State<CleanScoreStateModel>({
  name: 'smartCleanRecord',
  defaults: {
    smartCleanRecords: [],
    error: null
  }
})
@Injectable()
export class CleanScoreState {

  constructor(private service: SmartCleanService,
              private toastrService: NbToastrService) {}

  @Selector()
  public static smartCleanRecords(state: CleanScoreStateModel) {
    return state.smartCleanRecords;
  }

  @Action(LoadSmartCleanRecords)
  public async loadSmartCleanRecords(ctx: StateContext<CleanScoreStateModel>, { payload }: any) {
    ctx.dispatch(ToggleShow);
    this.service.loadSmartCleanRecordsAmplify(payload);
  }

  @Action(LoadSmartCleanRecordsSuccess)
  public loadSmartCleanRecordsSuccess(ctx: StateContext<CleanScoreStateModel>, { payload }: any) {
    const state = ctx.getState();
    const newCleanRecordsState = state.smartCleanRecords.concat(payload);

    ctx.setState({
      ...state,
      smartCleanRecords: newCleanRecordsState
    });
    ctx.dispatch(ToggleHide);
  }

  @Action(LoadSmartCleanRecordsFail)
  public loadSmartCleanRecordsFail(ctx: StateContext<CleanScoreStateModel>, { payload }: any) {
    this.toastrService.show(status, 'Fetching Smart Clean Record Failed: ' + payload, {
      status: 'danger'
    });
    ctx.dispatch(ToggleHide);
  }


  @Action(PatchCleanScore)
  public async patchCleanScore(ctx: StateContext<CleanScoreStateModel>, { payload }: any) {
    ctx.dispatch(ToggleShow);
    this.service.patchCleanScoreAmplify(payload);
  }

  @Action(PatchCleanScoreSuccess)
  public async patchCleanScoreSuccess(ctx: StateContext<CleanScoreStateModel>, { payload }: any) {

    this.toastrService.show(status, 'Update: Complete', { status: 'success', preventDuplicates: true });
    ctx.dispatch(ToggleHide);
  }

  @Action(PatchCleanScoreFail)
  public async PatchCleanScoreFail(ctx: StateContext<CleanScoreStateModel>, { payload }: any) {

    this.toastrService.show(status, 'Patching Clean Score Record Failed: ' + payload, {
      status: 'danger'
    });
    ctx.dispatch(ToggleHide);
}

  @Action(RemoveCleanScore)
  public async removeCleanScores(ctx: StateContext<CleanScoreStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      smartCleanRecords: []
    });
    ctx.dispatch(ToggleHide);
  }
}
